import * as React from 'react'
import { Control } from 'react-hook-form'
import { FieldPath } from 'react-hook-form/dist/types'
import { IFilterFormState, IFilter } from './filter.types'
import { FormDatePicker } from '../../common/utils/form/form.components'
import { IXtAutocompleteOption } from '../xtAutocomplete/XtAutocomplete.types'

export function makeDateFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>
): JSX.Element {
  if (filter.options) {
    throw new Error('Date filter cannot have options')
  }

  return (
    <FormDatePicker
      label={filter.label}
      key={`filter-${filter.fieldName}-controller`}
      control={control}
      name={filter.fieldName as FieldPath<IFilterFormState>}
    />
  )
}
