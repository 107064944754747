import { IPhone } from 'contacts/contacts.types'
import { IPhoneTableItem } from './phone-list.types'

export const normalizeData = (data: IPhone[]): IPhoneTableItem[] =>
  data.map((item, index) => ({
    ...item,
    id: index + 1,
  }))
export const normalizeDataPhonesNoId = (data: IPhoneTableItem[]): IPhone[] =>
  (data ?? []).map(({ id: _, ...other }) => ({
    ...other,
  }))
