export interface IRouting {
  item_number: string
  item_description: string
  document_number: string
  lead_time: number
  revision_number: string
  revision_date: string
  revision_status: string
  final_location_site: string
  final_location: string
  close_workorder: boolean
  routing_items: IRoutingItem[]
}

export interface IRoutingNew extends IRouting {
  isNew?: boolean
}

export interface IRoutingItem {
  sequence_number: number
  work_center: string
  standard_operation: string | null
  operation_type: string
  routing_description1: string
  routing_description2: string
  effective: string
  expires: string
  execution_day: number
  tooling_reference: string
  setup_time: number
  report_setup_as: string
  report_setup: boolean
  run_time: number | null
  run_qty_per: number | null
  report_runtime_as: string
  report_runtime: boolean
  production_uom: string
  inventory_uom: string
  production_inventory_ratio: number
  issue_components: boolean
  receive_inventory: boolean
  allow_overlap: boolean
  instructions: string
  wip_location_site: string | null
  wip_location: string | null
}

export enum RoutingItemMode {
  Edit = 'edit',
  New = 'new',
  View = 'view',
}
export enum RoutingDetailsMode {
  View = 'view',
  Edit = 'edit',
}
