import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { ShipmentFormField } from './shipment-dialog.types'

export const ShipmentDialogFormSchema = yup.object().shape({
  [ShipmentFormField.Number]: yup.string().required(validationMessage.isRequired),
  [ShipmentFormField.Name]: yup.string().required(validationMessage.isRequired),
  [ShipmentFormField.ShippingCharges]: yup.mixed().required(validationMessage.isRequired),
  [ShipmentFormField.TaxZone]: yup.mixed().required(validationMessage.isRequired),
  [ShipmentFormField.ShipZone]: yup.mixed().required(validationMessage.isRequired),
  [ShipmentFormField.SalesRep]: yup.mixed().required(validationMessage.isRequired),
  [ShipmentFormField.ShippingForm]: yup.mixed().required(validationMessage.isRequired),
  [ShipmentFormField.Site]: yup.mixed().required(validationMessage.isRequired),
})
