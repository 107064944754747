import { parseDate } from 'common/utils/date.utils'
import { BomDetailFormInput, BomDetailsFormField, BomFormData } from './BomDetails.types'
import { globalConstants } from '../../common/constants'
import { BomItemMode, BomItemSubmitFunc } from '../bomItem/BomItem.types'
import { BomItemService } from '../services/BomItemService'
import { IBomItem } from '../bom.types'
import { bomDetailsActionsEditMode, bomDetailsActionsViewMode, bomNewActionsNewBom } from './BomDetails.constants'
import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
// TODO remove me
export const defineFormData = (data: BomDetailFormInput | null): BomFormData => ({
  [BomDetailsFormField.Revision]: data?.revision ?? '',
  [BomDetailsFormField.RevisionDate]: data?.revision_date ? parseDate(data.revision_date, globalConstants.serverDateFormat) : new Date(),
  [BomDetailsFormField.DocumentNumber]: data?.document_number ?? '',
  [BomDetailsFormField.BatchSize]: data?.batch_size ?? '',
  [BomDetailsFormField.ItemNumber]: null,
})

// eslint-disable-next-line consistent-return
export const submitBomItemDialog: BomItemSubmitFunc = async (parentItemNumber, bomItem, newItem, dialogMode) => {
  if ((dialogMode === BomItemMode.Edit || dialogMode === BomItemMode.Search) && !bomItem) {
    return BomItemService.create(parentItemNumber, newItem)
  }
  if (!bomItem) {
    throw new Error(`The action ${dialogMode} cannot be done - initial bom item is undefined.`)
  }
  if (dialogMode === BomItemMode.Edit || dialogMode === BomItemMode.Search) {
    return BomItemService.update(parentItemNumber, { id: bomItem.id, ...newItem })
  }
  if (dialogMode === BomItemMode.Replace) {
    return BomItemService.replace(parentItemNumber, bomItem.sequence_number, newItem)
  }
}

export const findBomItem: (itemId: number, items: IBomItem[]) => IBomItem | undefined = (itemId, items) =>
  items.find(({ id }) => id === itemId)

export function defineAvailableActions(newBom: boolean, isViewMode: boolean, isMobile: boolean): IXtTableCellAction[] {
  if (isMobile) {
    return []
  }
  if (newBom) {
    return bomNewActionsNewBom
  }
  if (isViewMode) {
    return bomDetailsActionsViewMode
  }
  return bomDetailsActionsEditMode
}
