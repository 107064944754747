import { IXtTableCellAction } from '../../../components/xtTableCellActions/XtTableCellActions'
import { ISalesOrderLineItemDialog, SalesOrderLineItem, SalesOrderLineItemAction } from './sales-order-line-items.types'
import { SvgIconIds } from '../../../components/svgIcon/SvgIcon.types'
import { IXtTableColumn } from '../../../components/table/table-head/table-head.types'
import { SalesOrderItem } from '../../sales-orders.types'
import { dateConverter } from '../../../common/utils/date.utils'

export const defaultItemDialogState: ISalesOrderLineItemDialog<SalesOrderItem> = {
  item: null,
  open: false,
  viewMode: true,
}

export const SalesOrderLineItemsViewActions: IXtTableCellAction[] = [
  {
    name: SalesOrderLineItemAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const SalesOrderLineItemsNewActions: IXtTableCellAction[] = [
  {
    name: SalesOrderLineItemAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: SalesOrderLineItemAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const SalesOrderLineItemsEditActions: IXtTableCellAction[] = [
  {
    name: SalesOrderLineItemAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: SalesOrderLineItemAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: SalesOrderLineItemAction.Cancel,
    label: 'Cancel',
    icon: SvgIconIds.DELETE,
  },
]

export const salesOrderLineItemsColumns: IXtTableColumn<SalesOrderLineItem>[] = [
  {
    id: 'line_number',
    field: 'line_number',
    headerName: '#',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Status ',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'sold_from_site',
    field: 'sold_from_site',
    headerName: 'Site',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'scheduled_date',
    field: 'scheduled_date',
    headerName: 'Scheduled Date',
    flex: '1 0 200px',
    width: 200,
    converter: dateConverter,
  },
  {
    id: 'qty_ordered',
    field: 'qty_ordered',
    headerName: 'Ordered',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'price_uom',
    field: 'price_uom',
    headerName: 'Selling UOM',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'qty_shipped',
    field: 'qty_shipped',
    headerName: 'Shipped',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'net_unit_price',
    field: 'net_unit_price',
    headerName: 'Price',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'extended',
    field: 'extended',
    headerName: 'Extended',
    flex: '1 0 160px',
    width: 160,
  },
]
