import * as Yup from 'yup'
import { NumberSchema, ObjectSchema, StringSchema } from 'yup'
import { maxNumber, validationMessage } from '../../../../common/validation/validation'
import { LineItemAdditionalSectionField } from './sales-order-line-items-additional-section.types'
import { IXtAutocompleteOption } from '../../../../components/xtAutocomplete/XtAutocomplete.types'

export const salesOrderMainValidationSchema = Yup.object().shape(
  {
    [LineItemAdditionalSectionField.MiscChargeSalesAccount]: Yup.object()
      .nullable()
      .when(LineItemAdditionalSectionField.MiscCharge, (miscCharge: number, schema: ObjectSchema) =>
        miscCharge ? schema.required(validationMessage.isRequired) : schema
      ),
    [LineItemAdditionalSectionField.ChargeTaxType]: Yup.object()
      .nullable()
      .when(
        [LineItemAdditionalSectionField.MiscCharge, LineItemAdditionalSectionField.MiscChargeSalesAccount],
        (miscCharge: number, miscChargeSalesAccount: string, schema: ObjectSchema) =>
          miscCharge || miscChargeSalesAccount ? schema.required(validationMessage.isRequired) : schema
      ),
    [LineItemAdditionalSectionField.FreightTaxType]: Yup.object()
      .nullable()
      .when(LineItemAdditionalSectionField.Freight, (freight: number, schema: StringSchema) =>
        freight ? schema.required(validationMessage.isRequired) : schema
      ),
    [LineItemAdditionalSectionField.MiscCharge]: Yup.number()
      .max(maxNumber, validationMessage.max(maxNumber))
      .typeError(validationMessage.invalidNumber)
      .when(
        LineItemAdditionalSectionField.MiscChargeSalesAccount,
        (miscChargeSalesAccount: IXtAutocompleteOption | null, schema: NumberSchema) =>
          miscChargeSalesAccount ? schema.moreThan(0, validationMessage.min(0, 2)) : schema
      ),
    [LineItemAdditionalSectionField.Freight]: Yup.number()
      .max(maxNumber, validationMessage.max(maxNumber))
      .typeError(validationMessage.invalidNumber),
    [LineItemAdditionalSectionField.Currency]: Yup.object().nullable().required(validationMessage.isRequired),
  },
  [[LineItemAdditionalSectionField.MiscCharge, LineItemAdditionalSectionField.MiscChargeSalesAccount]]
)
