import React, { FC, memo, useMemo } from 'react'

// import { XtRemarks } from 'components/xt-remarks/xt-remarks'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { TaskList } from 'components/tasks/task-list/task-list'
import { XtRemarks } from 'components/xt-remarks/xt-remarks'
import { XtCharacteristics } from 'characteristics/characteristics'
import { XtDocuments } from 'components/documents/documents'
import { ContactList } from 'contacts/contact-list/contact-list'
import { UsedOnValue } from 'documents/documents.types'
import { CRMProspectDetailsTabsName, ICRMProspectDetailsTabs } from './crm-prospect-details-tabs.types'
import { CRMProspectDetailsGeneralTab } from './crm-prospect-details-tabs.general'

export const CRMProspectDetailsTabs: FC<ICRMProspectDetailsTabs> = memo(
  ({
    disabled,
    isMobile,
    markAsDirtyCallback,
    characteristics,
    onCreateCharacteristic,
    onUpdateCharacteristic,
    onDeleteCharacteristic,
    remarks,
    prospectNumber,
    prospect,
    documents,
  }) => {
    const tabs = useMemo(
      () => [
        {
          name: CRMProspectDetailsTabsName.General,
          template: <CRMProspectDetailsGeneralTab markAsDirtyCallback={markAsDirtyCallback} disabled={disabled} isMobile={isMobile} />,
        },
        {
          name: CRMProspectDetailsTabsName.Contacts,
          template: prospect && (
            <ContactList account_name={prospect?.name} account_number={prospect?.number} isMobile={isMobile} disabled={disabled} />
          ),
        },
        {
          name: CRMProspectDetailsTabsName.Tasks,
          template: <TaskList prospect_number={prospectNumber ?? ''} disabled={disabled} />,
        },
        {
          name: CRMProspectDetailsTabsName.Remarks,
          template: (
            <XtRemarks
              canLoadMore={remarks.canLoadMore}
              loadMore={remarks.loadMore}
              total={remarks.total}
              onCommentsUpdate={remarks.updateComment}
              onCommentsSave={remarks.saveComment}
              comments={remarks.comments}
              username={remarks.username}
              textAreaOnChange={remarks.setNotes}
              textAreaValue={remarks.notes}
              textAreaName="Notes"
              disabled={disabled}
            />
          ),
        },
        {
          // TODO: implement quotes once API is ready
          name: CRMProspectDetailsTabsName.Quotes,
          template: <div>{CRMProspectDetailsTabsName.Quotes}</div>,
        },
        {
          name: CRMProspectDetailsTabsName.Characteristics,
          template: (
            <XtCharacteristics
              usedOnFilter={UsedOnValue.Prospect}
              onCreate={onCreateCharacteristic}
              characteristics={characteristics}
              onUpdate={onUpdateCharacteristic}
              onDelete={onDeleteCharacteristic}
              isViewMode={disabled}
            />
          ),
        },
        {
          name: CRMProspectDetailsTabsName.Documents,
          template: (
            <XtDocuments
              usedOnFilter={UsedOnValue.Customer}
              isViewMode={isMobile || !documents.creationEnabled}
              onDocumentCreate={documents.saveDocument}
              onDocumentDelete={documents.deleteDocument}
              loadMore={documents.loadMore}
              canLoadMore={documents.canLoadMore}
              documents={documents.documents}
            />
          ),
        },
      ],
      [
        disabled,
        isMobile,
        markAsDirtyCallback,
        characteristics,
        onCreateCharacteristic,
        onUpdateCharacteristic,
        onDeleteCharacteristic,
        prospectNumber,
        prospect,
        remarks,
        documents,
      ]
    )
    return <XtTabs tabs={tabs} />
  }
)
