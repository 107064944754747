export const productNavbarRoutes = {
  products: [
    {
      path: 'items/',
      name: 'Items',
    },
    {
      path: 'bom/',
      name: 'Bill of Materials',
    },
    {
      path: 'routing',
      name: 'Routing',
    },
  ],
}
