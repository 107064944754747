import * as React from 'react'
import { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormCheckboxLabel, FormField } from 'common/utils/form/form.components'
import { CRMProspectFormField, CRMProspectFormLabel, ICRMProspectForm } from '../crm-prospect-details.types'
import { ICRMProspectDetailsForm } from './crm-prospect-details-form.types'
import styles from '../crm-prospect-details.module.scss'

export const CRMProspectDetailsForm: FC<ICRMProspectDetailsForm> = memo(({ isViewMode, isNewMode }) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<ICRMProspectForm>()

  const disabled = isSubmitting || isViewMode

  return (
    <div className={styles.prospectDetailsMainContentForm}>
      <FormField
        control={control}
        disabled={disabled || !isNewMode}
        name={CRMProspectFormField.Number}
        label={CRMProspectFormLabel.Number}
      />

      <FormField control={control} disabled={disabled} name={CRMProspectFormField.Name} label={CRMProspectFormLabel.Name} />

      <FormCheckboxLabel control={control} label={CRMProspectFormLabel.Active} name={CRMProspectFormField.Active} disabled={disabled} />
    </div>
  )
})
