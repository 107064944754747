import { UsedOnValue } from 'documents/documents.types'

export type CharacteristicValue = Date | string | number | string[]

export interface IAppliedCharacteristic {
  id: number
  characteristic: string
  characteristic_value: CharacteristicValue
  characteristic_group: string | null
  default_characteristic: boolean
}

export interface IAppliedCharacteristicNew extends Omit<IAppliedCharacteristic, 'id'> {}
/**
 * This interface described a characteristic not applied to any object.
 *
 * Based on the API /characteristics/{characteristic}
 */
export interface ICharacteristic {
  name: string
  description: string
  characteristic_type: CharacteristicType
  characteristic_group: string | null
  mask: string | null
  characteristic_validator: string | null
  characteristic_order: number
  searchable: boolean
  unique_assignment: boolean
  list_options: ICharacteristicListOption[]
  used_on: UsedOnValue[]
}

// TODO: not specified in API, make sure the values are correct
export enum CharacteristicType {
  Text = 'text',
  List = 'list',
  Date = 'date',
  Number = 'number',
}

export enum AppliedCharacteristicAction {
  Delete = 'Delete',
  Edit = 'Edit',
}

export interface ICharacteristicListOption {
  option_value: string
  option_order: number
}

export interface IXTCharacteristics {
  characteristics: IAppliedCharacteristic[]
  usedOnFilter: UsedOnValue
  onCreate: (newCharacteristic: IAppliedCharacteristic) => Promise<void>
  onUpdate: (updatedCharacteristic: IAppliedCharacteristic) => Promise<void>
  onDelete: (deletedCharacteristic: IAppliedCharacteristic) => Promise<void>
  isViewMode: boolean
}

export enum CharacteristicDialogFormField {
  Characteristic = 'characteristic',
  Value = 'characteristic_value',
}
export interface ICharacteristicFormState {
  [CharacteristicDialogFormField.Characteristic]: ICharacteristic | null
  [CharacteristicDialogFormField.Value]: CharacteristicValue
}
export interface ICharacteristicDialogState {
  open: boolean
  editMode: boolean
  characteristic: ICharacteristicFormState | null
  characteristicId: number | null
}
export interface IDeletionDialog {
  open: boolean
  item: IAppliedCharacteristic | null
}
