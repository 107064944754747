import React, { FC, memo } from 'react'
import { Dialog } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/xtButton/XtButton'
import { FormDatePicker, FormXtAutocomplete } from 'common/utils/form/form.components'
import { loadCustomers, renderCustomerOption } from 'sales-orders/components/sales-order-main/sales-order-main.utils'
import styles from './copy-quote-dialog.module.scss'
import { CopyQuotesDialogFormSchema } from './copy-quote-dialog.validation'
import { ICopyQuotesDialog, IQuotesDialogFormData, QuotesDialogFormField, QuotesDialogFormLabel } from './copy-quote-dialog.types'
import { defineQuotesDialogFormValues } from './copy-quote-dialog.utils'
// TODO update form values
export const CopyQuoteDialog: FC<ICopyQuotesDialog> = memo(({ onClose, open, quoteData }) => {
  const { handleSubmit, control } = useForm<IQuotesDialogFormData>({
    defaultValues: defineQuotesDialogFormValues(quoteData),
    resolver: yupResolver(CopyQuotesDialogFormSchema),
    mode: 'onBlur',
  })

  const onSaveForm = (formData: IQuotesDialogFormData) => {
    // TODO onnConfirm
    console.log(formData)
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSaveForm)} className={styles.copyQuoteDialog}>
        <FormXtAutocomplete
          control={control}
          name={QuotesDialogFormField.CustomerName}
          label={QuotesDialogFormLabel.CustomerName}
          loadOptions={loadCustomers}
          renderOption={renderCustomerOption}
        />
        {/* TODO: update Name and Address value(integration api) */}
        <span>Name </span>
        <span>Address</span>
        <FormDatePicker name={QuotesDialogFormField.ScheduledDate} label={QuotesDialogFormLabel.ScheduledDate} control={control} />
        <div className={styles.copyQuoteDialogButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton type="submit" label="Save" />
        </div>
      </form>
    </Dialog>
  )
})
