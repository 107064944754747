import React, { FC, memo, useCallback, useEffect } from 'react'

import { FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { loadCountries } from 'sales-orders/components/sales-order-header-information/sales-order-bill-to-and-ship-to/sales-order-bill-to-and-ship-to-form/sales-order-bill-to-and-ship-to-form.utils'
import { DictionaryService } from 'services/dictionary.service'
import { useXtForm } from 'common/hooks/form/form'
import { ErrorHandler } from 'services/ErrorService'

import { CountryOption, getCountryStates } from 'common/utils/country-state.utils'
import { loadStates } from 'common/utils'
import { PhoneList } from './phone-list/phone-list'
import { normalizeDataPhonesNoId } from './phone-list/phone-list.utils'
import { ContactFormLabel } from '../../contact-dialog-form.types'
import styles from './address-and-phone.module.scss'
import { ContactFormValues } from '../../../contacts.types'
import { IPhoneTableItem } from './phone-list/phone-list.types'
import { convertAddressAndPhonesData, defineAddressData } from './address-and-phone.utils'
import { IAddressAndPhoneTab, IAddressAndPhoneValues } from './address-and-phone.types'

export const AddressAndPhoneTab: FC<IAddressAndPhoneTab> = memo(({ data, disabled, isMobile, onChange }) => {
  const { control, reset, watch, register, setValue, formChanges$ } = useXtForm<IAddressAndPhoneValues>({
    defaultValues: defineAddressData(null),
    mode: 'all',
  })
  const [phones, countrySelected] = watch([ContactFormValues.Phones, ContactFormValues.Country])
  const country = countrySelected as CountryOption

  const init = async () => {
    try {
      const countryStates = await getCountryStates(data?.address?.country, data?.address?.state)
      reset(defineAddressData(data, countryStates))
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  useEffect(() => void init(), [data])
  register(ContactFormValues.Phones)

  const handlePhonesChange: (items: IPhoneTableItem[]) => void = (items: IPhoneTableItem[]) => {
    const phonesNoId = normalizeDataPhonesNoId(items)

    setValue(ContactFormValues.Phones, phonesNoId, { shouldDirty: true, shouldValidate: true })
  }

  useEffect(() => {
    const obsSub = formChanges$.subscribe(({ state, data: value }) =>
      onChange({ state: convertAddressAndPhonesData(value), isDirty: state.isDirty, isValid: true })
    )
    return () => {
      obsSub.unsubscribe()
    }
  }, [formChanges$, onChange])

  const handleCountryChange = useCallback(
    async (option: CountryOption | null) => {
      if (!option?.id) {
        setValue(ContactFormValues.Country, null, {
          shouldValidate: true,
          shouldDirty: true,
        })
        setValue(ContactFormValues.State, '', { shouldValidate: true, shouldDirty: true })
        return
      }
      const statesData = await DictionaryService.getStates(option.id, { limit: 1, page: 1 })
      const hasStates = Boolean(statesData?.data?.length)
      setValue(ContactFormValues.Country, option ? { ...option, hasStates } : null, {
        shouldValidate: true,
        shouldDirty: true,
      })

      if (hasStates) {
        setValue(ContactFormValues.State, null, { shouldValidate: true, shouldDirty: true })
      }
      if (country?.hasStates && !hasStates) {
        setValue(ContactFormValues.State, '', { shouldValidate: true, shouldDirty: true })
      }
    },
    [country]
  )
  const loadStatesOptions = useCallback((page, limit) => loadStates(page, limit, country?.id), [country?.id])
  const disabledState = !country || disabled
  return (
    <form>
      <div className={styles.addressFields}>
        <FormField control={control} disabled={disabled} name={ContactFormValues.Address1} label={ContactFormLabel.Address1} />
        <FormField control={control} disabled={disabled} name={ContactFormValues.Address2} label={ContactFormLabel.Address2} />
        <FormField control={control} disabled={disabled} name={ContactFormValues.Address3} label={ContactFormLabel.Address3} />
        <FormField control={control} disabled={disabled} name={ContactFormValues.City} label={ContactFormLabel.City} />
        <FormXtAutocomplete
          disabled={disabled}
          label={ContactFormLabel.Country}
          control={control}
          onChange={handleCountryChange}
          name={ContactFormValues.Country}
          loadOptions={loadCountries}
        />
        <div className={styles.stateDetails}>
          {!country?.hasStates ? (
            <FormField disabled={disabledState} control={control} name={ContactFormValues.State} label={ContactFormLabel.State} />
          ) : (
            <FormXtAutocomplete
              disabled={disabledState}
              label={ContactFormLabel.State}
              control={control}
              name={ContactFormValues.State}
              loadOptions={loadStatesOptions}
            />
          )}
          <FormField control={control} disabled={disabled} name={ContactFormValues.Postalcode} label={ContactFormLabel.Postalcode} />
        </div>
        <FormField
          type="number"
          control={control}
          disabled={disabled}
          name={ContactFormValues.Latitude}
          label={ContactFormLabel.Latitude}
        />
        <FormField
          type="number"
          control={control}
          disabled={disabled}
          name={ContactFormValues.Longitude}
          label={ContactFormLabel.Longitude}
        />
        <FormField
          type="number"
          control={control}
          disabled={disabled}
          name={ContactFormValues.Accuracy}
          label={ContactFormLabel.Accuracy}
        />
      </div>
      <PhoneList isMobile={isMobile} disabled={disabled} onChange={handlePhonesChange} data={phones} />
    </form>
  )
})
