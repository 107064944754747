export enum SvgIconIds {
  ADD_CIRCLE = 'addCircle',
  CREATE = 'create',
  DELETE = 'delete',
  PRINT = 'print',
  PRODUCTS = 'products',
  VISIBILITY = 'visibility',
  COUNTER = 'counter',
  COMPARE_ARROW = 'compareArrow',
  EXPIRE = 'expire',
  EXPAND_MORE = 'expandMore',
  MARK_AS_COMPLETED = 'markAsCompleted',
  ARROW = 'arrow',
  CLOSE_ICON = 'close-icon',
  FILTER = 'filter',
  ADD_FILE = 'addFile',
  ATTACH = 'attach',
  DOWNLOAD = 'download',
  CONVERT = 'convert',
}

export interface ISvgIconProps {
  iconId: SvgIconIds
  className?: string
  hidden?: boolean
}
