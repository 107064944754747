import * as React from 'react'
import { Control } from 'react-hook-form'
import { FormField } from '../../common/utils/form/form.components'
import { IFilterFormState, IFilter } from './filter.types'
import { IXtAutocompleteOption } from '../xtAutocomplete/XtAutocomplete.types'

export function makeTextFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>
): JSX.Element {
  if (filter.options) {
    throw new Error('Text filter cannot have options')
  }

  return <FormField label={filter.label} key={`filter-${filter.fieldName}-controller`} control={control} name={filter.fieldName} />
}
