import * as React from 'react'
import { FC, memo, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  CheckboxLabel,
  FormDatePicker,
  FormField,
  FormSelectField,
  FormXtAutocomplete,
  renderOptions,
} from 'common/utils/form/form.components'
import { loadDocumentPriorityOptions } from 'common/utils/document.utils'
import { loadUserOptions } from 'common/utils'
import { ITaskDetailsForm } from './task-details-form.types'
import styles from './task-details-form.module.scss'
import { PERCENT_MAX, PERCENT_MIN, StageTaskFormOptions, statusOptions } from './task-details-form.constants'
import { ITaskForm, MarkTaskAsComplete, TaskFormField, TaskFormLabel } from '../task-details.types'

export const TaskDetailsForm: FC<ITaskDetailsForm> = memo(({ isViewMode, initialData, isCompletedTask, setCompletedTask }) => {
  const {
    control,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useFormContext<ITaskForm>()

  const [startedDate, completedDate, percent] = watch([TaskFormField.StartedDate, TaskFormField.CompletedDate, TaskFormField.Percent])
  const disabled = isSubmitting || isViewMode

  const setValuesFormData: (data: Partial<ITaskForm>) => void = (data) => {
    Object.entries(data).forEach(([key, value]) => setValue(key as keyof ITaskForm, value!, { shouldDirty: true, shouldValidate: true }))
  }

  const isPercentCompleted = Number(percent) === PERCENT_MAX

  const markTaskAsComplete = (): MarkTaskAsComplete => {
    setCompletedTask(false)
    return {
      [TaskFormField.CompletedDate]: null,
      [TaskFormField.Percent]: isPercentCompleted ? 0 : percent,
    }
  }

  const onChangeStatus: (value: StageTaskFormOptions) => void = (value) => {
    switch (value) {
      case StageTaskFormOptions.InProces: {
        if (!initialData?.actual_start_date || completedDate || isPercentCompleted) {
          setCompletedTask(false)
          setValuesFormData({
            [TaskFormField.StartedDate]: new Date(),
            [TaskFormField.CompletedDate]: null,
            [TaskFormField.Status]: value,
            [TaskFormField.Percent]: isPercentCompleted ? 0 : percent,
          })
          return
        }
        setValue(TaskFormField.Status, value)
        return
      }
      case StageTaskFormOptions.Completed: {
        if (!initialData?.completed_date || !isCompletedTask) {
          setCompletedTask(true)
          setValuesFormData({
            [TaskFormField.StartedDate]: !startedDate ? new Date() : startedDate,
            [TaskFormField.CompletedDate]: new Date(),
            [TaskFormField.Status]: value,
            [TaskFormField.Percent]: PERCENT_MAX,
          })
          return
        }
        setValue(TaskFormField.Status, value)
        return
      }
      case StageTaskFormOptions.Deferred: {
        if (!initialData?.actual_start_date || completedDate || isPercentCompleted) {
          setValuesFormData({
            [TaskFormField.StartedDate]: !startedDate ? new Date() : startedDate,
            ...markTaskAsComplete(),
          })
        }
        setValue(TaskFormField.Status, value)
        return
      }
      default: {
        setValuesFormData({
          [TaskFormField.Status]: value,
          ...markTaskAsComplete(),
        })
      }
    }
  }

  const onCompletedChange = (checked: boolean): void => {
    setCompletedTask(checked)
    if (checked) {
      onChangeStatus(StageTaskFormOptions.Completed)
    } else {
      setValuesFormData({
        [TaskFormField.Status]: initialData?.status ?? '',
        [TaskFormField.CompletedDate]: null,
        [TaskFormField.Percent]: initialData?.percent_complete ?? PERCENT_MIN,
      })
    }
  }

  useEffect(() => {
    if ((isPercentCompleted && !isCompletedTask) || (completedDate && !isCompletedTask)) {
      onChangeStatus(StageTaskFormOptions.Completed)
    }
  }, [percent, completedDate, isCompletedTask])

  return (
    <div className={styles.mainContentForm}>
      <FormField control={control} disabled={disabled} name={TaskFormField.Name} label={TaskFormLabel.Name} />

      <FormField control={control} disabled={disabled} name={TaskFormField.Description} label={TaskFormLabel.Description} />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={TaskFormField.Priority}
        label={TaskFormLabel.Priority}
        loadOptions={loadDocumentPriorityOptions}
      />

      <FormSelectField
        control={control}
        disabled={disabled}
        name={TaskFormField.Status}
        label={TaskFormLabel.Status}
        onChange={onChangeStatus}
        options={statusOptions.map(renderOptions)}
      />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={TaskFormField.Owner}
        label={TaskFormLabel.Owner}
        loadOptions={loadUserOptions}
      />
      <FormDatePicker name={TaskFormField.PlannedStartDate} label={TaskFormLabel.PlannedStartDate} control={control} disabled={disabled} />
      <FormDatePicker name={TaskFormField.DueDate} label={TaskFormLabel.DueDate} control={control} disabled={disabled} />
      <FormDatePicker name={TaskFormField.StartedDate} label={TaskFormLabel.StartedDate} control={control} disabled={disabled} />
      <FormDatePicker name={TaskFormField.CompletedDate} label={TaskFormLabel.CompletedDate} control={control} disabled={disabled} />

      <CheckboxLabel
        disabled={disabled}
        className="MuiFormControlLabel"
        value={isCompletedTask}
        label={TaskFormLabel.Completed}
        onChange={onCompletedChange}
      />
      <FormField
        inputProps={{ min: PERCENT_MIN, max: PERCENT_MAX, type: 'number' }}
        control={control}
        disabled={disabled || isCompletedTask}
        name={TaskFormField.Percent}
        label={TaskFormLabel.Percent}
      />
    </div>
  )
})
