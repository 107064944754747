import { FC, memo, useState, useMemo } from 'react'
import { Fade, Modal, TextField } from '@material-ui/core'
import * as React from 'react'
import { XtTable } from 'components/table/table'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import {
  ISalesOrderLineItemPriceListItem,
  ISalesOrderLineItemPriceListParams,
  ISalesOrderLineItemPriceListState,
} from './sales-order-line-item-price-list.types'
import { globalConstants } from '../../../../../common/constants'
import * as styles from './sales-order-line-item-price-list.module.scss'
import { XtButton } from '../../../../../components/xtButton/XtButton'
import { salesOrderLineItemPriceListColumns } from './sales-order-line-item-price-list.constants'
import { defineItemsPricesWithId, defaultPriceListState } from './sales-order-line-item-price-list.utils'

export const SalesOrderLineItemPriceList: FC<ISalesOrderLineItemPriceListParams> = memo(
  ({ open, itemNumber, site, onClose, onSelect, currency, priceList }) => {
    const [state, setState] = useState<ISalesOrderLineItemPriceListState>(defaultPriceListState)

    const onCancel: VoidFunction = () => {
      onClose()
      setState(defaultPriceListState)
    }

    const rows = useMemo<ISalesOrderLineItemPriceListItem[]>(() => defineItemsPricesWithId(priceList.pricingItems, state.selected?.id), [
      priceList.pricingItems,
      state.selected?.id,
    ])

    const cols = useMemo<IXtTableColumn<ISalesOrderLineItemPriceListItem>[]>(() => salesOrderLineItemPriceListColumns(currency), [currency])

    const onItemSelect: VoidFunction = () => {
      if (state.selected) {
        onSelect(state.selected)
        onCancel()
      }
    }

    const selectItem: (item: ISalesOrderLineItemPriceListItem) => void = (item) => {
      setState((prevState) => ({ ...prevState, selected: item }))
    }

    return (
      <Modal open={open}>
        <Fade timeout={globalConstants.dialogAnimationFadeTime} in={open}>
          <div className={styles.salesOrderLineItemPriceList}>
            <div className={styles.salesOrderLineItemPriceListHeader}>
              <h3 className="xt-page-title" title={itemNumber ?? ''}>
                {itemNumber ?? ''}
              </h3>
              <TextField disabled className="MuiFormField" value={site} label="Site" variant="outlined" />
              <span>{`List Price: ${priceList.listPrice ?? '0.00'}`}</span>
              <span>{`Wholesale Price: ${priceList.wholesalePrice ?? '0.00'}`}</span>
              <span>{`Unit Cost: ${priceList.unitCost ?? '0.00'}`}</span>
            </div>
            <XtTable loading={priceList.loading} onRowClick={selectItem} columns={cols} rows={rows} />
            <div className={styles.salesOrderLineItemPriceListFooter}>
              <XtButton label="Cancel" onClick={onCancel} />
              <XtButton label="Select" disabled={!state.selected} onClick={onItemSelect} />
            </div>
          </div>
        </Fade>
      </Modal>
    )
  }
)
