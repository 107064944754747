import * as React from 'react'
import { CircularProgress, TableCell, TableHead, TableRow } from '@material-ui/core'

import { IXtTableHeadType } from './table-head.types'
import * as styles from '../table-loading-spinner.module.scss'
import * as generalStyles from '../table.module.scss'
import { cls } from '../../../common/utils'
import { definePositionStyles } from '../table.utils'

const tableHeadClasses = { root: generalStyles.MuiTableHeadRoot }
const tableCellClasses = { root: cls(generalStyles.MuiTableCell, generalStyles.MuiTableCellHead) }
const tableRowClasses = { root: generalStyles.MuiTableRow }

export const XtTableHead: IXtTableHeadType = ({ columns, loading }) => (
  <TableHead component="div" classes={tableHeadClasses}>
    <TableRow component="div" classes={tableRowClasses}>
      {loading && <CircularProgress size={styles.spinnerSize} className={styles.loadingSpinner} />}
      {columns.map(({ id, align, width, flex, headerName, sticky }) => (
        <TableCell
          classes={tableCellClasses}
          title={headerName}
          component="div"
          key={id}
          align={align}
          style={{ width, flex, ...definePositionStyles(sticky) }}
        >
          {headerName}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
)
