import {
  CurrencyOption,
  ILineItemAdditionalSectionForm,
  LineItemAdditionalSectionData,
  LineItemAdditionalSectionField,
  LineItemAdditionalSectionInput,
} from './sales-order-line-items-additional-section.types'
import { IPaginationData } from '../../../../common/common.types'
import { GlAccountsService } from '../../../../services/gl-accounts.service'
import { IXtAutocompleteOption } from '../../../../components/xtAutocomplete/XtAutocomplete.types'
import { DocumentsService } from '../../../../documents/documents.service'
import { DocumentType } from '../../../../documents/documents.types'
import { NumberTypeUtils } from '../../../../common/typeUtils'
import { DictionaryService } from '../../../../services/dictionary.service'

const defaultCurrency = 'USD'
function convertToCurrencyOption(currency?: string, symbol?: string): CurrencyOption | null {
  if (!currency) {
    return {
      id: defaultCurrency,
      label: defaultCurrency,
      currency: defaultCurrency,
      iso_code: defaultCurrency,
      rates: [],
      symbol: '$',
      conversion_basis: '',
      base_currency: false,
    }
  }
  return currency && symbol
    ? {
        id: currency,
        label: currency,
        currency,
        iso_code: currency,
        rates: [],
        symbol,
        conversion_basis: '',
        base_currency: false,
      }
    : null
}

export function defineFormData(data: LineItemAdditionalSectionInput | null): ILineItemAdditionalSectionForm {
  const tax = data?.tax_total ?? 0
  const subtotal = data?.subtotal ?? 0
  const miscCharge = data?.misc_charge_amount ?? 0
  const freight = data?.freight ?? 0
  const total = subtotal + tax + miscCharge + freight
  const authorizedCC = data?.authorized_cc ?? 0
  const allocatedMemo = data?.allocated_memo ?? 0
  const balance = total - allocatedMemo - authorizedCC

  return {
    [LineItemAdditionalSectionField.RequireSufficientInventory]: false, // TODO update me
    [LineItemAdditionalSectionField.Currency]: convertToCurrencyOption(data?.currency, data?.currency_symbol),
    [LineItemAdditionalSectionField.MiscChargeDescription]: data?.misc_charge_description ?? '',
    [LineItemAdditionalSectionField.MiscChargeSalesAccount]: data?.misc_charge_account_number
      ? { id: data.misc_charge_account_number, label: data.misc_charge_account_number }
      : null,
    [LineItemAdditionalSectionField.ChargeTaxType]: data?.misc_charge_tax_type
      ? { id: data.misc_charge_tax_type, label: data.misc_charge_tax_type }
      : null,
    [LineItemAdditionalSectionField.MiscChargeDiscount]: data?.misc_charge_is_discount ?? false,
    [LineItemAdditionalSectionField.FreightWeight]: data?.freight_weight ?? 0,
    [LineItemAdditionalSectionField.FreightTaxType]: data?.freight_tax_type
      ? { id: data.freight_tax_type, label: data.freight_tax_type }
      : null,
    [LineItemAdditionalSectionField.AllocatedCreditMemoOrInvoice]: allocatedMemo,
    [LineItemAdditionalSectionField.OutstandingCredit]: data?.outstanding_memo ?? 0,
    [LineItemAdditionalSectionField.AuthorizedCCPayments]: authorizedCC,
    [LineItemAdditionalSectionField.AtShipping]: data?.at_shipping ?? 0,
    [LineItemAdditionalSectionField.Subtotal]: subtotal,
    [LineItemAdditionalSectionField.MiscCharge]: miscCharge,
    [LineItemAdditionalSectionField.Freight]: freight,
    [LineItemAdditionalSectionField.Tax]: tax,
    [LineItemAdditionalSectionField.Total]: total,
    [LineItemAdditionalSectionField.Balance]: balance,
    [LineItemAdditionalSectionField.Margin]: data?.ordermargin ?? 0,
    [LineItemAdditionalSectionField.MarginPercent]: (data?.ordermarginpercent ?? 0).toFixed(2),
  }
}

export function convertFormData(data: ILineItemAdditionalSectionForm): LineItemAdditionalSectionData {
  return {
    tax_total: data.tax_total,
    freight: data.freight,
    misc_charge_account_number: data.misc_charge_account_number?.id ?? null,
    misc_charge_amount: data.misc_charge_amount,
    misc_charge_description: data.misc_charge_description,
    misc_charge_tax_type: data.misc_charge_tax_type?.id ?? '',
    misc_charge_is_discount: data.misc_charge_is_discount,
    ordermargin: data.ordermargin,
    ordermarginpercent: NumberTypeUtils.parseFloatString(data.ordermarginpercent) / 100,
    freight_tax_type: data.freight_tax_type?.id ?? '',
    currency: data.currency?.id ?? '',
    currency_symbol: data.currency?.symbol ?? '',
  }
}

export async function loadGlAccounts(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  // TODO update globalConstants.paginationLimit
  const { data, total } = await GlAccountsService.getAll({ page, limit })
  const supportedTypes = ['Revenue', 'Expense']
  return {
    data: data
      .filter(({ type }) => supportedTypes.includes(type))
      .map(({ glaccount, description }) => ({ id: glaccount, label: description })),
    total,
  }
}

export async function loadTaxTypes(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.TaxType, { page, limit })
  return {
    data: data.map(({ number, name }) => ({ id: number, label: name })),
    total,
  }
}

export async function loadCurrencies(page: number, limit: number, currencyCode?: string): Promise<IPaginationData<CurrencyOption>> {
  const { data, total } = await DictionaryService.getCurrencies({ page, limit }, { currency: currencyCode })
  return {
    data: data.map((currency) => ({ ...currency, id: currency.iso_code, label: currency.iso_code })),
    total,
  }
}
