import { IRemarksHook } from 'common/hooks/remarks'
import { IDocumentsHook } from 'common/hooks/documents'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { ICRMProspect } from 'crm-prospects/crm-prospects.types'
import { CRMProspectDetailsMode } from '../crm-prospect-details.types'

export enum CRMProspectDetailsTabsName {
  General = 'General',
  Contacts = 'Contacts',
  Tasks = 'Tasks',
  Remarks = 'Remarks',
  Quotes = 'Quotes',
  Characteristics = 'Characteristics',
  Documents = 'Documents',
}

export interface ICRMProspectDetailsTabs extends ICRMProspectDetailsCharacteristicsTab {
  disabled: boolean
  isMobile: boolean
  markAsDirtyCallback: () => void
  characteristics: IAppliedCharacteristic[]
  onCreateCharacteristic: (newCharacteristic: IAppliedCharacteristic) => Promise<void>
  onUpdateCharacteristic: (updatedCharacteristic: IAppliedCharacteristic) => Promise<void>
  onDeleteCharacteristic: (deletedCharacteristic: IAppliedCharacteristic) => Promise<void>
  remarks: IRemarksHook
  documents: IDocumentsHook
  prospectNumber?: string
  prospect?: ICRMProspect | null
  mode: CRMProspectDetailsMode
}

export interface ICRMProspectDetailsCommonTab {
  disabled: boolean
  isMobile: boolean
  markAsDirtyCallback: () => void
}

export interface ICRMProspectDetailsGeneralTab extends ICRMProspectDetailsCommonTab {}

export interface ICRMProspectDetailsCharacteristicsTab extends ICRMProspectDetailsCommonTab {
  characteristics: IAppliedCharacteristic[]
  onCreateCharacteristic: (newCharacteristic: IAppliedCharacteristic) => Promise<void>
  onUpdateCharacteristic: (updatedCharacteristic: IAppliedCharacteristic) => Promise<void>
  onDeleteCharacteristic: (deletedCharacteristic: IAppliedCharacteristic) => Promise<void>
}
