import * as React from 'react'
import { FC, useCallback, useMemo, useState } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorHandler } from 'services/ErrorService'
import { ToastService } from 'services/ToasterService'
import { confirmationMessages } from 'common/constants'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { XtItemNumber } from 'components/item-number/item-number'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { BomItemQuickAdd } from '../components/bomItemQuickAdd/BomItemQuickAdd'
import { cls } from '../../common/utils'
import * as styles from '../bomDetails/BomDetails.module.scss'
import { XtQuickAddButton } from '../../components/quickAddButton/QuickAddButton'
import { XtButton } from '../../components/xtButton/XtButton'
import { SvgIcon } from '../../components/svgIcon/SvgIcon'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { BomService } from '../services/BomService'
import { BomDetailsSection } from '../components/bomDetailsSection/BomDetailsSection'
import { IItemsSearchOption } from '../../components/itemsSearch/ItemsSearch'
import { BomCreateInput, BomItemQuickAddInput, IBomItemsFilters, IBomItem, IBom } from '../bom.types'
import { BomItemService } from '../services/BomItemService'
import { NewBomSubmitExistingItemFunc, IResetData } from './BomNew.types'
import { defaultNewBomFormValues } from './BomNew.constants'
import { newBomValidationSchema } from './BomNew.validation'
import { BomDetailsAction, BomDetailsFormField, BomFormData, IBomItemDialogState, IItemDeletionState } from '../bomDetails/BomDetails.types'
import { BomDetailsForm } from '../components/bomDetailsForm/BomDetailsForm'
import { BomItemMode, BomItemSubmitFunc } from '../bomItem/BomItem.types'
import { bomDetailsTableColumns, defaulDeletionState, defaultBomItemDialogState } from '../bomDetails/BomDetails.constants'
import { BomItem } from '../bomItem/BomItem'
import { defineAvailableActions, submitBomItemDialog } from '../bomDetails/BomDetails.utils'
import { processBom } from './BomNew.utils'
import { NumberTypeUtils } from '../../common/typeUtils'
import { XtList } from '../../components/list/list'
import { useTable } from '../../common/hooks/useTable'

let bomItemId: number = 1
// TODO redirect to routing-list if mobile version
export const BomNew: FC = () => {
  const [deletionState, setDeletionState] = useState<IItemDeletionState>(defaulDeletionState)
  const [isQuickAddOpen, setOpenQuickAdd] = useState(false)
  const [bomData, setBomData] = useState<IBom | null>(null)
  const [bomItemDialogState, setBomItemDialogState] = useState<IBomItemDialogState>(defaultBomItemDialogState)
  const history = useHistory()
  const anchorEl = React.useRef(null)

  const {
    formState: { isSubmitting, isDirty, errors },
    setValue,
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    control,
  } = useForm<BomFormData>({
    defaultValues: defaultNewBomFormValues,
    resolver: yupResolver(newBomValidationSchema),
    mode: 'onBlur',
  })

  const itemNumber = watch(BomDetailsFormField.ItemNumber)

  register(BomDetailsFormField.ItemNumber)

  const fetchBomItems = useCallback(
    async (filters: IBomItemsFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IBomItem>> => {
      const { item_number } = getValues()
      if (item_number) {
        const { total, data } = await BomItemService.getAll(item_number, filters, paginationParams)
        return {
          data,
          total,
        }
      }
      return {
        data: [],
        total: 0,
      }
    },
    [itemNumber]
  )
  const { state, setLoading, refresh, filter, pagination, setData } = useTable({ showExpired: false, showFuture: false }, fetchBomItems)

  async function onShowFilterChange({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    await filter({ ...state.filters, [target.name]: target.checked })
  }

  const actions = useMemo(() => defineAvailableActions(!bomData, false, false), [bomData])

  const openQuickAddForm = useCallback<() => void>(() => setOpenQuickAdd(true), [])
  const closeQuickAddForm = useCallback<() => void>(() => setOpenQuickAdd(false), [])
  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaulDeletionState), [])
  const onSubmit: (formValues: BomFormData) => Promise<void> = async ({ ...formValues }) => {
    try {
      const bomInput = {
        ...formValues,
        bom_items: state.data,
        revision_date: formValues.revision_date.toISOString(),
        batch_size: NumberTypeUtils.parseString(formValues.batch_size),
        item_number: formValues.item_number!,
      }
      if (bomData) {
        await BomService.update(bomInput)
        ToastService.showSuccess(`BOM ${bomData.item_number} has been updated.`)
      } else {
        const bomCreateInput: BomCreateInput = {
          ...bomInput,
          bom_items: bomInput.bom_items.map(({ id: _, ...other }) => ({ ...other })),
        }
        await BomService.create(bomCreateInput)
        ToastService.showSuccess(`BOM ${bomCreateInput.item_number} has been created.`)
      }
      history.push('/products/bom')
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const resetData = (data: IResetData) => {
    setBomData(data?.bom ?? null)
    reset(data.form)
    void filter({ showExpired: false, showFuture: false })
  }
  const onItemChange = useCallback<(item: IItemsSearchOption | null) => Promise<void>>(
    async (item) => {
      const formData = { ...processBom(null), item_number: item?.item_number ?? '' }
      if (!item?.item_number) {
        resetData({ form: formData })
        return
      }
      try {
        setLoading(true)
        const bom = await BomService.get(item.item_number, false, false)
        resetData({ bom, form: processBom(bom) })
        setLoading(false)
      } catch (error) {
        resetData({ form: formData })
        setLoading(false)
      }
    },
    [reset, setValue]
  )

  const onCancel = useCallback<() => void>(() => {
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }
    history.push('/products/bom')
  }, [isDirty, history])

  const openBomItem: (itemId: number | null, items: IBomItem[] | undefined, selectedMode: BomItemMode) => void = (
    itemId,
    items = [],
    selectedMode
  ) => {
    const bomItem = items.find(({ id }) => id === itemId)
    setBomItemDialogState({ open: true, bomItem: bomItem ?? null, mode: selectedMode })
  }

  const deleteItem: () => Promise<void> = async () => {
    closeConfirmationDialog()
    if (!deletionState.itemId || !itemNumber) {
      return
    }
    if (!bomData) {
      const itemIndex = state.data.findIndex(({ id }) => id === deletionState.itemId)
      setData([...state.data.slice(0, itemIndex), ...state.data.slice(itemIndex + 1)])
      return
    }
    const bomItem = state.data.find(({ id }) => id === deletionState.itemId)
    if (bomItem) {
      try {
        setLoading(true)
        await BomItemService.delete(itemNumber, bomItem.sequence_number)
        await refresh()
        setLoading(false)
        ToastService.showSuccess(`BOM Item has been deleted.`)
      } catch (error) {
        setLoading(false)
        ErrorHandler.handleError(error)
      }
    }
  }

  const expireItem: (bomItemNumber: string, bomItem: IBomItem) => Promise<void> = async (bomItemNumber, bomItem) => {
    try {
      setLoading(true)
      await BomItemService.expire(bomItemNumber, bomItem.sequence_number)
      ToastService.showSuccess(`BOM Item has been expired.`)
      await refresh()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      ErrorHandler.handleError(error)
    }
  }

  const handleAction = useCallback<(item: IBomItem, action: BomDetailsAction) => void>(
    ({ id: itemId }, action) => {
      switch (action) {
        case BomDetailsAction.View:
          return openBomItem(itemId, state.data, BomItemMode.View)
        case BomDetailsAction.Edit: {
          const itemMode = bomData ? BomItemMode.Edit : BomItemMode.Search
          return openBomItem(itemId, state.data, itemMode)
        }
        case BomDetailsAction.Replace:
          return openBomItem(itemId, state.data, BomItemMode.Replace)
        case BomDetailsAction.Delete:
          return setDeletionState({ itemId, confirmationOpen: true })
        case BomDetailsAction.Expire: {
          const item = state.data.find(({ id }) => id === itemId)
          return itemNumber && item && expireItem(itemNumber, item)
        }
        default:
          return openBomItem(itemId, state.data, BomItemMode.View)
      }
    },
    [state.data, bomData]
  )

  const addBomItem = useCallback<(quickAddFormData: BomItemQuickAddInput) => Promise<void>>(
    async (quickAddFormData) => {
      const newBomItem = {
        ...quickAddFormData,
        bom_item_inventory_uom: quickAddFormData.item.inventory_uom_name,
        bom_item_description: quickAddFormData.item.description1,
        bom_item_number: quickAddFormData.item.item_number,
      }
      if (!bomData || !itemNumber) {
        const newItem: IBomItem = {
          ...newBomItem,
          id: bomItemId++,
        } as IBomItem
        setData([...state.data, newItem])
        closeQuickAddForm()
        return
      }
      try {
        setLoading(true)
        await BomItemService.quickAdd(itemNumber, newBomItem)
        await refresh()
        ToastService.showSuccess(`BOM Item has been created.`)
        setLoading(false)
        closeQuickAddForm()
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    },
    [bomData, closeQuickAddForm, state.data]
  )

  const handleBomItemClick = useCallback<(item: IBomItem) => void>(
    ({ id }) => {
      openBomItem(id, state.data, BomItemMode.Edit)
    },
    [openBomItem, state.data]
  )

  const closeBomItemDialog = useCallback<() => void>(() => {
    setBomItemDialogState({ open: false, bomItem: null, mode: BomItemMode.View })
    closeQuickAddForm()
  }, [closeQuickAddForm])

  const submitBomItemForExistingBom: NewBomSubmitExistingItemFunc = async (parentItemNumber, bomItem, newItem, dialogMode, closeDialog) => {
    try {
      setLoading(true)
      await submitBomItemDialog(parentItemNumber, bomItem, newItem, dialogMode)
      await refresh()
      setLoading(false)
      closeDialog()
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }

  const onBomItemSubmit = useCallback<BomItemSubmitFunc>(
    async (parentItemNumber, bomItem, newItem, dialogMode) => {
      if (bomData) {
        await submitBomItemForExistingBom(parentItemNumber, bomItem, newItem, dialogMode, closeBomItemDialog)
        return
      }
      if (dialogMode === BomItemMode.Search && !bomItem) {
        // eslint-disable-next-line no-plusplus
        const newBomItem: IBomItem = { ...newItem, id: bomItemId++ } as IBomItem
        setData([...state.data, newBomItem])
        closeBomItemDialog()
        return
      }
      if ((dialogMode === BomItemMode.Search || dialogMode === BomItemMode.Edit) && bomItem) {
        const itemIndex = state.data.findIndex(({ id }) => id === bomItem.id)
        const updatedBom = { ...bomItem, ...newItem, item_type: newItem.item.item_type }
        setData([...state.data.slice(0, itemIndex), updatedBom, ...state.data.slice(itemIndex + 1)])
      }
      closeBomItemDialog()
    },
    [closeBomItemDialog, state.data, bomData]
  )

  const onAdvancedSearch = useCallback<() => void>(() => openBomItem(null, state.data, BomItemMode.Search), [state.data])

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete BOM"
        confirmationButtonLabel="Delete"
        onConfirm={deleteItem}
        onClose={closeConfirmationDialog}
      />
      <main className={cls('xt-content')}>
        <form onSubmit={handleSubmit(onSubmit, (error) => console.error(error))}>
          {itemNumber && (
            <BomItem
              parentItemNumber={itemNumber}
              bomItem={bomItemDialogState.bomItem}
              open={bomItemDialogState.open}
              onSubmit={onBomItemSubmit}
              onClose={closeBomItemDialog}
              mode={bomItemDialogState.mode}
            />
          )}
          <div className={cls(styles.bomDetailsHeaderSection, 'xt-section-border')}>
            <Controller
              name={BomDetailsFormField.ItemNumber}
              control={control}
              render={() => (
                <XtItemNumber
                  itemNumber={bomData?.item_number}
                  uom={bomData?.item_inventory_uom}
                  description={bomData?.item_description}
                  isEditMode
                  editModeConfig={{
                    error: errors[BomDetailsFormField.ItemNumber]?.message,
                    loading: state.loading,
                    onChange: onItemChange,
                  }}
                />
              )}
            />

            <SvgIcon iconId={SvgIconIds.PRINT} className={styles.bomDetailsPrintIcon} />
            <XtButton className={styles.bomDetailsCancelButton} disabled={isSubmitting} label="Cancel" onClick={onCancel} />
            <XtButton
              className={styles.bomDetailsSubmitButton}
              loading={isSubmitting}
              label="Save"
              type="submit"
              disabled={isSubmitting || !isDirty || state.loading}
            />
          </div>
          <BomDetailsForm disabled={state.loading || isSubmitting} control={control} />
          <div className={styles.bomDetailsTableFilters}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={state.filters.showExpired}
                  disabled={!bomData}
                  onChange={onShowFilterChange}
                  name="showExpired"
                />
              }
              label="Show Expired"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={state.filters.showFuture}
                  disabled={!bomData}
                  onChange={onShowFilterChange}
                  name="showFuture"
                />
              }
              label="Show Future"
            />
            <div className={styles.bomQuickAddContainer}>
              <XtQuickAddButton disabled={!itemNumber} className={styles.bomQuickAddButton} onClick={openQuickAddForm} ref={anchorEl} />
              {itemNumber && (
                <BomItemQuickAdd
                  itemNumber={itemNumber}
                  onAdvancedSearch={onAdvancedSearch}
                  anchorEl={anchorEl.current}
                  onClose={closeQuickAddForm}
                  open={isQuickAddOpen}
                  onAddItem={addBomItem}
                />
              )}
            </div>
          </div>
          <XtList
            actions={actions}
            onRowClick={handleBomItemClick}
            onAction={handleAction}
            pagination={bomData ? pagination : undefined}
            loading={state.loading}
            data={state.data}
            columns={bomDetailsTableColumns}
            className={styles.bomTableContainer}
          />
        </form>
        <BomDetailsSection data={bomData?.bom_details} />
      </main>
    </div>
  )
}
