import * as React from 'react'
import { FC, useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { MenuItem, Modal, Slide, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IItem } from 'items/Items.types'
import { XtItemNumber } from 'components/item-number/item-number'
import { IItemsFilters } from 'items/items-list/items-list.types'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'
import { globalConstants } from '../../../../common/constants'
import { XtButton } from '../../../../components/xtButton/XtButton'
import * as styles from './sales-order-line-item-details.module.scss'
import { IItemsSearchOption, ItemsSearch } from '../../../../components/itemsSearch/ItemsSearch'
import {
  ISalesOrderLineItemDetailsForm,
  ISalesOrderLineItemDetailsParams,
  ISalesOrderLineItemDetailsPriceListState,
  ISalesOrderLineItemDetailsPriceSubjectParams,
  LineItemFormField,
} from './sales-order-line-item-details.types'
import { validationSchema } from './sales-order-line-item-details.validation'
import { cls } from '../../../../common/utils'
import { extractSites, extractUomOptions, getDefaultSite, renderOptions } from '../sales-order-line-items.utils'
import {
  calculateCustomerDiscount,
  calculateDiscount,
  calculateNetUnitPrice,
  convertLineItemStatus,
  convertToFormData,
  lineItemStatusButtonLabel,
} from './sales-order-line-item-details.utils'
import { ErrorHandler } from '../../../../services/ErrorService'
import { ItemsService } from '../../../../items/items.service'
import { NumberTypeUtils } from '../../../../common/typeUtils'
import { SalesOrderLineItemSupply } from './sales-order-line-item-supply/sales-order-line-item-supply'
import { SalesOrderLineItemPriceList } from './sales-order-line-item-price-list/sales-order-line-item-price-list'
import { FormCheckboxLabel, FormDatePicker, FormField, FormSelectField } from '../../../../common/utils/form/form.components'
import { ISalesOrderLineItemPriceListItem } from './sales-order-line-item-price-list/sales-order-line-item-price-list.types'
import { useSalesOrderLineItemPrice } from './sales-order-line-item-price-list/sales-order-line-item-price-list.hook'

export interface ISalesOrderLineItemDetailsState {
  loading: boolean
}

export const SalesOrderLineItemDetails: FC<ISalesOrderLineItemDetailsParams> = React.memo(
  ({ lineItem, open, onClose, onSubmit, viewMode, onDeleteItem, customerNumber, currency, orderNumber }) => {
    const [{ loading }, setState] = useState<ISalesOrderLineItemDetailsState>({ loading: false })
    const [priceListState, setPriceListState] = useState<ISalesOrderLineItemDetailsPriceListState>({ open: false })
    const isNewMode = !lineItem
    const {
      control,
      formState: { isDirty, errors, isSubmitting },
      watch,
      setValue,
      reset,
      getValues,
      handleSubmit,
      register,
    } = useForm<ISalesOrderLineItemDetailsForm>({
      defaultValues: convertToFormData(lineItem),
      mode: 'all',
      resolver: yupResolver(validationSchema),
    })

    const priceSubject = useRef<Subject<ISalesOrderLineItemDetailsPriceSubjectParams>>(
      new Subject<ISalesOrderLineItemDetailsPriceSubjectParams>()
    )
    const priceState = useSalesOrderLineItemPrice()

    register(LineItemFormField.Status)

    const init: () => Promise<void> = async () => {
      const formData = convertToFormData(lineItem)
      if (!lineItem) {
        reset(formData)
        return
      }
      try {
        setState((prevState) => ({ ...prevState, loading: true }))
        const substituteForPromise = lineItem.substitute_for ? ItemsService.get(lineItem.substitute_for) : Promise.resolve(null)
        const [item, substituteFor] = await Promise.all([ItemsService.get(lineItem.item_number), substituteForPromise])
        reset({ ...formData, [LineItemFormField.Item]: item, [LineItemFormField.Substitute]: substituteFor })
        setState((prevState) => ({ ...prevState, loading: false }))
        priceSubject.current.next({ itemNumber: lineItem.item_number, site: lineItem.sold_from_site, quantity: lineItem.qty_ordered })
      } catch (e) {
        ErrorHandler.handleError(e)
        setState((prevState) => ({ ...prevState, loading: false }))
      }
    }

    useEffect(() => {
      if (open) {
        void init()
      }
    }, [lineItem, open])

    const [lineFormItem, substitute_for, site, status, quantity] = watch([
      LineItemFormField.Item,
      LineItemFormField.SubstituteFor,
      LineItemFormField.Site,
      LineItemFormField.Status,
      LineItemFormField.QtyOrdered,
    ])

    useEffect(() => {
      const item = lineFormItem as IItemsSearchOption | null
      if (!item || !site || !quantity) {
        return
      }
      priceSubject.current.next({ itemNumber: item.item_number, site, quantity: NumberTypeUtils.parseString(quantity) })
    }, [lineFormItem, site, quantity])

    useEffect(() => {
      const sub = priceSubject.current
        .asObservable()
        .pipe(
          distinctUntilChanged(
            (prev, current) => prev.itemNumber === current.itemNumber && prev.quantity === current.quantity && prev.site === current.site
          ),
          debounceTime(globalConstants.inputDebounce),
          switchMap((data) =>
            priceState.requestPrice(customerNumber, data.itemNumber, data.site, NumberTypeUtils.parseString(data.quantity), currency)
          ),
          filter(() => !lineItem),
          tap(({ listPrice, customerPrice, discountFromCustomer, discountFromList }) => {
            setValue(LineItemFormField.ListPrice, listPrice ?? '')
            setValue(LineItemFormField.CustomerPrice, customerPrice ?? '')
            setValue(LineItemFormField.ListPriceDiscount, discountFromList ?? '')
            setValue(LineItemFormField.CustomerPriceDiscount, discountFromCustomer ?? '')
            setValue(LineItemFormField.NetUnitPrice, customerPrice ?? '')
          })
        )
        .subscribe()

      return () => sub.unsubscribe()
    }, [currency, customerNumber, lineItem])

    const currentItem = lineFormItem as IItem
    const searchItem = currentItem ? { ...currentItem, id: currentItem.item_number, label: currentItem.item_number } : null
    const uomOptions = extractUomOptions(currentItem)
    const sites = extractSites(currentItem)

    const saveItem: (shouldReset: boolean) => (values: ISalesOrderLineItemDetailsForm) => Promise<void> = (shouldReset) => async (
      values
    ) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }))
        await onSubmit(values, shouldReset)
        // We should reset the data explicitly from the component because a parent component doesn't have an ability to reset the state in case of New Mode
        if (shouldReset) {
          reset(convertToFormData(null))
        }
        setState((prevState) => ({ ...prevState, loading: false }))
      } catch (error) {
        ErrorHandler.handleError(error)
        setState((prevState) => ({ ...prevState, loading: false }))
      }
    }

    const setExtendedPrice = useCallback<VoidFunction>(() => {
      const { net_unit_price, qty_ordered } = getValues()
      const extendedPrice = (NumberTypeUtils.parseFloatString(net_unit_price) || 0) * (NumberTypeUtils.parseString(qty_ordered) || 0)
      setValue(LineItemFormField.ExtendedPrice, extendedPrice.toFixed(2))
    }, [getValues, setValue])

    const handleNetUnitPriceChange: (price: string | number) => void = (price) => {
      const customerPriceValue = getValues(LineItemFormField.CustomerPrice) ?? 0
      const customerPrice = NumberTypeUtils.parseFloatString(customerPriceValue)
      const netUnitPrice = NumberTypeUtils.parseFloatString(price) || 0
      const discount = calculateDiscount(netUnitPrice, priceState.state.listPrice)
      const customerDiscount = calculateCustomerDiscount(netUnitPrice, customerPrice)
      setValue(LineItemFormField.NetUnitPrice, price)
      setValue(LineItemFormField.CustomerPriceDiscount, customerDiscount.toFixed(2))
      setValue(LineItemFormField.ListPriceDiscount, discount.toFixed(2))
      setExtendedPrice()
    }

    const handleCustomerDiscountChange: (value: string | number) => void = (value) => {
      const customerPriceValue = getValues(LineItemFormField.CustomerPrice) ?? 0
      const customerPrice = NumberTypeUtils.parseFloatString(customerPriceValue)
      const customerDiscount = NumberTypeUtils.parseFloatString(value || 0)
      const netUnitPrice = calculateNetUnitPrice(customerPrice, customerDiscount)
      const discount = calculateDiscount(netUnitPrice, priceState.state.listPrice)
      setValue(LineItemFormField.NetUnitPrice, netUnitPrice.toFixed(4))
      setValue(LineItemFormField.CustomerPriceDiscount, value)
      setValue(LineItemFormField.ListPriceDiscount, discount.toFixed(2))
      setExtendedPrice()
    }

    const onItemChange = useCallback<(item: IItemsSearchOption | null) => void>(
      (item) => {
        setValue(LineItemFormField.Item, item, { shouldValidate: true, shouldDirty: true })
        setValue(LineItemFormField.SellingUom, item?.list_price_uom_name ?? '', { shouldValidate: true })
        setValue(LineItemFormField.Site, getDefaultSite(item), { shouldValidate: true })
      },
      [setValue]
    )

    const handleQtyOrderedChange: (value: string | number) => void = (value) => {
      setValue(LineItemFormField.QtyOrdered, value)
      setExtendedPrice()
    }

    const onCancel = useCallback<VoidFunction>(() => {
      const dialog = 'Are you sure you want to leave the page? Updates will not be applied.'
      // TODO implement confirmation dialog
      // eslint-disable-next-line no-restricted-globals
      if (isDirty && !confirm(dialog)) {
        return
      }
      onClose()
    }, [isDirty, onClose, reset])

    const displayPriceListDialog = useCallback<VoidFunction>(() => setPriceListState({ open: true }), [])
    const closePriceListDialog = useCallback<VoidFunction>(() => setPriceListState({ open: false }), [])

    const selectPrice = useCallback<(priceItem: ISalesOrderLineItemPriceListItem) => void>(
      (priceItem) => {
        setValue(LineItemFormField.NetUnitPrice, priceItem.price_in_base, { shouldValidate: true, shouldDirty: true })
        setValue(LineItemFormField.ListPriceDiscount, priceItem.percent, { shouldValidate: true, shouldDirty: true })
        setValue(LineItemFormField.CustomerPriceDiscount, 0, { shouldValidate: true, shouldDirty: true })
        setExtendedPrice()
      },
      [setValue, setExtendedPrice]
    )

    const onSetLineItemStatus = () =>
      setValue(LineItemFormField.Status, convertLineItemStatus(status), { shouldValidate: true, shouldDirty: true })

    const onDelete = () => {
      if (lineItem) {
        onDeleteItem(lineItem.line_number)
      }
    }

    const itemFilters = useMemo<IItemsFilters>(() => ({ sold: true, customer_number: customerNumber ?? '', showDetail: true }), [
      customerNumber,
    ])

    return (
      <div>
        <SalesOrderLineItemPriceList
          open={priceListState.open}
          onClose={closePriceListDialog}
          onSelect={selectPrice}
          site={site}
          priceList={priceState.state}
          currency={currency}
          itemNumber={currentItem?.item_number}
        />
        <Modal open={open}>
          <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
            <form
              className={cls(
                'xt-content',
                'xt-dialog-scrollable',
                styles.salesOrderLineItemDetails,
                viewMode ? styles.salesOrderLineItemDetailsViewMode : ''
              )}
            >
              <div className={cls(styles.salesOrderLineItemDetailsHeader, 'xt-section-border')}>
                <Controller
                  name={LineItemFormField.Item}
                  control={control}
                  render={({ field: { value } }) => (
                    <XtItemNumber
                      itemNumber={value?.item_number}
                      uom={value?.inventory_uom_name}
                      description={value?.description1}
                      isEditMode
                      editModeConfig={{
                        disabled: viewMode || isSubmitting || loading || !isNewMode,
                        value: searchItem,
                        error: errors[LineItemFormField.Item]?.message,
                        loading,
                        onChange: onItemChange,
                        filters: itemFilters,
                      }}
                    />
                  )}
                />
                <XtButton label="Cancel" onClick={onCancel} />
                <XtButton
                  hidden={viewMode}
                  loading={isSubmitting}
                  label="Save Item and Add New"
                  onClick={handleSubmit(saveItem(true))}
                  disabled={isSubmitting || !isDirty}
                />
                <XtButton
                  hidden={viewMode}
                  loading={isSubmitting || loading}
                  label="Save Item"
                  onClick={handleSubmit(saveItem(false))}
                  disabled={isSubmitting || !isDirty}
                />
                <XtButton
                  disabled={isSubmitting}
                  hidden={viewMode}
                  loading={isSubmitting}
                  label={lineItemStatusButtonLabel(status)}
                  onClick={onSetLineItemStatus}
                />
                <XtButton hidden={viewMode || isNewMode} loading={isSubmitting} label="Delete" onClick={onDelete} />
              </div>

              <div className={styles.salesOrderLineItemDetailsForm}>
                <div className={styles.salesOrderLineItemDetailsFormSection}>
                  <FormSelectField
                    name={LineItemFormField.Site}
                    control={control}
                    options={renderOptions(sites)}
                    disabled={!sites.length || viewMode || !isNewMode}
                    label="Site"
                  />
                  <FormField
                    name={LineItemFormField.QtyOrdered}
                    control={control}
                    disabled={viewMode}
                    label="Qty Ordered"
                    onChange={(value) => handleQtyOrderedChange(value)}
                  />
                  <FormSelectField
                    name={LineItemFormField.SellingUom}
                    control={control}
                    disabled={!uomOptions.length || viewMode}
                    options={renderOptions(uomOptions)}
                    label="Selling UOM"
                  />

                  <FormField name={LineItemFormField.QtyShipped} control={control} disabled label="Qty Shipped" />
                  <FormDatePicker name={LineItemFormField.ScheduledDate} control={control} disabled={viewMode} label="Scheduled Date" />
                  <FormDatePicker name={LineItemFormField.PromiseDate} control={control} disabled={viewMode} label="Promised Date" />
                  <FormCheckboxLabel label="Warranty" name={LineItemFormField.Warranty} control={control} disabled={viewMode} />
                </div>
                <div className={styles.salesOrderLineItemDetailsFormSection}>
                  <FormField name={LineItemFormField.CustomerPN} control={control} disabled={viewMode} label="Customer P/N" />

                  <div className={styles.salesOrderLineItemDetailsSubstitute}>
                    <FormCheckboxLabel
                      name={LineItemFormField.SubstituteFor}
                      control={control}
                      label="Substitute for"
                      disabled={viewMode}
                    />

                    <Controller
                      name={LineItemFormField.Substitute}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ItemsSearch
                          disabled={!substitute_for || viewMode}
                          value={value}
                          onChange={onChange}
                          error={errors[LineItemFormField.Substitute]?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={styles.salesOrderLineItemDetailsPriceSection}>
                    <FormField disabled name={LineItemFormField.ListPrice} control={control} label="List Price" />

                    <FormField name={LineItemFormField.ListPriceDiscount} control={control} label="Discount %" disabled />

                    <FormField name={LineItemFormField.CustomerPrice} control={control} label="Customer Price" disabled />

                    <FormField
                      name={LineItemFormField.CustomerPriceDiscount}
                      control={control}
                      disabled={viewMode}
                      label="Customer Discount %"
                      onChange={handleCustomerDiscountChange}
                    />

                    <FormField
                      name={LineItemFormField.NetUnitPrice}
                      control={control}
                      disabled={viewMode}
                      label="Net Unit Price"
                      onChange={handleNetUnitPriceChange}
                    />

                    <TextField select disabled defaultValue={currency} variant="outlined">
                      <MenuItem value={currency}>{currency}</MenuItem>
                    </TextField>
                  </div>
                  <XtButton
                    label="Price List"
                    disabled={viewMode || !currentItem || !customerNumber || !site || !currency || !quantity}
                    className={styles.salesOrderLineItemDetailsPriceListButton}
                    onClick={displayPriceListDialog}
                  />

                  <FormField name={LineItemFormField.ExtendedPrice} control={control} label="Extended Price" disabled />
                </div>
              </div>
              <SalesOrderLineItemSupply
                site={site}
                quantity={quantity}
                orderNumber={orderNumber}
                lineNumber={lineItem?.line_number}
                itemNumber={currentItem?.item_number}
              />
            </form>
          </Slide>
        </Modal>
      </div>
    )
  }
)
