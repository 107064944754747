import * as React from 'react'
import { Card, CardActionArea, CardContent } from '@material-ui/core'
import { ReactElement } from 'react'
import * as styles from './card-list.module.scss'
import LoadingSpinner from '../LoadingSpinner'
import { XtButton } from '../xtButton/XtButton'
import { ICardListParams } from './card-list.types'
import { IObjectWithId } from '../../common/common.types'

export function XtCardList<TData extends IObjectWithId>({
  data,
  onCardClick,
  loading,
  canLoadMore,
  loadMore,
}: ICardListParams<TData>): ReactElement {
  return (
    <div className={styles.cardList}>
      {loading && !data.length && <LoadingSpinner />}
      <div hidden={loading || !!data.length} className={styles.noDataContainer}>
        No data available.
      </div>
      <div hidden={!data.length} className={styles.cardContentContainer}>
        {data.map((item, key1) => (
          <Card key={`${item.data.id}`} onClick={() => onCardClick && onCardClick(item.data)} elevation={0} classes={{ root: styles.card }}>
            <CardActionArea>
              <CardContent>
                {item.fields.map((field, key2) => (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <div key={`${field.value}_${key1}_${key2}`}>
                    <span className={styles.label}>{field.label}: </span>
                    <div className={styles.value} title={field.value?.toString() ?? ''}>
                      {field.value}
                    </div>
                  </div>
                ))}
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>
      <XtButton
        className={styles.loadMoreButton}
        label="Load more"
        hidden={!data.length || !loadMore || !canLoadMore}
        disabled={loading || !canLoadMore}
        loading={loading}
        onClick={loadMore}
      />
    </div>
  )
}
