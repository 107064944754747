import React, { FC, useCallback, memo, useEffect, useState } from 'react'

import { Modal, Slide } from '@material-ui/core'
import { globalConstants } from 'common/constants'
import { cls, loadSalesReps, loadShipmentsVia, loadStates } from 'common/utils'
import { XtButton } from 'components/xtButton/XtButton'
import { IContact } from 'contacts/contacts.types'
import { ContactSearch } from 'contacts/contact-search/contact-search'

import { FormField, FormCheckboxLabel, FormXtAutocomplete } from 'common/utils/form/form.components'

import { useXtForm } from 'common/hooks/form/form'
import { ContactService } from 'contacts/contacts.service'
import { ErrorHandler } from 'services/ErrorService'
import {
  IShipmentDialog,
  IShipmentFormValues,
  ShipmentFormField,
  ShipmentFormLabel,
  ShipmentDialogMode,
  ShipmentAddressFormField,
  IContactState,
} from './shipment-dialog.types'
import styles from './shipment-dialog.module.scss'
import { convertToShipmentValues, defineShipmentFormData, initialContactState } from './shipment-dialog.utils'
import { ShipmentDialogFormSchema } from './shipment-dialog.validation'
import { loadShippingCharges, loadShippingForm, loadShippingZones, loadSiteOptions, loadTaxZones } from '../../common/utils/document.utils'
import { loadCountries } from '../../sales-orders/components/sales-order-header-information/sales-order-bill-to-and-ship-to/sales-order-bill-to-and-ship-to-form/sales-order-bill-to-and-ship-to-form.utils'
import { DictionaryService } from '../../services/dictionary.service'
import { CountryOption, getCountryStates } from '../../common/utils/country-state.utils'

export const ShipmentDialog: FC<IShipmentDialog> = memo(({ state, onClose, onConfirm, isMobile }) => {
  const { control, reset, handleSubmit, formState, watch, setValue } = useXtForm<IShipmentFormValues>({
    defaultValues: defineShipmentFormData(null),
    mode: 'onBlur',
    validationSchema: ShipmentDialogFormSchema,
  })
  const [contactState, setContactState] = useState<IContactState>(initialContactState)
  const { item, mode, open } = state
  const viewMode = mode === ShipmentDialogMode.View
  const disabled = viewMode || formState.isSubmitting || isMobile
  const isDirty = formState.isDirty || contactState.isDirty
  const title = item?.shipto_number ? `Ship To #: ${item.shipto_number}` : 'New Ship To'
  const country = watch(ShipmentAddressFormField.Country)

  const init = async () => {
    try {
      if (item?.shipto_contact?.contact_number) {
        const contactData = await ContactService.get(item.shipto_contact.contact_number)
        setContactState((prev) => ({ ...prev, initialContact: contactData }))
      } else {
        setContactState(initialContactState)
      }
      const countryStates = await getCountryStates(item?.shipto_address?.country, item?.shipto_address?.state)
      reset(defineShipmentFormData(item, countryStates))
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  useEffect(() => void init(), [item])

  const onSaveForm = (shipToFormValues: IShipmentFormValues) => {
    onConfirm({ shipTo: convertToShipmentValues(shipToFormValues, contactState?.contact), mode })
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm, (error) => console.error(error))
    void eventHandler(e)
  }

  const handleContactChange = useCallback<(contactOption: IContact) => void>((contactOption) => {
    setContactState((prev) => ({
      ...prev,
      contact: contactOption,
      isDirty: prev.initialContact?.contact_number !== contactOption?.contact_number,
    }))
  }, [])

  const onCancel = (): void => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
    reset(defineShipmentFormData(null))
    setContactState(initialContactState)
  }

  const handleCountryChange = useCallback(
    async (option: CountryOption | null) => {
      if (!option?.id) {
        setValue(ShipmentAddressFormField.Country, null, {
          shouldValidate: true,
          shouldDirty: true,
        })
        setValue(ShipmentAddressFormField.State, '', { shouldValidate: true, shouldDirty: true })
        return
      }
      const statesData = await DictionaryService.getStates(option.id, { limit: 1, page: 1 })
      const hasStates = Boolean(statesData?.data?.length)
      setValue(ShipmentAddressFormField.Country, option ? { ...option, hasStates } : null, {
        shouldValidate: true,
        shouldDirty: true,
      })

      if (hasStates) {
        setValue(ShipmentAddressFormField.State, null, { shouldValidate: true, shouldDirty: true })
      }
      if (country?.hasStates && !hasStates) {
        setValue(ShipmentAddressFormField.State, '', { shouldValidate: true, shouldDirty: true })
      }
    },
    [country]
  )
  const loadStatesOptions = useCallback((page, limit) => loadStates(page, limit, country?.id), [country?.id])
  const disabledState = !country || disabled
  return (
    <div>
      <Modal open={open}>
        <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
          <form onSubmit={submitForm} className={styles.shipmentDialogContent}>
            <div className={cls(styles.shipmentDialogFormHeader, 'xt-section-border')}>
              <h3 className="xt-page-title" title={title}>
                {title}
              </h3>
              <div className={styles.containerHeaderButtons}>
                <XtButton label="Cancel" onClick={onCancel} />
                <XtButton hidden={isMobile || viewMode} label="Save" type="submit" disabled={disabled || !isDirty} />
              </div>
            </div>
            <div className={styles.shipmentCheckboxGroup}>
              <FormCheckboxLabel disabled={disabled} control={control} name={ShipmentFormField.Active} label={ShipmentFormLabel.Active} />
              <FormCheckboxLabel disabled={disabled} control={control} name={ShipmentFormField.Default} label={ShipmentFormLabel.Default} />
              <FormCheckboxLabel
                disabled={disabled}
                control={control}
                name={ShipmentAddressFormField.AllowMarketing}
                label={ShipmentFormLabel.AllowMarketing}
              />
            </div>
            <div className={styles.shipmentDialogFormContent}>
              <div className={styles.shipmentAddressSide}>
                <FormField
                  disabled={mode === ShipmentDialogMode.Edit || disabled}
                  control={control}
                  name={ShipmentFormField.Number}
                  label={ShipmentFormLabel.Number}
                />
                <FormField disabled={disabled} control={control} name={ShipmentFormField.Name} label={ShipmentFormLabel.Name} />
                <FormField
                  disabled={disabled}
                  control={control}
                  name={ShipmentAddressFormField.Address1}
                  label={ShipmentFormLabel.Address1}
                />
                <FormField
                  disabled={disabled}
                  control={control}
                  name={ShipmentAddressFormField.Address2}
                  label={ShipmentFormLabel.Address2}
                />
                <FormField
                  disabled={disabled}
                  control={control}
                  name={ShipmentAddressFormField.Address3}
                  label={ShipmentFormLabel.Address3}
                />
                <FormField disabled={disabled} control={control} name={ShipmentAddressFormField.City} label={ShipmentFormLabel.City} />
                <FormXtAutocomplete
                  disabled={disabled}
                  label={ShipmentFormLabel.Country}
                  control={control}
                  name={ShipmentAddressFormField.Country}
                  loadOptions={loadCountries}
                  onChange={handleCountryChange}
                />
                {!country?.hasStates ? (
                  <FormField
                    disabled={disabledState}
                    control={control}
                    name={ShipmentAddressFormField.State}
                    label={ShipmentFormLabel.State}
                  />
                ) : (
                  <FormXtAutocomplete
                    disabled={disabledState}
                    label={ShipmentFormLabel.State}
                    control={control}
                    name={ShipmentAddressFormField.State}
                    loadOptions={loadStatesOptions}
                  />
                )}
                <FormField
                  disabled={disabled}
                  control={control}
                  name={ShipmentAddressFormField.PostalCode}
                  label={ShipmentFormLabel.PostalCode}
                />
                <ContactSearch
                  isMobile={isMobile}
                  className={styles.shipToContactDropDown}
                  disabled={disabled}
                  labelContact="Contact"
                  initialData={contactState.initialContact}
                  onChange={handleContactChange}
                />
              </div>

              <div className={styles.shipmentShippingSide}>
                <FormXtAutocomplete
                  disabled={disabled}
                  loadOptions={loadSalesReps}
                  name={ShipmentFormField.SalesRep}
                  control={control}
                  label={ShipmentFormLabel.SalesRep}
                />
                <FormXtAutocomplete
                  disabled={disabled}
                  loadOptions={loadShippingZones}
                  name={ShipmentFormField.ShipZone}
                  control={control}
                  label={ShipmentFormLabel.ShipZone}
                />
                <FormXtAutocomplete
                  disabled={disabled}
                  loadOptions={loadTaxZones}
                  name={ShipmentFormField.TaxZone}
                  control={control}
                  label={ShipmentFormLabel.TaxZone}
                />
                <FormXtAutocomplete
                  disabled={disabled}
                  loadOptions={loadShipmentsVia}
                  name={ShipmentFormField.ShipVia}
                  control={control}
                  label={ShipmentFormLabel.ShipVia}
                />
                <FormXtAutocomplete
                  disabled={disabled}
                  loadOptions={loadShippingForm}
                  name={ShipmentFormField.ShippingForm}
                  control={control}
                  label={ShipmentFormLabel.ShippingForm}
                />
                <FormXtAutocomplete
                  disabled={disabled}
                  loadOptions={loadShippingCharges}
                  name={ShipmentFormField.ShippingCharges}
                  control={control}
                  label={ShipmentFormLabel.ShippingCharges}
                />
                <FormXtAutocomplete
                  disabled={disabled}
                  loadOptions={loadSiteOptions}
                  name={ShipmentFormField.Site}
                  control={control}
                  label={ShipmentFormLabel.Site}
                />
              </div>
            </div>
          </form>
        </Slide>
      </Modal>
    </div>
  )
})
