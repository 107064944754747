import { FilterFieldName, HoldTypeField } from 'sales-orders/sales-orders.types'
import { FilterType, IFilter } from 'components/filter/filter.types'
import { loadCustomers, renderCustomerOption } from 'sales-orders/components/sales-order-main/sales-order-main.utils'
import { loadCustomerTypeOptions, loadSaleTypes, loadSiteOptions } from 'common/utils/document.utils'
import { loadProjectOptions, loadSalesReps, loadUserOptions } from 'common/utils'
import { getInlineLabel, getInputId, renderInlineOption, renderOption } from 'common/utils/autocomplete.utils'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { dateConverter } from '../../common/utils/date.utils'
import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'
import { SalesOrderRow, SalesOrdersListAction } from './sales-orders-list.types'
import { CustomerOption } from '../components/sales-order-main/sales-order-main.types'

export const tableColumns: IXtTableColumn<SalesOrderRow>[] = [
  {
    id: 'order_number',
    field: 'order_number',
    headerName: 'Order #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'sale_type',
    field: 'sale_type',
    headerName: 'Sale Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'customer_number',
    field: 'customer_number',
    headerName: 'Customer Number',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'customer_name',
    field: 'customer_name',
    headerName: 'Customer Name',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'ship_to',
    field: 'ship_to',
    headerName: 'Ship To',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'customer_po_number',
    field: 'customer_po_number',
    headerName: 'Customer Purchase #',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'order_date',
    field: 'order_date',
    headerName: 'Ordered',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'scheduled_date',
    field: 'scheduled_date',
    headerName: 'Scheduled Date',
    flex: '1 0 200px',
    width: 200,
    converter: dateConverter,
  },
  {
    id: 'order_total',
    field: 'order_total',
    headerName: 'Total',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'ordermargin',
    field: 'ordermargin',
    headerName: 'Margin',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'ordermarginpercent',
    field: 'ordermarginpercent',
    headerName: 'Margin %',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'status',
    field: 'status', // TODO use the correct field
    headerName: 'Status',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'hold_type',
    field: 'hold_type',
    headerName: 'Hold Type',
    flex: '1 0 160px',
    width: 160,
  },
]

export const salesOrderActions: IXtTableCellAction[] = [
  {
    name: SalesOrdersListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: SalesOrdersListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: SalesOrdersListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: SalesOrdersListAction.Copy,
    label: 'Copy',
    icon: SvgIconIds.ADD_FILE,
  },
  {
    name: SalesOrdersListAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]

export const salesOrderActionsView: IXtTableCellAction[] = [
  {
    name: SalesOrdersListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

const customerFilter: IFilter<CustomerOption> = {
  type: FilterType.Autocomplete,
  label: 'Customer',
  fieldName: FilterFieldName.CustomerNumber,
  autocompleteProps: {
    loadOptions: loadCustomers,
    renderOption: renderCustomerOption,
  },
}

export const filterFields: Array<IFilter | IFilter<CustomerOption>> = [
  {
    type: FilterType.Autocomplete,
    label: 'Site',
    fieldName: FilterFieldName.Site,
    autocompleteProps: {
      loadOptions: loadSiteOptions,
    },
  },
  customerFilter,
  {
    type: FilterType.Autocomplete,
    label: 'Customer Type',
    fieldName: FilterFieldName.CustomerType,
    autocompleteProps: {
      loadOptions: loadCustomerTypeOptions,
    },
  },
  {
    type: FilterType.Text,
    label: 'Customer Type Pattern',
    fieldName: FilterFieldName.CustomerPattern,
  },
  {
    type: FilterType.Text,
    label: 'Customer Purchase #',
    fieldName: FilterFieldName.PONumber,
  },
  {
    type: FilterType.Autocomplete,
    label: 'Sales Rep.',
    fieldName: FilterFieldName.SalesRep,
    autocompleteProps: {
      loadOptions: loadSalesReps,
    },
  },
  {
    type: FilterType.Autocomplete,
    label: 'Sales Type.',
    fieldName: FilterFieldName.SalesType,
    autocompleteProps: {
      loadOptions: loadSaleTypes,
      getInputLabel: getInlineLabel,
      renderOption: renderInlineOption,
    },
  },
  {
    type: FilterType.Autocomplete,
    label: 'Project',
    fieldName: FilterFieldName.Project,
    autocompleteProps: {
      loadOptions: loadProjectOptions,
      renderOption,
      getInputLabel: getInputId,
    },
  },
  {
    type: FilterType.Autocomplete,
    label: 'Created By',
    fieldName: FilterFieldName.CreatedBy,
    autocompleteProps: {
      loadOptions: loadUserOptions,
      renderOption,
      getInputLabel: getInputId,
    },
  },
  {
    type: FilterType.Dropdown,
    label: 'Hold Type',
    fieldName: FilterFieldName.HoldType,
    options: Object.values(HoldTypeField).map((value) => ({ value, label: value })),
  },
]

export const defaultFilterValues = {
  [FilterFieldName.Site]: '',
  [FilterFieldName.SalesType]: '',
  [FilterFieldName.CustomerNumber]: '',
  [FilterFieldName.CustomerType]: '',
  [FilterFieldName.CustomerPattern]: '',
  [FilterFieldName.PONumber]: '',
  [FilterFieldName.SalesRep]: '',
  [FilterFieldName.SalesType]: '',
  [FilterFieldName.Project]: '',
  [FilterFieldName.CreatedBy]: '',
  [FilterFieldName.HoldType]: '',
}
