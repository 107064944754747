import { useState } from 'react'

export interface IDeletionConfirmationDialog<ID> extends IItemDeletionState<ID> {
  openDialog(itemId: ID): void
  closeDialog(): void
}

interface IItemDeletionState<ID> {
  itemId: ID | null
  open: boolean
}

export function useConfirmationDialog<ID>(): IDeletionConfirmationDialog<ID> {
  const [deletionState, setDeletionState] = useState<IItemDeletionState<ID>>({ itemId: null, open: false })

  function open(itemId: ID): void {
    setDeletionState({ itemId, open: true })
  }

  function close(): void {
    setDeletionState({ itemId: null, open: false })
  }

  return {
    openDialog: open,
    closeDialog: close,
    itemId: deletionState.itemId,
    open: deletionState.open,
  }
}
