import React, { FC, useMemo } from 'react'
import { XtTabs } from 'components/xt-tabs/xt-tabs'

import { ICustomer } from 'customers/customers.types'
import { ContactList } from 'contacts/contact-list/contact-list'
import { TaskList } from 'components/tasks/task-list/task-list'
import styles from './crm-tabs.module.scss'

interface ICRMTabs {
  disabled: boolean
  customer: ICustomer | null
  isMobile: boolean
}
enum СRMTabName {
  Contacts = 'Contacts',
  Activities = 'Activities',
}

export const CRMTabs: FC<ICRMTabs> = ({ disabled, customer, isMobile }) => {
  const tabs = useMemo(
    () => [
      {
        name: СRMTabName.Contacts,
        template: (
          <ContactList
            disabled={disabled}
            isMobile={isMobile}
            account_name={customer?.customer_name ?? ''}
            account_number={customer?.customer_number ?? ''}
          />
        ),
      },
      {
        name: СRMTabName.Activities,
        template: <TaskList account_number={customer?.customer_number ?? ''} disabled={disabled} />,
      },
    ],
    [customer, disabled, isMobile]
  )
  return <XtTabs className={styles.xtTabs} tabs={tabs} />
}
