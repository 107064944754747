import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from '../../../../components/xtAutocomplete/XtAutocomplete.types'
import { ISalesOrder, ISalesOrderValidation } from '../../../sales-orders.types'
import { IFormStateChanges } from '../../../../common/hooks/form/form.types'

export type ISalesOrderShippingAndSalesChange = (changes: IFormStateChanges<ISalesOrderShippingAndSalesInput>) => void

export interface ISalesOrderShippingAndSalesParams extends ISalesOrderValidation {
  onChange: ISalesOrderShippingAndSalesChange
  viewMode: boolean
  data: ISalesOrderShippingAndSalesInput | null
}

export enum SalesOrderShippingAndSalesFormField {
  PackDate = 'pack_date',
  SaleType = 'sale_type',
  ShippingForm = 'shipping_form',
  Terms = 'terms',
  ShippingCharges = 'shipping_charges',
  SalesRep = 'sales_rep',
  ShippingZone = 'shipping_zone',
  TaxZone = 'tax_zone',
  Incoterm = 'fob',
  ShipComplete = 'ship_complete',
}

export interface ISalesOrderShippingAndSalesInput
  extends Pick<
    ISalesOrder,
    | SalesOrderShippingAndSalesFormField.PackDate
    | SalesOrderShippingAndSalesFormField.SalesRep
    | SalesOrderShippingAndSalesFormField.ShippingForm
    | SalesOrderShippingAndSalesFormField.ShippingCharges
    | SalesOrderShippingAndSalesFormField.SaleType
    | SalesOrderShippingAndSalesFormField.ShippingZone
    | SalesOrderShippingAndSalesFormField.TaxZone
    | SalesOrderShippingAndSalesFormField.Incoterm
    | SalesOrderShippingAndSalesFormField.Terms
    | SalesOrderShippingAndSalesFormField.ShipComplete
  > {}

export interface ISalesOrderShippingAndSalesForm
  extends Overwrite<
    ISalesOrderShippingAndSalesInput,
    {
      [SalesOrderShippingAndSalesFormField.PackDate]: Date
      [SalesOrderShippingAndSalesFormField.SaleType]: IXtAutocompleteOption | null
      [SalesOrderShippingAndSalesFormField.Terms]: IXtAutocompleteOption | null
      [SalesOrderShippingAndSalesFormField.ShippingCharges]: IXtAutocompleteOption | null
      [SalesOrderShippingAndSalesFormField.SalesRep]: IXtAutocompleteOption | null
      [SalesOrderShippingAndSalesFormField.ShippingZone]: IXtAutocompleteOption | null
      [SalesOrderShippingAndSalesFormField.ShippingForm]: IXtAutocompleteOption | null
      [SalesOrderShippingAndSalesFormField.TaxZone]: IXtAutocompleteOption | null
    }
  > {}

export type SalesOrderShippingAndSalesFormDefaultValues = Pick<
  ISalesOrderShippingAndSalesForm,
  | SalesOrderShippingAndSalesFormField.ShippingCharges
  | SalesOrderShippingAndSalesFormField.SalesRep
  | SalesOrderShippingAndSalesFormField.ShippingForm
  | SalesOrderShippingAndSalesFormField.TaxZone
  | SalesOrderShippingAndSalesFormField.ShippingZone
  | SalesOrderShippingAndSalesFormField.Terms
  | SalesOrderShippingAndSalesFormField.SaleType
>
