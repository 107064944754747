export interface IContact1 {
  contact_number: number
  honorific: string
  first: string
  middle: string
  last: string
  suffix: string
  phone: string
  title: string
  fax: string
  email: string
}
export interface IContact2 {
  contact_number: number
  honorific: string
  first: string
  middle: string
  last: string
  suffix: string
  phone: string
  title: string
  fax: string
  email: string
}

export interface IBilltoAddress {
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  postal_code: string
  country: string
}

export interface IBillTo {
  billto_name: string
  contact: IContact1
  billto_address: IBilltoAddress
}

export interface IShiptoAddress {
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  postal_code: string
  country: string
}

export interface IShipTo {
  shipto_number: string
  shipto_name: string
  contact: IContact2
  shipto_address: IShiptoAddress
}

export interface IOrderItem {
  notes: string
  qty_uom: string
  tax_type: string
  price_uom: string
  customer_pn: string
  item_number: string
  line_number: string
  qty_ordered: number
  order_number: string
  customer_price: number
  net_unit_price: number
  scheduled_date: string
  sold_from_site: string
  discount_percent: string
}

export interface IQuote {
  quote_number: string
  site: string
  quote_date: string
  scheduled_date: string
  pack_date: string
  sale_type: string
  sales_rep: string
  commission: number
  tax_zone: string
  terms: string
  project_number: string
  customer_number: string
  customer_name: string
  bill_to: IBillTo
  ship_to: IShipTo
  shipping_zone: string
  customer_po_number: string
  fob: string
  ship_via: string
  currency: string
  currency_symbol: string
  order_total: number
  sales_total: number
  tax_total: number
  misc_charge_description: string
  misc_charge_account_number: string
  misc_charge_amount: number
  misc_charge_is_discount: boolean
  misc_charge_tax_type: string
  freight: number
  freight_tax_type: string
  order_notes: string
  shipping_notes: string
  expire_date: Date | string
  status: string
  order_items: IOrderItem[]
  quote_characteristics: []
  created: Date | string
  lastupdated: Date | string
}

export enum FilterFieldName {
  Site = 'site',
  ShowExpired = 'expired',
  ShowConverted = 'converted',
  ExcludeProspects = 'excludeProspects',
  Customer = 'customer',
  CustomerType = 'customerType',
  CustomerPattern = 'customerPattern',
  PONumber = 'poNumber',
  SalesRep = 'salesRep',
  StartDate = 'startDate',
  EndDate = 'endDate',
  CustomerNumber = 'customerNumber',
}

export interface IQuoteFilters {
  [FilterFieldName.Site]?: string
  [FilterFieldName.ShowExpired]?: boolean
  [FilterFieldName.ExcludeProspects]?: string
  [FilterFieldName.Customer]?: string
  [FilterFieldName.CustomerType]?: string
  [FilterFieldName.CustomerPattern]?: string
  [FilterFieldName.PONumber]?: string
  [FilterFieldName.SalesRep]?: string
  [FilterFieldName.StartDate]?: string
  [FilterFieldName.EndDate]?: string
  [FilterFieldName.CustomerNumber]?: string
  [FilterFieldName.ShowConverted]?: boolean
}
