import http from "../common/api";

const upload = (file, doctype, docid, docnum, onUploadProgress) => {
  let formdata = new FormData();
  formdata.append("file", file);

  formdata.append("doctype", doctype);

  if (docid && docnum) {
    formdata.append("docid", docid);
    formdata.append("docnum", docnum);
  }
  else if (docid) {
    formdata.append("docid", docid);
  }
  else if (docnum) {
    formdata.append("docnum", docnum)
  }
  else {
    return Promise.reject("At least one of docid or docnum must be provided");
  }

  return http.post("/file/create", formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

export default { upload };
