import { Overwrite } from 'utility-types'
import { IRoutingItem } from '../../routing/Routing.types'
import { BomItemCreateRequest, IBomItem } from '../bom.types'
import { IItem } from '../../items/Items.types'

export enum BomItemMode {
  Edit = 'edit',
  View = 'view',
  Replace = 'replace',
  Search = 'search',
}

export enum BomItemFormField {
  Item = 'item',
  Effective = 'effective',
  Expires = 'expires',
  ScheduleOperation = 'schedule_at_wo_operation',
  CreateChild = 'create_child_wo',
  IssueChild = 'issue_child_to_parent',
  FixedQty = 'qty_fxd',
  QtyPer = 'qty_per',
  IssueUom = 'issue_uom',
  IssueMethod = 'issue_method',
  EcnNumber = 'ecn_number',
  Scrap = 'scrap',
  UsedAt = 'used_at',
  SequenceNumber = 'sequence_number',
  Reference = 'reference',
  Notes = 'notes',
}

export enum FieldName {
  ItemNumber = 'Item #',
  Effective = 'Effective',
  Expires = 'Expires',
  SequenceCount = 'Sequence Count',
  FixedQty = 'Fixed Qty.',
  QtyPer = 'Qty. Per',
  IssueUOM = 'Issue UOM',
  IssueMethod = 'Issue Method',
  EcnNumber = 'ECN #',
  Scrap = 'Scrap %',
  UsedAt = 'Used At',
  Notes = 'Notes',
  Reference = 'Reference',
  Comments = 'Comments',
}

interface IBomItemFormOverrides {
  [BomItemFormField.Item]: IItem | null
  [BomItemFormField.Effective]: string | Date
  [BomItemFormField.Expires]: string | Date
  [BomItemFormField.Scrap]: string | number
  [BomItemFormField.QtyPer]: string | number
  [BomItemFormField.FixedQty]: string | number
}

export interface IBomItemForm
  extends Overwrite<
    Pick<
      IBomItem,
      | BomItemFormField.Effective
      | BomItemFormField.Expires
      | BomItemFormField.ScheduleOperation
      | BomItemFormField.CreateChild
      | BomItemFormField.FixedQty
      | BomItemFormField.QtyPer
      | BomItemFormField.IssueUom
      | BomItemFormField.IssueMethod
      | BomItemFormField.EcnNumber
      | BomItemFormField.Scrap
      | BomItemFormField.UsedAt
      | BomItemFormField.SequenceNumber
      | BomItemFormField.Reference
      | BomItemFormField.Notes
    >,
    IBomItemFormOverrides
  > {
  [BomItemFormField.IssueChild]: boolean
  [BomItemFormField.Item]: IItem | null
}

export interface IBomItemState {
  routingItems: IRoutingItem[]
  loading: boolean
}

export type BomItemSubmitFunc = (
  parentItemNumber: string,
  bomItem: IBomItem | null,
  newItem: BomItemCreateRequest,
  mode: BomItemMode
) => Promise<void>

export interface IBomItemParams {
  parentItemNumber: string
  bomItem: IBomItem | null
  open: boolean
  onSubmit: BomItemSubmitFunc
  onClose: () => void
  mode: BomItemMode
}
