import React from 'react'
import { MenuItem } from '@material-ui/core'
import { IssueMethod } from '../../../common/common.types'
import { IUomConversion } from '../../../services/UomService'

export function renderIssueMethods(): JSX.Element[] {
  return Object.values(IssueMethod).map((method) => (
    <MenuItem key={method} value={method}>
      {method}
    </MenuItem>
  ))
}

export function renderUomOptions(conversions: IUomConversion[]): JSX.Element[] {
  return (conversions ?? []).map(({ uom_to }) => (
    <MenuItem key={uom_to} value={uom_to}>
      {uom_to}
    </MenuItem>
  ))
}
