import * as React from 'react'
import { FC, KeyboardEventHandler } from 'react'
import { Key } from 'ts-key-enum'
import { CircularProgress } from '@material-ui/core'
import { SvgIcon } from '../svgIcon/SvgIcon'
import { cls } from '../../common/utils'
import * as styles from './XtButton.module.scss'
import { IXtButton, XtButtonVariant } from './XtButton.types'

export const XtButton: FC<IXtButton> = ({
  icon,
  label,
  className,
  onClick,
  disabled = false,
  type = 'button',
  iconClass,
  labelClass,
  variant = XtButtonVariant.Primary,
  hidden = false,
  loading = false,
}) => {
  const handleClick: () => void = () => {
    if (loading || disabled || typeof onClick !== 'function') {
      return
    }
    onClick()
  }

  const onKeyDown: KeyboardEventHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === Key.Enter) {
      handleClick()
    }
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={cls(styles.xtButton, className, styles[variant])}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      hidden={hidden}
    >
      <div className={styles.xtButtonSpinner}>
        <CircularProgress color="inherit" hidden={!loading} size={styles.xtButtonSpinnerSize} />
      </div>
      {icon && <SvgIcon className={cls(styles.xtButtonIcon, iconClass, loading ? styles.xtButtonIconHidden : '')} iconId={icon} />}
      <span className={cls(styles.xtButtonLabel, labelClass, loading ? styles.xtButtonLabelHidden : '')}>{label}</span>
    </button>
  )
}
