import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { IContact } from 'contacts/contacts.types'

export enum ContactFormField {
  Contact = 'contact',
}

export interface IContactSearch {
  onChange(contact: IContact | null, isDirty?: boolean, isValid?: boolean): void
  initialData: IContact | null
  isMobile?: boolean
  labelContact: string
  disabled?: boolean
  className?: string
}
export interface IContactOption extends IContact, IXtAutocompleteOption {}
export type ContactData = { label: string; value: string }
