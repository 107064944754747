import * as yup from 'yup'
import { validationMessage } from '../../common/validation/validation'
import { BomItemFormField } from './BomItem.types'

export const BomItemFormSchema = yup.object().shape({
  [BomItemFormField.Item]: yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [BomItemFormField.FixedQty]: yup.number().required(validationMessage.isRequired).typeError(validationMessage.invalidNumber),
  [BomItemFormField.QtyPer]: yup.number().required(validationMessage.isRequired).typeError(validationMessage.invalidNumber),
  [BomItemFormField.IssueUom]: yup.string().required(validationMessage.isRequired),
  [BomItemFormField.IssueMethod]: yup.string().required(validationMessage.isRequired),
  [BomItemFormField.Scrap]: yup.number().required(validationMessage.isRequired).typeError(validationMessage.invalidNumber),
})
