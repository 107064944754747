import * as React from 'react'
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { MenuItem } from '@material-ui/core'

import { IXtCommentParams, IXtCommentState } from './comment.types'
import styles from './comment.module.scss'
import { XtButton } from '../../components/xtButton/XtButton'
import { cls } from '../../common/utils'
import { ErrorHandler } from '../../services/ErrorService'
import { IXtAutocompleteOption } from '../../components/xtAutocomplete/XtAutocomplete.types'
import { XtAutocomplete } from '../../components/xtAutocomplete/XtAutocomplete'
import { loadCommentTypes } from './comment.utils'

const commentDateFormat = 'MMMM dd hh:mm a'

function renderOptions(option: IXtAutocompleteOption): React.ReactNode {
  return (
    <MenuItem key={option.id} value={option.id}>
      {option.label}
    </MenuItem>
  )
}

const defaultState = {
  type: null,
  value: '',
  loading: false,
  editMode: false,
}

export const XtComment: FC<IXtCommentParams> = React.memo(({ disabled = false, comment, onUpdate }) => {
  const [state, setState] = useState<IXtCommentState>(defaultState)

  const resetToInitialState = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      value: comment?.comment ?? prevState.value,
      type: comment?.comment_type ? { id: comment.comment_type, label: comment.comment_type } : null,
      editMode: false,
    }))
  }, [comment])

  useEffect(resetToInitialState, [comment])

  const cancel = useCallback<() => void>(resetToInitialState, [])

  const save = useCallback<VoidFunction>(() => {
    if (state.loading || !state.type) {
      return
    }
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      onUpdate({ ...comment, comment: state.value, comment_type: state.type.id })
      setState((prevState) => ({ ...prevState, loading: false, editMode: false }))
    } catch (e) {
      setState((prevState) => ({ ...prevState, loading: false }))
      ErrorHandler.handleError(e)
    }
  }, [onUpdate, comment, state.loading, state.type, state.value])

  const onCommentChange: (e: ChangeEvent<HTMLTextAreaElement>) => void = (e) => {
    const value = e?.target?.value
    setState((prevState) => ({ ...prevState, value }))
  }

  const commentDate = format(comment?.created ? new Date(comment.created) : new Date(), commentDateFormat)

  return (
    <div className={cls(styles.xtComment)}>
      <div className={styles.xtCommentHeader}>
        <p className={styles.xtCommentUserName}>{comment?.username}</p>
        <span className={styles.xtCommentDate}>{commentDate}</span>
      </div>
      <div className={styles.xtCommentContent}>
        <textarea
          disabled={disabled || !state.editMode}
          className={styles.xtCommentTextarea}
          placeholder="Comment"
          value={state.value}
          onChange={onCommentChange}
        />
        <div className={styles.xtCommentControls}>
          <XtAutocomplete
            placeholder="Comment Type"
            onChange={(type) => setState((prevState) => ({ ...prevState, type }))}
            disabled={disabled || !state.editMode}
            renderOption={renderOptions}
            loadOptions={loadCommentTypes}
            value={state.type}
          />
          <XtButton
            hidden={state.editMode || disabled}
            label="Edit"
            onClick={() => setState((prevState) => ({ ...prevState, editMode: true }))}
          />
          <div hidden={!state.editMode} className={styles.xtCommentButtonsSection}>
            <XtButton disabled={state.loading} label="Close" onClick={cancel} />
            <XtButton disabled={state.loading || !state.value || !state.type} label="Done" loading={state.loading} onClick={save} />
          </div>
        </div>
      </div>
    </div>
  )
})
