import * as yup from 'yup'
import { minDate, validationMessage } from 'common/validation/validation'
import { TaskFormField } from '../task-details.types'
import { PERCENT_MAX, PERCENT_MIN } from './task-details-form.constants'

export const TaskFormSchema = yup.object().shape({
  [TaskFormField.Name]: yup.string().required(validationMessage.isRequired),
  [TaskFormField.Priority]: yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [TaskFormField.Status]: yup.string().required(validationMessage.isRequired),
  [TaskFormField.PlannedStartDate]: yup
    .date()
    .notRequired()
    .nullable()
    .typeError(validationMessage.invalidDate)
    .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
  [TaskFormField.DueDate]: yup
    .date()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.invalidDate)
    .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
  [TaskFormField.StartedDate]: yup
    .date()
    .notRequired()
    .nullable()
    .typeError(validationMessage.invalidDate)
    .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
  [TaskFormField.CompletedDate]: yup
    .date()
    .notRequired()
    .nullable()
    .typeError(validationMessage.invalidDate)
    .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
  [TaskFormField.Percent]: yup
    .number()
    .max(PERCENT_MAX, validationMessage.max(PERCENT_MAX))
    .min(PERCENT_MIN, validationMessage.min(PERCENT_MIN))
    .typeError(validationMessage.isRequired),
})
