import { IFilterFormState } from 'components/filter/filter.types'
import { dateToServerFormat } from 'common/utils/date.utils'
import { ITaskListFilters } from 'tasks/tasks.service'
import { ITask } from '../tasks.types'

export const normalizedData = (data: ITask[]) =>
  data.map((item) => ({
    ...item,
    assignees: item.assignees?.map((v) => v.username).join(', '),
    parent: item.parent_number ? `${item.parent_type} - ${item.parent_number}` : item.parent_type,
  }))

export const postprocessFilters = (inputFilters: IFilterFormState): IFilterFormState => {
  const updatedFilters = { ...inputFilters }
  const dayFilterNames: (keyof ITaskListFilters)[] = [
    'duebefore',
    'dueafter',
    'plannedstartbefore',
    'plannedstartafter',
    'startbefore',
    'startafter',
  ]
  dayFilterNames.forEach((filterName) => {
    const filterValue = inputFilters[filterName]
    if (filterValue && filterValue instanceof Date) {
      updatedFilters[filterName] = dateToServerFormat(filterValue)
    }
  })

  return updatedFilters
}
