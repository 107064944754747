import React, { FC, useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import { cls } from 'common/utils'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import { useCharacteristics } from 'common/hooks/characteristics'
import { useDocuments } from 'common/hooks/documents'
import LoadingSpinner from 'components/LoadingSpinner'
import { XtItemNumber } from 'components/item-number/item-number'
import { ItemsService } from 'items/items.service'
import { IItemDetailsState } from './item-form.types'
import * as styles from './item-form.module.scss'
import { ItemTabs } from './item-from-tabs/item-form-tabs'
import { useRemarks } from '../../common/hooks/remarks'
import { DocumentType } from '../../documents/documents.types'

export const ItemForm: FC = () => {
  const { itemNumber } = useParams<{ itemNumber: string }>()
  const history = useHistory()
  const [{ loading, item }, setState] = useState<IItemDetailsState>({ loading: false, item: null })
  const remarksState = useRemarks(DocumentType.Item, itemNumber, item?.notes, item?.ext_description)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.Item, itemNumber, Boolean(itemNumber))

  async function init(): Promise<void> {
    if (!itemNumber) {
      return
    }
    try {
      setState({ loading: true, item: null })
      const itemData = await ItemsService.get(itemNumber)
      characteristicsState.reset(itemData?.item_characteristics || [])
      setState({ loading: false, item: itemData })
    } catch (error) {
      history.push('/items')
      ErrorHandler.handleError(error)
    }
  }

  useEffect(() => void init(), [])

  const onCancel = () => history.push('/items')
  const headerDescription =
    item?.description1 && item?.description2
      ? `${item?.description1} - ${item?.description2}`
      : `${item?.description1 ?? item?.description2}`
  return (
    <div>
      {loading && <LoadingSpinner />}
      <div hidden={loading} className="xt-content xt-content-with-remarks">
        <div className={cls(styles.itemFormHeader, 'xt-section-border')}>
          <XtItemNumber itemNumber={`Item #${item?.item_number}`} description={headerDescription} />
          <XtButton label="Cancel" onClick={onCancel} />
        </div>
        <div className={styles.itemDetailsContent}>
          <ItemTabs data={item} disabled remarks={remarksState} characteristics={characteristicsState} documents={documentsState} />
        </div>
      </div>
    </div>
  )
}
