import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IInventoryAvailability, IItem, IItemPrice, IOrderAvailabilityItem } from './Items.types'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse, IResponseStatusPagination } from '../common/common.types'
import { IItemsFilters } from './items-list/items-list.types'
import { prepareRequestParams } from '../common/utils/request.utils'
import { IOrderAvailabilityFilters } from '../sales-orders/components/sales-order-line-items/sales-order-line-item-details/sales-order-line-item-supply/sales-order-line-item-supply.types'

interface IItemPriceFilters {
  customer_number: string
  quantity: string | number
  site: string
  currency: string
}
export interface IItemsService {
  getAll(pagination: IPaginationParams, filters?: IItemsFilters): Promise<IPaginationData<IItem>>
  get(itemNumber: string): Promise<IItem>
  getItemPrices(itemNumber: string, params: IItemPriceFilters): Promise<IItemPrice>
  getInventoryAvailabilityItems(
    itemNumber: string,
    site: string,
    pagination: IPaginationParams
  ): Promise<IPaginationData<IInventoryAvailability>>
  getOrderAvailability(
    itemNumber: string,
    site: string,
    filters: IOrderAvailabilityFilters
  ): Promise<IResponse<IOrderAvailabilityItem, IResponseStatusPagination>>
}

class Service implements IItemsService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll(paginationParams: IPaginationParams, filters?: IItemsFilters): Promise<IPaginationData<IItem>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IItem>>('/item', { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(itemNumber: string): Promise<IItem> {
    const response = await this.apiClient.get<IResponse<IItem>>(`/item/${itemNumber}`)
    return response.data.data
  }

  public async getItemPrices(itemNumber: string, params: IItemPriceFilters): Promise<IItemPrice> {
    const response = await this.apiClient.get<IResponse<IItemPrice>>(`/item/${itemNumber}/prices`, { params })
    return response.data.data
  }

  public async delete(item_number: string): Promise<void> {
    const body = { data: { item_number } }
    await this.apiClient.post('/item/delete', body)
  }

  public async getInventoryAvailabilityItems(
    itemNumber: string,
    site: string,
    pagination: IPaginationParams
  ): Promise<IPaginationData<IInventoryAvailability>> {
    const params = { ...pagination, item_number: itemNumber, site }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IInventoryAvailability>>(`/inventoryavailability`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getOrderAvailability(
    itemNumber: string,
    site: string,
    filters: IOrderAvailabilityFilters
  ): Promise<IResponse<IOrderAvailabilityItem, IResponseStatusPagination>> {
    // TODO: TBD discuss with David about lineNumber and orderNumber and update me
    const {
      data: { data, status },
    } = await this.apiClient.get<IResponse<IOrderAvailabilityItem, IResponseStatusPagination>>(
      `/item/${itemNumber}/orderavailability/${site}`,
      {
        params: filters,
      }
    )

    return {
      data,
      status,
    }
  }
}

export const ItemsService = new Service(api)
