import { convertPhone } from 'contacts/contact-search/contact-search.utils'
import { PhoneRole, IContact } from 'contacts/contacts.types'
import { IContactTableItem } from './contact-list.types'

export const normalizeData = (items: IContact[], number: string): IContactTableItem[] =>
  items.map((contact, index) => ({
    id: contact.contact_number ? contact.contact_number.toString() : index.toString(),
    first_name: contact?.first_name ?? '',
    last_name: contact?.last_name ?? '',
    job_title: contact?.job_title ?? '',
    email: contact?.email ?? '',
    created: contact?.created ?? null,
    lastupdated: contact?.lastupdated ?? null,
    web: contact?.web,
    account_number: number ?? '',
    office_phone: convertPhone(PhoneRole.Office, contact?.phones),
    mobile_phone: convertPhone(PhoneRole.Mobile, contact?.phones),
    fax_phone: convertPhone(PhoneRole.Fax, contact?.phones),
    address: contact?.contact_address?.address1 ?? '',
    city: contact?.contact_address?.city ?? '',
    state: contact?.contact_address?.state ?? '',
    country: contact?.contact_address?.country ?? '',
    postalcode: contact?.contact_address?.postalcode ?? '',
    active: contact?.active ? 'yes' : 'no',
    owner: contact?.owner ?? '',
    company: contact?.company ?? '',
  }))

export const defaultSelectDialogState = { open: false, selectData: null }
export const defaultContactDialogFormState = { open: false, selectData: null, mode: null }
export const defaulDeletionState = { open: false, contact: null }
