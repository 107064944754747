import { IDashboard } from 'services/DashboardService'
import { IDataChart } from './home.types'

export const convertSalesData = ({ data, labels }: IDashboard): IDataChart => ({
  labels,
  datasets: [
    {
      label: 'Sales',
      data,
      backgroundColor: '#70CAD1',
    },
  ],
})

export const converDeliveryData = ({ data, labels }: IDashboard): IDataChart => ({
  labels,
  datasets: [
    {
      label: 'On-Time Delivery Percentage',
      data,
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    },
  ],
})
