import * as React from 'react'
import { Control } from 'react-hook-form'
import { IFilterFormState, IFilter } from './filter.types'
import { FormCheckboxLabel } from '../../common/utils/form/form.components'
import { IXtAutocompleteOption } from '../xtAutocomplete/XtAutocomplete.types'

export function makeCheckboxFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>
): JSX.Element {
  return <FormCheckboxLabel label={filter.label} key={`filter-${filter.fieldName}-controller`} control={control} name={filter.fieldName} />
}
