import { IAssignee, ITask, ITaskCreatePayload } from 'tasks/tasks.types'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { characteristicsToPayload } from 'characteristics/characteristic.utils'
import { PERCENT_MAX, PERCENT_MIN } from './task-details-form/task-details-form.constants'
import { ITaskForm, TaskDetailsMode, TaskFormField } from './task-details.types'
import { NumberTypeUtils } from '../../common/typeUtils'
import { dateToServerFormat } from '../../common/utils/date.utils'

export const defineFormState: (data: ITask | null) => ITaskForm = (data) => {
  const percent: () => number = () => {
    if (data?.completed_date) {
      return PERCENT_MAX
    }
    return data?.percent_complete ?? PERCENT_MIN
  }
  return {
    [TaskFormField.Name]: data?.name ?? '',
    [TaskFormField.Description]: data?.description ?? '',
    [TaskFormField.Priority]: data?.priority ? { id: data?.priority, label: data?.priority } : null,
    [TaskFormField.Status]: data?.status ?? 'New',
    [TaskFormField.Owner]: data?.owner ? { id: data?.owner, label: data?.owner } : null,
    [TaskFormField.DueDate]: data?.due_date ? new Date(data?.due_date) : new Date(),
    [TaskFormField.PlannedStartDate]: data?.planned_start_date ? new Date(data?.planned_start_date) : null,
    [TaskFormField.CompletedDate]: data?.completed_date ? new Date(data?.completed_date) : null,
    [TaskFormField.StartedDate]: data?.actual_start_date ? new Date(data?.actual_start_date) : null,
    [TaskFormField.Percent]: percent(),
  }
}

export const convertMode = (mode: TaskDetailsMode | null, isNewMode: boolean) => {
  if (!mode || isNewMode) {
    return [false, false]
  }
  if (Object.values(TaskDetailsMode).includes(mode)) {
    switch (mode) {
      case TaskDetailsMode.Edit:
        return [false, true]
      default:
        return [true, false]
    }
  }
  return [true, false]
}

// TODO remove me when the server is fixed
/**
 * the functions is required to replace all the empty dates with null. Otherwise server responds with error.
 * @param assignees
 */
function processAssignees(assignees: IAssignee[]): IAssignee[] {
  return assignees.map(({ assigned_date, ...other }) => ({
    ...other,
    assigned_date: assigned_date !== '' ? assigned_date : null,
  }))
}

export function convertFormDataToTaskPayload(
  formData: ITaskForm,
  assignees: IAssignee[],
  notes: string,
  username: string,
  characteristics: IAppliedCharacteristic[]
): ITaskCreatePayload {
  const defaultAssigneeState = { username, role: 'Primary', assigned_date: dateToServerFormat(new Date()) }
  return {
    ...formData,
    owner: formData?.owner?.id ?? '',
    priority: formData?.priority?.id ?? '',
    assignees: !assignees?.length ? [defaultAssigneeState] : processAssignees(assignees),
    due_date: dateToServerFormat(formData?.due_date!),
    planned_start_date: formData?.planned_start_date ? dateToServerFormat(formData?.planned_start_date) : null,
    actual_start_date: formData?.actual_start_date ? dateToServerFormat(formData?.actual_start_date) : null,
    completed_date: formData?.completed_date ? dateToServerFormat(formData?.completed_date) : null,
    percent_complete: NumberTypeUtils.parseString(formData?.percent_complete),
    notes,
    comments: [], // TODO check that comments are not overridden
    task_characteristics: characteristicsToPayload(characteristics),
  }
}
