import { CommentCreateRequest, NewComment } from './comments.types'
import { DocumentType } from '../documents/documents.types'
import { CommentItem } from './comment/comment.types'

export function convertNewComment({ comment, comment_type }: NewComment, source: DocumentType, sourceNumber: string): CommentCreateRequest {
  return {
    comment,
    comment_type,
    source,
    comment_source_number: sourceNumber,
  }
}

export function convertNewComments(comments: NewComment[], source: DocumentType, sourceNumber: string): CommentCreateRequest[] {
  return comments.map((comment) => convertNewComment(comment, source, sourceNumber))
}

export function convertToNewComment({ comment, comment_type }: CommentItem): NewComment {
  return { comment, comment_type }
}

export function convertToNewComments(comments: CommentItem[]): NewComment[] {
  return comments.map(convertToNewComment)
}
