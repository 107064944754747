import { IQuote } from '../../quotes.types'

export enum QuotesDialogFormField {
  Customer = 'customer_number',
  CustomerName = 'customer_name',
  ScheduledDate = 'scheduled_date',
}
export enum QuotesDialogFormLabel {
  Customer = 'Customer #',
  CustomerName = 'Name',
  ScheduledDate = 'Target Schedule Date',
}

export interface IQuotesDialogFormData {
  [QuotesDialogFormField.Customer]: string
  [QuotesDialogFormField.CustomerName]: string
  [QuotesDialogFormField.ScheduledDate]: string
}

export interface ICopyQuotesDialog {
  open: boolean
  onClose(): void
  onConfirm(item: IQuotesDialogFormData): void
  quoteData: IQuote | null
}
