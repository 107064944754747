import { deepEqual } from 'fast-equals'

export function filterByField<TData, TField extends keyof TData>(data: TData[] = [], field: TField): TData[] {
  const map = new Map([...data.map<readonly [TData[TField], TData]>((item) => [item[field], item])])
  return Array.from(map.values())
}

export function updateByFieldValue<TData, TField extends keyof TData>(data: TData[] = [], field: TField, item: TData): TData[] {
  const index = data.findIndex((dataItem) => dataItem[field] === item[field])
  return [...data.slice(0, index), item, ...data.slice(index + 1)]
}

export function areEqual<T1 extends Array<unknown>, T2 extends Array<unknown>>(array1: T1, array2: T2): boolean {
  if (array1.length !== array2.length) {
    return false
  }
  return deepEqual(array1, array2)
}
