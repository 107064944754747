import * as React from 'react'
import { FC, useEffect, useState, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingSpinner from 'components/LoadingSpinner'
import { useMediaQuery } from '@material-ui/core'
import { xsMq } from 'common/constants'
import { cls } from 'common/utils'
import { ErrorHandler } from 'services/ErrorService'
import { ToastService } from 'services/ToasterService'
import { XtItemNumber } from 'components/item-number/item-number'
import { useCharacteristics } from 'common/hooks/characteristics'
import { useRemarks } from 'common/hooks/remarks'
import { useDocuments } from 'common/hooks/documents'
import { DocumentType } from 'documents/documents.types'
import { XtButton } from 'components/xtButton/XtButton'
import { CRMProspectFormSchema } from './crm-prospect-details-form/crm-prospect-details-form.validation'
import { convertMode, defineFormState } from '../crm-prospects.utils'
import { CRMProspectDetailsMode, ICRMProspectDetailsState, ICRMProspectForm } from './crm-prospect-details.types'
import { CRMProspectDetailsForm } from './crm-prospect-details-form/crm-prospect-details-form'
import { CRMProspectService } from '../crm-prospects.service'
import * as styles from './crm-prospect-details.module.scss'
import { CRMProspectDetailsTabs } from './crm-prospect-details-tabs/crm-prospect-details-tabs'
import { ICRMProspectUpdatePayload } from '../crm-prospects.types'
import { convertFormDataToTaskPayload } from './crm-prospect-details.utils'
import { convertToNewComments } from '../../comments/comments.utils'

const defaultCRMProspectState = { loading: false, prospect: null, isAdditionallyDirty: false, characteristics: [] }

export const CRMProspectDetails: FC = () => {
  const isMobile = useMediaQuery(xsMq)
  const { id, mode } = useParams<{ id: string; mode: CRMProspectDetailsMode }>()
  const history = useHistory()
  const [isViewMode, isEditMode] = convertMode(mode, !id)
  const isNewMode = !id && !mode

  const [state, setState] = useState<ICRMProspectDetailsState>(defaultCRMProspectState)
  const characteristicsState = useCharacteristics()
  const remarks = useRemarks(DocumentType.Prospect, state.prospect?.number, state.prospect?.notes || '')
  const documents = useDocuments(DocumentType.Prospect, state.prospect?.number)
  const markAsDirtyCallback = useCallback(() => setState((prev) => ({ ...prev, isAdditionallyDirty: true })), [setState])

  const formMethods = useForm({
    defaultValues: defineFormState(null),
    resolver: yupResolver(CRMProspectFormSchema),
    mode: 'all',
  })

  const {
    formState: { isDirty: isFormDirty, isSubmitting },
    reset,
    handleSubmit,
  } = formMethods

  async function init(): Promise<void> {
    try {
      setState((prev) => ({ ...prev, loading: true, task: null }))
      if (isNewMode) {
        if (isMobile) {
          history.push('/crm/prospects')
        }
        setState(defaultCRMProspectState)
        return
      }
      const prospectData = await CRMProspectService.get(id)
      reset(defineFormState(prospectData))
      setState((prev) => ({
        ...prev,
        loading: false,
        prospect: prospectData,
      }))
      characteristicsState.reset(prospectData.prospect_characteristics || [])
    } catch (error) {
      history.push('/crm/prospects')
      ErrorHandler.handleError(error)
    }
  }

  useEffect(() => void init(), [])

  const onCancel = (): void => history.push('/crm/prospects')

  const onSubmit: (data: ICRMProspectForm) => Promise<void> = async (formValues) => {
    try {
      const payload: ICRMProspectUpdatePayload = {
        ...convertFormDataToTaskPayload(formValues, characteristicsState.characteristics, remarks.notes),
      }
      if (!state.prospect || !isEditMode) {
        await CRMProspectService.create({ ...payload, comments: convertToNewComments(remarks.comments) }, documents.getUnsavedDocuments())

        ToastService.showSuccess(`Prospect ${formValues.name} has been created.`)
      } else {
        await CRMProspectService.update({ ...payload })
        ToastService.showSuccess(`Prospect ${formValues.name} has been updated.`)
      }

      history.push('/crm/prospects')
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const disabled = isViewMode || isSubmitting || isMobile
  const editTitle = `${state.prospect?.number}${state.prospect?.name ? `: ${state.prospect?.name}` : ''}`
  const isDirty = isFormDirty || state.isAdditionallyDirty || remarks.isDirty || characteristicsState.isDirty || documents.isDirty

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formMethods}>
        {state.loading && <LoadingSpinner />}
        <form hidden={state.loading} onSubmit={handleSubmit(onSubmit)} className="xt-content xt-content-with-remarks">
          <div className={cls(styles.prospectDetailsHeader, 'xt-page-header')}>
            <XtItemNumber itemNumber={!isNewMode ? editTitle : 'New Prospect'} />
            <div className={styles.prospectDetailsHeaderButtons}>
              <XtButton label="Cancel" onClick={onCancel} />
              <XtButton disabled={disabled || !isDirty} loading={isSubmitting} label="Save" type="submit" />
            </div>
          </div>
          <div>
            <CRMProspectDetailsForm
              isViewMode={isViewMode}
              isNewMode={isNewMode}
              initialData={state.prospect}
              markAsDirtyCallback={markAsDirtyCallback}
            />
          </div>
          <CRMProspectDetailsTabs
            prospectNumber={id}
            characteristics={characteristicsState.characteristics}
            onDeleteCharacteristic={characteristicsState.deleteCharacteristic}
            onUpdateCharacteristic={characteristicsState.updateCharacteristic}
            onCreateCharacteristic={characteristicsState.createCharacteristic}
            isMobile={isMobile}
            disabled={disabled}
            markAsDirtyCallback={markAsDirtyCallback}
            prospect={state.prospect}
            mode={mode}
            remarks={remarks}
            documents={documents}
          />
        </form>
      </FormProvider>
    </div>
  )
}
