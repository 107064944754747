import * as React from 'react'
import { FC, memo, useMemo } from 'react'

import { MenuItem } from '@material-ui/core'
import { FormField, FormSelectField, FormDatePicker } from 'common/utils/form/form.components'
import { CharacteristicType, CharacteristicDialogFormField } from '../characteristics.types'
import { CharacteristicDialogFormLabel, ICharacteristicDialogValue } from './characteristic-dialog.types'

export const CharacteristicDialogValueInput: FC<ICharacteristicDialogValue> = memo(({ type, control, options }) => {
  const label = CharacteristicDialogFormLabel.Value
  const name = CharacteristicDialogFormField.Value
  const items = useMemo<JSX.Element[]>(
    () =>
      options.map(({ option_value }) => (
        <MenuItem value={option_value} key={option_value}>
          {option_value}
        </MenuItem>
      )),
    [options]
  )
  switch (type) {
    case CharacteristicType.Text:
      return <FormField control={control} name={name} label={label} />
    case CharacteristicType.List:
      return <FormSelectField options={items} control={control} name={name} label={label} />
    case CharacteristicType.Date:
      return <FormDatePicker control={control} name={name} label={label} />
    case CharacteristicType.Number:
      // TODO type number
      return <FormField type="number" control={control} name={name} label={label} />
    default:
      return <FormField control={control} name={name} label={label} disabled />
  }
})
