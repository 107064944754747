import { IObjectWithId } from 'common/common.types'
import { IContact } from 'contacts/contacts.types'

export enum ContactAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'view',
}

export interface IContactTableItem
  extends IObjectWithId,
    Pick<IContact, 'first_name' | 'last_name' | 'job_title' | 'email' | 'created' | 'lastupdated' | 'web' | 'owner' | 'company'> {
  account_number: string
  office_phone: string
  mobile_phone: string
  fax_phone: string
  address: string
  city: string
  state: string
  country: string
  postalcode: string
  active: string
}

export interface IContactDialogFormState {
  open: boolean
  selectData: IContact | null
  mode: ContactAction | null
}
export interface ISelectDialogState {
  open: boolean
  selectData: IContact | null
}

export interface IContactList {
  account_number: string
  account_name: string
  isMobile: boolean
  disabled: boolean
}

export interface IDeletionState {
  contact: IContact | null
  open: boolean
}
