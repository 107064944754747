import { IXtAutocompleteOption } from '../../components/xtAutocomplete/XtAutocomplete.types'
import { DictionaryService } from '../../services/dictionary.service'
import { parseAutocompleteOption } from '../utils'

export type CountryOption = IXtAutocompleteOption & { hasStates?: boolean }
export type CountryStates = { initialState: IXtAutocompleteOption | string | null; initialCountry: CountryOption | null }

export function convertStateForRequest(state: string | IXtAutocompleteOption | null = ''): string {
  return typeof state === 'object' ? parseAutocompleteOption(state) : state
}

export async function getCountryStates(countryId?: string, stateId: string = ''): Promise<CountryStates> {
  if (!countryId) {
    return {
      initialState: '',
      initialCountry: null,
    }
  }
  const [states, country] = await Promise.all([DictionaryService.getStates(countryId), DictionaryService.get(countryId)])
  const state = stateId ? states.data.find(({ state_code }) => state_code === stateId) : null

  return {
    initialState: state ? { id: state.state_code, label: state.state } : stateId,
    initialCountry: { id: country.country_code, label: country.country, hasStates: Boolean(states.data.length) },
  }
}
