import React, { FC, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { ToastService } from 'services/ToasterService'
import { useMediaQuery } from '@material-ui/core'
import { XtFilter } from 'components/filter/filter'
import { useFilter } from 'common/hooks/filter'
import { XtFilterButton } from 'components/filter/filterButton'
import { useTable } from '../../common/hooks/useTable'
import { cls } from '../../common/utils'
import { XtButton } from '../../components/xtButton/XtButton'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import * as styles from './sales-orders-list.module.scss'
import { requestSalesOrders } from './sales-orders-list.utils'
import { SalesOrderRow, SalesOrdersListAction } from './sales-orders-list.types'
import { defaultFilterValues, filterFields, salesOrderActions, tableColumns } from './sales-orders-list.constants'
import { XtList } from '../../components/list/list'
import { SalesOrderMode } from '../sales-orders-new/sales-orders-new.types'
import { TableActionHandler } from '../../common/types/table.types'
import { useConfirmationDialog } from '../../common/hooks/confirmation-dialog'
import { XtConfirmationDialog } from '../../components/xtConfirmationDialog/XtConfirmationDialog'
import { confirmationMessages, xsMq } from '../../common/constants'
import { SalesOrdersService } from '../sales-orders.service'
import { ErrorHandler } from '../../services/ErrorService'
import { IFilter } from '../../components/filter/filter.types'

export const SalesOrdersList: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const isMobile = useMediaQuery(xsMq)
  const { itemId, open, openDialog, closeDialog } = useConfirmationDialog<string>()

  const { state, pagination, refresh, filter } = useTable({}, requestSalesOrders)
  // TODO fix useFilter typification
  const { openFilters, closeFilters, onFiltersSubmit, filterOpen, filters } = useFilter(async () => filterFields as IFilter[], filter)
  const handleClick = useCallback<(item: SalesOrderRow) => void>(
    (item) => {
      history.push(`${path}/${item.order_number}/${SalesOrderMode.Edit}`)
    },
    [history, path]
  )
  const copySalesOrder = async (orderNumber: string) => {
    try {
      await SalesOrdersService.copySalesOrder(orderNumber)
      ToastService.showSuccess(`Sales Order ${orderNumber} has been copied.`)
      await refresh()
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const handleAction = useCallback<TableActionHandler<SalesOrderRow, SalesOrdersListAction>>(
    (item, action) => {
      switch (action) {
        case SalesOrdersListAction.View: {
          history.push(`${path}/${item.order_number}/${SalesOrderMode.View}`)
          break
        }
        case SalesOrdersListAction.Edit: {
          history.push(`${path}/${item.order_number}/${SalesOrderMode.Edit}`)
          break
        }
        case SalesOrdersListAction.Delete: {
          openDialog(item.order_number)
          break
        }
        case SalesOrdersListAction.Copy: {
          void copySalesOrder(item.order_number)
          break
        }
        case SalesOrdersListAction.Print: {
          console.log('PRINT') // TODO update me
          break
        }
        default: {
          history.push(`${path}/${item.order_number}/${SalesOrderMode.View}`)
        }
      }
    },
    [history, path, openDialog]
  )

  const onDeletionConfirm = useCallback<() => Promise<void>>(async () => {
    try {
      if (!itemId) {
        return
      }
      await SalesOrdersService.delete(itemId)
      ToastService.showSuccess(`Sales Order ${itemId} has been deleted.`)
      closeDialog()
      await refresh()
    } catch (e) {
      ErrorHandler.handleError(e)
      closeDialog()
    }
  }, [itemId, closeDialog])

  const openSalesOrderForm = useCallback<() => void>(() => history.push(`${path}/new`), [path, history])

  return (
    <div>
      <XtConfirmationDialog
        open={open}
        message={confirmationMessages.deleted}
        title="Delete Sales Order"
        confirmationButtonLabel="Delete"
        onConfirm={onDeletionConfirm}
        onClose={closeDialog}
      />
      {filters.length && (
        <XtFilter
          defaultValues={defaultFilterValues}
          open={filterOpen}
          onClose={closeFilters}
          onSubmit={onFiltersSubmit}
          filters={filters}
        />
      )}
      <div className={cls('xt-content', styles.salesOrdersListContent)}>
        <div className={cls(styles.salesOrdersListHeader, 'xt-section-border')}>
          <h3 className={cls('xt-page-title')}>Sales Orders</h3>

          <XtButton
            onClick={openSalesOrderForm}
            label="New Sales Order"
            hidden={isMobile}
            icon={SvgIconIds.ADD_CIRCLE}
            className={styles.salesOrdersListNewButton}
            iconClass={styles.salesOrdersListNewButtonIcon}
            labelClass={styles.salesOrdersListNewButtonLabel}
          />
        </div>
        <div className={styles.filters}>
          <XtFilterButton isMobile={isMobile} loading={state.loading} onClick={openFilters} />
        </div>
        <XtList
          isMobile={isMobile}
          onRowClick={handleClick}
          onAction={handleAction}
          columns={tableColumns}
          data={state.data}
          loading={state.loading}
          pagination={pagination}
          actions={salesOrderActions}
        />
      </div>
    </div>
  )
}
