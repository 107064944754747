import React, { useCallback, FC, useEffect, memo, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { cls, loadContactOptions } from 'common/utils'
import { IContact } from 'contacts/contacts.types'
import { FormXtAutocomplete } from 'common/utils/form/form.components'

import { buildFullContactName } from 'contacts/contacts.utils'
import styles from './contact-search.module.scss'
import { ContactData, ContactFormField, IContactOption, IContactSearch } from './contact-search.types'
import { convertContact } from './contact-search.utils'

export const defineSelectDialogItem = (data: IContact | null) => ({
  [ContactFormField.Contact]: data ? { ...data, id: data.contact_number, label: buildFullContactName(data) } : null,
})

export const ContactSearch: FC<IContactSearch> = memo(
  ({ onChange, initialData, isMobile = false, labelContact, disabled = false, className }) => {
    const { control, watch, setValue, reset, formState } = useForm({
      defaultValues: defineSelectDialogItem(null),
      mode: 'onBlur',
    })
    const { isValid, isDirty } = formState
    const contact = watch(ContactFormField.Contact)

    useEffect(() => void reset(defineSelectDialogItem(initialData ?? null)), [initialData])

    useEffect(() => onChange(contact ? { ...contact, accounts: contact?.accounts ?? [] } : null, isDirty, isValid), [formState])

    const setContactValue = useCallback<(contactOption: IContactOption) => void>(
      (contactOption: IContactOption) => setValue(ContactFormField.Contact, contactOption, { shouldValidate: true, shouldDirty: true }),
      []
    )

    const convertedContactData = useMemo<ContactData[]>(() => convertContact(contact, isMobile, labelContact), [
      contact,
      isMobile,
      labelContact,
    ])

    return (
      <div className={cls(styles.addressContent, className)}>
        <div hidden={isMobile}>
          <FormXtAutocomplete
            disabled={disabled}
            control={control}
            name={ContactFormField.Contact}
            className={styles.xtAutocompleteContact}
            label={labelContact}
            loadOptions={loadContactOptions}
            onChange={setContactValue}
          />
        </div>
        {convertedContactData.map(({ label, value }) => (
          <span key={label} className={styles.itemContent}>
            <p className={styles.label}>{label}: &nbsp;</p>
            <p className={styles.value} title={value}>
              {value}
            </p>
          </span>
        ))}
      </div>
    )
  }
)
