import * as React from 'react'
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import * as Icon from 'react-feather'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import {
  ISalesOrderBillToAndShipToParams,
  ISalesOrderContactAndAddress,
  SalesOrderBillToAndShipToFormState,
} from './sales-order-bill-to-and-ship-to.types'
import * as styles from './sales-order-bill-to-and-ship-to.module.scss'
import { SalesOrderBillToAndShipToForm } from './sales-order-bill-to-and-ship-to-form/sales-order-bill-to-and-ship-to-form'
import { XtButton } from '../../../../components/xtButton/XtButton'
import { SvgIconIds } from '../../../../components/svgIcon/SvgIcon.types'
import { IBillToAndShipToForm } from './sales-order-bill-to-and-ship-to-form/sales-order-bill-to-and-ship-to-form.types'
import { convertFormDataToBillTo, isValidForm } from './sales-order-bill-to-and-ship-to.utils'
import { globalConstants } from '../../../../common/constants'
import { IFormStateChanges } from '../../../../common/hooks/form/form.types'

export const SalesOrderBillToAndShipTo: FC<ISalesOrderBillToAndShipToParams> = memo(
  ({ customer, billTo, shipTo, viewMode, onChange, validationObservable }) => {
    const [shipToValue, setShipToValue] = useState<ISalesOrderContactAndAddress | null>(shipTo)
    const [showInvalid, setShowInvalid] = useState<boolean>(false)

    const billToSubject = useRef<BehaviorSubject<SalesOrderBillToAndShipToFormState>>(
      new BehaviorSubject<SalesOrderBillToAndShipToFormState>({
        data: billTo,
        state: { isValid: false, isDirty: false, touched: false },
      })
    )
    const shipToSubject = useRef<BehaviorSubject<SalesOrderBillToAndShipToFormState>>(
      new BehaviorSubject<SalesOrderBillToAndShipToFormState>({
        data: shipTo,
        state: { isValid: false, isDirty: false, touched: false },
      })
    )

    useEffect(() => {
      setShipToValue(shipTo)
    }, [shipTo])

    useEffect(() => {
      const sub = combineLatest([billToSubject.current.asObservable(), shipToSubject.current.asObservable()])
        .pipe(debounceTime(globalConstants.formChangeDebounce), filter(isValidForm))
        .subscribe(([billToChange, shipToChange]) => {
          const isValid = billToChange.state.isValid && shipToChange.state.isValid
          const isDirty = billToChange.state.isDirty || shipToChange.state.isDirty
          const touched = billToChange.state.touched || shipToChange.state.touched

          setShowInvalid(!isValid && touched)

          onChange({
            data: {
              billTo: billToChange.data,
              shipTo: shipToChange.data,
            },
            state: {
              isValid,
              isDirty,
              touched,
            },
          })
        })
      return () => sub.unsubscribe()
    }, [onChange])

    const onBillToChange = useCallback<(change: IFormStateChanges<IBillToAndShipToForm>) => void>(({ data, state }) => {
      billToSubject.current.next({
        data: convertFormDataToBillTo(data),
        state,
      })
    }, [])

    const onShipToChange = useCallback<(change: IFormStateChanges<IBillToAndShipToForm>) => void>(({ data, state }) => {
      shipToSubject.current.next({
        data: convertFormDataToBillTo(data),
        state,
      })
    }, [])

    const copyToShipTo: VoidFunction = () => {
      shipToSubject.current.next(billToSubject.current.value)
      setShipToValue(billToSubject.current.value.data)
    }

    const disabled = viewMode || !customer?.customer_number

    return (
      <Accordion component="section">
        <AccordionSummary
          classes={{ root: showInvalid ? 'xt-invalid' : '' }}
          expandIcon={<Icon.ChevronDown />}
          aria-controls="panel1a-content"
        >
          <Typography>Bill To and Ship To</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.salesOrderBillToAndShipTo}>
            <SalesOrderBillToAndShipToForm
              validationObservable={validationObservable}
              label="Bill To"
              customerNumber={customer?.customer_number ?? null}
              contact={billTo?.contact}
              address={billTo?.address}
              disabled={disabled || !customer?.allow_free_form_billto}
              onChange={onBillToChange}
            />
            <XtButton
              disabled={disabled || !customer?.allow_free_form_shipto}
              label="Copy to Ship-to"
              onClick={copyToShipTo}
              icon={SvgIconIds.ARROW}
              className={styles.salesOrderBillToAndShipToButton}
              iconClass={styles.salesOrderBillToAndShipToButtonIcon}
              labelClass={styles.salesOrderBillToAndShipToButtonLabel}
            />
            <SalesOrderBillToAndShipToForm
              validationObservable={validationObservable}
              label="Ship To"
              customerNumber={customer?.customer_number ?? null}
              contact={shipToValue?.contact}
              address={shipToValue?.address}
              disabled={disabled || !customer?.allow_free_form_shipto}
              onChange={onShipToChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
)
