import { AxiosInstance } from 'axios'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../common/common.types'
import api from '../common/api'
import { prepareRequestParams } from '../common/utils/request.utils'

export interface ICountry {
  country_code: string
  country: string
  currency: string
  currency_symbol: string
}

export interface IState {
  state_code: string
  state: string
  country: string
}

export interface ICurrencyRate {
  effective_date: string
  expiry_date: string
  rate: number
}

export interface ICurrency {
  currency: string
  symbol: string
  iso_code: string
  base_currency: boolean
  conversion_basis: string
  rates: ICurrencyRate[]
}

export interface ICurrenciesFilters {
  currency?: string
}

export interface ICountriesService {
  getAll(params: IPaginationParams): Promise<IPaginationData<ICountry>>
  get(countryCode: string): Promise<ICountry>
  getStates(countryCode: string, params?: IPaginationParams): Promise<IPaginationData<IState>>
  getCurrencies(params: IPaginationParams, filters?: ICurrenciesFilters): Promise<IPaginationData<ICurrency>>
}

class Service implements ICountriesService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<ICountry>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICountry>>('/countries', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(countryCode: string): Promise<ICountry> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<ICountry>>(`/countries/${countryCode}`)
    if (!Object.keys(data).length) {
      throw new Error(`Country with the code: ${countryCode} is not found.`)
    }
    return data
  }

  public async getStates(countryCode: string, pagination?: IPaginationParams): Promise<IPaginationData<IState>> {
    const params = prepareRequestParams(pagination)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IState>>(`/states/${countryCode}`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCurrencies({ limit, page }: IPaginationParams, filters?: ICurrenciesFilters): Promise<IPaginationData<ICurrency>> {
    const params: Record<string, true | number | string> = { limit, page: page === 0 ? 1 : page }

    if (filters?.currency) {
      params.currency = filters.currency
    }

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICurrency>>('/currency', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}

export const DictionaryService: ICountriesService = new Service(api)
