import React, { FC } from 'react'

import { Modal, Slide } from '@material-ui/core'
import { globalConstants } from 'common/constants'

import { TaskDetails } from 'tasks/task-details/task-details.component'
import { TaskDetailsMode } from 'tasks/task-details/task-details.types'
import styles from './task-details-dialog.module.scss'

interface ITaskDetailsDialog {
  open: boolean
  onCancel(): void
  onSubmit(): void
  mode: TaskDetailsMode | null
  id: number | null
}
export const TaskDetailsDialog: FC<ITaskDetailsDialog> = ({ open, id, mode, onCancel, onSubmit }) => (
  <div>
    <Modal open={open}>
      <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
        <div className={styles.taskDetailsContent}>
          <TaskDetails onCancel={onCancel} onSubmit={onSubmit} id={id} mode={mode} />
        </div>
      </Slide>
    </Modal>
  </div>
)
