import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../common/common.types'

export interface IWorkingCenter {
  active: boolean
  average_setup_time: number
  capacity_load_uom: string
  code: string
  daily_capacity: number
  department: string
  description: string
  efficiency_factor: number
  notes: string
  number_of_machines: number
  number_of_people: number
  percent_labor: number
  queue_days: number
  rate_per_labor_hr: number
  rate_per_machine_hr: number
  rate_per_production_unit: number
  run_labor_rate: number
  run_standard_labor?: string
  setup_labor_rate: number
  setup_standard_labor?: number
  site: string
  wip_location: string
}

export interface IWorkingCenterService {
  getAll: (params: IPaginationParams) => Promise<IPaginationData<IWorkingCenter>>
}

class Service implements IWorkingCenterService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<IWorkingCenter>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IWorkingCenter>>('/workcenter', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}

export const WorkCenterService = new Service(api)
