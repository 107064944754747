import { AxiosInstance } from 'axios'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import api from 'common/api'
import { DocumentCreationError, DocumentType, IAttachedDocumentWithFile, IDocumentsService } from 'documents/documents.types'
import { DocumentsService } from 'documents/documents.service'
import { ContactCreatePayload, IContact, IContactAddress } from './contacts.types'

export interface IContactService {
  getAll: (params: IPaginationParams, filters?: IContactFilters) => Promise<IPaginationData<IContact>>
  get: (contactNumber: string) => Promise<IContact>
  delete(contact_number: string): Promise<void>
  create(body: ContactCreatePayload, documents: IAttachedDocumentWithFile[]): Promise<IContact>
  update(body: ContactCreatePayload): Promise<void>
  getAddresses(params: IPaginationParams, accountNumber?: string): Promise<IPaginationData<IContactAddress>>
}

export interface IContactFilters {
  showinactive?: boolean
  account_number?: string | null
}

class Service implements IContactService {
  constructor(private readonly apiClient: AxiosInstance, private readonly documentsService: IDocumentsService) {}

  public async getAll({ limit, page }: IPaginationParams, filters?: IContactFilters): Promise<IPaginationData<IContact>> {
    const params: Record<string, true | number | string> = { limit, page: page === 0 ? 1 : page }

    if (filters?.account_number) {
      params.account_number = filters.account_number
    }
    if (filters?.showinactive) {
      params.showinactive = filters.showinactive
    }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IContact>>('/contact', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(contactNumber: string): Promise<IContact> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<IContact>>(`/contact/${contactNumber}`)

    return data
  }

  public async delete(contact_number: string): Promise<void> {
    const body = { data: { contact_number } }
    await this.apiClient.post('/contact/delete', body)
  }

  public async create(data: ContactCreatePayload, documents: IAttachedDocumentWithFile[]): Promise<IContact> {
    const body = { data }
    const response = await this.apiClient.post(`/contact/create`, body)
    const contactNumber = response.data.data.contact_number
    await this.createDocumentsForContact(contactNumber, documents)
    return this.get(contactNumber)
  }

  public async update(data: ContactCreatePayload): Promise<void> {
    const body = { data }
    await this.apiClient.post(`/contact/update`, body)
  }

  public async getAddresses({ limit, page }: IPaginationParams, accountNumber?: string): Promise<IPaginationData<IContactAddress>> {
    const params: Record<string, true | number | string> = { limit, page: page === 0 ? 1 : page }
    if (accountNumber) {
      params.account = accountNumber
    }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IContactAddress>>('/address', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  private async createDocumentsForContact(number: string, documents: IAttachedDocumentWithFile[]): Promise<void> {
    if (!documents.length) {
      return
    }
    try {
      await this.documentsService.createFilesForSource(documents, DocumentType.Contact, number)
    } catch (e) {
      throw new DocumentCreationError()
    }
  }
}

export const ContactService = new Service(api, DocumentsService)
