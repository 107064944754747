import { FC, memo, useEffect, useState } from 'react'
import * as React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import * as Icon from 'react-feather'
import { useXtForm } from 'common/hooks/form/form'
import { FormCheckboxLabel, FormDatePicker, FormField, FormXtAutocomplete } from '../../../../common/utils/form/form.components'
import {
  ISalesOrderShippingAndSalesForm,
  ISalesOrderShippingAndSalesParams,
  SalesOrderShippingAndSalesFormDefaultValues,
  SalesOrderShippingAndSalesFormField,
} from './sales-order-shipping-and-sales.types'
import {
  convertShippingAndSalesFormData,
  defineShippingAndSalesFormData,
  requestDefaultValues,
} from './sales-order-shipping-and-sales.utils'
import * as styles from './sales-order-shipping-and-sales.module.scss'
import { salesOrderShippingAndSalesValidationSchema } from './sales-order-shipping-and-sales.validation'
import { getInlineLabel, renderInlineOption, renderOption } from '../../../../common/utils/autocomplete.utils'
import { loadSalesReps, loadTerms } from '../../../../common/utils'
import { defaultShippingAndSalesOptions } from './sales-order-shipping-and-sales.constants'
import {
  loadSaleTypes,
  loadShippingCharges,
  loadShippingForm,
  loadShippingZones,
  loadTaxZones,
} from '../../../../common/utils/document.utils'

export const SalesOrderShippingAndSales: FC<ISalesOrderShippingAndSalesParams> = memo(
  ({ data, viewMode, onChange, validationObservable }) => {
    const { control, formState, trigger, reset, formChanges$ } = useXtForm<ISalesOrderShippingAndSalesForm>({
      defaultValues: defineShippingAndSalesFormData(data, defaultShippingAndSalesOptions),
      mode: 'onBlur',
      validationSchema: salesOrderShippingAndSalesValidationSchema,
    })
    const [defaultOptions, setDefaultOptions] = useState<SalesOrderShippingAndSalesFormDefaultValues>(defaultShippingAndSalesOptions)

    const init: () => Promise<void> = async () => {
      const options = await requestDefaultValues()
      setDefaultOptions(options)
    }

    useEffect(() => {
      void init()
    }, [])

    useEffect(() => {
      reset(defineShippingAndSalesFormData(data, defaultOptions))
    }, [data, defaultOptions])

    useEffect(() => {
      const validationSub = validationObservable.subscribe(() => trigger())

      const obsSub = formChanges$.subscribe(({ state, data: formData }) =>
        onChange({
          data: convertShippingAndSalesFormData(formData),
          state,
        })
      )

      return () => {
        obsSub.unsubscribe()
        validationSub.unsubscribe()
      }
    }, [validationObservable, formChanges$, onChange, trigger])

    const markAsInvalid = formState.touched && !formState.isValid

    return (
      <Accordion component="section">
        <AccordionSummary
          classes={{ root: markAsInvalid ? 'xt-invalid' : '' }}
          expandIcon={<Icon.ChevronDown />}
          aria-controls="panel1a-content"
        >
          <Typography>Shipping and Sales</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.salesOrderShippingAndSales}>
            <div className={styles.salesOrderShippingAndSalesForm}>
              <FormDatePicker disabled={viewMode} label="Pack Date" control={control} name={SalesOrderShippingAndSalesFormField.PackDate} />
              <FormXtAutocomplete
                disabled={viewMode}
                loadOptions={loadShippingForm}
                name={SalesOrderShippingAndSalesFormField.ShippingForm}
                control={control}
                label="Shipping Form"
              />
              <FormXtAutocomplete
                disabled={viewMode}
                loadOptions={loadShippingCharges}
                getInputLabel={getInlineLabel}
                renderOption={renderOption}
                name={SalesOrderShippingAndSalesFormField.ShippingCharges}
                control={control}
                label="Shipping Charges"
              />
              <FormXtAutocomplete
                disabled={viewMode}
                loadOptions={loadShippingZones}
                name={SalesOrderShippingAndSalesFormField.ShippingZone}
                control={control}
                label="Shipping Zone"
              />
              <FormField disabled={viewMode} name={SalesOrderShippingAndSalesFormField.Incoterm} control={control} label="Incoterm" />
              <FormCheckboxLabel
                disabled={viewMode}
                name={SalesOrderShippingAndSalesFormField.ShipComplete}
                control={control}
                label="Ship Complete"
              />
            </div>
            <div className={styles.salesOrderShippingAndSalesForm}>
              <FormXtAutocomplete
                disabled={viewMode}
                loadOptions={loadSaleTypes}
                renderOption={renderInlineOption}
                getInputLabel={getInlineLabel}
                name={SalesOrderShippingAndSalesFormField.SaleType}
                control={control}
                label="Sale Type"
              />
              <FormXtAutocomplete
                disabled={viewMode}
                loadOptions={loadTerms}
                renderOption={renderOption}
                getInputLabel={getInlineLabel}
                name={SalesOrderShippingAndSalesFormField.Terms}
                control={control}
                label="Terms"
              />
              <FormXtAutocomplete
                disabled={viewMode}
                loadOptions={loadSalesReps}
                getInputLabel={getInlineLabel}
                renderOption={renderInlineOption}
                name={SalesOrderShippingAndSalesFormField.SalesRep}
                control={control}
                label="Sales Rep"
              />
              <FormXtAutocomplete
                disabled={viewMode}
                loadOptions={loadTaxZones}
                getInputLabel={getInlineLabel}
                renderOption={renderOption}
                name={SalesOrderShippingAndSalesFormField.TaxZone}
                control={control}
                label="Tax Zone"
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
)
