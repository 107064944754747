import React, { FC, useMemo, useCallback, useState } from 'react'

import { XtList } from 'components/list/list'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { confirmationMessages } from 'common/constants'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { XtButton } from 'components/xtButton/XtButton'
import { PhoneDialog } from './phone-dialog-form/phone-dialog-form'
import { normalizeData } from './phone-list.utils'
import { IPhoneList, IPhoneTableItem, IDeletionState } from './phone-list.types'
import { PhoneActions, PhoneActionsEditMode, phoneColumns, defaultDeletionState, defaultPhoneDialogState } from './phone-list.constants'
import styles from './phone-list.module.scss'

export const PhoneList: FC<IPhoneList> = ({ data, onChange, disabled, isMobile }) => {
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaultDeletionState)
  const [phoneDialog, setPhoneDialog] = useState<{ open: boolean; phone: IPhoneTableItem | null; action: PhoneActions | null }>(
    defaultPhoneDialogState
  )
  const phones = useMemo<IPhoneTableItem[]>(() => normalizeData(data), [data])
  const closePhoneDialog = useCallback<VoidFunction>(() => setPhoneDialog(defaultPhoneDialogState), [])
  const handleAction = useCallback<(item: IPhoneTableItem, action: PhoneActions) => void>(
    (item, action) => {
      if (action === PhoneActions.Edit) {
        setPhoneDialog({ open: true, phone: item, action })
      } else if (action === PhoneActions.Delete) {
        setDeletionState({ open: true, phone: item })
      }
    },
    [data]
  )
  const handleRowClick = () => {}

  const updatePhonesItem: (item: IPhoneTableItem) => void = (item: IPhoneTableItem) => {
    const updatedPhones = [...phones.slice(0, item.id), item, ...phones.slice(item.id + 1)]
    onChange(updatedPhones)
  }

  const confirmPhoneDialog = useCallback<(phoneData: IPhoneTableItem) => void>(
    (phoneData: IPhoneTableItem) => {
      if (phoneDialog.action === PhoneActions.Edit) {
        updatePhonesItem(phoneData)
      } else {
        // create phone
        onChange([...phones, phoneData])
      }
      closePhoneDialog()
    },
    [phoneDialog.action, phones, onChange]
  )

  const closeConfirmationDialog = useCallback<VoidFunction>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeConfirmationDialog()
    if (!deletionState.phone?.id) {
      return
    }

    const updatedPhones = phones.filter(({ id }) => id !== deletionState?.phone?.id)
    onChange(updatedPhones)
  }, [deletionState.phone, closeConfirmationDialog, phones, onChange, data])
  const openPhoneDialog = useCallback<VoidFunction>(() => setPhoneDialog({ open: true, phone: null, action: null }), [])
  return (
    <div className={styles.phoneContent}>
      <XtConfirmationDialog
        open={deletionState.open}
        message={confirmationMessages.deleted}
        title="Delete Phone"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <PhoneDialog phoneData={phoneDialog.phone} onConfirm={confirmPhoneDialog} open={phoneDialog.open} onClose={closePhoneDialog} />
      <div className={styles.phoneListTable}>
        <XtButton
          hidden={isMobile}
          disabled={disabled}
          onClick={openPhoneDialog}
          icon={SvgIconIds.ADD_CIRCLE}
          label="Add Phone"
          className={styles.addItemButtonContainer}
        />
        <XtList
          actions={!disabled ? PhoneActionsEditMode : []}
          onAction={handleAction}
          onRowClick={handleRowClick}
          columns={phoneColumns}
          data={phones}
          isMobile={isMobile}
        />
      </div>
    </div>
  )
}
