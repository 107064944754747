import { IAssigneeTableItem } from '../assignee-tab.types'
import { IXtAutocompleteOption } from '../../../../../components/xtAutocomplete/XtAutocomplete.types'

export enum AssigneeFormField {
  Username = 'username',
  Role = 'role',
  Assigned = 'assigned_date',
}
export enum AssigneeFormLabel {
  Username = 'Username',
  Role = 'Role',
  Assigned = 'Assigned',
}

export interface IAssigneeDialog {
  open: boolean
  onClose(): void
  onConfirm(item: IAssigneeDialogOutput): void
  assigneeData: IAssigneeTableItem | null
}

export interface IAssigneeDialogForm {
  [AssigneeFormField.Username]: IXtAutocompleteOption | null
  [AssigneeFormField.Role]: IXtAutocompleteOption | null
  [AssigneeFormField.Assigned]: Date
}

export interface IAssigneeDialogOutput {
  [AssigneeFormField.Username]: string
  [AssigneeFormField.Role]: string
  [AssigneeFormField.Assigned]: Date | null
}
