import * as React from 'react'
import { FC, memo } from 'react'
import { FormDatePicker, FormXtAutocomplete } from 'common/utils/form/form.components'
import { loadUserOptions, loadSalesRepOptions, loadCurrencyOptions } from 'common/utils'
import { loadDocumentWarehouseOptions, loadDocumentOppSourceOptions, loadDocumentTaxZoneOptions } from 'common/utils/document.utils'
import { useFormContext } from 'react-hook-form'
import { CRMProspectFormField, CRMProspectFormLabel, ICRMProspectForm } from '../crm-prospect-details.types'
import { ICRMProspectDetailsGeneralTab } from './crm-prospect-details-tabs.types'
import styles from '../crm-prospect-details.module.scss'

export const CRMProspectDetailsGeneralTab: FC<ICRMProspectDetailsGeneralTab> = memo(({ disabled }) => {
  const { control } = useFormContext<ICRMProspectForm>()

  return (
    <div className={styles.generalTabDetailsWrapper}>
      <div className={styles.generalTabMainDetailsGrid}>
        <FormXtAutocomplete
          disabled={disabled}
          control={control}
          name={CRMProspectFormField.Site}
          label={CRMProspectFormLabel.Site}
          loadOptions={loadDocumentWarehouseOptions}
        />

        <FormXtAutocomplete
          disabled={disabled}
          control={control}
          name={CRMProspectFormField.SalesRep}
          label={CRMProspectFormLabel.SalesRep}
          loadOptions={loadSalesRepOptions}
        />

        <FormXtAutocomplete
          disabled={disabled}
          control={control}
          name={CRMProspectFormField.TaxZone}
          label={CRMProspectFormLabel.TaxZone}
          loadOptions={loadDocumentTaxZoneOptions}
        />

        <FormXtAutocomplete
          disabled={disabled}
          control={control}
          name={CRMProspectFormField.Source}
          label={CRMProspectFormLabel.Source}
          loadOptions={loadDocumentOppSourceOptions}
        />

        <FormXtAutocomplete
          disabled={disabled}
          control={control}
          name={CRMProspectFormField.Owner}
          label={CRMProspectFormLabel.Owner}
          loadOptions={loadUserOptions}
        />

        <FormXtAutocomplete
          disabled={disabled}
          control={control}
          name={CRMProspectFormField.AssignedUser}
          label={CRMProspectFormLabel.AssignedUser}
          loadOptions={loadUserOptions}
        />

        <FormDatePicker
          control={control}
          disabled={disabled}
          name={CRMProspectFormField.AssignedDate}
          label={CRMProspectFormLabel.AssignedDate}
        />

        <FormDatePicker
          control={control}
          disabled={disabled}
          name={CRMProspectFormField.LastTouched}
          label={CRMProspectFormLabel.LastTouched}
        />
      </div>

      <div className={styles.generalTabAdditionalDetailsGrid}>
        <FormDatePicker control={control} disabled name={CRMProspectFormField.Created} label={CRMProspectFormLabel.Created} />

        <FormDatePicker control={control} disabled name={CRMProspectFormField.LastUpdated} label={CRMProspectFormLabel.LastUpdated} />

        <FormXtAutocomplete
          disabled={disabled}
          control={control}
          name={CRMProspectFormField.Currency}
          label={CRMProspectFormLabel.Currency}
          loadOptions={loadCurrencyOptions}
        />
      </div>
    </div>
  )
})
