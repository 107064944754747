import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import {MenuNavbar} from './navbarRoutes/MenuNavbar'
import NavbarLinks from "./NavbarLinks.jsx";

const Header = (props) => {
  const [sidebarExists, setSidebarExists] = useState(true);

  const mobileSidebarToggle = e => {
    if (sidebarExists === false) {
      setSidebarExists(true);
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }

  const [menu] = props.routes.filter((prop)=> prop.path === props.location.pathname.split('/')[1])

  return (
    <Navbar collapseOnSelect bg="light" expand="lg">
     { menu?.navbarRoutes
      ? <MenuNavbar mainPath={`/${menu.path}`} navbarRoutes={menu.navbarRoutes}/>
      : <Navbar.Brand>{props.brandText}</Navbar.Brand>}
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={mobileSidebarToggle} />
      <Navbar.Collapse id="basic-navbar-nav">
        <NavbarLinks />
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
