import React, { FC } from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { SvgIcon } from 'components/svgIcon/SvgIcon'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import styles from './xt-accordion.module.scss'
import { cls } from '../../common/utils'

export interface IXtAccordion {
  summary: string
  expanded?: boolean
}
export const XtAccordion: FC<IXtAccordion> = ({ children, summary, expanded }) => (
  <Accordion
    elevation={0}
    defaultExpanded={expanded}
    className={styles.accordionTab}
    classes={{ root: styles.muiAccordion, expanded: cls(styles.muiAccordion, styles.muiAccordionMargin) }}
  >
    <AccordionSummary
      expandIcon={<SvgIcon iconId={SvgIconIds.EXPAND_MORE} />}
      classes={{
        root: styles.muiAccordionSummary,
        content: styles.muiAccordionSummaryContent,
        expanded: styles.muiAccordionSummaryExpanded,
        expandIcon: styles.muiExpandIcon,
      }}
    >
      <p className={styles.titleSummaryContent}>{summary}</p>
    </AccordionSummary>
    <AccordionDetails classes={{ root: styles.muiAccordionDetails }}>{children}</AccordionDetails>
  </Accordion>
)
