import * as React from 'react'
import { FC, ChangeEventHandler, useCallback, memo } from 'react'
import TextField from '@material-ui/core/TextField'
import { IFileUploadInput } from './file-upload.types'
import styles from './file-upload.module.scss'

export const XtFileUpload: FC<IFileUploadInput> = memo(({ file, onChange }) => {
  const onUploadClick: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!event.target!) {
        return
      }
      const fileInput = event.target! as HTMLInputElement
      const newFile = fileInput?.files && fileInput?.files.length > 0 ? fileInput.files[0] : null
      onChange(newFile)
    },
    [onChange]
  )

  return (
    <div className={styles.fileUpload}>
      <TextField label="Filename" value={file?.name ?? ''} variant="outlined" disabled />
      <label className={styles.browseLabel} htmlFor="hidden-document-file-input">
        <div className={styles.fakeButton}>
          <div className={styles.fakeButtonLabel}>Browse</div>
        </div>
        <input hidden id="hidden-document-file-input" type="file" onChange={onUploadClick} />
      </label>
    </div>
  )
})
