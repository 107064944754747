import React from 'react'
import { XtButton } from 'components/xtButton/XtButton'
import * as styles from './filter.module.scss'
import { SvgIconIds } from '../svgIcon/SvgIcon.types'
import { cls } from '../../common/utils'

export interface IXtFilterButton {
  onClick: () => void
  disabled?: boolean
  className?: string
  hidden?: boolean
  label?: string
  loading?: boolean
  isMobile: boolean
}

export const XtFilterButton = ({ isMobile, label = 'Filters', onClick, disabled = false, className, hidden, loading }: IXtFilterButton) => (
  <XtButton
    hidden={hidden}
    icon={isMobile ? SvgIconIds.FILTER : undefined}
    className={cls(isMobile && styles.xtFilterMobileButton, className)}
    disabled={disabled}
    loading={loading}
    label={isMobile ? '' : label}
    onClick={onClick}
  />
)
