import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import http from "../common/api";
import Card from "../components/Card.jsx";
import LoadingSpinner from "../components/LoadingSpinner"

const UserTokens = () => {
  const [tokens, setTokens] = useState([]);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    http.get("/auth/token").then(
      (response) => {
        setLoading(false);
        setTokens(response.data);
      },
      (error) => {
        setLoading(false);
        const _msg =
          (error.response &&
            error.response.data &&
            error.response.data.status.message) ||
          error.message ||
          error.toString();
          setMsg(_msg);
      }
    );
  }, []);

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="API Tokens"
              category={msg}
              ctTableResponsive
              content={
                <>
                {loading ?
                  <LoadingSpinner /> : 
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>JTI</th>
                        <th>Type</th>
                        <th>Expiration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tokens.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <td>{prop['jti']}</td>
                            <td>{prop['type']}</td>
                            <td>{prop['expiration']}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              </>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UserTokens;
