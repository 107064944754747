import { ICustomeDetailsMode, CustomerFormField, CustomerFormValues } from './customer-details.types'

export const defineCustomerFormData = (data: CustomerFormValues | null) => ({
  [CustomerFormField.Number]: data?.customer_number ?? '',
  [CustomerFormField.Name]: data?.customer_name ?? '',
  [CustomerFormField.Type]: data?.customer_type ? { id: data?.customer_type, label: data?.customer_type } : null,
  [CustomerFormField.Active]: data?.active ?? true,
})

const defaultFormDataState = { state: null, isSubmitting: false, isValid: true }
export const defaultCustomerState = { loading: false, customer: null, formState: defaultFormDataState }

export const convertMode = (mode: ICustomeDetailsMode, isNewMode: boolean) => {
  if (Object.values(ICustomeDetailsMode).includes(mode)) {
    switch (mode) {
      case ICustomeDetailsMode.Edit:
        return [false, true]
      default:
        return [true, false]
    }
  } else if (isNewMode) {
    return [false, false]
  } else {
    return [true, false]
  }
}
