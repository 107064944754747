import { nanoid } from 'nanoid'
import { ICustomer, ICustomerFilters } from 'customers/customers.types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { CustomersService } from 'customers/customers.service'
import { ICustomersTableItem } from './customers-list.types'
import { convertPhone } from '../../contacts/contact-search/contact-search.utils'
import { PhoneRole } from '../../contacts/contacts.types'

const normalizeData = (data: ICustomer[]): ICustomersTableItem[] =>
  data.map((customer) => ({
    ...customer,
    id: customer?.customer_number ?? nanoid(),
    correspond_contact_first: customer?.correspond_contact?.first_name ?? '',
    active: customer?.active ? 'yes' : 'no',
    correspond_contact_last: customer?.correspond_contact?.last_name ?? '',
    correspond_contact_job_title: customer?.correspond_contact?.job_title ?? '',
    correspond_contact_voice: convertPhone(PhoneRole.Office, customer?.billing_contact?.phones) ?? '',
    correspond_contact_email: customer?.correspond_contact?.email ?? '',
    correspond_contact_address1: customer?.correspond_contact?.contact_address?.address1 ?? '',
    correspond_contact_city: customer?.correspond_contact?.contact_address?.city ?? '',
    correspond_contact_state: customer?.correspond_contact?.contact_address?.state ?? '',
    correspond_contact_country: customer?.correspond_contact?.contact_address?.country ?? '',
    correspond_contact_postalcode: customer?.correspond_contact?.contact_address?.postalcode ?? '',
  }))

export async function fetchCustomers(
  filters: ICustomerFilters,
  paginationParams: IPaginationParams
): Promise<IPaginationData<ICustomersTableItem>> {
  const { total, data } = await CustomersService.getAll(paginationParams, filters)
  const normalizedData = normalizeData(data)
  return {
    data: normalizedData,
    total,
  }
}
