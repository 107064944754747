import { dateToServerFormat } from 'common/utils/date.utils'
import { SalesOrderLineItemNewForm } from './sales-order-line-item-new.types'
import { NumberTypeUtils } from '../../../../common/typeUtils'
import { ISalesOrderLineItemCreatePayload, SalesOrderItem } from '../../../sales-orders.types'

export function processQuickAddItem(lineNumber: string, lineItem: SalesOrderLineItemNewForm): SalesOrderItem {
  const { item, price_uom, sold_from_site, scheduled_date, qty_ordered, net_unit_price } = lineItem
  if (!item || !price_uom || !sold_from_site) {
    throw new Error('Quick Add Line Item: some of the required form values are missed.')
  }
  return {
    qty_ordered: NumberTypeUtils.parseString(qty_ordered),
    net_unit_price: NumberTypeUtils.parseFloatString(net_unit_price),
    item_number: item.item_number,
    line_number: lineNumber,
    price_uom,
    scheduled_date: dateToServerFormat(scheduled_date),
    item_description: item.description1,
    sold_from_site,
    sub_items: [],
    warranty: false,
  }
}

export function convertToLineItemPayload(data: SalesOrderLineItemNewForm): ISalesOrderLineItemCreatePayload {
  const { item, price_uom, sold_from_site, scheduled_date, qty_ordered, net_unit_price } = data
  if (!item || !price_uom || !sold_from_site) {
    throw new Error('Quick Add Line Item: some of the required form values are missed.')
  }
  return {
    qty_ordered: NumberTypeUtils.parseString(qty_ordered),
    net_unit_price: NumberTypeUtils.parseFloatString(net_unit_price),
    item_number: item.item_number,
    price_uom,
    scheduled_date: dateToServerFormat(scheduled_date),
    sold_from_site,
    customer_pn: null,
    qty_uom: null,
    promise_date: null,
    tax_type: null,
    est_unit_cost: 0,
    create_order: false,
    notes: '',
    substitute_for: null,
    warranty: false,
  }
}
