import * as React from 'react'
import { FC, useState, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { xsMq } from 'common/constants'
import { XtButton } from 'components/xtButton/XtButton'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import {
  IAppliedCharacteristic,
  AppliedCharacteristicAction,
  ICharacteristicDialogState,
  IDeletionDialog,
  IXTCharacteristics,
  CharacteristicDialogFormField,
  IAppliedCharacteristicNew,
} from './characteristics.types'
import { AppliedCharacteristicActionsEditMode, characteristicColumns } from './characteristics.constants'
import styles from './characteristics.module.scss'
import { XtList } from '../components/list/list'
import { CharacteristicDialog } from './characteristic-dialog/characteristic-dialog'
import { CharacteristicService } from './characteristics.sevice'
import { XtConfirmationDialog } from '../components/xtConfirmationDialog/XtConfirmationDialog'
import { confirmationMessages } from '../common/constants'
import { defaultCharacteristicState, defaultDeletionState } from './characteristic.utils'

/**
 *  XtCharacteristics displays a list of characteristics applied to a certain entity (the entity type is
 *  determined by the usedOn property) with the ability to add new characteristics and edit the existing ones.
 */
export const XtCharacteristics: FC<IXTCharacteristics> = React.memo(
  ({ characteristics, isViewMode, usedOnFilter, onDelete, onUpdate, onCreate }) => {
    const [characteristicDialog, setCharacteristicDialog] = useState<ICharacteristicDialogState>(defaultCharacteristicState)
    const [deletionState, setDeletionDialog] = useState<IDeletionDialog>(defaultDeletionState)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isMobile = useMediaQuery(xsMq)

    const openCharacteristicDialogEditMode = async (item: IAppliedCharacteristic): Promise<void> => {
      const characteristicData = await CharacteristicService.get(item.characteristic)
      setCharacteristicDialog({
        open: true,
        editMode: true,
        characteristic: {
          [CharacteristicDialogFormField.Characteristic]: characteristicData,
          [CharacteristicDialogFormField.Value]: item.characteristic_value,
        },
        characteristicId: item.id,
      })
    }

    const onAction = useCallback((item: IAppliedCharacteristic, action: AppliedCharacteristicAction) => {
      switch (action) {
        case AppliedCharacteristicAction.Delete: {
          return setDeletionDialog({ item, open: true })
        }
        case AppliedCharacteristicAction.Edit:
          return openCharacteristicDialogEditMode(item)
        default:
          return null
      }
    }, [])

    const executeAction = async (action: Promise<void>): Promise<void> => {
      setIsLoading(true)
      await action
      setIsLoading(false)
    }

    const onOpenCharacteristicDialog = useCallback(() => setCharacteristicDialog((prev) => ({ ...prev, open: true, editMode: false })), [])

    const onCloseCharacteristicDialog = useCallback(() => setCharacteristicDialog(defaultCharacteristicState), [])

    const onConfirmCharacteristicDialog = useCallback(
      (formData: IAppliedCharacteristicNew) => {
        if (
          characteristicDialog.editMode &&
          characteristicDialog.characteristicId !== null &&
          characteristicDialog.characteristicId !== undefined
        ) {
          void executeAction(onUpdate({ ...formData, id: characteristicDialog.characteristicId }))
        } else {
          // negative IDs are used for newly-added characteristics, to avoid clashes with positive previous ones
          void executeAction(onCreate({ ...formData, id: -new Date().getTime() }))
        }
        onCloseCharacteristicDialog()
      },
      [characteristicDialog, onCloseCharacteristicDialog, onUpdate, onCreate]
    )

    const handleRowClick = () => {}
    const disabled = isViewMode || isLoading
    const closeConfirmationDialog = useCallback(() => setDeletionDialog(defaultDeletionState), [])
    const handleDeletion = useCallback(() => {
      if (deletionState.item) {
        void executeAction(onDelete(deletionState.item))
      }
      closeConfirmationDialog()
    }, [deletionState.item, closeConfirmationDialog, onDelete])

    return (
      <div>
        <XtConfirmationDialog
          open={deletionState.open}
          message={confirmationMessages.deleted}
          title="Delete Characteristic"
          confirmationButtonLabel="Delete"
          onConfirm={handleDeletion}
          onClose={closeConfirmationDialog}
        />
        <CharacteristicDialog
          onConfirm={onConfirmCharacteristicDialog}
          characteristicState={characteristicDialog.characteristic}
          usedOnFilter={usedOnFilter}
          open={characteristicDialog.open}
          onClose={onCloseCharacteristicDialog}
        />
        <XtButton
          disabled={disabled}
          onClick={onOpenCharacteristicDialog}
          icon={SvgIconIds.ADD_CIRCLE}
          className={styles.characteristicsAddButton}
          loading={isLoading}
          label="Add Characteristic"
        />
        <XtList
          loading={isLoading}
          className={styles.characteristicsTable}
          actions={disabled ? [] : AppliedCharacteristicActionsEditMode}
          data={characteristics}
          isMobile={isMobile}
          onAction={onAction}
          columns={characteristicColumns}
          onRowClick={handleRowClick}
        />
      </div>
    )
  }
)
