import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { FilterType, IFilter, IFilterFormState } from './filter.types'
import { IXtAutocompleteOption } from '../xtAutocomplete/XtAutocomplete.types'

export function makeValidationSchema<TOption extends IXtAutocompleteOption, TFilters extends Array<IFilter<TOption>>>(
  filtersForSchema: TFilters
): yup.ObjectSchemaDefinition<IFilterFormState> {
  const result: Record<string, unknown> = {}
  filtersForSchema
    .filter((f) => f.type === FilterType.Date)
    .forEach((filter) => {
      result[filter.fieldName] = yup.date().notRequired().nullable().typeError(validationMessage.invalidDate)
    })

  return result as yup.ObjectSchemaDefinition<IFilterFormState>
}
