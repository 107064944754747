import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { IQuotesTableItem } from 'quotes/quotes-list/quote-list.types'
import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { QuotesAction } from 'quotes/quotes-list/quote-list.constants'

export const QuotesListActionsEditMode: IXtTableCellAction[] = [
  {
    name: QuotesAction.Edit,
    label: QuotesAction.Edit,
    icon: SvgIconIds.CREATE,
  },
  {
    name: QuotesAction.View,
    label: QuotesAction.View,
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: QuotesAction.Delete,
    label: QuotesAction.Delete,
    icon: SvgIconIds.DELETE,
  },
  {
    name: QuotesAction.Copy,
    label: QuotesAction.Copy,
    icon: SvgIconIds.ADD_FILE,
  },
  {
    name: QuotesAction.CopyTo,
    label: QuotesAction.CopyTo,
    icon: SvgIconIds.COMPARE_ARROW,
  },
  {
    name: QuotesAction.ConvertToSalesOrder,
    label: QuotesAction.ConvertToSalesOrder,
    icon: SvgIconIds.CONVERT,
  },
  {
    name: QuotesAction.PrintForm,
    label: QuotesAction.PrintForm,
    icon: SvgIconIds.PRINT,
  },
]

export const QuotesColumns: IXtTableColumn<IQuotesTableItem>[] = [
  {
    id: 'quote_number',
    field: 'quote_number',
    headerName: 'Quote #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'customer_po_number',
    field: 'customer_po_number',
    headerName: 'P/O Number',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Status',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'quote_date',
    field: 'quote_date',
    headerName: 'Quote Date',
    flex: '1 0 200px',
    width: 200,
    converter: dateConverter,
  },
  {
    id: 'order_total',
    field: 'order_total',
    headerName: 'Total',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'order_notes',
    field: 'order_notes',
    headerName: 'Notes',
    flex: '1 0 200px',
    width: 200,
  },
]
