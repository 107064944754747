import React, { memo, FC, useEffect } from 'react'
import { Dialog } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/xtButton/XtButton'
import { FormField, FormXtAutocomplete } from 'common/utils/form/form.components'

import { loadCRMPhoneRoleOptions } from 'common/utils'
import styles from './phone-dialog-form.module.scss'
import { IPhoneDialog, IPhoneFormValues, PhoneFormField, PhoneFormLabel } from './phone-dialog-form.types'
import { PhoneDialogFormSchema } from './phone-dialog-form.validation'
import { definePhoneDialogItem } from './phone-dialog-form.utils'

export const PhoneDialog: FC<IPhoneDialog> = memo(({ onClose, open, onConfirm, phoneData }) => {
  const { handleSubmit, control, reset, formState } = useForm<IPhoneFormValues>({
    defaultValues: definePhoneDialogItem(null),
    resolver: yupResolver(PhoneDialogFormSchema),
    mode: 'onBlur',
  })

  useEffect(() => void reset(definePhoneDialogItem(phoneData)), [phoneData])

  const onCancel = () => {
    onClose()
    reset(definePhoneDialogItem(null))
  }
  const onSaveForm = (formData: IPhoneFormValues) => {
    onConfirm({ ...formData, role: formData.role?.id ?? '', id: phoneData?.id ?? Date.now() })
    onCancel()
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm, (error) => console.error(error))
    void eventHandler(e)
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <form onSubmit={submitForm} className={styles.phoneDialog}>
        <FormXtAutocomplete
          control={control}
          name={PhoneFormField.Role}
          label={PhoneFormLabel.Role}
          loadOptions={loadCRMPhoneRoleOptions}
        />
        {/* TODO format number value */}
        <FormField control={control} name={PhoneFormField.Number} label={PhoneFormLabel.Number} />
        <div className={styles.phoneDialogButtons}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton type="submit" disabled={!formState.isDirty} label="Save" />
        </div>
      </form>
    </Dialog>
  )
})
