import { Overwrite } from 'utility-types'

import { IObjectWithId } from 'common/common.types'
import { ISalesOrder } from 'sales-orders/sales-orders.types'

export type SalesOrderRow = Overwrite<
  Pick<
    ISalesOrder,
    | 'order_number'
    | 'order_date'
    | 'scheduled_date'
    | 'order_total'
    | 'ship_to'
    | 'sale_type'
    | 'customer_number'
    | 'customer_name'
    | 'customer_po_number'
    | 'ordermargin'
    | 'ordermarginpercent'
    | 'hold_type'
  >,
  {
    ship_to: string
  }
> &
  IObjectWithId & { status: string }

export enum SalesOrdersListAction {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  Copy = 'copy',
  Print = 'print',
}
