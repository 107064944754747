import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core'
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase'
import { useHistory, useRouteMatch } from 'react-router'
import { ErrorHandler } from '../../services/ErrorService'
import { BomService, IBomListFilters } from '../services/BomService'
import { XtButton } from '../../components/xtButton/XtButton'
import { cls } from '../../common/utils'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { normalizeData } from '../utils'
import { IPaginationData, IPaginationParams } from '../../common/common.types'
import { confirmationMessages, xsMq } from '../../common/constants'
import './BomList.scss'
import * as styles from './BomList.module.scss'
import { BomDetailsMode } from '../bom.types'
import { bomListActionsEditMode, bomListColumns, defaultDeletionState } from './BomList.constants'
import { BomListAction, IBomTableItem, IItemDeletionState } from './BomList.types'
import { useTable } from '../../common/hooks/useTable'
import { XtConfirmationDialog } from '../../components/xtConfirmationDialog/XtConfirmationDialog'
import { XtList } from '../../components/list/list'

const fetchBoms = async (filters: IBomListFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IBomTableItem>> => {
  const { total, data } = await BomService.getAll(filters, paginationParams)
  const normalizedData = normalizeData(data)
  return {
    data: normalizedData,
    total,
  }
}

export const BomList: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  const [deletionState, setDeletionState] = useState<IItemDeletionState>(defaultDeletionState)

  const { state, setLoading, refresh, filter, pagination } = useTable({ showComponentItems: false, showInactive: false }, fetchBoms)

  const onShowFilterChange: SwitchBaseProps['onChange'] = async ({ target }) => {
    await filter({ ...state.filters, [target.name]: target.checked })
  }

  const handleRowClick = useCallback<(item: IBomTableItem) => void>(
    ({ item_number }) => history.push(`${path}/${item_number}/${BomDetailsMode.View}`),
    [path, history]
  )

  const handleAction = useCallback<(item: IBomTableItem, action: BomListAction) => void>(
    ({ item_number }, action) => {
      if (action === BomListAction.Delete && !state.loading) {
        setDeletionState({ itemId: item_number, confirmationOpen: true })
        return
      }
      if (action === BomListAction.Edit) {
        history.push(`${path}/${item_number}/${BomDetailsMode.Edit}`)
      }
      if (action === BomListAction.View) {
        history.push(`${path}/${item_number}/${BomDetailsMode.View}`)
      }
      if (action === BomListAction.Print) {
        // TODO handle print
      }
    },
    [history, path, state.loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.itemId) {
      try {
        setLoading(true)
        await BomService.delete(deletionState.itemId)
        await refresh()
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState.itemId, setLoading, refresh, closeConfirmationDialog])

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete BOM"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <div className={cls('routingListMuiOverrides', 'xt-content', styles.bomListContent)}>
        <h1 className={cls('xt-page-title', 'xt-section-border', styles.bomListHeader)}>List of BOM</h1>
        <div hidden={isMobile} className={styles.bomListFilters}>
          <FormControlLabel
            disabled={state.loading}
            control={<Checkbox name="showInactive" checked={state.filters.showInactive} onChange={onShowFilterChange} color="primary" />}
            label="Show BOMs for Inactive Items"
          />
          <FormControlLabel
            disabled={state.loading}
            control={
              <Checkbox
                checked={state.filters.showComponentItems}
                name="showComponentItems"
                onChange={onShowFilterChange}
                color="primary"
              />
            }
            label="Show BOMs with Component items"
          />
          <XtButton
            className={styles.newButton}
            label="New Item"
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push(`${path}/new`)}
          />
        </div>
        <XtList
          actions={bomListActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data}
          columns={bomListColumns}
        />
      </div>
    </div>
  )
}
