import React from "react";
import { Nav } from "react-bootstrap";
import styles from './navbarRoutes/menuNavbar.module.scss'
import {AuthService} from "../auth/auth.service";

const NavbarLinks = props => {
  const handleSelect = function (evt) {
    switch (evt) {
      case "logout":
        AuthService.logout();
        break;
      default:
        break;
    }
  }
  return (
    <>
      <div className="mr-auto"></div>
      <Nav className={styles.userSize} onSelect={handleSelect}>
        <Nav.Link eventKey="logout" href="/">
          <i className="fa fa-user-circle pr-1" />Log out
        </Nav.Link>
      </Nav>
    </>
    );
  };

export default NavbarLinks;
