import * as React from 'react'
import { FC } from 'react'
import Paper from '@material-ui/core/Paper'
import InfiniteScroll from 'react-infinite-scroll-component'
import LoadingSpinner from 'components/LoadingSpinner'
import { cls } from 'common/utils'
import { IAttachedDocument } from 'documents/documents.types'
import { XtTableCellActions } from 'components/xtTableCellActions/XtTableCellActions'
import { IAttachedDocumentListInput } from './document-list.types'
import {
  AttachedDocumentAction,
  AttachedDocumentActionsEditMode,
  AttachedDocumentActionsEditModeWithFileLink,
} from './document-list.constants'
import styles from '../documents.module.scss'

export const XtDocumentList: FC<IAttachedDocumentListInput> = React.memo(({ isMobile, onAction, loadMore, canLoadMore, documents }) => {
  const actionsWithFileLink = isMobile ? [] : AttachedDocumentActionsEditModeWithFileLink
  const actionsWithoutFileLink = isMobile ? [] : AttachedDocumentActionsEditMode

  const itemRows = (
    <div className={styles.listWrapper} id="xt-documents-scrollable">
      {documents.length === 0 && (
        <div className={styles.noDataAvailableMessage}>
          <p>No data available.</p>
        </div>
      )}
      <InfiniteScroll
        scrollableTarget="xt-documents-scrollable"
        className={styles.xtCommentsList}
        next={loadMore}
        hasMore={canLoadMore()}
        dataLength={documents.length}
        loader={<LoadingSpinner />}
      >
        {documents.map(
          (item: IAttachedDocument): JSX.Element => (
            <div className={styles.listItemWrapper}>
              <Paper className={styles.listItem} square>
                <span className={styles.listItemName}>{item.name}</span>
                <XtTableCellActions
                  key={item.id}
                  className={cls('xt-table-cell-actions', styles.listItemActions)}
                  actions={item.file_link ? actionsWithFileLink : actionsWithoutFileLink}
                  onClick={(action) => onAction(item, action as AttachedDocumentAction)}
                />
              </Paper>
            </div>
          )
        )}
      </InfiniteScroll>
    </div>
  )

  return <div className={styles.listItemContainer}>{itemRows}</div>
})
