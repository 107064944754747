import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../common/common.types'

export interface ITerm {
  code: string
  description: string
  type: string
  due_days: number
  discount_days: number
  discount_percent: number
  cutoff_day: number
  use_for_ap: boolean
  use_for_ar: boolean
  terms_fincharg: boolean
  terms_created: string
  terms_lastupdated: string
}

export interface ITermsService {
  getAll(pagination: IPaginationParams): Promise<IPaginationData<ITerm>>
}

class Service implements ITermsService {
  constructor(private readonly httpClient: AxiosInstance) {}

  public async getAll({ page, limit }: IPaginationParams): Promise<IPaginationData<ITerm>> {
    const params = { page: page === 0 ? 1 : page, limit }
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<ITerm>>('/terms', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}

export const TermsService: ITermsService = new Service(api)
