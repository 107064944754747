import { IObjectWithId } from '../../common/common.types'
import { IBom } from '../bom.types'

export enum BomListAction {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  Print = 'print',
}

export interface IItemDeletionState {
  itemId: string | null
  confirmationOpen: boolean
}

export interface IBomTableItem extends IObjectWithId, Pick<IBom, 'item_number' | 'item_description' | 'revision' | 'revision_status'> {}

export type BomListActionHandler = (item: IBomTableItem, action: BomListAction) => void
