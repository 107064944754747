import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { characteristicsToPayload } from 'characteristics/characteristic.utils'
import {
  ISalesOrder,
  ISalesOrderAddress,
  ISalesOrderBill,
  ISalesOrderExtended,
  ISalesOrderShipment,
  SalesOrderCreateInput,
  SalesOrderUpdateInput,
} from '../sales-orders.types'
import { ISalesOrdersNewState, SalesOrderNewStateFilled } from './sales-orders-new.types'
import { CustomerData, SalesOrderMainInput, ShipmentOption } from '../components/sales-order-main/sales-order-main.types'
import { ISalesOrderHeaderInformationState } from '../components/sales-order-header-information/sales-order-header-information.types'
import { IContactAddress } from '../../contacts/contacts.types'
import { IFormStateChangesNullable } from '../../common/hooks/form/form.types'
import { ISalesOrderShippingAndSalesInput } from '../components/sales-order-header-information/sales-order-shipping-and-sales/sales-order-shipping-and-sales.types'
import { ISalesOrderContactAndAddress } from '../components/sales-order-header-information/sales-order-bill-to-and-ship-to/sales-order-bill-to-and-ship-to.types'

export function parseSalesOrderBillTo(billTo: ISalesOrderBill | undefined | null): ISalesOrderContactAndAddress | null {
  if (!billTo) {
    return null
  }
  return {
    contact: billTo.billto_contact,
    address: billTo.billto_address,
  }
}

export function convertToBillTo(billTo: ISalesOrderContactAndAddress, name: string): ISalesOrderBill {
  return {
    billto_name: name,
    billto_contact: billTo.contact,
    billto_address: billTo.address,
  }
}

export function parseSalesOrderShipTo(shipTo: ISalesOrderShipment | undefined | null): ISalesOrderContactAndAddress | null {
  if (!shipTo) {
    return null
  }
  return {
    contact: shipTo.shipto_contact,
    address: shipTo.shipto_address,
  }
}

export function convertToShipTo(shipTo: ISalesOrderContactAndAddress, name: string, number: string): ISalesOrderShipment {
  return {
    shipto_name: name,
    shipto_number: number,
    shipto_contact: shipTo.contact,
    shipto_address: shipTo.address,
  }
}

export function retrieveMainFormValue(salesOrder: ISalesOrderExtended | null): SalesOrderMainInput | null {
  return !salesOrder
    ? null
    : {
        ...salesOrder,
        ship_to_name: salesOrder.ship_to.shipto_name,
        ship_to_option: { id: salesOrder.ship_to.shipto_number, label: salesOrder.ship_to.shipto_name } as ShipmentOption,
      }
}

export function retrieveMainFormState(salesOrder: ISalesOrderExtended | null): IFormStateChangesNullable<SalesOrderMainInput> {
  return {
    data: retrieveMainFormValue(salesOrder),
    state: { isValid: false, isDirty: false, touched: false },
  }
}

export function retrieveHeaderInfoValue(salesOrder: ISalesOrder | null): ISalesOrderHeaderInformationState {
  return {
    shippingAndSales: salesOrder,
    billTo: parseSalesOrderBillTo(salesOrder?.bill_to ?? null),
    shipTo: parseSalesOrderShipTo(salesOrder?.ship_to ?? null),
  }
}

export function defineState(salesOrder: ISalesOrderExtended | null): ISalesOrdersNewState {
  return {
    salesOrder: null,
    loading: false,
    isSubmitting: false,
    characteristics: [],
    documents: null,
    lineItemsState: { data: null, state: { isValid: false, isDirty: false, touched: false } },
    headerInfoState: {
      data: retrieveHeaderInfoValue(salesOrder),
      state: { isValid: false, isDirty: false, touched: false },
    },
    mainFormState: retrieveMainFormState(salesOrder),
    billTo: parseSalesOrderBillTo(salesOrder?.bill_to ?? null),
    shipTo: parseSalesOrderShipTo(salesOrder?.ship_to ?? null),
    shippingAndSales: salesOrder ?? null,
  }
}

export function convertContactAddressToSalesOrderAddress(address: IContactAddress | null | undefined): ISalesOrderAddress {
  return {
    address1: address?.address1 ?? '',
    address2: address?.address2 ?? '',
    address3: address?.address3 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    postal_code: address?.postalcode ?? '',
    country: address?.country ?? '',
  }
}

export function convertCustomerToContactAndAddressType(customer: CustomerData | null | undefined): ISalesOrderContactAndAddress | null {
  if (!customer) {
    return null
  }
  return {
    contact: customer?.billing_contact,
    address: convertContactAddressToSalesOrderAddress(customer?.billing_contact?.contact_address),
  }
}

export function convertShipToToContactAndAddressType(shipTo: ShipmentOption | null): ISalesOrderContactAndAddress | null {
  if (!shipTo) {
    return null
  }
  return {
    contact: shipTo.shipto_contact,
    address: convertContactAddressToSalesOrderAddress(shipTo.shipto_address),
  }
}

export function isFormFilled(state: ISalesOrdersNewState): state is SalesOrderNewStateFilled {
  const { lineItemsState, headerInfoState, mainFormState } = state
  if (!lineItemsState.data?.lineItems || !lineItemsState.data?.additionalSection) {
    return false
  }
  if (!headerInfoState.data?.billTo || !headerInfoState.data.shipTo || !headerInfoState.data.shippingAndSales) {
    return false
  }
  return !!mainFormState.data
}

export function isValid(state: ISalesOrdersNewState): boolean {
  return (
    isFormFilled(state) && state.headerInfoState.state.isValid && state.mainFormState.state.isValid && state.lineItemsState.state.isValid
  )
}

export function isStateDirty(state: ISalesOrdersNewState): boolean {
  return state.headerInfoState.state.isDirty || state.mainFormState.state.isDirty || state.lineItemsState.state.isDirty
}

export function convertToSalesOrderCreateInput(
  state: SalesOrderNewStateFilled,
  order_notes: string,
  shipping_notes: string,
  characteristics: IAppliedCharacteristic[]
): SalesOrderCreateInput {
  const { lineItemsState, headerInfoState, mainFormState } = state
  const { customer, ship_to_option, ship_to_name, ...otherMainFields } = mainFormState.data
  return {
    customer_number: customer?.customer_number ?? '',
    order_notes,
    salesorder_characteristics: characteristicsToPayload(characteristics),
    shipping_notes,
    bill_to: convertToBillTo(headerInfoState.data.billTo, customer?.customer_name ?? ''),
    ship_to: convertToShipTo(headerInfoState.data.shipTo, ship_to_name ?? '', ship_to_option?.id ?? ''),
    ...headerInfoState.data.shippingAndSales,
    ...otherMainFields,
    order_items: state.lineItemsState.data.lineItems,
    ...lineItemsState.data.additionalSection,
    currency: lineItemsState.data.additionalSection.currency,
  }
}

export function convertToSalesOrderUpdateInput(
  state: SalesOrderNewStateFilled,
  salesOrder: ISalesOrderExtended,
  order_notes: string,
  shipping_notes: string,
  characteristics: IAppliedCharacteristic[]
): SalesOrderUpdateInput {
  const { lineItemsState, headerInfoState, mainFormState } = state
  const { customer, ship_to_option, ship_to_name, ...otherMainFields } = mainFormState.data
  const { order_number, customer: _, ...other } = salesOrder
  return {
    ...other,
    customer_number: customer?.customer_number ?? '',
    order_notes,
    salesorder_characteristics: characteristicsToPayload(characteristics),
    shipping_notes,
    bill_to: convertToBillTo(headerInfoState.data.billTo, customer?.customer_name ?? ''),
    ship_to: convertToShipTo(headerInfoState.data.shipTo, ship_to_name ?? '', ship_to_option?.id ?? ''),
    ...headerInfoState.data.shippingAndSales,
    ...otherMainFields,
    order_items: state.lineItemsState.data.lineItems.map((item) => ({ ...item, order_number })),
    ...lineItemsState.data.additionalSection,
  }
}
export function convertToShipmentsAndSalesInput(
  shipmentsAndSales: ISalesOrderShippingAndSalesInput | null,
  shipTo: ShipmentOption | null
): ISalesOrderShippingAndSalesInput {
  return {
    pack_date: shipmentsAndSales?.pack_date ?? new Date().toISOString(),
    sale_type: shipmentsAndSales?.sale_type ?? '',
    ship_complete: shipmentsAndSales?.ship_complete ?? false,
    terms: shipmentsAndSales?.terms ?? '',
    fob: shipmentsAndSales?.fob ?? '',
    sales_rep: shipTo?.sales_rep ?? shipmentsAndSales?.sales_rep ?? '',
    shipping_form: shipTo?.shipping_form ?? shipmentsAndSales?.shipping_form ?? '',
    shipping_zone: shipTo?.shipping_zone ?? shipmentsAndSales?.shipping_zone ?? '',
    shipping_charges: shipTo?.shipping_charges ?? shipmentsAndSales?.shipping_charges ?? '',
    tax_zone: shipTo?.tax_zone ?? shipmentsAndSales?.tax_zone ?? '',
  }
}
