import { IItem } from 'items/Items.types'
import React, { FC, useMemo } from 'react'

import { ItemCards } from './item-cards/item-cards'
import { convertItemDetailsData, convertItemSoldData, convertItemWeightData } from './details-tab.utils'

export interface IDetailsTab {
  data: IItem | null
}
export const ViewDetailsTab: FC<IDetailsTab> = ({ data }) => {
  const viewCardsData = useMemo(
    () => [
      { title: 'Item Details', data: convertItemDetailsData(data) },
      { title: 'Item Sold', data: convertItemSoldData(data) },
      { title: 'Weight in Lb', data: convertItemWeightData(data) },
    ],
    [data]
  )
  return <ItemCards viewCardsData={viewCardsData} />
}
