import { CRMProspectDetailsMode, CRMProspectFormField, ICRMProspectForm } from './crm-prospect-details/crm-prospect-details.types'
import { ICRMProspect } from './crm-prospects.types'

export const defineFormState = (data: ICRMProspect | null): ICRMProspectForm => ({
  [CRMProspectFormField.Name]: data?.name ?? '',
  [CRMProspectFormField.Number]: data?.number ?? '',
  [CRMProspectFormField.Owner]: data?.owner ? { id: data?.owner, label: data?.owner } : null,
  [CRMProspectFormField.Active]: !!(data?.active ?? false),
  [CRMProspectFormField.AssignedUser]: data?.assigned_user ? { id: data?.assigned_user, label: data?.assigned_user } : null,
  [CRMProspectFormField.AssignedDate]: data?.assigned_date ? new Date(data?.assigned_date) : new Date(),
  [CRMProspectFormField.LastTouched]: data?.last_touched ? new Date(data?.last_touched) : new Date(),
  [CRMProspectFormField.Site]: data?.site ? { id: data?.site, label: data?.site } : null,
  [CRMProspectFormField.TaxZone]: data?.tax_zone ? { id: data?.tax_zone, label: data?.tax_zone } : null,
  [CRMProspectFormField.SalesRep]: data?.sales_rep ? { id: data?.sales_rep, label: data?.sales_rep } : null,
  [CRMProspectFormField.Source]: data?.source ? { id: data?.source, label: data?.source } : null,
  [CRMProspectFormField.Created]: data?.last_touched ? new Date(data?.last_touched) : new Date(),
  [CRMProspectFormField.LastUpdated]: data?.last_touched ? new Date(data?.last_touched) : new Date(),
  [CRMProspectFormField.Currency]: data?.currency ? { id: data?.currency, label: data?.currency } : null,
})

// TODO: maybe unify this code with TaskDetailsMode?
export function convertMode(mode: CRMProspectDetailsMode, isNewMode: boolean): [boolean, boolean] {
  if (Object.values(CRMProspectDetailsMode).includes(mode)) {
    switch (mode) {
      case CRMProspectDetailsMode.Edit:
        return [false, true]
      default:
        return [true, false]
    }
  } else if (isNewMode) {
    return [false, false]
  } else {
    return [true, false]
  }
}
