import { IObjectWithId } from '../../common/common.types'
import { IItem } from '../Items.types'

export interface IDeletionState {
  itemNumber: string
  confirmationOpen: boolean
}

export enum ItemsAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'view',
  Print = 'Print',
}

export interface IItemsTableItem
  extends IObjectWithId,
    Pick<IItem, 'item_number' | 'description1' | 'class_code' | 'item_type' | 'inventory_uom_name'> {
  active: string
}

export interface IItemsFilters {
  classCode?: string
  classCodePattern?: string
  freightClass?: string
  freightClassPattern?: string
  itemDescription?: string
  itemGroup?: string
  itemNumberPattern?: string
  itemType?: string
  productCategoryPattern?: string
  productCategory?: string
  showinactive?: boolean
  sold?: boolean
  customer_number?: string
  showDetail?: boolean
}
