import React, { FC } from 'react'
import { ItemsSearch } from 'components/itemsSearch/ItemsSearch'
import { IXtItemNumber } from './item-number.types'
import * as styles from './item-number.module.scss'

export const XtItemNumber: FC<IXtItemNumber> = (props) => {
  const { itemNumber, uom, description, isEditMode, editModeConfig } = props
  return (
    <div className={styles.xtItemNumber}>
      {isEditMode && editModeConfig ? (
        <ItemsSearch
          itemsFilter={editModeConfig.itemsFilter}
          value={editModeConfig.value}
          error={editModeConfig.error}
          disabled={editModeConfig.loading || editModeConfig.disabled}
          onChange={editModeConfig.onChange}
          className={styles.itemSearch}
          filters={editModeConfig.filters}
        />
      ) : (
        <h3 hidden={!itemNumber} className="xt-page-title" title={itemNumber}>
          {itemNumber}
        </h3>
      )}
      <div className={styles.additionalInfo}>
        <span hidden={!uom}>{`UOM: ${uom}`}</span>
        <span hidden={!description}>{description}</span>
      </div>
    </div>
  )
}
