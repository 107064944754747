import { IObjectWithId } from '../../common/common.types'
import { IRouting } from '../Routing.types'

export enum RoutingListAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  Print = 'Print',
}

export interface IRoutingListTableRow
  extends IObjectWithId,
    Pick<IRouting, 'item_number' | 'item_description' | 'revision_number' | 'revision_status'> {}
