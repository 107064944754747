import { AxiosInstance } from 'axios'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../common/common.types'
import { IShipment, IShipmentResponse, IShipmentVia, PartialShipment } from './shipments.types'
import api from '../common/api'

export interface IShipmentsService {
  getShipmentsVia(pagination: IPaginationParams): Promise<IPaginationData<IShipmentVia>>
  get(accountNumber: string): Promise<IShipment[]>
  delete(shipmentNumber: string, customerNumber: string): Promise<void>
  update(data: PartialShipment, customerNumber: string): Promise<void>
  create(data: PartialShipment, customerNumber: string): Promise<void>
}

class Service implements IShipmentsService {
  constructor(private readonly httpClient: AxiosInstance) {}

  public async getShipmentsVia({ limit, page }: IPaginationParams): Promise<IPaginationData<IShipmentVia>> {
    const params = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IShipmentVia>>('/shipvia', { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(accountNumber: string): Promise<IShipment[]> {
    const {
      data: { data },
    } = await this.httpClient.get<IResponse<IShipmentResponse>>(`/shipto/${accountNumber}`)
    return Array.isArray(data?.shiptos) ? data.shiptos : []
  }

  public async delete(shipmentNumber: string, customerNumber: string): Promise<void> {
    const body = { data: { shipto_number: shipmentNumber, customer_number: customerNumber } }
    await this.httpClient.post('/shipto/delete', body)
  }

  public async update(data: PartialShipment, customerNumber: string): Promise<void> {
    const body = { data: { ...data, customer_number: customerNumber } }
    await this.httpClient.post('/shipto/update', body)
  }

  public async create(data: PartialShipment, customerNumber: string): Promise<void> {
    const body = { data: { ...data, customer_number: customerNumber } }
    await this.httpClient.post('/shipto/create', body)
  }
}

export const ShipmentsService: IShipmentsService = new Service(api)
