import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { BehaviorSubject, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators'
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab'
import { CircularProgress, TextField as TextInput } from '@material-ui/core'
import * as React from 'react'
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete'
import { globalConstants } from '../../common/constants'
import { IXtAutocompleteOption, IXtAutocompleteState, IXtAutocompleteParams } from './XtAutocomplete.types'
import './XtAutocomplete.scss'
import { cls } from '../../common/utils'
import { ErrorHandler } from '../../services/ErrorService'

function renderAutocompleteOption(option: IXtAutocompleteOption): React.ReactNode {
  return (
    <div title={option.label} className="xt-autocomplete-container" key={option.id}>
      <div className="xt-autocomplete-label">{option.label}</div>
    </div>
  )
}

const defaultState = {
  loading: false,
  items: [],
  value: null,
  meta: {
    page: 1,
    total: 0,
  },
}

function getLabel(option: IXtAutocompleteOption | null): string {
  return option?.label ?? ''
}

export function XtAutocomplete<Option extends IXtAutocompleteOption>({
  value,
  onChange,
  onBlur = () => {},
  loadOptions,
  limit = globalConstants.paginationLimit * 2,
  renderOption = renderAutocompleteOption,
  placeholder,
  disabled = false,
  className,
  error,
  hidden = false,
  onFirstAvailableOption,
  getInputLabel = getLabel,
}: IXtAutocompleteParams<Option>) {
  const inputValueRef = useRef<BehaviorSubject<string | null>>()
  const initializedRef = useRef<boolean>(false)
  const userActionRef = useRef<Subject<boolean>>()
  const [state, setState] = useState<IXtAutocompleteState<Option>>(defaultState)

  const loadItems = useCallback<(items: Option[], page: number, filter?: string) => Promise<void>>(
    async (items, page, itemsFilter) => {
      try {
        setState((oldState) => ({ ...oldState, loading: true }))
        const { data, total } = await loadOptions(page, limit, itemsFilter)

        // Pass the first available option to the parent component
        if (!initializedRef.current && onFirstAvailableOption && data.length) {
          onFirstAvailableOption(data[0])
        }

        setState((oldState) => ({
          ...oldState,
          items: [...items, ...data],
          loading: false,
          meta: { ...oldState.meta, total, page },
        }))
        initializedRef.current = true
      } catch (e) {
        setState((oldState) => ({ ...oldState, loading: false }))
        ErrorHandler.handleError(e)
      }
    },
    [loadOptions]
  )

  useEffect(() => {
    void loadItems([], state.meta.page)
  }, [loadItems])

  useEffect(() => {
    setState((oldState) => ({ ...oldState, value: value ?? null }))
  }, [value])

  useEffect(() => {
    userActionRef.current = new Subject<boolean>() // used to stop filtering data on user action
    inputValueRef.current = new BehaviorSubject<string | null>(null)
    const subscription = inputValueRef.current
      .pipe(
        debounceTime(globalConstants.inputDebounce),
        distinctUntilChanged(),
        filter<string | null>((inputValue) => inputValue !== null),
        switchMap((inputValue) => loadItems([], 1, inputValue ?? ''))
      )
      .subscribe()

    return () => {
      subscription.unsubscribe()
    }
  }, [loadItems])

  const loadMoreItems: () => Promise<void> = async () => {
    const {
      meta: { page, total },
    } = state
    if (page * limit < total) {
      void (await loadItems(state.items, state.meta.page + 1, inputValueRef.current?.value ?? undefined))
    }
  }

  const onSelect: (item: Option | null, reason: AutocompleteChangeReason) => Promise<void> = async (item, reason) => {
    if (reason === 'select-option' || reason === 'clear') {
      userActionRef.current!.next(true)
      setState((oldState) => ({ ...oldState, value: item }))
      onChange(item)
    }
    if (reason === 'clear' && inputValueRef.current?.value) {
      inputValueRef.current!.next(null)
      await loadItems([], 1)
    }
  }

  const onUserInput: (inputValue: string, reason: AutocompleteInputChangeReason) => void = (
    inputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      inputValueRef.current!.next(inputValue)
    } else if (reason === 'reset' && inputValueRef.current?.value) {
      inputValueRef.current!.next(null)
      void loadItems([], 1)
    }
  }

  const onScroll: (event: SyntheticEvent) => void = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget
    if (!state.loading && scrollTop !== 0 && scrollTop + clientHeight >= scrollHeight * 0.8) {
      void loadMoreItems()
    }
  }

  return (
    <Autocomplete
      hidden={hidden}
      value={state.value}
      title={getInputLabel(state.value ?? null)}
      options={state.items}
      onBlur={onBlur}
      loading={state.loading}
      getOptionLabel={getInputLabel}
      getOptionSelected={(option: IXtAutocompleteOption, item: IXtAutocompleteOption) => option && item && option.id === item.id}
      ListboxProps={{ onScroll }}
      className={cls('xtAutocompleteInput', className)}
      onInputChange={(_, inputValue, reason) => onUserInput(inputValue, reason)}
      renderInput={(params) => (
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={placeholder ?? ''}
          variant="outlined"
          error={!!error}
          helperText={error}
        />
      )}
      renderOption={renderOption}
      noOptionsText="No matching items found."
      onChange={(_, option, reason) => onSelect(option, reason)}
      disabled={disabled}
    />
  )
}
