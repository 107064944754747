import * as React from 'react'
import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './menuNavbar.module.scss'
import { IRoutesNavbar } from './MenuNavbar.types'
import { cls } from '../../common/utils'

export const MenuNavbar: FC<IRoutesNavbar> = ({ mainPath, navbarRoutes }) => {
  const { pathname } = useLocation()
  return (
    <ul className={styles.menuNav}>
      {navbarRoutes.map(({ path, name }) => {
        const nextPath = `${mainPath}/${path}`
        const isExactLink = pathname === nextPath
        return (
          <li key={path}>
            <NavLink activeClassName={styles.activeLink} to={nextPath} className={cls(isExactLink && styles.exactLink)}>
              {name}
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}
