import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import { cls } from 'common/utils'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { CustomersService } from 'customers/customers.service'
import { ICustomeDetailsMode } from 'customers/customer-details/customer-details.types'
import { ToastService } from 'services/ToasterService'
import { XtFilterButton } from 'components/filter/filterButton'
import { XtFilter } from 'components/filter/filter'
import { useFilter } from 'common/hooks/filter'
import { fetchCustomers } from './customers-list.utils'

import { confirmationMessages, xsMq } from '../../common/constants'
import {
  defaultDeletionState,
  CustomersListActionsEditMode,
  customerColumns,
  filterFields,
  defaultFilterValues,
} from './customers-list.constants'

import { CustomersAction, ICustomersTableItem, IDeletionState } from './customers-list.types'

import * as styles from './customers-list.module.scss'
import { useTable } from '../../common/hooks/useTable'
import { XtList } from '../../components/list/list'

export const CustomersList: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  // TODO implement modes
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaultDeletionState)

  const { state, setLoading, refresh, pagination, filter } = useTable({}, fetchCustomers)
  const { openFilters, closeFilters, onFiltersSubmit, filterOpen, filters } = useFilter(async () => filterFields, filter)
  const handleRowClick = useCallback<(item: ICustomersTableItem, mode?: ICustomeDetailsMode) => void>(
    ({ id }, mode: ICustomeDetailsMode = ICustomeDetailsMode.View) => history.push(`${path}/${id}/${mode}`),
    [path, history]
  )
  const handleAction = useCallback<(item: ICustomersTableItem, action: CustomersAction) => void>(
    (item, action) => {
      switch (action) {
        case CustomersAction.View:
          return handleRowClick(item)
        case CustomersAction.Edit:
          return handleRowClick(item, ICustomeDetailsMode.Edit)
        case CustomersAction.Delete:
          return !state.loading && setDeletionState({ customersId: item.customer_number, confirmationOpen: true })
        default:
          return null
      }
    },
    [history, path, state.loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.customersId) {
      try {
        setLoading(true)
        await CustomersService.delete(deletionState.customersId)
        await refresh()
        setLoading(false)
        ToastService.showSuccess(`Customer ${deletionState.customersId} has been deleted.`)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState.customersId, setLoading, refresh, closeConfirmationDialog])

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Customer"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      {filters.length && (
        <XtFilter
          defaultValues={defaultFilterValues}
          open={filterOpen}
          onClose={closeFilters}
          onSubmit={onFiltersSubmit}
          filters={filters}
        />
      )}
      <div className={cls('xt-content', styles.listContent)}>
        <h1 className={cls('xt-page-title', 'xt-section-border', styles.listHeader)}>Customers</h1>
        <div className={styles.headerContent}>
          <XtFilterButton isMobile={isMobile} loading={state.loading} onClick={openFilters} />
          <XtButton
            hidden={isMobile}
            className={styles.newButton}
            label="Customer New"
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push(`${path}/new`)}
          />
        </div>
        <XtList
          actions={CustomersListActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data ?? []}
          columns={customerColumns}
        />
      </div>
    </div>
  )
}
