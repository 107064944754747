import { AxiosInstance } from 'axios'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../common/common.types'
import { CommentCreateRequest, CommentUpdateRequest, IComment, ICommentsFilters, ICommentType } from './comments.types'
import api from '../common/api'
import { DocumentType } from '../documents/documents.types'
import { globalConstants } from '../common/constants'

export interface ICommentsService {
  getAll(pagination: IPaginationParams, filters?: ICommentsFilters): Promise<IPaginationData<IComment>>
  get(id: number): Promise<IComment>
  getForSource(source: DocumentType, sourceNumber: string, pagination: IPaginationParams): Promise<IPaginationData<IComment>>
  create(data: CommentCreateRequest): Promise<void>
  createAll(data: CommentCreateRequest[]): Promise<void>
  update(data: CommentCreateRequest): Promise<void>
  updateAll(data: CommentCreateRequest[]): Promise<void>
  getCommentTypes(page: number): Promise<IPaginationData<ICommentType>>
}

class Service implements ICommentsService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll({ limit, page }: IPaginationParams, filters?: ICommentsFilters): Promise<IPaginationData<IComment>> {
    const params: Record<string, string | number> = { limit, page: page !== 0 ? page : 1 } // page bomItemCounter should start from 1
    if (filters?.commentSourceNumber) {
      params.comment_source_number = filters.commentSourceNumber
    }
    const response = await this.apiClient.get<IPaginationResponse<IComment>>('/comment', { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(id: number): Promise<IComment> {
    const response = await this.apiClient.get<IPaginationResponse<IComment>>('/comment', { params: { id } })

    const {
      data: { data },
    } = response

    if (!Array.isArray(data) || !data.length) {
      throw new Error(`The comment with ID: ${id} cannot be found.`)
    }

    return data[0]
  }

  public async getForSource(
    source: DocumentType,
    sourceNumber: string,
    { limit, page }: IPaginationParams
  ): Promise<IPaginationData<IComment>> {
    const params: Record<string, string | number> = { limit, page: page !== 0 ? page : 1 } // page bomItemCounter should start from 1
    const response = await this.apiClient.get<IPaginationResponse<IComment>>(`/comment/${source}/${sourceNumber}`, { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async create(data: CommentCreateRequest): Promise<void> {
    await this.createAll([data])
  }

  public async createAll(comments: CommentCreateRequest[]): Promise<void> {
    await this.apiClient.post<IResponse<void>>('/comment/create', { data: { comments } })
  }

  public async update(data: CommentUpdateRequest): Promise<void> {
    await this.updateAll([data])
  }

  public async updateAll(comments: CommentUpdateRequest[]): Promise<void> {
    await this.apiClient.post<IResponse<void>>('/comment/update', { data: { comments } })
  }

  public async getCommentTypes(page: number): Promise<IPaginationData<ICommentType>> {
    const params = { page, limit: globalConstants.paginationLimit }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICommentType>>('/commenttype', { params })

    return { data: Array.isArray(data) ? data : [], total: status.totalrowcount }
  }
}

export const CommentsService = new Service(api)
