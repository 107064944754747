import { BillToAndShipToFormField, IBillToAndShipToForm } from './sales-order-bill-to-and-ship-to-form.types'
import { IPaginationData } from '../../../../../common/common.types'
import { IXtAutocompleteOption } from '../../../../../components/xtAutocomplete/XtAutocomplete.types'
import { DictionaryService } from '../../../../../services/dictionary.service'
import { CountryStates } from '../../../../../common/utils/country-state.utils'
import { ISalesOrderAddress } from '../../../../sales-orders.types'

export function defineFormData(address: ISalesOrderAddress | null | undefined, countryStates?: CountryStates): IBillToAndShipToForm {
  return {
    [BillToAndShipToFormField.Street1]: address?.address1 ?? '',
    [BillToAndShipToFormField.Street2]: address?.address2 ?? '',
    [BillToAndShipToFormField.Street3]: address?.address3 ?? '',
    [BillToAndShipToFormField.City]: address?.city ?? '',
    [BillToAndShipToFormField.Country]: countryStates?.initialCountry ?? null,
    [BillToAndShipToFormField.State]: countryStates?.initialState ?? '',
    [BillToAndShipToFormField.Postal]: address?.postal_code ?? '',
    [BillToAndShipToFormField.Contact]: null,
  }
}
// TODO use loadCountryOptions from common/utils
export async function loadCountries(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DictionaryService.getAll({ page, limit })
  return {
    data: data.map(({ country_code, country }) => ({ id: country_code, label: country })),
    total,
  }
}
