import * as React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import * as Icon from 'react-feather'

import { FC } from 'react'
import { cls } from '../../../common/utils'
import { IBomDetailsSectionProps } from './BomDetailsSection.types'
import './BomDetailsSection.scss'
import * as styles from './BomDetailsSection.module.scss'

// TODO Update me after API is finished

export const BomDetailsSection: FC<IBomDetailsSectionProps> = ({ isMobile = false, data }) => {
  const makeBomDetailsRows = () => [
    { label: 'Pick-List Items', count: data?.picklist_number ?? '0.00', total: data?.picklist_qtyper ?? '0.00' },
    { label: 'Non Pick-List Items', count: data?.non_picklist_number ?? '0.00', total: data?.non_picklist_qtyper ?? '0.00' },
    { label: 'Totals', count: data?.total_number ?? '0.00', total: data?.total_qtyper ?? '0.00' },
  ]
  // TODO Update me after API is finished
  const makeCostRows = () => [
    { label: 'Standard Material Cost:', total: data?.standard_cost ?? '0.00' },
    { label: 'Actual Material Cost:', total: data?.actual_cost ?? '0.00' },
    { label: 'Maximum Desired Cost:', total: data?.max_cost ?? '0.00' },
  ]
  return (
    <Accordion component="section" className={cls('MuiBomDetailsSectionContent', styles.bomDetailsSectionContent)}>
      <AccordionSummary expandIcon={<Icon.ChevronDown />} aria-controls="panel1a-content">
        <Typography>Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.tablesContainer}>
          <Table aria-label="Bom Details">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell variant="head" align="center">
                  # of items
                </TableCell>
                <TableCell variant="head" align="center">
                  Total Qty. Per
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {makeBomDetailsRows().map((row) => (
                <TableRow key={row.label}>
                  <TableCell variant="head" component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="center">{row.count}</TableCell>
                  <TableCell align="center">{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isMobile ? <Divider /> : <Divider variant="middle" orientation="vertical" />}

          <Table aria-label="Cost Details">
            <TableBody>
              {makeCostRows().map((row) => (
                <TableRow key={row.label}>
                  <TableCell variant="head" component="th" scope="row">
                    {row.label}
                  </TableCell>
                  <TableCell align="right">{row.total}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell variant="head" component="th" scope="row">
                  Total Qty.Per should equals:
                </TableCell>
                <TableCell align="right">
                  <TextField disabled variant="outlined" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
