import * as React from 'react'
import { FC, useEffect } from 'react'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import { ToastService } from './services/ToasterService'

const toast = {
  autoDismissTimeout: 4000,
  animationTimeout: 500,
}

const ToastRegister: FC = ({ children }) => {
  const { addToast, removeToast, removeAllToasts } = useToasts()
  useEffect(() => ToastService.initialize({ addToast, removeToast, removeAllToasts }), [addToast, removeToast, removeAllToasts])
  return <>{children}</>
}
export const Toast: FC = ({ children }) => (
  <ToastProvider
    autoDismiss={toast.animationTimeout}
    transitionDuration={toast.animationTimeout}
    autoDismissTimeout={toast.autoDismissTimeout}
  >
    <ToastRegister>{children}</ToastRegister>
  </ToastProvider>
)
