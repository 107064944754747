import { ICRMAccount } from 'crm-accounts/crm-accounts.types'
import { IObjectWithId } from '../../common/common.types'

export interface IDeletionState {
  CRMAccountNumberId: string
  confirmationOpen: boolean
}

export enum CRMAccountAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'view',
}

export interface ICRMAccountTableItem extends IObjectWithId, Pick<ICRMAccount, 'number' | 'name' | 'owner' | 'assigned_user'> {
  first_name: string
  last_name: string
  phone: string
}
