import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import { cls } from 'common/utils'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { CRMAccountService } from 'crm-accounts/crm-accounts.service'
import { normalizeData } from './crm-accounts-list.utils'
import { IPaginationData, IPaginationParams } from '../../common/common.types'
import { confirmationMessages, xsMq } from '../../common/constants'
import { defaulDeletionState, CRMAccountActionsEditMode, crmAccountColumns } from './crm-accounts-list.constants'

import { CRMAccountAction, ICRMAccountTableItem, IDeletionState } from './crm-accounts.types'

import * as styles from './crm-accounts-list.module.scss'
import { useTable } from '../../common/hooks/useTable'
import { XtList } from '../../components/list/list'
// TODO filters?
const fetchCRMAccounts = async (_: unknown, paginationParams: IPaginationParams): Promise<IPaginationData<ICRMAccountTableItem>> => {
  const { total, data } = await CRMAccountService.getAll(paginationParams)
  return {
    data: normalizeData(data),
    total,
  }
}

export const CRMAccountsList: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  // TODO implement modes
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaulDeletionState)

  const { state, setLoading, refresh, pagination } = useTable({}, fetchCRMAccounts)

  const handleRowClick = useCallback<(item: ICRMAccountTableItem) => void>(({ id }) => history.push(`${path}/${id}`), [path, history])

  const handleAction = useCallback<(item: ICRMAccountTableItem, action: CRMAccountAction) => void>(
    ({ number }, action) => {
      if (action === CRMAccountAction.Delete && !state.loading) {
        setDeletionState({ CRMAccountNumberId: number, confirmationOpen: true })
        return
      }
      if (action === CRMAccountAction.Edit) {
        // TODO edit mode
        console.log('edit')
      }
    },
    [history, path, state.loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaulDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.CRMAccountNumberId) {
      try {
        setLoading(true)
        await CRMAccountService.delete(deletionState.CRMAccountNumberId)
        await refresh()
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState.CRMAccountNumberId, setLoading, refresh, closeConfirmationDialog])

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Account"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <div className={cls('xt-content', styles.listContent)}>
        <h1 className={cls('xt-page-title', 'xt-section-border', styles.listHeader)}>CRM Accounts</h1>
        <div hidden={isMobile} className={styles.headerContent}>
          <XtButton
            className={styles.newButton}
            label="Create CRM Accounts"
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push(`${path}/create`)}
          />
        </div>
        <XtList
          actions={CRMAccountActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data ?? []}
          columns={crmAccountColumns}
        />
      </div>
    </div>
  )
}
