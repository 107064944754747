import * as React from 'react'
import { isValid } from 'date-fns'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FC, useEffect, useState } from 'react'
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'

interface IKeyboardDatePickerWithTextProps extends Omit<KeyboardDatePickerProps, 'onChange'> {
  invalidDateValue: string
  // initValue: ParsableDate
  setValue: (date: MaterialUiPickersDate | string | null, value?: string | null) => void
}

export const KeyboardDatePickerWithText: FC<IKeyboardDatePickerWithTextProps> = (props) => {
  const { invalidDateValue, setValue, ...otherProps } = props

  const propsValue = new Date(String(otherProps.value))

  const dateValue = propsValue

  const [isInnerValue, setIsInnerValue] = useState<ParsableDate>(isValid(dateValue) ? propsValue : invalidDateValue)
  const [isFirstRender, setIsFirstRender] = useState(false)

  const inputProps: { value?: string; onChange?: () => void } = {}

  if (isInnerValue) {
    inputProps.value = isInnerValue.toString()
    inputProps.onChange = () => setIsInnerValue(null)
  }

  useEffect(() => {
    setIsFirstRender(true)
  }, [])

  return (
    <KeyboardDatePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      value={isFirstRender ? propsValue : null} // propsValue !== format mm/dd/yyyy, need add date-fns format()
      invalidDateMessage=""
      inputProps={inputProps}
      onChange={(date, value) => {
        if (isInnerValue && value !== invalidDateValue) {
          setIsInnerValue(null)
        }
        setValue(date, value)
      }}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        const { value } = e.target
        if (value !== invalidDateValue && !isValid(new Date(e.target.value))) {
          setIsInnerValue(invalidDateValue)
          setValue(invalidDateValue)
        }
      }}
    />
  )
}
