import { validationMessage } from 'common/validation/validation'

import { ContactFormValues } from 'contacts/contacts.types'
import * as Yup from 'yup'

export const ContactFormValidationSchema = Yup.object().shape(
  {
    [ContactFormValues.FirstName]: Yup.string().when(ContactFormValues.LastName, {
      is: (last_name: string) => !last_name,
      then: Yup.string().required(validationMessage.isRequired),
    }),
    [ContactFormValues.LastName]: Yup.string().when(ContactFormValues.FirstName, {
      is: (first_name: string) => !first_name,
      then: Yup.string().required(validationMessage.isRequired),
    }),
    [ContactFormValues.Email]: Yup.string().email(validationMessage.invalidEmail),
  },
  [[ContactFormValues.LastName, ContactFormValues.FirstName]]
)
