import React, { FC, useCallback, useState } from 'react'
import { Fade, Modal } from '@material-ui/core'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ContactService } from 'contacts/contacts.service'
import { useTable } from '../../../common/hooks/useTable'
import { CheckboxLabel } from '../../../common/utils/form/form.components'
import { XtTable } from '../../../components/table/table'
import { XtButton } from '../../../components/xtButton/XtButton'
import { AddressTableItem, IAddressDialogParams, IAddressTableFilters } from './address-dialog.types'
import { addressTableColumns } from './address-dialog.constants'
import { globalConstants } from '../../../common/constants'
import * as styles from './address-dialog.module.scss'
import { convertAddressData } from './address-dialog.utils'

const loadAddresses = async (
  { filterByCustomer, customerNumber }: IAddressTableFilters,
  pagination: IPaginationParams
): Promise<IPaginationData<AddressTableItem>> => {
  const isCustomerNumber = filterByCustomer && customerNumber ? customerNumber : ''
  const { data, total } = await ContactService.getAddresses(pagination, isCustomerNumber)
  return {
    data: convertAddressData(data),
    total,
  }
}

export const AddressDialog: FC<IAddressDialogParams> = ({ open, customerNumber, onClose, onSelect }) => {
  const [selectedItem, setSelectedItem] = useState<AddressTableItem | null>(null)

  const { state, filter, setData, pagination } = useTable<AddressTableItem, IAddressTableFilters>(
    { filterByCustomer: Boolean(customerNumber), customerNumber },
    loadAddresses,
    100
  )

  const filterAddresses = useCallback<(filterByCustomer: boolean) => void>(
    (filterByCustomer) => {
      void filter({ customerNumber, filterByCustomer })
    },
    [filter, customerNumber]
  )

  const handleItemSelection = useCallback<(item: AddressTableItem) => void>(
    (item) => {
      setSelectedItem(item)
      setData(state.data.map((itemData) => ({ ...itemData, selected: itemData.id === item.id })))
    },
    [state, setData]
  )

  const selectItem = useCallback<VoidFunction>(() => {
    if (selectedItem) {
      onSelect(selectedItem)
      onClose()
    }
  }, [selectedItem, onSelect, onClose])

  return (
    <Modal open={open}>
      <Fade timeout={globalConstants.dialogAnimationFadeTime} in={open}>
        <div className={styles.addressesDialog}>
          <div className={styles.addressesDialogHeader}>
            <CheckboxLabel
              hidden={!customerNumber}
              value={state.filters.filterByCustomer}
              onChange={filterAddresses}
              label={`Only addresses for ${customerNumber}`}
              className={styles.customerNumberCheckbox}
            />
            <XtButton label="Cancel" onClick={onClose} />
            <XtButton label="Select" disabled={!selectedItem} onClick={selectItem} />
          </div>
          <XtTable
            pagination={pagination}
            onRowClick={handleItemSelection}
            columns={addressTableColumns}
            rows={state.data}
            loading={state.loading}
          />
        </div>
      </Fade>
    </Modal>
  )
}
