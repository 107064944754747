import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import * as yup from 'yup';
import { Formik } from 'formik';

import logo from "../assets/img/xtuple.png";
import { AuthService } from "../auth/auth.service";

const schema = yup.object({
  email: yup.string().required("Email address is required"),
  password: yup.string().required("Password is required"),
});

const Login = (props) => {
  const [message, setMessage] = useState("");

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        AuthService.login(values.email, values.password).then(
          () => {
            props.history.push("/");
          },
          (error) => {
            if (error.response) {
              if (error.response.status === 422 || error.response.status === 401) {
                setMessage("Invalid username or password");
              }
              else {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                setMessage(resMessage);
              }
            }
            else if (error.request) {
              setMessage("Connection error or API not responding");
            }
            else {
              setMessage("Unspecified error attempting to validate username and password")
            }
          }
        );
        setSubmitting(false);
      }}
      initialValues={{
        email: '',
        password: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="xcard xcard-container">
                <img src={logo} alt="xTuple Logo" />
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                    <Row>
                        <Col>
                          <Form.Group controlId="email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              isValid={touched.email && !errors.email}
                              isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              isValid={touched.password && !errors.password}
                              isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {message && (
                    <Form.Group>
                      <div className="alert alert-danger pt-2 pb-2" role="alert">
                        {message}
                      </div>
                    </Form.Group>
                  )}
                  <Button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block mr-2">
                    {isSubmitting && (
                      <i className="fa fa-spinner fa-spin" />
                    )}
                    <span>Login</span>
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
};

export default Login;
