import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'

export enum AttachedDocumentAction {
  Delete = 'Delete',
  Download = 'Download',
  Edit = 'Edit',
  Preview = 'Preview',
}

export const AttachedDocumentActionsEditMode: IXtTableCellAction[] = [
  {
    name: AttachedDocumentAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const AttachedDocumentActionsEditModeWithFileLink: IXtTableCellAction[] = [
  {
    name: AttachedDocumentAction.Preview,
    label: 'Preview',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: AttachedDocumentAction.Download,
    label: 'Download',
    icon: SvgIconIds.DOWNLOAD,
  },
  ...AttachedDocumentActionsEditMode,
]
