import { IPaginationData, IPaginationParams } from 'common/common.types'
import { IQuote, IQuoteFilters } from 'quotes/quotes.types'
import { QuotesService } from 'quotes/quotes.service'
import { IQuotesTableItem } from 'quotes/quotes-list/quote-list.types'

function normalizeData(data: IQuote[]): IQuotesTableItem[] {
  return data.map((item) => ({ ...item, id: item.quote_number }))
}

export async function fetchQuotes(filters: IQuoteFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IQuotesTableItem>> {
  const { total, data } = await QuotesService.getAll(paginationParams, filters)

  return {
    data: normalizeData(data),
    total,
  }
}
