import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { loadCustomers, renderCustomerOption } from 'sales-orders/components/sales-order-main/sales-order-main.utils'
import { loadCustomerTypeOptions, loadSiteOptions } from 'common/utils/document.utils'
import { loadSalesReps } from 'common/utils'
import { dateConverter } from 'common/utils/date.utils'
import { IQuotesTableItem } from './quote-list.types'
import { FilterType, IFilter } from '../../components/filter/filter.types'

import { FilterFieldName } from '../quotes.types'
import { CustomerOption } from '../../sales-orders/components/sales-order-main/sales-order-main.types'

export enum QuotesAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  Copy = 'Copy ',
  CopyTo = 'Copy to Cust/Prospect',
  PrintForm = 'Print Quote Form',
  ConvertToSalesOrder = 'Convert the Quote to a Sales Order',
}
export const QuotesListActionsEditMode: IXtTableCellAction[] = [
  {
    name: QuotesAction.Edit,
    label: QuotesAction.Edit,
    icon: SvgIconIds.CREATE,
  },
  {
    name: QuotesAction.View,
    label: QuotesAction.View,
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: QuotesAction.Delete,
    label: QuotesAction.Delete,
    icon: SvgIconIds.DELETE,
  },
  {
    name: QuotesAction.Copy,
    label: QuotesAction.Copy,
    icon: SvgIconIds.ADD_FILE,
  },
  {
    name: QuotesAction.CopyTo,
    label: QuotesAction.CopyTo,
    icon: SvgIconIds.COMPARE_ARROW,
  },
  {
    name: QuotesAction.PrintForm,
    label: QuotesAction.PrintForm,
    icon: SvgIconIds.PRINT,
  },
]
export const QuotesListActionsViewMode: IXtTableCellAction[] = [
  {
    name: QuotesAction.View,
    label: QuotesAction.View,
    icon: SvgIconIds.VISIBILITY,
  },
]

export const QuotesColumns: IXtTableColumn<IQuotesTableItem>[] = [
  {
    id: 'quote_number',
    field: 'quote_number',
    headerName: 'Quote #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'customer_number',
    field: 'customer_number',
    headerName: 'Customer',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'customer_po_number',
    field: 'customer_po_number',
    headerName: 'P/O Number',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Status',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'quote_date',
    field: 'quote_date',
    headerName: 'Quote Date',
    flex: '1 0 200px',
    width: 200,
    converter: dateConverter,
  },
  {
    id: 'expire_date',
    field: 'expire_date',
    headerName: 'Expire Date',
    flex: '1 0 320px',
    width: 320,
    converter: dateConverter,
  },
  {
    id: 'order_total',
    field: 'order_total',
    headerName: 'Total',
    flex: '1 0 260px',
    width: 260,
  },
]

const customerFilters: IFilter<CustomerOption> = {
  type: FilterType.Autocomplete,
  label: 'Customer',
  fieldName: FilterFieldName.Customer,
  autocompleteProps: {
    loadOptions: loadCustomers,
    renderOption: renderCustomerOption,
  },
}

export const filterFields: Array<IFilter | IFilter<CustomerOption>> = [
  {
    type: FilterType.Autocomplete,
    label: 'Site',
    fieldName: FilterFieldName.Site,
    autocompleteProps: {
      loadOptions: loadSiteOptions,
    },
  },

  {
    type: FilterType.Checkbox,
    label: 'Show Expired',
    fieldName: FilterFieldName.ShowExpired,
  },
  {
    type: FilterType.Checkbox,
    label: 'Exclude Prospects',
    fieldName: FilterFieldName.ExcludeProspects,
  },
  customerFilters,
  {
    type: FilterType.Autocomplete,
    label: 'Customer Type',
    fieldName: FilterFieldName.CustomerType,
    autocompleteProps: {
      loadOptions: loadCustomerTypeOptions,
    },
  },
  {
    type: FilterType.Text,
    label: 'Customer Type Pattern',
    fieldName: FilterFieldName.CustomerPattern,
  },
  {
    type: FilterType.Text,
    label: 'P/O #',
    fieldName: FilterFieldName.PONumber,
  },
  {
    type: FilterType.Autocomplete,
    label: 'Sales Rep.',
    fieldName: FilterFieldName.SalesRep,
    autocompleteProps: {
      loadOptions: loadSalesReps,
    },
  },

  {
    type: FilterType.Date,
    label: 'Start Date',
    fieldName: FilterFieldName.StartDate,
  },
  {
    type: FilterType.Date,
    label: 'End Date',
    fieldName: FilterFieldName.EndDate,
  },
]

export const defaultCopyQuoteDialogState = {
  open: false,
  quote: null,
}
export const defaultFilterValues = {
  [FilterFieldName.Site]: '',
  [FilterFieldName.ShowExpired]: false,
  [FilterFieldName.ExcludeProspects]: '',
  [FilterFieldName.Customer]: '',
  [FilterFieldName.CustomerType]: '',
  [FilterFieldName.CustomerPattern]: '',
  [FilterFieldName.PONumber]: '',
  [FilterFieldName.SalesRep]: '',
  [FilterFieldName.StartDate]: '',
  [FilterFieldName.EndDate]: '',
}
