import React, { FC, useState, useEffect, useCallback } from 'react'

import { XtList } from 'components/list/list'
import { ErrorHandler } from 'services/ErrorService'
import { confirmationMessages } from 'common/constants'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { ToastService } from 'services/ToasterService'
import { XtQuickAddButton } from 'components/quickAddButton/QuickAddButton'
import { ShipmentsService } from 'shipments/shipments.service'
import { IShipToList, IShipmentTableItem, IShipmentState } from './shipments-list.types'
import { ShipToActionEditMode, shipToColumns, ShipmentAction, ShipToActionViewMode } from './shipments.constants'
import {
  normalizeData,
  defaultShipmentState,
  defaultDialogState,
  defaultDeletionState,
  convertToShipmentData,
} from './shipments-list.utils'
import styles from './shipments-list.module.scss'
import { ShipmentDialog } from '../shipment-dialog/shipment-dialog'
import { ShipmentConfirmDialog, ShipmentDialogMode } from '../shipment-dialog/shipment-dialog.types'

export const ShipmentsList: FC<IShipToList> = ({ accountNumber, isMobile, disabled }) => {
  const [shipmentState, setShipmentState] = useState<IShipmentState>(defaultShipmentState)
  const { deletionState, shipmentDialogState, tableState } = shipmentState

  const setLoading = (loading: boolean) => setShipmentState((prev) => ({ ...prev, tableState: { ...prev.tableState, loading } }))

  const openShipmentDialog = (item: IShipmentTableItem | null, mode: ShipmentDialogMode) =>
    setShipmentState((prev) => ({
      ...prev,
      shipmentDialogState: { item: item ? convertToShipmentData(item) : null, open: true, mode },
    }))

  const init = async () => {
    if (accountNumber) {
      setLoading(true)
      try {
        const shipToData = await ShipmentsService.get(accountNumber)
        setShipmentState((prev) => ({ ...prev, tableState: { loading: false, items: normalizeData(shipToData) } }))
      } catch (e) {
        setLoading(false)
        ErrorHandler.handleError(e)
      }
    }
  }
  useEffect(() => void init(), [accountNumber])

  const handleAction = useCallback<(item: IShipmentTableItem, action: ShipmentAction) => void>(
    (item, action) => {
      switch (action) {
        case ShipmentAction.View:
          return openShipmentDialog(item, ShipmentDialogMode.View)
        case ShipmentAction.Edit:
          return openShipmentDialog(item, ShipmentDialogMode.Edit)
        case ShipmentAction.Delete:
          return setShipmentState((prev) => ({ ...prev, deletionState: { id: item.id.toString(), open: true } }))
        default:
          return null
      }
    },
    [tableState]
  )
  const onCloseDeletionDialog = useCallback<VoidFunction>(
    () => setShipmentState((prev) => ({ ...prev, deletionState: defaultDeletionState })),
    []
  )
  const onCloseShipmentDialog = useCallback<VoidFunction>(
    () => setShipmentState((prev) => ({ ...prev, shipmentDialogState: defaultDialogState })),
    []
  )
  const onConfirmShipToDialog = useCallback<(data: ShipmentConfirmDialog) => void>(
    async ({ shipTo, mode }) => {
      try {
        onCloseShipmentDialog()
        switch (mode) {
          case ShipmentDialogMode.Edit: {
            await ShipmentsService.update(shipTo, accountNumber)
            await init()
            ToastService.showSuccess(`Ship To ${shipTo.name} has been updated.`)
            return
          }
          case ShipmentDialogMode.New: {
            await ShipmentsService.create(shipTo, accountNumber)
            await init()
            ToastService.showSuccess(`Ship To ${shipTo.name} has been created.`)
            return
          }
          default:
            return
        }
      } catch (error) {
        ErrorHandler.handleError(error)
        onCloseShipmentDialog()
      }
    },
    [accountNumber]
  )
  const handleRowClick = useCallback<(item: IShipmentTableItem) => void>((item) => openShipmentDialog(item, ShipmentDialogMode.View), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    onCloseDeletionDialog()
    if (deletionState.id) {
      try {
        await ShipmentsService.delete(deletionState.id, accountNumber)
        await init()
        ToastService.showSuccess(`Ship To ${deletionState.id} has been deleted.`)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState, accountNumber])

  return (
    <div hidden={!accountNumber || (!tableState.items.length && isMobile)} className={styles.shipmentContent}>
      <XtConfirmationDialog
        open={deletionState.open}
        message={confirmationMessages.deleted}
        title="Delete Ship To"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={onCloseDeletionDialog}
      />
      <ShipmentDialog isMobile={isMobile} state={shipmentDialogState} onConfirm={onConfirmShipToDialog} onClose={onCloseShipmentDialog} />
      <p className={styles.shipmentTitle}>Ship To</p>
      <div className={styles.shipmentTable}>
        <div className={styles.shipmentNewButton}>
          <XtQuickAddButton hidden={isMobile} disabled={disabled} onClick={() => openShipmentDialog(null, ShipmentDialogMode.New)} />
        </div>
        <XtList
          loading={tableState.loading}
          onAction={handleAction}
          actions={!disabled ? ShipToActionEditMode : ShipToActionViewMode}
          onRowClick={handleRowClick}
          isMobile={isMobile}
          columns={shipToColumns}
          data={tableState.items}
        />
      </div>
    </div>
  )
}
