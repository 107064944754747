import * as React from 'react'
import { FC, useState } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Controller, useFormContext } from 'react-hook-form'
import { validationMessage } from 'common/validation/validation'
import { globalConstants } from 'common/constants'
import { XtButton } from 'components/xtButton/XtButton'
import { KeyboardDatePickerWithText } from 'components/KeyboardDatePickerWithText'
import { ItemType } from 'items/Items.types'
import styles from './BomItemForm.module.scss'
import { BomItemFormField, BomItemMode, FieldName, IBomItemForm } from '../BomItem.types'
import { RoutingItemsDialog } from '../RoutingItemsDialog/RoutingItemsDialog'
import { IBomItemFormParams } from './BomItemForm.types'
import { FormCheckboxLabel, FormField, FormSelectField } from '../../../common/utils/form/form.components'
import { renderIssueMethods, renderUomOptions } from './bom-item-form.utils'

export const BomItemForm: FC<IBomItemFormParams> = ({ routingItems, mode, className, itemDetails }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const {
    setValue,
    control: formControl,
    formState: { isSubmitting },
    watch,
  } = useFormContext<IBomItemForm>()

  const [effective, expires, usedAt, createChild, issueUom] = watch([
    BomItemFormField.Effective,
    BomItemFormField.Expires,
    BomItemFormField.UsedAt,
    BomItemFormField.CreateChild,
    BomItemFormField.IssueUom,
  ])
  const isView = mode === BomItemMode.View

  const onUsedAtFieldClear: VoidFunction = () => {
    setValue(BomItemFormField.UsedAt, '')
    setValue(BomItemFormField.ScheduleOperation, false)
  }

  const uomConversions = [
    ...(itemDetails?.inventory_uom?.uom_conversions?.filter(({ uom_to }) => uom_to !== issueUom) ?? []),
    { uom_to: issueUom },
  ].filter(({ uom_to }) => !!uom_to)

  const routingItemsAvailable: boolean = Array.isArray(routingItems) && !!routingItems.length

  return (
    <div className={className}>
      {routingItemsAvailable && (
        <RoutingItemsDialog
          bomUom={itemDetails?.inventory_uom_name ?? ''}
          bomItemNumber={itemDetails?.item_number ?? ''}
          items={routingItems ?? []}
          onClose={(value) => {
            if (value) {
              void setValue(BomItemFormField.UsedAt, value, { shouldDirty: true })
            }
            setOpenDialog(false)
          }}
          open={openDialog}
        />
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={styles.mainContentForm}>
          <Controller
            name={BomItemFormField.Effective}
            control={formControl}
            render={({ field: { onChange, value } }) => (
              <KeyboardDatePickerWithText
                disabled={isSubmitting || isView}
                autoOk
                value={value}
                setValue={(date) => onChange(date)}
                variant="inline"
                inputVariant="outlined"
                label={FieldName.Effective}
                format={globalConstants.dateFormat}
                invalidDateValue="Always"
                maxDate={expires}
                maxDateMessage={validationMessage.maxDate(FieldName.Expires)}
              />
            )}
          />
          <Controller
            name={BomItemFormField.Expires}
            control={formControl}
            render={({ field: { onChange, value } }) => (
              <KeyboardDatePickerWithText
                value={value}
                disabled={isSubmitting || isView}
                autoOk
                setValue={(date) => onChange(date)}
                variant="inline"
                inputVariant="outlined"
                label={FieldName.Expires}
                format={globalConstants.dateFormat}
                invalidDateValue="Never"
                minDate={effective}
                minDateMessage={validationMessage.minDate(BomItemFormField.Effective)}
              />
            )}
          />
          <div className={styles.mainContentFormCheckboxes}>
            <FormCheckboxLabel
              control={formControl}
              name={BomItemFormField.ScheduleOperation}
              label="Schedule at W/O Operation"
              disabled={!usedAt || isSubmitting || isView}
            />
            <FormCheckboxLabel
              control={formControl}
              disabled={isSubmitting || isView || itemDetails?.item_type === ItemType.Manufactured}
              name={BomItemFormField.CreateChild}
              label="Create Child W/O at Parent W/O Explosion"
            />
            <FormCheckboxLabel
              control={formControl}
              disabled={isSubmitting || isView || !createChild || itemDetails?.item_type === ItemType.Manufactured}
              name={BomItemFormField.IssueChild}
              label="Issue Child W/O to Parent W/O at Receipt"
            />
          </div>
          <FormField
            type="number"
            disabled={isSubmitting || isView}
            name={BomItemFormField.FixedQty}
            control={formControl}
            label={FieldName.FixedQty}
          />
          <FormField
            type="number"
            disabled={isSubmitting || isView}
            name={BomItemFormField.QtyPer}
            control={formControl}
            label={FieldName.QtyPer}
          />
          <FormSelectField
            disabled={isSubmitting || isView || !uomConversions?.length}
            control={formControl}
            name={BomItemFormField.IssueUom}
            options={renderUomOptions(uomConversions)}
            label={FieldName.IssueUOM}
          />
          <FormSelectField
            disabled={isSubmitting || isView}
            name={BomItemFormField.IssueMethod}
            control={formControl}
            options={renderIssueMethods()}
            label={FieldName.IssueMethod}
          />
          <FormField
            control={formControl}
            disabled={isSubmitting || isView}
            name={BomItemFormField.EcnNumber}
            label={FieldName.EcnNumber}
          />
          <FormField
            disabled={isSubmitting || isView}
            name={BomItemFormField.Scrap}
            control={formControl}
            type="number"
            label={FieldName.Scrap}
          />
          <div className={styles.bomItemUsedAtSection}>
            <FormField
              disabled
              name={BomItemFormField.UsedAt}
              control={formControl}
              label={FieldName.UsedAt}
              inputProps={{ readOnly: true, placeholder: FieldName.UsedAt }}
            />
            <div className={styles.bomItemButtonsSection}>
              <XtButton
                disabled={!routingItemsAvailable || isSubmitting || isView}
                className={styles.bomItemFormButton}
                // TODO notify user if no data is available
                onClick={() => setOpenDialog(true)}
                label="Routing Item"
              />
              <XtButton className={styles.bomItemFormButton} label="Clear" disabled={isSubmitting || isView} onClick={onUsedAtFieldClear} />
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}
