import React from 'react'
import { IXtAutocompleteOption } from '../../components/xtAutocomplete/XtAutocomplete.types'

export function getInlineLabel(option: IXtAutocompleteOption | null): string {
  return option ? `${option.id}-${option.label}` : ''
}

export function renderInlineOption(option: IXtAutocompleteOption): React.ReactNode {
  return (
    <div className="xt-autocomplete-container">
      <div className="xt-autocomplete-label">{getInlineLabel(option)}</div>
    </div>
  )
}

export function renderOption(option: IXtAutocompleteOption): React.ReactNode {
  return (
    <div className="xt-autocomplete-container">
      <div className="xt-autocomplete-label">{option.id?.toString()}</div>
      <div className="xt-autocomplete-label">{option.label}</div>
    </div>
  )
}

export function getInputId(option: IXtAutocompleteOption | null): string {
  return option ? option.id : ''
}
