import * as React from 'react'
import { FC, memo, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormCheckboxLabel, FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { loadCustomerTypeOptions } from 'common/utils/document.utils'

import { ICustomer } from 'customers/customers.types'
import styles from './customer-details-form.module.scss'
import { CustomerDetailsFormSchema } from './customer-details-form.validation'
import { CustomerFormValues, CustomerFormField, CustomerFormLabel, ICustomerFormState, ICustomerFormData } from '../customer-details.types'
import { defineCustomerFormData } from '../customer-details.utils'

export interface ICustomerFormChange extends ICustomerFormState {
  state: CustomerFormValues
}
export interface ICustomerDetailsForm {
  disabled: boolean
  onChange(state: ICustomerFormChange): void
  customer: ICustomer | null
  isSubmitting: boolean
}

export const CustomerDetailsForm: FC<ICustomerDetailsForm> = memo(({ disabled, onChange, customer, isSubmitting }) => {
  const { control, reset, watch, formState, trigger } = useForm<ICustomerFormData>({
    defaultValues: defineCustomerFormData(null),
    resolver: yupResolver(CustomerDetailsFormSchema),
    mode: 'onBlur',
  })
  // TODO update me
  const values = watch()
  const { isDirty, isValid } = formState
  useEffect(() => void reset(defineCustomerFormData(customer)), [customer])

  useEffect(() => onChange({ state: { ...values, customer_type: values?.customer_type?.id ?? '' }, isDirty, isValid }), [formState, values])

  useEffect(() => {
    onChange({ state: { ...values, customer_type: values?.customer_type?.id ?? '' }, isDirty, isValid })
  }, [formState, values])

  useEffect(() => {
    if (isSubmitting) {
      void trigger([CustomerFormField.Name, CustomerFormField.Number, CustomerFormField.Type])
    }
  }, [isSubmitting])

  return (
    <div className={styles.customerDetailsFields}>
      <FormField
        control={control}
        disabled={disabled || Boolean(customer?.customer_number)}
        name={CustomerFormField.Number}
        label={CustomerFormLabel.Number}
      />
      <FormField control={control} disabled={disabled} name={CustomerFormField.Name} label={CustomerFormLabel.Name} />
      <FormXtAutocomplete
        control={control}
        name={CustomerFormField.Type}
        label={CustomerFormLabel.Type}
        loadOptions={loadCustomerTypeOptions}
        disabled={disabled}
      />
      <FormCheckboxLabel control={control} disabled={disabled} name={CustomerFormField.Active} label={CustomerFormLabel.Active} />
    </div>
  )
})
