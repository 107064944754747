import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'
import { IRoutingDetailsTableItem, RoutingDetailsAction } from './RoutingDetails.types'

export enum RoutingDetailsFormField {
  RevisionNumber = 'revision_number',
  DocumentNumber = 'document_number',
  RevisionDate = 'revision_date',
  FinalLocation = 'final_location',
  FinalLocationSite = 'final_location_site',
  ShowExpired = 'showExpired',
  ShowFuture = 'showFuture',
  CloseWorkorder = 'close_workorder',
  RoutingItems = 'routing_items',
  ItemNumber = 'item_number',
  ItemDescription = 'item_description',
  LeadTime = 'lead_time',
  RevisionStates = 'revision_status',
}

export const RoutingDetailsActionsViewMode: IXtTableCellAction[] = [
  {
    name: RoutingDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const RoutingDetailsActionsEditMode: IXtTableCellAction[] = [
  {
    name: RoutingDetailsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: RoutingDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: RoutingDetailsAction.Expire,
    label: 'Expire',
    icon: SvgIconIds.EXPIRE,
  },
  {
    name: RoutingDetailsAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const RoutingDetailsActionsNewMode: IXtTableCellAction[] = [
  {
    name: RoutingDetailsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: RoutingDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: RoutingDetailsAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const routingDetailsColumns: IXtTableColumn<IRoutingDetailsTableItem>[] = [
  {
    id: 'sequence_number',
    field: 'sequence_number',
    headerName: '#',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'standard_operation',
    field: 'standard_operation',
    headerName: 'Std. Oper.',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'work_center',
    field: 'work_center',
    headerName: 'Work Cntr.',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'description',
    field: 'description',
    headerName: 'Description',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'effective',
    field: 'effective',
    headerName: 'Effective',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'expires',
    field: 'expires',
    headerName: 'Expires',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'execution_day',
    field: 'execution_day',
    headerName: 'Exec. Day',
    flex: '1 0 160px',
    width: 160,
    align: 'center',
  },
]
