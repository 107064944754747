import * as React from 'react'
import { FC, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { IRouting } from 'routing/Routing.types'
import { loadDocumentListOptions } from 'common/utils/document.utils'
import { XtAutocomplete } from 'components/xtAutocomplete/XtAutocomplete'
import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import * as styles from './RoutingDetailsForm.module.scss'
import { RoutingDetailsFormField } from '../RoutingDetails.constants'
import { renderWipLocationOption } from '../../routingItem/routingItemTabs/RoutingItemTabs'
import { FormDatePicker, FormField } from '../../../common/utils/form/form.components'

interface IRoutingDetailsFormProps {
  disabled: boolean
  leadTime: number
}

export interface IRenderOptions {
  name?: string
  number?: string
}
function registerHiddenFields(register: (name: keyof IRouting) => void, fields: (keyof IRouting)[]): void {
  fields.forEach(register)
}

export const RoutingDetailsForm: FC<IRoutingDetailsFormProps> = ({ disabled, leadTime }) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useFormContext()

  registerHiddenFields(register, [RoutingDetailsFormField.FinalLocationSite, RoutingDetailsFormField.FinalLocation])

  const [finalLocationSite, finalLocation] = watch([RoutingDetailsFormField.FinalLocationSite, RoutingDetailsFormField.FinalLocation])

  const setFinalLocationValue = useCallback((option: IXtAutocompleteOption | null) => {
    setValue(RoutingDetailsFormField.FinalLocationSite, option ? option.label : '')
    setValue(RoutingDetailsFormField.FinalLocation, option ? option.id : '')
  }, [])

  const finalLocationValue = finalLocation ? { id: finalLocation, label: `${finalLocationSite}-${finalLocation}` } : null

  return (
    <div className={styles.routingDetailsFields}>
      <FormField name={RoutingDetailsFormField.RevisionNumber} control={control} disabled label="Revision" />
      <FormField label="Document #" name={RoutingDetailsFormField.DocumentNumber} control={control} disabled={disabled} />
      <FormDatePicker label="Date" name={RoutingDetailsFormField.RevisionDate} control={control} disabled={disabled} />

      <XtAutocomplete
        disabled={disabled}
        value={finalLocationValue}
        error={errors[RoutingDetailsFormField.FinalLocation]?.message}
        placeholder="Final Location"
        onChange={(option) => setFinalLocationValue(option)}
        loadOptions={loadDocumentListOptions}
        renderOption={renderWipLocationOption}
      />
      {leadTime >= 0 && <div>{`Prod. Lead Time: ${leadTime}`}</div>}
    </div>
  )
}
