import { useCallback, useState } from 'react'
import { IPricingItem } from '../../../../../items/Items.types'
import { ItemsService } from '../../../../../items/items.service'
import { ErrorHandler } from '../../../../../services/ErrorService'

export interface IRequestPriceFuncResponse {
  listPrice: number
  customerPrice: number
  discountFromCustomer: number
  discountFromList: number
}

export type RequestPriceFunc = (
  customerNumber: string,
  itemNumber: string,
  site: string,
  quantity: number,
  currency: string
) => Promise<IRequestPriceFuncResponse>

export interface ISalesOrderLineItemPriceHookState {
  listPrice: number
  wholesalePrice: number
  unitCost: number
  pricingItems: IPricingItem[]
  loading: boolean
}

export interface ISalesOrderLineItemPriceHook {
  state: ISalesOrderLineItemPriceHookState
  requestPrice: RequestPriceFunc
}

const defaultState: ISalesOrderLineItemPriceHookState = {
  listPrice: 0,
  wholesalePrice: 0,
  unitCost: 0,
  pricingItems: [],
  loading: false,
}

export function useSalesOrderLineItemPrice(): ISalesOrderLineItemPriceHook {
  const [state, setState] = useState<ISalesOrderLineItemPriceHookState>(defaultState)

  const requestPrice = useCallback<RequestPriceFunc>(async (customerNumber, itemNumber, site, quantity, currency) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      const price = await ItemsService.getItemPrices(itemNumber, { customer_number: customerNumber, site, quantity, currency })
      setState({
        listPrice: price.list_price,
        pricingItems: price.pricing_items,
        wholesalePrice: price.wholesale_price,
        unitCost: price.unit_cost,
        loading: false,
      })
      return {
        listPrice: price.list_price,
        customerPrice: price.customer_price,
        discountFromList: price.discount_from_list,
        discountFromCustomer: 0,
      }
    } catch (e) {
      ErrorHandler.handleError(e)
      setState((prevState) => ({ ...prevState, loading: false }))
      return { listPrice: 0, customerPrice: 0, discountFromList: 0, discountFromCustomer: 0 }
    }
  }, [])

  return {
    state,
    requestPrice,
  }
}
