import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Observable, Subject } from 'rxjs'
import { XtCharacteristics } from 'characteristics/characteristics'
import { useCharacteristics } from 'common/hooks/characteristics'
import { XtDocuments } from 'components/documents/documents'
import { IDocumentsHook, useDocuments } from 'common/hooks/documents'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { AdditionalDataCreationError } from 'common/common.types'
import { ISalesOrderParams, ISalesOrdersNewState, SalesOrderNewStateFilled, SalesOrdersNewTab } from './sales-orders-new.types'
import * as styles from './sales-orders-new.module.scss'
import { cls } from '../../common/utils'
import { XtButton } from '../../components/xtButton/XtButton'
import LoadingSpinner from '../../components/LoadingSpinner'
import { SalesOrderMain } from '../components/sales-order-main/sales-order-main'
import { SalesOrderLineItems } from '../components/sales-order-line-items/sales-order-line-items'
import { xsMq } from '../../common/constants'
import { SalesOrderHeaderInformation } from '../components/sales-order-header-information/sales-order-header-information'
import { SalesOrderHeaderInformationChangeHandler } from '../components/sales-order-header-information/sales-order-header-information.types'
import {
  convertCustomerToContactAndAddressType,
  convertShipToToContactAndAddressType,
  convertToSalesOrderCreateInput,
  convertToSalesOrderUpdateInput,
  convertToShipmentsAndSalesInput,
  defineState,
  isStateDirty,
  isValid,
  retrieveMainFormValue,
} from './sales-orders-new.utils'
import { SalesOrderMainInput } from '../components/sales-order-main/sales-order-main.types'
import { ErrorHandler } from '../../services/ErrorService'
import { ISalesOrderLineItemsData } from '../components/sales-order-line-items/sales-order-line-items.types'
import { SalesOrdersService } from '../sales-orders.service'
import { ToastService } from '../../services/ToasterService'
import { XtRemarks } from '../../components/xt-remarks/xt-remarks'
import { useRemarks } from '../../common/hooks/remarks'
import { DocumentType, UsedOnValue } from '../../documents/documents.types'
import { SalesOrderCreateInput } from '../sales-orders.types'
import { NewComment } from '../../comments/comments.types'
import { IXtTab } from '../../components/xt-tabs/xt-tabs.types'
import { FormStateChangeCallback, IFormStateChanges } from '../../common/hooks/form/form.types'

export const SalesOrder: FC<ISalesOrderParams> = ({ salesOrder, viewMode: isViewMode, onCancel, onSubmit }) => {
  const resetSubject = useRef<Subject<void>>(new Subject())
  const validationSubject = useRef<Subject<void>>(new Subject())
  const reset$ = useRef<Observable<void>>(resetSubject.current.asObservable())
  const validate$ = useRef<Observable<void>>(validationSubject.current.asObservable())
  const [state, setState] = useState<ISalesOrdersNewState>(defineState(salesOrder))
  const remarksState = useRemarks(DocumentType.SalesOrder, salesOrder?.order_number, salesOrder?.order_notes, salesOrder?.shipping_notes)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.SalesOrder, salesOrder?.order_number)
  const isMobile = useMediaQuery(xsMq)
  const viewMode = isViewMode || isMobile
  const isDirty = isStateDirty(state) || remarksState.isDirty || characteristicsState.isDirty || documentsState.isDirty
  const cancel = useCallback<VoidFunction>(() => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }
    onCancel()
  }, [onCancel, state, remarksState, characteristicsState])

  const saveSalesOrder = async (input: SalesOrderCreateInput, comments: NewComment[], documents: IDocumentsHook): Promise<void> => {
    try {
      await SalesOrdersService.create(input, comments, documents.getUnsavedDocuments())
    } catch (e) {
      if (e instanceof AdditionalDataCreationError) {
        ErrorHandler.handleError(e)
      } else {
        throw e
      }
    }
  }

  const save: () => Promise<void> = async () => {
    validationSubject.current.next()
    if (!state.lineItemsState.data?.lineItems?.length) {
      ToastService.showError('Your should add one Line Item at least.')
    }
    if (!isValid(state) || state.isSubmitting) {
      return
    }
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }))
      const { notes, additionalNotes } = remarksState
      if (salesOrder) {
        await SalesOrdersService.update(
          convertToSalesOrderUpdateInput(
            state as SalesOrderNewStateFilled,
            salesOrder,
            notes,
            additionalNotes,
            characteristicsState.characteristics
          )
        )
        ToastService.showSuccess('Sales Order has been updated.')
      } else {
        await saveSalesOrder(
          convertToSalesOrderCreateInput(state as SalesOrderNewStateFilled, notes, additionalNotes, characteristicsState.characteristics),
          remarksState.comments,
          documentsState
        )
        ToastService.showSuccess('Sales Order has been created.')
      }
      setState((prevState) => ({ ...prevState, isSubmitting: false }))
      onCancel()
      if (onSubmit) {
        onSubmit()
      }
    } catch (e) {
      ErrorHandler.handleError(e)
      setState((prevState) => ({ ...prevState, isSubmitting: false }))
    }
  }

  const handleMainFormChange = useCallback<(formState: IFormStateChanges<SalesOrderMainInput>) => void>((change) => {
    setState((prevState) => {
      const shipToOptionHasChanged = prevState.mainFormState.data?.ship_to_option?.id !== change.data.ship_to_option?.id
      const customerHasChanged = prevState.mainFormState.data?.customer?.customer_number !== change.data.customer?.customer_number
      return {
        ...prevState,
        mainFormState: change,
        billTo: customerHasChanged ? convertCustomerToContactAndAddressType(change?.data?.customer) : prevState.billTo,
        shipTo: shipToOptionHasChanged ? convertShipToToContactAndAddressType(change.data.ship_to_option) : prevState.shipTo,
        shippingAndSales: shipToOptionHasChanged
          ? convertToShipmentsAndSalesInput(prevState.shippingAndSales, change.data.ship_to_option)
          : prevState.shippingAndSales,
      }
    })
  }, [])

  const handleLineItemsChange = useCallback<FormStateChangeCallback<ISalesOrderLineItemsData>>((change) => {
    setState((prevState) => ({ ...prevState, lineItemsState: change }))
  }, [])

  const handleHeaderInformationChange = useCallback<SalesOrderHeaderInformationChangeHandler>((change) => {
    setState((prevState) => ({ ...prevState, headerInfoState: change }))
  }, [])

  useEffect(() => {
    setState(defineState(salesOrder))
    characteristicsState.reset(salesOrder?.salesorder_characteristics || [])
  }, [salesOrder])

  const mainFormDefaultValue = useMemo(() => retrieveMainFormValue(salesOrder), [salesOrder])

  const tabs = useMemo<IXtTab[]>(
    () => [
      {
        markAsInvalid: !state.lineItemsState.state.isValid && state.lineItemsState.state.touched,
        name: SalesOrdersNewTab.LineItems,
        template: (
          <SalesOrderLineItems
            customerNumber={state.mainFormState.data?.customer?.customer_number}
            validationObservable={validate$.current}
            salesOrder={salesOrder}
            onChange={handleLineItemsChange}
            viewMode={viewMode}
            isMobile={isMobile}
          />
        ),
      },
      {
        name: SalesOrdersNewTab.HeaderInfo,
        markAsInvalid: !state.headerInfoState.state.isValid && state.headerInfoState.state.touched,
        template: (
          <SalesOrderHeaderInformation
            validationObservable={validate$.current}
            viewMode={viewMode}
            shipTo={state.shipTo}
            shippingAndSales={state.shippingAndSales}
            customer={state.mainFormState.data?.customer ?? null}
            billTo={state.billTo}
            onChange={handleHeaderInformationChange}
          />
        ),
      },

      {
        name: SalesOrdersNewTab.Documents,
        template: (
          <XtDocuments
            usedOnFilter={UsedOnValue.SalesOrderItem}
            documents={documentsState.documents}
            onDocumentCreate={documentsState.saveDocument}
            onDocumentDelete={documentsState.deleteDocument}
            loadMore={documentsState.loadMore}
            canLoadMore={documentsState.canLoadMore}
            isViewMode={viewMode || !documentsState.creationEnabled}
          />
        ),
      },
      {
        name: SalesOrdersNewTab.Remarks,
        template: (
          <XtRemarks
            total={remarksState.total}
            canLoadMore={remarksState.canLoadMore}
            loadMore={remarksState.loadMore}
            comments={remarksState.comments}
            disabled={viewMode}
            onCommentsSave={remarksState.saveComment}
            onCommentsUpdate={remarksState.updateComment}
            username={remarksState.username}
            textAreaName="Order Notes"
            textAreaOnChange={remarksState.setNotes}
            textAreaValue={remarksState.notes}
            extendedTextAreaName="Shipping Notes"
            extendedTextAreaValue={remarksState.additionalNotes}
            extendedTextAreaOnChange={remarksState.setAdditionalNotes}
          />
        ),
      },
      {
        name: SalesOrdersNewTab.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.SalesOrder}
            isViewMode={viewMode}
            onCreate={characteristicsState.createCharacteristic}
            onUpdate={characteristicsState.updateCharacteristic}
            onDelete={characteristicsState.deleteCharacteristic}
            characteristics={characteristicsState.characteristics}
          />
        ),
      },
    ],
    [
      state,
      salesOrder,
      handleHeaderInformationChange,
      viewMode,
      handleLineItemsChange,
      isMobile,
      remarksState,
      characteristicsState,
      documentsState,
    ]
  )

  return (
    <div className={cls('xt-content', ' xt-content-with-remarks', styles.salesOrdersNew)}>
      {state.loading && <LoadingSpinner />}
      <form hidden={state.loading} className={styles.salesOrdersNewContent}>
        <div className={cls(viewMode ? styles.headerViewMode : styles.salesOrdersNewHeader, 'xt-page-header')}>
          <h3 className="xt-page-title">{salesOrder ? `Sales Order: ${salesOrder.order_number}` : 'Sales Order New'}</h3>
          <XtButton label="Cancel" onClick={cancel} disabled={state.isSubmitting} />
          <XtButton label="Save" onClick={save} hidden={viewMode} disabled={!isDirty || state.isSubmitting} loading={state.isSubmitting} />
        </div>
        <SalesOrderMain
          customerDisabled={!!salesOrder?.order_number || !!state.lineItemsState.data?.lineItems.length}
          disabled={viewMode}
          validationObservable={validate$.current}
          onChange={handleMainFormChange}
          resetObservable={reset$.current}
          data={mainFormDefaultValue}
        />
        <div className={styles.salesOrdersNewTabs}>
          <XtTabs tabs={tabs} />
        </div>
      </form>
    </div>
  )
}
