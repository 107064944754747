import { XtCharacteristics } from 'characteristics/characteristics'
import { ICharacteristicsHook } from 'common/hooks/characteristics'
import { IRemarksHook } from 'common/hooks/remarks'
import { IDocumentsHook } from 'common/hooks/documents'
import { XtDocuments } from 'components/documents/documents'
import { XtRemarks } from 'components/xt-remarks/xt-remarks'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { IContact } from 'contacts/contacts.types'
import { UsedOnValue } from 'documents/documents.types'

import React, { FC, useMemo } from 'react'
import { AddressAndPhoneTab } from './address-and-phone/address-and-phone'
import { IAddressAndPhoneChangeValues } from './address-and-phone/address-and-phone.types'

interface IContactTabs {
  data: IContact | null
  disabled: boolean
  isMobile: boolean
  characteristicsState: ICharacteristicsHook
  documentsState: IDocumentsHook
  detailsLoading?: boolean
  onChange(formValues: { state: IAddressAndPhoneChangeValues; isDirty: boolean }): void
  remarks: IRemarksHook
}
enum ContactTabName {
  Address = 'Address/Phone',
  Remarks = 'Remarks',
  Characteristics = 'Characteristics',
  Documents = 'Documents',
}
export const ContactTabs: FC<IContactTabs> = ({
  data,
  disabled,
  isMobile,
  onChange,
  remarks,
  detailsLoading,
  characteristicsState,
  documentsState,
}) => {
  const addressAndPhoneData = useMemo(() => ({ phones: data?.phones ?? null, address: data?.contact_address ?? null }), [
    data?.phones,
    data?.contact_address,
  ])
  const tabs = useMemo(
    () => [
      {
        name: ContactTabName.Address,
        template: <AddressAndPhoneTab onChange={onChange} data={addressAndPhoneData} disabled={disabled} isMobile={isMobile} />,
      },
      {
        name: ContactTabName.Remarks,
        template: (
          <XtRemarks
            loadMore={remarks.loadMore}
            canLoadMore={remarks.canLoadMore}
            total={remarks.total}
            textAreaOnChange={remarks.setNotes}
            textAreaValue={remarks.notes}
            textAreaName="Notes"
            onCommentsUpdate={remarks.updateComment}
            onCommentsSave={remarks.saveComment}
            comments={remarks.comments}
            username={remarks.username}
            disabled={disabled}
          />
        ),
      },
      {
        name: ContactTabName.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.Contact}
            isViewMode={disabled || Boolean(detailsLoading)}
            onCreate={characteristicsState.createCharacteristic}
            onUpdate={characteristicsState.updateCharacteristic}
            onDelete={characteristicsState.deleteCharacteristic}
            characteristics={characteristicsState.characteristics}
          />
        ),
      },
      {
        name: ContactTabName.Documents,
        template: (
          <XtDocuments
            usedOnFilter={UsedOnValue.Contact}
            isViewMode={disabled || !documentsState.creationEnabled}
            onDocumentCreate={documentsState.saveDocument}
            onDocumentDelete={documentsState.deleteDocument}
            loadMore={documentsState.loadMore}
            canLoadMore={documentsState.canLoadMore}
            documents={documentsState.documents}
          />
        ),
      },
    ],
    [
      // AddressAndPhoneTab deps
      addressAndPhoneData,
      disabled,
      isMobile,
      onChange,
      // XtRemarks deps
      remarks,
      // characteristic deps
      detailsLoading,
      characteristicsState,
      // XtDocuments deps
      documentsState,
    ]
  )
  return <XtTabs tabs={tabs} />
}
