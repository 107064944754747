import React, { FC, memo, useCallback, useState } from 'react'
import { ContactDialogForm } from 'contacts/contact-dialog-form/contact-dialog-form'
import { ToastService } from 'services/ToasterService'
import { IAttachedDocumentWithFile } from 'documents/documents.types'
import { ContactCreatePayload, IContact } from '../../contacts.types'
import { IPaginationData } from '../../../common/common.types'
import { IXtAutocompleteOption } from '../../../components/xtAutocomplete/XtAutocomplete.types'
import { ContactService } from '../../contacts.service'
import { buildFullContactName } from '../../contacts.utils'
import { XtAutocomplete } from '../../../components/xtAutocomplete/XtAutocomplete'
import * as styles from './contact-search.module.scss'
import { CheckboxLabel } from '../../../common/utils/form/form.components'
import { XtQuickAddButton } from '../../../components/quickAddButton/QuickAddButton'

export interface IContactSearchParams {
  label: string
  disabled?: boolean
  contact: IContactOption | null
  onChange(contact: IContactOption | null): void
  hidden?: boolean
  accountNumber?: string | null
  onBlur?: VoidFunction
}

export function convertToContactOption(contact: IContact): IContactOption {
  return { ...contact, id: contact.contact_number, label: buildFullContactName(contact) }
}

export interface IContactOption extends IContact, IXtAutocompleteOption {}

export const ContactSearch: FC<IContactSearchParams> = memo(({ disabled, hidden, contact, onChange, accountNumber, onBlur, label }) => {
  const [filterByAccount, setFilterByAccount] = useState<boolean>(!accountNumber)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const loadCustomers = useCallback<(page: number, limit: number) => Promise<IPaginationData<IContactOption>>>(
    async (page, limit) => {
      const { data, total } = await ContactService.getAll({ page, limit }, { account_number: filterByAccount ? accountNumber : null })
      return {
        data: data.map(convertToContactOption),
        total,
      }
    },
    [accountNumber, filterByAccount]
  )

  const openAddContactDialog: VoidFunction = () => setOpenDialog(true)

  const onCloseContactDialog = useCallback<VoidFunction>(() => setOpenDialog(false), [])

  const onConfirmContactDialog = useCallback(
    async (payload: ContactCreatePayload, unsavedDocuments: IAttachedDocumentWithFile[]): Promise<void> => {
      const newContact = await ContactService.create(payload, unsavedDocuments)
      ToastService.showSuccess(`Contact has been created.`)
      onChange(convertToContactOption(newContact))
      onCloseContactDialog()
    },
    []
  )

  return (
    <div className={styles.contactSearch}>
      <ContactDialogForm onSaveForm={onConfirmContactDialog} open={openDialog} onCancel={onCloseContactDialog} />
      <CheckboxLabel
        disabled={disabled}
        className={styles.contactSearchCheckbox}
        hidden={!accountNumber}
        value={filterByAccount}
        label={`Only contacts for ${accountNumber}`}
        onChange={(value) => setFilterByAccount(value)}
      />
      <div className={styles.contactSearchHorizontal}>
        <XtAutocomplete
          disabled={disabled}
          hidden={hidden}
          placeholder={label}
          value={contact}
          onChange={onChange}
          onBlur={onBlur}
          loadOptions={loadCustomers}
        />
        <XtQuickAddButton disabled={disabled} onClick={openAddContactDialog} />
      </div>
    </div>
  )
})
