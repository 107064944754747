import { ICharacteristicsHook } from 'common/hooks/characteristics'
import { IDocumentsHook } from 'common/hooks/documents'
import { IAssignee } from 'tasks/tasks.types'
import { IRemarksHook } from '../../../common/hooks/remarks'

export enum TaskDetailsTabsName {
  Assignee = 'Assignee',
  Characteristics = 'Characteristics',
  Documents = 'Documents',
  Remarks = 'Remarks',
}
export interface ITaskDetailsTabs {
  disabled: boolean
  isMobile: boolean
  onChange(state: IAssignee[]): void
  assignees: IAssignee[]
  remarks: IRemarksHook
  characteristics: ICharacteristicsHook
  documents: IDocumentsHook
}
