import { FC, memo, useCallback, useEffect, useState } from 'react'
import * as React from 'react'
import { Controller } from 'react-hook-form'

import { ErrorHandler } from 'services/ErrorService'
import { FormField, FormXtAutocomplete } from '../../../../../common/utils/form/form.components'
import {
  BillToAndShipToFormField,
  IBillToAndShipToForm,
  IBillToAndShipToFormParams,
  ISalesOrderContactOption,
} from './sales-order-bill-to-and-ship-to-form.types'
import { defineFormData, loadCountries } from './sales-order-bill-to-and-ship-to-form.utils'
import * as styles from './sales-order-bill-to-and-ship-to-form.module.scss'
import { salesOrderBillToAndShipToValidationSchema } from './sales-order-bill-to-and-ship-to-form.validation'
import { XtButton } from '../../../../../components/xtButton/XtButton'
import { ContactSearch, convertToContactOption } from '../../../../../contacts/components/contact-search/contact-search'
import { retrievePhone } from '../../../../../contacts/contacts.utils'
import { IContact, IContactAddress, PhoneRole } from '../../../../../contacts/contacts.types'
import { AddressDialog } from '../../../../../contacts/components/address-dialog/address-dialog'
import { DictionaryService } from '../../../../../services/dictionary.service'
import { useXtForm } from '../../../../../common/hooks/form/form'
import { CountryOption, getCountryStates } from '../../../../../common/utils/country-state.utils'
import { loadStates } from '../../../../../common/utils'

export const SalesOrderBillToAndShipToForm: FC<IBillToAndShipToFormParams> = memo(
  ({ contact, address, disabled, onChange: onFormChange, customerNumber, label, validationObservable }) => {
    const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false)

    const { control, setValue, getValues, watch, reset, trigger, formChanges$, setDisabled } = useXtForm<IBillToAndShipToForm>({
      defaultValues: defineFormData(address),
      mode: 'onBlur',
      validationSchema: salesOrderBillToAndShipToValidationSchema,
    })

    const [formContact, formCountry] = watch([BillToAndShipToFormField.Contact, BillToAndShipToFormField.Country])
    const selectedContact = formContact as ISalesOrderContactOption
    const country = formCountry as CountryOption

    const phone = retrievePhone(selectedContact, PhoneRole.Office)
    const fax = retrievePhone(selectedContact, PhoneRole.Fax)
    const email = selectedContact?.email ?? ''
    const title = selectedContact?.job_title ?? ''

    const handleCountryChange = useCallback(
      async (option: CountryOption | null) => {
        if (!option?.id) {
          setValue(BillToAndShipToFormField.Country, null, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setValue(BillToAndShipToFormField.State, '', { shouldValidate: true, shouldDirty: true })
          return
        }
        const statesData = await DictionaryService.getStates(option.id, { limit: 1, page: 1 })
        const hasStates = Boolean(statesData?.data?.length)
        setValue(BillToAndShipToFormField.Country, option ? { ...option, hasStates } : null, {
          shouldValidate: true,
          shouldDirty: true,
        })

        if (hasStates) {
          setValue(BillToAndShipToFormField.State, null, { shouldValidate: true, shouldDirty: true })
        }
        if (country?.hasStates && !hasStates) {
          setValue(BillToAndShipToFormField.State, '', { shouldValidate: true, shouldDirty: true })
        }
      },
      [country]
    )

    const setContact: (salesOrderContact: IContact | null | undefined) => Promise<void> = async (salesOrderContact) => {
      if (!salesOrderContact?.contact_number) {
        setValue(BillToAndShipToFormField.Contact, null)
        return
      }
      setValue(BillToAndShipToFormField.Contact, convertToContactOption(salesOrderContact))
    }

    const init = async () => {
      if (!address?.country) {
        reset({ ...defineFormData(address), contact: getValues(BillToAndShipToFormField.Contact) })
        return
      }
      try {
        const countryStates = await getCountryStates(address?.country, address?.state)
        reset({ ...defineFormData(address, countryStates), contact: getValues(BillToAndShipToFormField.Contact) })
      } catch (e) {
        reset({ ...defineFormData(address), contact: getValues(BillToAndShipToFormField.Contact) })
        ErrorHandler.handleError(e)
      }
    }

    useEffect(() => {
      void init()
    }, [address])

    useEffect(() => {
      void setContact(contact)
    }, [contact?.contact_number])

    useEffect(() => {
      setDisabled(disabled)
    }, [disabled])

    useEffect(() => {
      const validationSub = validationObservable.subscribe(() => {
        void trigger()
      })

      const obsSub = formChanges$.subscribe(onFormChange)

      return () => {
        obsSub.unsubscribe()
        validationSub.unsubscribe()
      }
    }, [validationObservable, trigger, onFormChange, formChanges$])

    const openAddressesDialog = useCallback<VoidFunction>(() => {
      setAddressDialogOpen(true)
    }, [])

    const closeAddressesDialog = useCallback<VoidFunction>(() => {
      setAddressDialogOpen(false)
    }, [])

    const selectAddress = useCallback<(address: IContactAddress) => Promise<void>>(
      async (selectedAddress) => {
        setValue(BillToAndShipToFormField.Street1, selectedAddress?.address1 ?? '', { shouldValidate: true, shouldDirty: true })
        setValue(BillToAndShipToFormField.Street2, selectedAddress?.address2 ?? '', { shouldValidate: true, shouldDirty: true })
        setValue(BillToAndShipToFormField.Street3, selectedAddress?.address3 ?? '', { shouldValidate: true, shouldDirty: true })
        setValue(BillToAndShipToFormField.City, selectedAddress?.city ?? '', { shouldValidate: true, shouldDirty: true })
        setValue(BillToAndShipToFormField.Postal, selectedAddress?.postalcode ?? '', { shouldValidate: true, shouldDirty: true })
        try {
          const { initialCountry, initialState } = await getCountryStates(selectedAddress?.country, selectedAddress?.state)
          setValue(BillToAndShipToFormField.Country, initialCountry, { shouldValidate: true, shouldDirty: true })
          setValue(BillToAndShipToFormField.State, initialState, { shouldValidate: true, shouldDirty: true })
        } catch (e) {
          setValue(BillToAndShipToFormField.Country, null, { shouldValidate: true, shouldDirty: true })
          setValue(BillToAndShipToFormField.State, '', { shouldValidate: true, shouldDirty: true })
          ErrorHandler.handleError(e)
        }
      },
      [reset]
    )

    const loadStatesOptions = useCallback((page, limit) => loadStates(page, limit, country?.id), [country?.id])
    const disabledState = !country || disabled

    return (
      <div>
        <AddressDialog customerNumber={customerNumber} onClose={closeAddressesDialog} onSelect={selectAddress} open={addressDialogOpen} />
        <div className={styles.salesOrderBillToAndShipToForm}>
          <h3>{label}</h3>
          <FormField disabled={disabled} label="Street 1" control={control} name={BillToAndShipToFormField.Street1} />
          <FormField disabled={disabled} label="Street 2" control={control} name={BillToAndShipToFormField.Street2} />
          <FormField disabled={disabled} label="Street 3" control={control} name={BillToAndShipToFormField.Street3} />
          <FormField disabled={disabled} label="City" control={control} name={BillToAndShipToFormField.City} />
          {/* TODO: use getCountryStates */}
          <FormXtAutocomplete
            disabled={disabled}
            label="Country"
            onChange={handleCountryChange}
            control={control}
            name={BillToAndShipToFormField.Country}
            loadOptions={loadCountries}
          />

          <div className={styles.salesOrderBillToAndShipToFormHorizontal}>
            {!country?.hasStates ? (
              <FormField disabled={disabledState} control={control} name={BillToAndShipToFormField.State} label="State" />
            ) : (
              <FormXtAutocomplete
                disabled={disabledState}
                label="State"
                control={control}
                name={BillToAndShipToFormField.State}
                loadOptions={loadStatesOptions}
              />
            )}
            <FormField disabled={disabled} label="Postal" control={control} name={BillToAndShipToFormField.Postal} />
          </div>

          <div className={styles.salesOrderBillToAndShipToFormHorizontal}>
            <XtButton label="..." disabled={disabled} onClick={openAddressesDialog} />
          </div>
          <Controller
            name={BillToAndShipToFormField.Contact}
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <ContactSearch
                label="Name"
                disabled={disabled}
                contact={value}
                onChange={onChange}
                onBlur={onBlur}
                accountNumber={customerNumber}
              />
            )}
          />
          <span className={styles.salesOrderBillToAndShipToFormLabel} title={title}>{`Title: ${title}`}</span>
          <span className={styles.salesOrderBillToAndShipToFormLabel} title={phone}>{`Phone: ${phone}`}</span>
          <span className={styles.salesOrderBillToAndShipToFormLabel} title={email}>{`Email: ${email}`}</span>
          <span className={styles.salesOrderBillToAndShipToFormLabel} title={fax}>{`Fax: ${fax}`}</span>
        </div>
      </div>
    )
  }
)
