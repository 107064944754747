import { AxiosInstance } from 'axios'
import api from 'common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { DocumentCreationError, DocumentType, IAttachedDocumentWithFile, IDocumentsService } from 'documents/documents.types'
import { DocumentsService } from 'documents/documents.service'
import { ErrorHandler } from 'services/ErrorService'

import { ICRMProspect, ICRMProspectCreatePayload, ICRMProspectUpdatePayload } from './crm-prospects.types'

export interface ICRMProspectFilters {
  prospect_number?: string | null
  prospect_name?: string | null
  owner?: string | null
  assignedto?: string | null
  source?: string | null
  contact_name?: string | null
  city?: string | null
  state?: string | null
  lastupdated?: string | null
  showInactive?: boolean
  showDetail?: boolean
}

export interface ICRMProspectService {
  getAll: (pagination: IPaginationParams, filters?: ICRMProspectFilters) => Promise<IPaginationData<ICRMProspect>>
  get(crmProspect: string): Promise<ICRMProspect>
  delete(crmProspectId: string): Promise<void>
  create(data: ICRMProspectCreatePayload, documents: IAttachedDocumentWithFile[]): Promise<void>
  update(data: ICRMProspectUpdatePayload): Promise<void>
}

class Service implements ICRMProspectService {
  constructor(private readonly apiClient: AxiosInstance, private readonly documentService: IDocumentsService) {}

  public async getAll(paginationParams: IPaginationParams, filters?: ICRMProspectFilters): Promise<IPaginationData<ICRMProspect>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICRMProspect>>('/prospect', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(crmProspect: string): Promise<ICRMProspect> {
    const params = { showInactive: true } // TODO it's required in order to request inactive prospect. Remove it after API is fixed.
    const response = await this.apiClient.get<IResponse<ICRMProspect>>(`/prospect/${crmProspect}`, { params })
    if (!Object.keys(response.data.data).length) {
      ErrorHandler.handleError(new Error(`CRM Prospect: ${crmProspect} not found.`))
    }
    return response.data.data
  }

  public async delete(crmProspectId: string): Promise<void> {
    const body = { data: { number: crmProspectId } }
    await this.apiClient.post('/prospect/delete', body)
  }

  public async update(data: ICRMProspectUpdatePayload): Promise<void> {
    const body = { data }
    await this.apiClient.post('/prospect/update', body)
  }

  public async create(data: ICRMProspectCreatePayload, documents: IAttachedDocumentWithFile[]): Promise<void> {
    const body = { data }
    const {
      data: {
        data: { prospect },
      },
    } = await this.apiClient.post('/prospect/create', body)
    const prospectNumber = (prospect as string).toUpperCase()
    await this.createDocumentsForProspect(prospectNumber, documents)
  }

  private async createDocumentsForProspect(number: string, documents: IAttachedDocumentWithFile[]): Promise<void> {
    if (!documents.length) {
      return
    }
    try {
      await this.documentService.createFilesForSource(documents, DocumentType.Prospect, number)
    } catch (e) {
      throw new DocumentCreationError()
    }
  }
}

export const CRMProspectService = new Service(api, DocumentsService)
