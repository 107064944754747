import { Overwrite } from 'utility-types'
import { BomItemQuickAddInput, IBomItem } from '../../bom.types'
import { IItem } from '../../../items/Items.types'

export enum BomItemQuickAddFormField {
  ItemNumber = 'bom_item_number',
  Quantity = 'qty_per',
  IssueUom = 'issue_uom',
  Item = 'item',
}

export type BomItemQuickAddFormState = Overwrite<
  Pick<
    IBomItem,
    | BomItemQuickAddFormField.Quantity
    | BomItemQuickAddFormField.IssueUom
    | BomItemQuickAddFormField.ItemNumber
    | BomItemQuickAddFormField.Item
  >,
  {
    [BomItemQuickAddFormField.ItemNumber]: string | null
    [BomItemQuickAddFormField.Quantity]: number | null
    [BomItemQuickAddFormField.IssueUom]: string | null
    [BomItemQuickAddFormField.Item]: IItem | null
  }
>

export type BomItemFormAddItem = (item: BomItemQuickAddInput) => Promise<void>

export interface IBomItemForm {
  itemNumber: string
  onAdvancedSearch: () => void
  anchorEl: null | Element | ((element: Element) => Element)
  open: boolean
  onClose: () => void
  onAddItem: BomItemFormAddItem
}
