import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { AdditionalDataCreationError, IPaginationData, IPaginationParams } from 'common/common.types'

export interface IDocumentsService {
  getDocuments(docType: DocumentType, paginationParams?: IPaginationParams): Promise<IPaginationData<IDocument>>
  createFileForSource(document: IAttachedDocumentWithFile, sourceType: DocumentType, sourceNumber: string): Promise<void>
  createFilesForSource(documents: IAttachedDocumentWithFile[], sourceType: DocumentType, sourceNumber: string): Promise<void>
  loadFileData(file_link: string): Promise<Blob>
}

export interface IDocument {
  id: number
  number: string
  name: string
  description: string
  active: boolean
}

export enum AttachedDocumentTargetType {
  File = 'File',
  Image = 'Image',
  URL = 'URL',
  Contact = 'Contact',
  Customer = 'Customer',
  // TODO: add remaining files
}

// TODO: add all fields
export enum AttachedDocumentPurpose {
  Sibling = 'Sibling',
  Inventory = 'Inventory',
}

// TODO: unify this with another type
export interface IAttachedDocument {
  id: number
  target_type: AttachedDocumentTargetType
  target_number: string
  target_id: number
  name: string
  description: string
  notes: string
  canview: boolean
  canedit: boolean
  purpose: AttachedDocumentPurpose
  file_link: string
  document_characteristics: IAppliedCharacteristic[] | null
  // assignedRoles: RoleId[] // TODO: Role assignments is out of scope for the first phase.
}

export interface IAttachedDocumentWithFile extends IAttachedDocument {
  file: File
}

export interface IAttachedDocumentWithOptionalFile extends IAttachedDocument {
  file?: File
}

export interface ICreateAttachedFileDocumentRequest {
  file: File
  document_type: DocumentType
  document_number: string
  document_notes: string
  file_name?: string
  document_characteristics: IAppliedCharacteristicNew[]
}

export interface IDetachDocumentAssignment {
  target_type: AttachedDocumentTargetType
  id: number // this is the ID element of the document association that is supplied in the /documents/<doctype>/<docnumber> endpoint
}

export interface IDetachDocumentRequest {
  assignments: IDetachDocumentAssignment[]
}

export enum DocumentType {
  Account = 'CRMA',
  Address = 'ADDR',
  BomHead = 'BMH',
  BomItem = 'BMI',
  BreederBom = 'BBH',
  BreederBomItem = 'BBI',
  ClassCode = 'CLSCODE',
  Contact = 'T',
  Contract = 'CNTR',
  Customer = 'C',
  CustomerType = 'CUSTTYPE',
  // TODO: find out what is the difference between CUSTTYPE and CT
  CustomerTypeShort = 'CT',
  Document = 'FILE',
  Employee = 'EMP',
  ExchangeRates = 'FX',
  FixedAsset = 'FADOC',
  FreightClass = 'FRGHTCLASS',
  GLJournal = 'JE',
  Honorific = 'HONORIFIC',
  Incident = 'INCDT',
  Invoice = 'INV',
  InvoiceItem = 'INVI',
  Item = 'I',
  // ItemGroup = 'ITEMGRP', // same as ItemGroups
  ItemGroups = 'ITEMGRP', // same as ItemGroup; introduced in xTuple web app before importing the full list
  ItemSite = 'IS',
  ItemSource = 'IR',
  // ItemType = 'ITEMTYPE', // same as ItemTypes
  ItemTypes = 'ITEMTYPE', // same as ItemType; introduced in xTuple web app before importing the full list
  List = 'l', // same as Location; introduced in xTuple web app before importing the full list
  // TODO: find out why our name ('List') is so different from API's description ('Location') and maybe rename it
  // Location = 'L', // same as List
  LotSerial = 'LS',
  LotSerialRegistration = 'LSR',
  MaintenanceOrder = 'FAMAINT',
  Opportunity = 'OPP',
  OppSource = 'OPPSOURCE', // same as OpportunitySource; introduced in xTuple web app before importing the full list
  // OpportunitySource = 'OPPSOURCE', // same as OppSource
  OpportunityStage = 'OPPSTAGE',
  OpportunityType = 'OPPTYPE',
  Priority = 'PRIORITY',
  ProdCategory = 'prodcat', // same as ProductCategory; introduced in xTuple web app before importing the full list
  // ProductCategory = 'PRODCAT', // same as ProdCategory
  Project = 'J',
  Prospect = 'PSPCT',
  PurchaseOrder = 'P',
  PurchaseOrderItem = 'PI',
  PurchaseRequest = 'PR',
  Quote = 'Q',
  QuoteItem = 'QI',
  RentalItem = 'RNTI',
  Return = 'CM',
  ReturnAuthorization = 'RA',
  ReturnAuthorizationItem = 'RI',
  ReturnItem = 'CMI',
  RoutingHead = 'BOH',
  RoutingItem = 'BOI',
  SaleType = 'SALETYPE',
  SalesOrder = 'S',
  SalesOrderItem = 'SI',
  SalesRep = 'SR',
  ShipTo = 'SHP',
  ShipCharges = 'SHIPCHRG', // same as ShippingCharges; introduced in xTuple web app before importing the full list
  // ShippingCharges = 'SHIPCHRG', // same as ShipCharges
  ShipForm = 'SHIPFORM', // same as ShippingForm; introduced in xTuple web app before importing the full list
  // ShippingForm = 'SHIPFORM', // same as ShipForm
  ShipZone = 'SHIPZONE', // same as ShippingZone; introduced in xTuple web app before importing the full list
  // ShippingZone = 'SHIPZONE', // same as ShipZone
  // Site = 'WH', // same as Warehouse
  Task = 'TA',
  TaxAuthority = 'TAXAUTH',
  TaxType = 'TAXTYPE',
  TaxZone = 'TAXZONE',
  TimeExpense = 'TE',
  TransferOrder = 'TO',
  TransferOrderItem = 'TI',
  User = 'USR',
  UserRoles = 'USERROLE',
  Vendor = 'V',
  Voucher = 'VCH',
  Warehouse = 'WH', // same as Site; introduced in xTuple web app before importing the full list
  WorkOrder = 'W',
}

// TODO: not specified in API, make sure the values are correct
export enum UsedOnValue {
  Item = 'Item',
  InvoiceItem = 'Invoice Item',
  QuoteItem = 'Quote Item',
  SalesOrder = 'Sales Order',
  SalesOrderItem = 'Sales Order Item',
  PurchaseOrderItem = 'Purchase Order Item',
  TransferOrderItem = 'Transfer Order Item',
  WorkOrder = 'Work Order',
  Incident = 'Incident',
  Customer = 'Customer',
  Prospect = 'Prospect',
  Task = 'Task',
  Contact = 'Contact',
}

export class DocumentCreationError extends AdditionalDataCreationError {
  public message = 'Document saving failed. Please try to add documents later.'
}
