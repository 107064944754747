import { useCallback, useState } from 'react'
import { IAssignee } from '../tasks.types'
import { areEqual } from '../../common/utils/array.utils'

export interface IAssigneesHookState {
  isDirty: boolean
  assignees: IAssignee[]
  initialAssignees: IAssignee[]
}

export interface IAssigneesHook {
  assignees: IAssignee[]
  isDirty: boolean
  onChange(assignees: IAssignee[]): void
  reset(assignees: IAssignee[]): void
}

export function useAssignees(initialAssignees: IAssignee[]): IAssigneesHook {
  const [state, setState] = useState<IAssigneesHookState>({ assignees: initialAssignees, initialAssignees, isDirty: false })

  const handleAssigneesChange = useCallback<(assignees: IAssignee[]) => void>((assignees) => {
    setState((prevState) => {
      const isDirty = !areEqual(prevState.initialAssignees, assignees)
      return { ...prevState, assignees, isDirty }
    })
  }, [])

  const reset = useCallback((assignees: IAssignee[]) => setState({ assignees, initialAssignees: assignees, isDirty: false }), [])

  return {
    assignees: state.assignees,
    isDirty: state.isDirty,
    onChange: handleAssigneesChange,
    reset,
  }
}
