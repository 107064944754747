import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from '../../../../components/svgIcon/SvgIcon.types'
import { IAssigneeTableItem } from './assignee-tab.types'
import { dateConverter } from '../../../../common/utils/date.utils'

export enum AssigneeAction {
  Delete = 'Delete',
  Edit = 'Edit',
}
export const assigneeActionsEditMode: IXtTableCellAction[] = [
  {
    name: AssigneeAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: AssigneeAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]
export const assigneeColumns: IXtTableColumn<IAssigneeTableItem>[] = [
  {
    id: 'username',
    field: 'username',
    headerName: 'Assigned To',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'assigned_date',
    field: 'assigned_date',
    headerName: 'Assigned',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'role',
    field: 'role',
    headerName: 'Role',
    flex: '1 0 160px',
    width: 160,
  },
]
