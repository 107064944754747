import { ISalesOrderLineItemPriceListItem } from './sales-order-line-item-price-list.types'
import { IXtTableColumn } from '../../../../../components/table/table-head/table-head.types'

export const salesOrderLineItemPriceListColumns = (currency?: string): IXtTableColumn<ISalesOrderLineItemPriceListItem>[] => [
  {
    id: 'schedule_name',
    field: 'schedule_name',
    headerName: 'Schedule',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'source',
    field: 'source',
    headerName: 'Source',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'qty_break',
    field: 'qty_break',
    headerName: 'Qty Break',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'qty_uom',
    field: 'qty_uom',
    headerName: 'Qty UOM',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'price',
    field: 'price',
    headerName: 'Price',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'price_uom',
    field: 'price_uom',
    headerName: 'Price UOM',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'percent',
    field: 'percent',
    headerName: 'Percent',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'fixed_amount',
    field: 'fixed_amount',
    headerName: 'Fixed Amt',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'pricing_type',
    field: 'pricing_type',
    headerName: 'Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'currency',
    field: 'currency',
    headerName: 'Currency',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'price_in_base',
    field: 'price_in_base',
    headerName: `Price (in ${currency})`,
    flex: '1 0 260px',
    width: 260,
  },
]
