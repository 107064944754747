import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IRouting, IRoutingItem } from './Routing.types'
import { IPaginationData, IPaginationParams, IResponse, IPaginationResponse } from '../common/common.types'

export interface IRoutingListFilters {
  showActiveItemsOnly: boolean
  showActiveBOOsOnly: boolean
}
export interface IRoutingItemsFilters {
  showExpired: boolean
  showFuture: boolean
}
export interface IRoutingService {
  getAll(filters: IRoutingListFilters, params: IPaginationParams, showDetail: boolean): Promise<IPaginationData<IRouting>>
  get(itemNumber: string, showExpired: boolean, showFuture: boolean): Promise<IRouting>
  update(data: IRouting): Promise<void>
  create(data: IRouting): Promise<void>
  delete(itemNumber: string, sequenceNumber: number): Promise<void>
  getAllItems(itemNumber: string, filters: IRoutingItemsFilters, { limit, page }: IPaginationParams): Promise<IPaginationData<IRoutingItem>>
  getItem(itemNumber: string, itemId: number): Promise<IRoutingItem>
  updateItem(itemNumber: string, body: IRoutingItem): Promise<void>
  createItem(itemNumber: string, body: IRoutingItem): Promise<void>
  deleteItem(itemNumber: string, sequenceNumber: number): Promise<void>
  expireItem(itemNumber: string, sequenceNumber: number): Promise<void>
}
class Service implements IRoutingService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll(
    { showActiveItemsOnly = false, showActiveBOOsOnly = false }: IRoutingListFilters,
    { limit, page }: IPaginationParams,
    showDetail: boolean = true
  ): Promise<IPaginationData<IRouting>> {
    const params: Record<string, true | number> = { limit, page }
    // TODO get rid off if(s)
    if (showActiveItemsOnly) {
      params.showActiveItemsOnly = true
    }
    if (showActiveBOOsOnly) {
      params.showActiveBOOsOnly = true
    }
    if (showDetail) {
      params.showDetail = true
    }

    const response = await this.apiClient.get<IPaginationResponse<IRouting>>('/item/boo', {
      params,
    })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(itemNumber: string, showExpired: boolean = false, showFuture: boolean = false): Promise<IRouting> {
    const response = await this.apiClient.get<IResponse<IRouting>>(`/item/${itemNumber}/boo`, { params: { showExpired, showFuture } })
    if (!Object.keys(response.data.data).length) {
      throw new Error(`response: ${JSON.stringify(response)}`)
    }

    return response.data.data
  }

  public async update(data: IRouting): Promise<void> {
    const body = { data }
    await this.apiClient.post('/item/boo/update', body)
  }

  public async create(data: IRouting): Promise<void> {
    const body = { data }
    await this.apiClient.post('/item/boo/create', body)
  }

  public async delete(itemNumber: string): Promise<void> {
    const body = { data: { item_number: itemNumber } }
    await this.apiClient.post('/item/boo/delete', body)
  }

  public async getAllItems(
    itemNumber: string,
    { showExpired, showFuture }: IRoutingItemsFilters,
    { limit, page }: IPaginationParams
  ): Promise<IPaginationData<IRoutingItem>> {
    const params: Record<string, true | number> = { limit, page }

    if (showFuture) {
      params.showFuture = true
    }
    if (showExpired) {
      params.showExpired = true
    }
    // TODO get rid off if(s)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IRoutingItem>>(`/item/${itemNumber}/booitem`, { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getItem(itemNumber: string, itemId: number): Promise<IRoutingItem> {
    const response = await this.apiClient.get<IResponse<IRoutingItem>>(`/item/${itemNumber}/boo/${itemId}`)
    return response.data.data
  }

  public async updateItem(itemNumber: string, body: IRoutingItem): Promise<void> {
    await this.apiClient.post(`/item/${itemNumber}/booitem/update`, { data: [body] })
  }

  public async createItem(itemNumber: string, body: IRoutingItem): Promise<void> {
    await this.apiClient.post(`/item/${itemNumber}/booitem/create`, { data: [body] })
  }

  public async deleteItem(itemNumber: string, sequenceNumber: number): Promise<void> {
    const body = { sequence_number: sequenceNumber }
    await this.apiClient.post(`item/${itemNumber}/booitem/delete`, { data: [body] })
  }

  public async expireItem(item_number: string, sequence_number: number): Promise<void> {
    const body = { item_number, sequence_number }
    await this.apiClient.post(`/item/boo/expire`, { data: body })
  }
}

export const RoutingService = new Service(api)
