import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../common/common.types'

export interface IUser {
  username: string
  proper_name: string
  status: string
  initials: string
  is_employee: boolean
  email: string
  locale: string
  purchasing_agent: boolean
}

export interface IUserService {
  getAll: (params: IPaginationParams) => Promise<IPaginationData<IUser>>
}

class Service implements IUserService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll(paginationParams: IPaginationParams, propername_pattern?: string): Promise<IPaginationData<IUser>> {
    // TODO: need to add filter for users
    const params: Record<string, true | number | string | undefined> = {
      ...paginationParams,
      page: paginationParams.page === 0 ? 1 : paginationParams.page,
      propername_pattern,
    }

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IUser>>('/users', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}

export const UserService = new Service(api)
