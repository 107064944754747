import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Home } from "home/home";

import routes from "../routes.tsx";

const Main = (props) => {
  let location = useLocation();
  let history = useHistory();

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/" && prop.path !== "") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => (
              <prop.component {...props} />
            )}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname === routes[i].layout + routes[i].path) {
        return routes[i].name;
      }
    }
    return "xTuple";
  };

  const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

  useEffect(() => {
    if (getWidth() < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }

  }, [location, history.action]);

  return (
    <div className="wrapper">
      <Sidebar {...props} routes={routes} />
      <div id="main-panel" className="main-panel">
        <Navbar className="xtupleNavbar"
          {...props}
          routes={routes}
          brandText={getBrandText(window.location.pathname)}
        />
        <Switch>{getRoutes(routes)}
        <Route
          path={routes[0].path}
          render={props => (
            <Home
              {...props}
            />
          )}
        />
        </Switch>
        <Footer className="xtupleFooter" />
      </div>
    </div>
  );
}

export default Main;
