import { IPaginationData, IPaginationParams } from 'common/common.types'
import { IFilterFormState } from 'components/filter/filter.types'
import { SalesOrdersService } from 'sales-orders/sales-orders.service'
import { FilterFieldName, ISalesOrder, ISalesOrderFilters } from 'sales-orders/sales-orders.types'

import { SalesOrderRow } from './sales-orders-list.types'

export function transformOrder(order: ISalesOrder): SalesOrderRow {
  return {
    id: order.order_number,
    order_date: order.order_date,
    order_total: order.order_total,
    order_number: order.order_number,
    scheduled_date: order.scheduled_date,
    ship_to: order.ship_to?.shipto_name ?? '',
    customer_number: order.customer_number,
    customer_po_number: order.customer_po_number,
    customer_name: order.customer_name,
    ordermargin: order.ordermargin,
    ordermarginpercent: order.ordermarginpercent,
    sale_type: order.sale_type,
    hold_type: order.hold_type,
    status: order.order_status, // TODO use the correct field from ISalesOrder type
  }
}

export async function requestSalesOrders(
  filters: ISalesOrderFilters,
  pagination: IPaginationParams
): Promise<IPaginationData<SalesOrderRow>> {
  const { data, total } = await SalesOrdersService.getAll(pagination, true, filters)
  return {
    data: data.map(transformOrder),
    total,
  }
}
export function defineDefaultFilterValues(customerNumber?: string): IFilterFormState {
  return {
    [FilterFieldName.ShowClosed]: false,
    [FilterFieldName.StartDate]: null,
    [FilterFieldName.EndDate]: null,
    [FilterFieldName.CustomerNumber]: customerNumber ?? '',
  }
}
