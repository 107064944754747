import { Overwrite } from 'utility-types'
import { ISalesOrderItem } from '../../../sales-orders.types'
import { IItem } from '../../../../items/Items.types'

export interface ISalesOrderLineItemNewParams {
  customerNumber: string | null | undefined
  open: boolean
  onClose(): void
  onSubmit(item: SalesOrderLineItemNewForm, shouldReset: boolean): void
  onAdvancedEntryClick(): void
  currency?: string
}

export enum LineItemFormField {
  Item = 'item',
  QtyOrdered = 'qty_ordered',
  ItemDescription = 'description1',
  NetUnitPrice = 'net_unit_price',
  ScheduledDate = 'scheduled_date',
  SellingUom = 'price_uom',
  Site = 'sold_from_site',
}

export type SalesOrderLineItemNewForm = Overwrite<
  Pick<
    ISalesOrderItem,
    | LineItemFormField.QtyOrdered
    | LineItemFormField.NetUnitPrice
    | LineItemFormField.ScheduledDate
    | LineItemFormField.SellingUom
    | LineItemFormField.Site
  >,
  {
    [LineItemFormField.ScheduledDate]: Date
    [LineItemFormField.QtyOrdered]: string | number
    [LineItemFormField.NetUnitPrice]: string | number
    [LineItemFormField.SellingUom]: string
    [LineItemFormField.Site]: string
  }
> & { [LineItemFormField.Item]: IItem | null; [LineItemFormField.ItemDescription]: string }
