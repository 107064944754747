import { dateToServerFormat } from 'common/utils/date.utils'
import { NumberTypeUtils } from '../../../../common/typeUtils'
import { ISalesOrderLineItemDetailsForm, LineItemFormField } from './sales-order-line-item-details.types'
import { ISalesOrderLineItemCreatePayload, SalesOrderItem, SalesOrderLineItemStatus } from '../../../sales-orders.types'

export function convertToFormData(item: SalesOrderItem | null): ISalesOrderLineItemDetailsForm {
  return {
    [LineItemFormField.CustomerPN]: item?.customer_pn,
    [LineItemFormField.Item]: null,
    [LineItemFormField.ScheduledDate]: item?.scheduled_date ? new Date(item.scheduled_date) : new Date(),
    [LineItemFormField.PromiseDate]: item?.promise_date ? new Date(item.promise_date) : null,
    [LineItemFormField.SellingUom]: item?.price_uom ?? '',
    [LineItemFormField.Site]: item?.sold_from_site ?? '',
    [LineItemFormField.QtyOrdered]: item?.qty_ordered ?? '',
    [LineItemFormField.NetUnitPrice]: item?.net_unit_price ?? '',
    [LineItemFormField.QtyShipped]: item?.qty_shipped ?? '',
    [LineItemFormField.Warranty]: item?.warranty ?? false,
    [LineItemFormField.SubstituteFor]: !!item?.substitute_for, // TODO find the field in API
    [LineItemFormField.Substitute]: null, // TODO find the field in API
    [LineItemFormField.CustomerPrice]: item?.customer_price ?? 0,
    [LineItemFormField.CustomerPriceDiscount]: item?.discount_from_customer ?? 0,
    [LineItemFormField.ListPrice]: item?.list_price ?? 0,
    [LineItemFormField.ListPriceDiscount]: item?.discount_from_list ?? 0,
    [LineItemFormField.ExtendedPrice]: item?.extended_price ?? 0,
    [LineItemFormField.Status]: item?.status ?? SalesOrderLineItemStatus.Open,
  }
}

export function convertDetailsToSalesOrderItem(lineNumber: string, lineItem: ISalesOrderLineItemDetailsForm): SalesOrderItem {
  const { item, price_uom, sold_from_site, scheduled_date, qty_ordered, net_unit_price } = lineItem
  if (!item || !price_uom || !sold_from_site) {
    throw new Error('Quick Add Line Item: some of the required form values are missed.')
  }
  return {
    qty_ordered: NumberTypeUtils.parseString(qty_ordered),
    net_unit_price: NumberTypeUtils.parseFloatString(net_unit_price),
    item_number: item.item_number,
    line_number: lineNumber,
    price_uom,
    scheduled_date: dateToServerFormat(scheduled_date),
    item_description: item.description1,
    sold_from_site,
    sub_items: [],
    warranty: lineItem.warranty,
    promise_date: lineItem.promise_date ? dateToServerFormat(lineItem.promise_date) : null,
    customer_pn: lineItem.customer_pn,
    substitute_for: lineItem.substitute?.item_number ?? null,
    discount_from_list: NumberTypeUtils.parseFloatString(lineItem.discount_from_list || 0),
    discount_from_customer: NumberTypeUtils.parseFloatString(lineItem.discount_from_customer || 0),
    list_price: lineItem.list_price,
    customer_price: lineItem.customer_price,
    extended_price: NumberTypeUtils.parseFloatString(lineItem.extended_price || 0),
  }
}

export function convertDetailsDataToLineItemPayload(data: ISalesOrderLineItemDetailsForm): ISalesOrderLineItemCreatePayload {
  const { item, price_uom, sold_from_site, scheduled_date, qty_ordered, net_unit_price, substitute, status } = data
  if (!item || !price_uom || !sold_from_site) {
    throw new Error('Line Item Details: some of the required form values are missed.')
  }
  return {
    qty_ordered: NumberTypeUtils.parseString(qty_ordered),
    net_unit_price: NumberTypeUtils.parseFloatString(net_unit_price),
    item_number: item.item_number,
    price_uom,
    scheduled_date: dateToServerFormat(scheduled_date),
    sold_from_site,
    customer_pn: data.customer_pn ?? null,
    qty_uom: null, // TODO the field is missed in UI
    promise_date: data.promise_date ? dateToServerFormat(data.promise_date) : null,
    tax_type: null, // TODO the field is missed in UI
    est_unit_cost: 0, // TODO the field is missed in UI
    create_order: false, // TODO the field is missed in UI
    notes: '', // TODO the field is missed in UI
    substitute_for: substitute?.item_number ?? null,
    warranty: data.warranty,
    status: status ?? SalesOrderLineItemStatus.Open,
  }
}
export function convertLineItemStatus(status: SalesOrderLineItemStatus): SalesOrderLineItemStatus {
  return status === SalesOrderLineItemStatus.Close ? SalesOrderLineItemStatus.Open : SalesOrderLineItemStatus.Close
}
export function lineItemStatusButtonLabel(status: SalesOrderLineItemStatus): string {
  return status === SalesOrderLineItemStatus.Close ? 'Open' : 'Close'
}

export function calculateDiscount(netUnitPrice: number, listPrice: number): number {
  return (1 - netUnitPrice / listPrice) * 100
}

export function calculateCustomerDiscount(netUnitPrice: number, customerPrice: number): number {
  return (1 - netUnitPrice / customerPrice) * 100
}

export function calculateNetUnitPrice(customerPrice: number, customerDiscount: number): number {
  const netUnitPrice = customerPrice * (1 - customerDiscount / 100)
  return netUnitPrice >= 0 ? netUnitPrice : 0
}
