import { IObjectWithId } from 'common/common.types'
import { Overwrite } from 'utility-types'
import { IShipment } from '../shipments.types'
import { IDialogState } from '../shipment-dialog/shipment-dialog.types'

export enum CheckboxView {
  IsTrue = 'yes',
  isFalse = 'no',
}
export interface IShipmentTableItem
  extends IObjectWithId,
    Overwrite<IShipment, { city_state_zip: string; active: CheckboxView; default_flag: CheckboxView }> {}
export interface IShipToList {
  accountNumber: string
  isMobile: boolean
  disabled: boolean
}

export interface IShipmentState {
  deletionState: { id: string | null; open: boolean }
  shipmentDialogState: IDialogState
  tableState: { loading: boolean; items: IShipmentTableItem[] }
}
