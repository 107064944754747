import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../common/common.types'

export enum RoleAppliesto {
  Contact = 'Contact',
  Address = 'Address',
  Phone = 'Phone',
}
export interface IRole {
  name: string
  system_role: boolean
  order: string
  appliesto: RoleAppliesto
}

export interface ICRMRoleService {
  getAll: (params: IPaginationParams) => Promise<IPaginationData<IRole>>
}

class Service implements ICRMRoleService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<IRole>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IRole>>('/crmrole', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}

export const CRMRoleService = new Service(api)
