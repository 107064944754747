import { IAssigneeTableItem } from '../assignee-tab.types'
import { AssigneeFormField, IAssigneeDialogForm } from './assignee-form-dialog.types'

export function defineAssigneeDialogItem(data: IAssigneeTableItem | null): IAssigneeDialogForm {
  return {
    [AssigneeFormField.Username]: data?.username ? { id: data.username, label: data.username } : null,
    [AssigneeFormField.Role]: data?.role ? { id: data.role, label: data.role } : { id: 'Primary', label: 'Primary' },
    [AssigneeFormField.Assigned]: data?.assigned_date ? new Date(data.assigned_date) : new Date(),
  }
}
