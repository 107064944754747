import { AxiosInstance } from 'axios'
import api from '../../common/api'
import { BomCreateInput, BomUpdateInput, IBom } from '../bom.types'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../../common/common.types'

export interface IBomListFilters {
  showComponentItems: boolean
  showInactive: boolean
}

export interface IBomService {
  get: (itemNumber: string, showExpired: boolean, showFuture: boolean) => Promise<IBom>
  getAll: (filters: IBomListFilters, pagination: IPaginationParams, showDetail: boolean) => Promise<IPaginationData<IBom>>
  create(data: BomUpdateInput): Promise<void>
  update(item: IBom): Promise<void>
  delete(itemNumber: string): Promise<void>
}

class Service implements IBomService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll(
    { showComponentItems = false, showInactive = false }: IBomListFilters,
    { limit, page }: IPaginationParams,
    showDetail: boolean = true
  ): Promise<IPaginationData<IBom>> {
    const params: Record<string, true | number> = { limit, page }
    // TODO get rid off if(s)
    if (showComponentItems) {
      params.showComponentItems = true
    }
    if (showInactive) {
      params.showInactive = true
    }
    if (showDetail) {
      params.showDetail = true
    }

    const response = await this.apiClient.get<IPaginationResponse<IBom>>('/item/bom', { params })
    const {
      data: { data, status },
    } = response
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(itemNumber: string, showExpired: boolean, showFuture: boolean): Promise<IBom> {
    const params: Record<string, true | number> = {}
    if (showExpired) {
      params.showExpired = true
    }
    if (showFuture) {
      params.showFuture = true
    }
    const response = await this.apiClient.get<IResponse<IBom>>(`/item/${itemNumber}/bom`, { params })
    if (!response.data.data || !Object.keys(response.data.data).length) {
      throw new Error(`BOM not found. Item number: ${itemNumber}.`)
    }
    return response.data.data
  }

  public async create(data: BomCreateInput): Promise<void> {
    const body = { data }
    await this.apiClient.post('/item/bom/create', body)
  }

  public async update(data: BomUpdateInput): Promise<void> {
    const body = { data }
    await this.apiClient.post('/item/bom/update', body)
  }

  public async delete(itemNumber: string): Promise<void> {
    const body = { data: { item_number: itemNumber } }
    await this.apiClient.post('/item/bom/delete', body)
  }
}

export const BomService = new Service(api)
