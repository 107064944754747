import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'

export enum PhoneActions {
  Edit = 'Edit',
  Delete = 'Delete',
}
export const phoneColumns = [
  {
    id: 'role',
    field: 'role',
    headerName: 'Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'number',
    field: 'number',
    headerName: 'Value',
    flex: '1 0 160px',
    width: 160,
  },
]

export const PhoneActionsEditMode: IXtTableCellAction[] = [
  {
    name: PhoneActions.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: PhoneActions.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]
export const defaultDeletionState = { open: false, phone: null }
export const defaultPhoneDialogState = { open: false, phone: null, action: null }
