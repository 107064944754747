import { IFilterOption } from 'components/filter/filter.types'
import { ITask } from '../tasks.types'

export interface IDeletionState {
  task: { id: number | null; name: string }
  confirmationOpen: boolean
}

export interface IFilterState {
  open: boolean
  userOptions?: IFilterOption[]
  accountOptions?: IFilterOption[]
  // prospectOptions?: IFilterOption[] // TODO: uncomment this once the CRM Prospects are merged
}

export enum TaskListAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'view',
  MarkAsCompleted = 'markAsCompleted',
}

export interface ITaskTableItem
  extends Pick<ITask, 'id' | 'priority' | 'name' | 'description' | 'owner' | 'status' | 'due_date' | 'number' | 'actual_start_date'> {}
