import React from 'react'
import { Controller, PathValue } from 'react-hook-form'
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  TextareaAutosize,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ClearIcon from '@material-ui/icons/Clear'
import DateFnsUtils from '@date-io/date-fns'
import { XtAutocomplete } from 'components/xtAutocomplete/XtAutocomplete'
import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { cls } from 'common/utils'
import { FieldPath } from 'react-hook-form/dist/types'
import {
  IFormField,
  IFormDatePicker,
  IFormCheckboxLabel,
  IFormSelectField,
  IFormXtAutocomplete,
  ICheckboxLabel,
} from './form.components.types'
import { convertDatePickerValue, convertToAutocompleteValue, convertToError, convertToTextAreaValue } from './form.components.utils'
import './form.components.scss'
import { globalConstants } from '../../constants'

export const renderOptions: (option: string) => React.ReactElement = (option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
)

export function FormField<TFieldValues>({
  name,
  control,
  label,
  disabled,
  onChange,
  className,
  inputProps,
  error,
  hidden = false,
  type,
}: IFormField<TFieldValues>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: onControlChange, onBlur }, fieldState: { error: fieldError } }) => (
        <TextField
          inputProps={{ type, ...inputProps }} // inputProps.type priority higher than props type
          className={cls('MuiFormField', className)}
          variant="outlined"
          label={label}
          title={value ? `${value}` : ''}
          value={value ?? ''}
          onBlur={onBlur}
          onChange={(e) => (onChange ? onChange(e.target.value) : onControlChange(e.target.value))}
          disabled={disabled}
          hidden={hidden}
          error={!!convertToError(error, fieldError)}
          helperText={convertToError(error, fieldError)}
        />
      )}
    />
  )
}

export function FormTextAreaField<TFieldValues>({
  name,
  control,
  label,
  disabled,
  onChange,
  className,
  hidden = false,
}: IFormField<TFieldValues>): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onFieldChange, value } }) => (
        <TextareaAutosize
          hidden={hidden}
          className={className}
          placeholder={label}
          value={convertToTextAreaValue(value)}
          disabled={disabled}
          onChange={({ target }) => (onChange ? onChange(target.value) : onFieldChange(target.value))}
        />
      )}
    />
  )
}

export function FormSelectField<TFieldValue>({
  name,
  control,
  label,
  disabled,
  options,
  onChange,
  className,
  error,
  hidden,
  multiple,
  clearable,
}: IFormSelectField<TFieldValue>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: onControlChange, onBlur }, fieldState: { error: fieldError } }) => (
        <FormControl
          hidden={hidden}
          className={cls('MuiFormField', 'MuiFormFieldSelect', className, !value && 'MufFormSelectEmpty')}
          variant="outlined"
          error={Boolean(convertToError(error, fieldError))}
        >
          <div>
            <div>
              <InputLabel id={`labelId-${name}`}>{label}</InputLabel>
              <Select
                labelId={`labelId-${name}`}
                value={value}
                onBlur={onBlur}
                onChange={(e) =>
                  onChange ? onChange(e.target.value as PathValue<TFieldValue, FieldPath<TFieldValue>>) : onControlChange(e.target.value)
                }
                disabled={disabled}
                label={label}
                multiple={multiple}
                endAdornment={
                  clearable && value ? (
                    <InputAdornment position="end">
                      <IconButton
                        className="MuiClearableFilterClearButton"
                        onClick={() => (onChange ? onChange('' as PathValue<TFieldValue, FieldPath<TFieldValue>>) : onControlChange(''))}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }
              >
                {options}
              </Select>
            </div>
            <FormHelperText hidden={!convertToError(error, fieldError)}>{convertToError(error, fieldError)}</FormHelperText>
          </div>
        </FormControl>
      )}
    />
  )
}
export function FormDatePicker<TFieldValue>({
  name,
  control,
  label,
  disabled,
  format = globalConstants.dateFormat,
  className,
  error,
  hidden,
}: IFormDatePicker<TFieldValue>): React.ReactElement {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, onBlur }, fieldState: { error: fieldError } }) => (
          <KeyboardDatePicker
            hidden={hidden}
            className={cls('MuiFormField MuiFormFieldPicker', className)}
            inputVariant="outlined"
            format={format}
            variant="inline"
            autoOk
            onClose={onBlur}
            onBlur={onBlur}
            label={label}
            value={convertDatePickerValue(value)}
            onChange={(date) => onChange(date)}
            disabled={disabled}
            helperText={convertToError(error, fieldError)}
            error={!!convertToError(error, fieldError)}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

export function FormCheckboxLabel<TFieldValue>({
  label,
  disabled,
  name,
  control,
  onChange,
  className,
  hidden,
}: IFormCheckboxLabel<TFieldValue>): React.ReactElement {
  return (
    <FormControlLabel
      label={label}
      hidden={hidden}
      disabled={disabled}
      className={cls('MuiFormControlLabel', className)}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange: onControlChange, onBlur } }) => (
            <Checkbox
              color="primary"
              disabled={disabled}
              onBlur={onBlur}
              checked={Boolean(value)}
              onChange={({ target: { checked } }) => (onChange ? onChange(checked) : onControlChange(checked))}
            />
          )}
        />
      }
    />
  )
}

export function CheckboxLabel({ label, disabled, onChange, className, hidden, value }: ICheckboxLabel): React.ReactElement {
  return (
    <FormControlLabel
      label={label}
      title={label}
      hidden={hidden}
      disabled={disabled}
      className={cls('MuiFormControlLabel', className)}
      control={
        <Checkbox
          color="primary"
          disabled={disabled}
          checked={value}
          onChange={({ target: { checked } }) => onChange && onChange(checked)}
        />
      }
    />
  )
}

// TODO update TS definition
export function FormXtAutocomplete<TFieldValue, Option extends IXtAutocompleteOption>({
  name,
  control,
  label,
  disabled,
  onChange,
  className,
  loadOptions,
  error,
  hidden,
  renderOption,
  onFirstAvailableOption,
  getInputLabel,
}: IFormXtAutocomplete<TFieldValue, Option>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: onControlChange, onBlur }, fieldState: { error: fieldError } }) => (
        <XtAutocomplete
          hidden={hidden}
          error={convertToError(error, fieldError)}
          className={cls('MuiFormField', className)}
          value={convertToAutocompleteValue<Option>(value)}
          onBlur={onBlur}
          onChange={(option) => (onChange ? onChange(option) : onControlChange(option))}
          loadOptions={loadOptions}
          renderOption={renderOption}
          disabled={disabled}
          placeholder={label}
          getInputLabel={getInputLabel}
          onFirstAvailableOption={onFirstAvailableOption}
        />
      )}
    />
  )
}
