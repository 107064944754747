import React, { FC, memo, useCallback, useEffect } from 'react'
import { Dialog } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/xtButton/XtButton'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import styles from './characteristic-dialog.module.scss'
import { CharacteristicDialogFormSchema } from './characteristic-dialog.validation'
import {
  ICharacteristicDialog,
  CharacteristicDialogFormLabel,
  ICharacteristicOption,
  ICharacteristicFormValues,
} from './characteristic-dialog.types'
import { CharacteristicDialogValueInput } from './characteristic-dialog.value-input'
import { convertCharacteristicFormValues, characteristicFormValues, defaultCharacteristicFormValues } from './characteristic-dialog.utils'
import { loadCharacteristicOptions } from '../characteristic.utils'
import { CharacteristicDialogFormField, ICharacteristicFormState } from '../characteristics.types'

export const CharacteristicDialog: FC<ICharacteristicDialog> = memo(({ onClose, onConfirm, open, usedOnFilter, characteristicState }) => {
  const { handleSubmit, control, watch, reset } = useForm<ICharacteristicFormValues>({
    defaultValues: defaultCharacteristicFormValues,
    resolver: yupResolver(CharacteristicDialogFormSchema),
    mode: 'onBlur',
  })
  const characteristic = watch(CharacteristicDialogFormField.Characteristic)

  const onSaveForm = (formData: ICharacteristicFormState): void => {
    onConfirm(convertCharacteristicFormValues(formData))
    reset(defaultCharacteristicFormValues)
  }

  useEffect(() => void reset(characteristicFormValues(characteristicState)), [characteristicState])

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm, (error) => console.error(error))
    void eventHandler(e)
    reset(defaultCharacteristicFormValues)
  }

  const onCancel = (): void => {
    reset(defaultCharacteristicFormValues)
    onClose()
  }

  const loadCharacteristics = useCallback((page, limit) => loadCharacteristicOptions(page, limit, usedOnFilter), [usedOnFilter])

  const onCharacteristicChange = useCallback((characteristicOption: ICharacteristicOption) => {
    reset({ [CharacteristicDialogFormField.Characteristic]: characteristicOption, [CharacteristicDialogFormField.Value]: '' })
  }, [])

  return (
    <Dialog open={open} onClose={onCancel}>
      <form onSubmit={submitForm} className={styles.characteristicDialog}>
        <FormXtAutocomplete
          control={control}
          name={CharacteristicDialogFormField.Characteristic}
          label={CharacteristicDialogFormLabel.Characteristic}
          loadOptions={loadCharacteristics}
          onChange={onCharacteristicChange}
        />
        <CharacteristicDialogValueInput
          control={control}
          options={characteristic?.list_options ?? []}
          type={characteristic?.characteristic_type}
        />
        <div className={styles.characteristicDialogButtons}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton type="submit" label="Save" />
        </div>
      </form>
    </Dialog>
  )
})
