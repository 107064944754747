export const crmNavbarRoutes = {
  products: [
    {
      path: 'accounts',
      name: 'Accounts',
    },
    {
      path: 'customers',
      name: 'Customers',
    },
    {
      path: 'prospects',
      name: 'Prospects',
    },
  ],
}
