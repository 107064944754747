import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Form } from "react-bootstrap";

import * as yup from 'yup';
import { Formik } from 'formik';
import http from "../common/api";

const schema = yup.object({
  customer_name: yup.string().required("Customer Name is required"),
  billing_contact_first: yup.string().required("First Name is required"),
  billing_contact_last: yup.string().required("Last Name is required"),
  billing_contact_address1: yup.string().required("Address 1 is required"),
  billing_contact_country: yup.string().required("Country is required"),
  billing_contact_city: yup.string().required("City is required"),
  billing_contact_state: yup.string().required("State is required"),
  billing_contact_postalcode: yup.string().required("Postal Code is required")
});

const CRMAccountForm = (props) => {
  const [message, setMessage] = useState("");

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setMessage("");
        let packet = {
          data: values
        };
        http.post("/customer/create", packet).then(
          (response) => {
            if (response.status === 201) {
              props.history.push("/crmaccount");
            }
            else {
              setMessage(response.message);
            }
          },
          (error) => {
            const _msg =
              (error.response &&
               error.response.data &&
               error.response.data.status.message) ||
              error.message ||
              error.toString();
            setMessage(_msg);
          }
        );
        setSubmitting(false);
      }}
      initialValues={{
        billing_contact_country: 'United States'
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <Container fluid className="content">
        <Form noValidate accept-charset="UTF-8" onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={4} className="mb-1">
                  <Form.Group controlId="customer_number">
                    <Form.Label>Customer Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="customer_number"
                      placeholder="Leave blank to generate"
                      value={values.customer_number}
                      onChange={handleChange}
                      isValid={touched.customer_number && !errors.customer_number}
                      isInvalid={!!errors.customer_number}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.customer_number} 
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-1">
                  <Form.Group controlId="customer_name">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="customer_name"
                      value={values.customer_name}
                      onChange={handleChange}
                      isValid={touched.customer_name && !errors.customer_name}
                      isInvalid={!!errors.customer_name}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.customer_name} 
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-1">
                  <Form.Group controlId="customer_type">
                    <Form.Label>Customer Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="customer_type"
                      value={values.customer_type}
                      onChange={handleChange}
                      isInvalid={!!errors.customer_type}
                    >
                      <option>NORMAL</option>
                      <option>NORMAL-INT</option>
                      <option>NORMAL-RETAIL</option>
                      <option>USA</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-1">
                  <Form.Group controlId="billing_contact_first">
                    <Form.Label>Billing First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_first"
                      value={values.billing_contact_first}
                      onChange={handleChange}
                      isValid={touched.billing_contact_first && !errors.billing_contact_first}
                      isInvalid={!!errors.billing_contact_first}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_first}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-1">
                  <Form.Group controlId="billing_contact_last">
                    <Form.Label>Billing Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_last"
                      value={values.billing_contact_last}
                      onChange={handleChange}
                      isValid={touched.billing_contact_last && !errors.billing_contact_last}
                      isInvalid={!!errors.billing_contact_last}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_last}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="mb-1">
                <Form.Group controlId="billing_contact_email">
                  <Form.Label>Billing Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_email"
                      value={values.billing_contact_email}
                      onChange={handleChange}
                      isValid={touched.billing_contact_email && !errors.billing_contact_email}
                      isInvalid={!!errors.billing_contact_email}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-1">
                  <Form.Group controlId="billing_contact_voice">
                    <Form.Label>Billing Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_voice"
                      value={values.billing_contact_voice}
                      onChange={handleChange}
                      isValid={touched.billing_contact_voice && !errors.billing_contact_voice}
                      isInvalid={!!errors.billing_contact_voice}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_voice}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-1">
                  <Form.Group controlId="billing_contact_job_title">
                    <Form.Label>Billing Contact Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_job_title"
                      value={values.billing_contact_job_title}
                      onChange={handleChange}
                      isValid={touched.billing_contact_job_title && !errors.billing_contact_job_title}
                      isInvalid={!!errors.billing_contact_job_title}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_job_title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-1">
                  <Form.Group controlId="billing_contact_address1">
                    <Form.Label>Billing Address 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_address1"
                      value={values.billing_contact_address1}
                      onChange={handleChange}
                      isValid={touched.billing_contact_address1 && !errors.billing_contact_address1}
                      isInvalid={!!errors.billing_contact_address1}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_address1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-1">
                  <Form.Group controlId="billing_contact_address2">
                    <Form.Label>Billing Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_address2"
                      value={values.billing_contact_address2}
                      onChange={handleChange}
                      isValid={touched.billing_contact_address2 && !errors.billing_contact_address2}
                      isInvalid={!!errors.billing_contact_address2}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_address2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="mb-1">
                  <Form.Group controlId="billing_contact_country">
                    <Form.Label>Billing Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_country"
                      value={values.billing_contact_country}
                      onChange={handleChange}
                      isValid={touched.billing_contact_country && !errors.billing_contact_country}
                      isInvalid={!!errors.billing_contact_country}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                     {errors.billing_contact_country}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-1">
                  <Form.Group controlId="billing_contact_city">
                    <Form.Label>Billing City</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_city"
                      value={values.billing_contact_city}
                      onChange={handleChange}
                      isValid={touched.billing_contact_city && !errors.billing_contact_city}
                      isInvalid={!!errors.billing_contact_city}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-1">
                  <Form.Group controlId="billing_contact_state">
                    <Form.Label>Billing State</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_state"
                      value={values.billing_contact_state}
                      onChange={handleChange}
                      isValid={touched.billing_contact_state && !errors.billing_contact_state}
                      isInvalid={!!errors.billing_contact_state}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-1">
                <Form.Group controlId="billing_contact_postalcode">
                    <Form.Label>Billing Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="billing_contact_postalcode"
                      value={values.billing_contact_postalcode}
                      onChange={handleChange}
                      isValid={touched.billing_contact_postalcode && !errors.billing_contact_postalcode}
                      isInvalid={!!errors.billing_contact_postalcode}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.billing_contact_postalcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-1">
                  <Form.Group controlId="notes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          {message && (
            <Form.Group>
              <div className="alert alert-danger pt-2 pb-2" role="alert">
                {message}
              </div>
            </Form.Group>
          )}
          <div className="pt-3">
          <Button type="submit" disabled={isSubmitting} className="mr-2">Save</Button>
          <Link to="/crmaccount"><Button variant="secondary" disabled={isSubmitting}>Cancel</Button></Link>
          </div>
        </Form>
        </Container>
      )}
    </Formik>
  );
}

export default CRMAccountForm;
