import { FieldError } from 'react-hook-form'
import { IXtAutocompleteOption } from '../../../components/xtAutocomplete/XtAutocomplete.types'

type DatePickerValue = string | Date | null
export const convertDatePickerValue = (date: unknown): DatePickerValue => {
  if (typeof date === 'string') {
    // not initial validation
    return date || null
  }
  return date instanceof Date ? date : null
}

export function convertToError(error?: string, fieldError?: FieldError): string | undefined {
  return error !== undefined ? error : fieldError?.message
}

export function convertToAutocompleteValue<Option extends IXtAutocompleteOption>(value: unknown): Option | null {
  if (!value || typeof value !== 'object') {
    return null
  }
  return 'id' in value! && 'label' in value ? (value as Option) : null
}

export function convertToTextAreaValue(value: unknown): string | number | ReadonlyArray<string> {
  if (!value) {
    return ''
  }
  if (Array.isArray(value)) {
    return value as ReadonlyArray<string>
  }
  if (typeof value === 'number') {
    return value
  }
  return String(value)
}
