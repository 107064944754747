import React, { FC, memo, useCallback, useState, useRef, useEffect } from 'react'
import { Modal, Slide } from '@material-ui/core'
import { XtButton } from 'components/xtButton/XtButton'
import { cls } from 'common/utils'
import { globalConstants } from 'common/constants'
import { useCharacteristics } from 'common/hooks/characteristics'
import { characteristicsToPayload } from 'characteristics/characteristic.utils'
import { ContactService } from 'contacts/contacts.service'
import { DocumentType } from 'documents/documents.types'
import { useDocuments } from 'common/hooks/documents'
import { ErrorHandler } from 'services/ErrorService'
import { useRemarks } from 'common/hooks/remarks'
import { Observable, Subject } from 'rxjs'
import { convertToNewComments } from 'comments/comments.utils'
import styles from './contact-dialog-form.module.scss'
import { IContactDialogForm, IContactDialogFormValues } from './contact-dialog-form.types'
import { ContactAction } from '../contact-list/contact-list.types'
import { ContactTabs } from './contact-tabs/contact-tabs'
import { ContactForm } from './contact-main-form/contact-form'
import { convertFromValues } from './contact-dialog-form.utils'

export const defaultContactDialogFormState = { state: null, isDirty: false, isSubmitting: false }

export const ContactDialogForm: FC<IContactDialogForm> = memo(
  ({ onCancel, open, mode = null, selectData = null, isMobile = false, onSaveForm }) => {
    const [formState, setState] = useState<IContactDialogFormValues>(defaultContactDialogFormState)
    const [detailsLoading, setDetailsLoading] = useState<boolean>(false)
    const characteristicsState = useCharacteristics()
    const remarksState = useRemarks(DocumentType.Contact, selectData?.contact_number, selectData?.notes)
    const documentsState = useDocuments(DocumentType.Contact, selectData?.contact_number)

    const validationSubject = useRef<Subject<void>>(new Subject())
    const validate$ = useRef<Observable<void>>(validationSubject.current.asObservable())
    const isEditMode = mode === ContactAction.Edit
    const disabled = formState?.isSubmitting || mode === ContactAction.View
    const title = `Number: ${selectData?.contact_number}`

    const init = async (): Promise<void> => {
      setDetailsLoading(true)
      try {
        if (selectData?.contact_number) {
          const fullContactData = await ContactService.get(selectData.contact_number)
          characteristicsState.reset(fullContactData?.contact_characteristics || [])
        }
      } catch (e) {
        ErrorHandler.handleError(e)
      }
      setDetailsLoading(false)
    }

    useEffect(() => void init(), [selectData])

    const onSubmit: VoidFunction = () => {
      validationSubject.current.next()
      if (!formState.isValid) {
        return
      }
      setState((prev) => ({ ...prev, isSubmitting: true }))
      const commentsPayload = isEditMode ? [] : convertToNewComments(remarksState.comments)
      onSaveForm(
        {
          ...convertFromValues({ ...selectData, ...formState?.state, notes: remarksState.notes, comments: commentsPayload }),
          contact_characteristics: characteristicsToPayload(characteristicsState.characteristics),
        },
        documentsState.getUnsavedDocuments(),
        isEditMode
      )
      setState((prev) => ({ ...prev, isSubmitting: false }))
      documentsState.setDocuments([])
    }

    const handleContactChange = useCallback<(formValues: IContactDialogFormValues) => void>(({ state, isDirty, isValid }) => {
      setState((prev) => ({ ...prev, state: { ...prev?.state, ...state }, isDirty, isValid }))
    }, [])

    const isDirty = formState?.isDirty || characteristicsState.isDirty || remarksState.isDirty

    const onClose = () => {
      // eslint-disable-next-line no-restricted-globals
      if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
        return
      }
      onCancel()
      setState(defaultContactDialogFormState)
      documentsState.setDocuments([])
    }
    return (
      <Modal open={open}>
        <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
          <div className={styles.contactDialogContent}>
            <div className={cls(styles.contactDialogFormHeader, 'xt-section-border')}>
              <h3 className="xt-page-title" hidden={!mode} title={title}>
                {title}
              </h3>
              <div className={styles.containerHeaderButtons}>
                <XtButton label="Cancel" onClick={onClose} />
                <XtButton
                  label="Save"
                  onClick={onSubmit}
                  loading={formState?.isSubmitting || detailsLoading}
                  disabled={!isDirty || disabled}
                />
              </div>
            </div>

            <div className={styles.contactFormContent}>
              <ContactForm validationObservable={validate$.current} data={selectData} onChange={handleContactChange} disabled={disabled} />
              <ContactTabs
                remarks={remarksState}
                onChange={handleContactChange}
                isMobile={isMobile}
                disabled={disabled}
                data={selectData}
                characteristicsState={characteristicsState}
                documentsState={documentsState}
                detailsLoading={detailsLoading}
              />
            </div>
          </div>
        </Slide>
      </Modal>
    )
  }
)
