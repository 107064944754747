import { FilterType, IFilter } from 'components/filter/filter.types'
import { loadCustomerTypeOptions } from 'common/utils/document.utils'
import { loadSalesReps } from 'common/utils'
import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'
import { CustomersAction, ICustomersTableItem } from './customers-list.types'
import { FilterFieldName } from '../customers.types'

export const defaultDeletionState = {
  customersId: '',
  confirmationOpen: false,
}

export const CustomersListActionsEditMode: IXtTableCellAction[] = [
  {
    name: CustomersAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: CustomersAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: CustomersAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const customerColumns: IXtTableColumn<ICustomersTableItem>[] = [
  {
    id: 'customer_number',
    field: 'customer_number',
    headerName: 'Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'customer_name',
    field: 'customer_name',
    headerName: 'Name',
    flex: '1 0 200px',
    width: 200,
  },

  {
    id: 'customer_type',
    field: 'customer_type',
    headerName: 'Type',
    flex: '1 0 200px',
    width: 200,
  },

  {
    id: 'credit_status',
    field: 'credit_status',
    headerName: 'Hold Type',
    flex: '1 0 200px',
    width: 200,
  },

  {
    id: 'correspond_contact_first',
    field: 'correspond_contact_first',
    headerName: 'Corr. First Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'correspond_contact_last',
    field: 'correspond_contact_last',
    headerName: 'Corr. Last Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'correspond_contact_job_title',
    field: 'correspond_contact_job_title',
    headerName: 'Corr. Title',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'correspond_contact_voice',
    field: 'correspond_contact_voice',
    headerName: 'Corr. Phone',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'correspond_contact_email',
    field: 'correspond_contact_email',
    headerName: 'Corr. Email',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'correspond_contact_address1',
    field: 'correspond_contact_address1',
    headerName: 'Corr. Address 1',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'correspond_contact_city',
    field: 'correspond_contact_city',
    headerName: 'Corr. City',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'correspond_contact_state',
    field: 'correspond_contact_state',
    headerName: 'Corr. State',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'correspond_contact_country',
    field: 'correspond_contact_country',
    headerName: 'Corr. Country',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'correspond_contact_postalcode',
    field: 'correspond_contact_postalcode',
    headerName: 'Corr. Postal Code',
    flex: '1 0 200px',
    width: 200,
  },
]

export const defaultFilterValues = {
  [FilterFieldName.ShowInactive]: '',
  [FilterFieldName.CustomerNumberPattern]: '',
  [FilterFieldName.CustomerNamePattern]: '',
  [FilterFieldName.CustomerType]: '',
  [FilterFieldName.ContactNamePattern]: '',
  [FilterFieldName.CityPattern]: '',
  [FilterFieldName.StatePattern]: '',
  [FilterFieldName.SalesRep]: '',
  [FilterFieldName.ShowDetail]: '',
}

export const filterFields: IFilter[] = [
  {
    type: FilterType.Checkbox,
    label: 'Show Inactive',
    fieldName: FilterFieldName.ShowInactive,
  },

  {
    type: FilterType.Text,
    label: 'Customer Number Pattern',
    fieldName: FilterFieldName.CustomerNumberPattern,
  },
  {
    type: FilterType.Text,
    label: 'Customer Name Pattern',
    fieldName: FilterFieldName.CustomerNamePattern,
  },
  {
    type: FilterType.Autocomplete,
    label: 'Customer Type',
    fieldName: FilterFieldName.CustomerType,
    autocompleteProps: {
      loadOptions: loadCustomerTypeOptions,
    },
  },
  {
    type: FilterType.Text,
    label: 'Contact Name Pattern',
    fieldName: FilterFieldName.ContactNamePattern,
  },
  {
    type: FilterType.Text,
    label: 'Contact Name Pattern',
    fieldName: FilterFieldName.CityPattern,
  },
  {
    type: FilterType.Text,
    label: 'Contact Name Pattern',
    fieldName: FilterFieldName.StatePattern,
  },
  {
    type: FilterType.Autocomplete,
    label: 'Sales Rep.',
    fieldName: FilterFieldName.SalesRep,
    autocompleteProps: {
      loadOptions: loadSalesReps,
    },
  },
]
