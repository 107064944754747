import { BomDetailsAction, IBomItemDialogState, IBomState } from './BomDetails.types'
import { BomItemMode } from '../bomItem/BomItem.types'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'
import { IBomItem } from '../bom.types'

export const defaultBomState: IBomState = {
  bom: null,
  initializing: true,
}

export const defaultBomItemDialogState: IBomItemDialogState = {
  open: false,
  bomItem: null,
  mode: BomItemMode.View,
}

export const defaulDeletionState = { itemId: null, confirmationOpen: false }

export const bomDetailsActionsViewMode: IXtTableCellAction[] = [
  {
    name: BomDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const bomDetailsActionsEditMode: IXtTableCellAction[] = [
  {
    name: BomDetailsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: BomDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: BomDetailsAction.Replace,
    label: 'Replace',
    icon: SvgIconIds.COMPARE_ARROW,
  },
  {
    name: BomDetailsAction.Expire,
    label: 'Expire',
    icon: SvgIconIds.EXPIRE,
  },
  {
    name: BomDetailsAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const bomNewActionsNewBom: IXtTableCellAction[] = [
  {
    name: BomDetailsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: BomDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: BomDetailsAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const bomDetailsTableColumns: IXtTableColumn<IBomItem>[] = [
  { id: 'sequence_number', field: 'sequence_number', headerName: '#', flex: '1 0 80px', width: 80 },
  {
    id: 'bom_item_number',
    field: 'bom_item_number',
    headerName: 'Item Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'bom_item_description',
    field: 'bom_item_description',
    headerName: 'Description',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'item_type',
    field: 'item.item_type',
    headerName: 'Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'issue_uom',
    field: 'issue_uom',
    headerName: 'Issue UOM',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'qty_fxd',
    field: 'qty_fxd',
    headerName: 'Fixed Qty.',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'qty_per',
    field: 'qty_per',
    headerName: 'Qty. Per',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'effective',
    field: 'effective',
    headerName: 'Effective',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'expires',
    field: 'expires',
    headerName: 'Expires',
    flex: '1 0 160px',
    width: 160,
  },
]
