import React, { FC, memo } from 'react'
import { Dialog } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/xtButton/XtButton'
import { FormDatePicker, FormXtAutocomplete } from 'common/utils/form/form.components'
import { loadCRMContactRoleOptions, loadUserOptions } from 'common/utils'
import styles from './assignee-form-dialog.module.scss'
import { AssigneeFormField, AssigneeFormLabel, IAssigneeDialog, IAssigneeDialogForm } from './assignee-form-dialog.types'
import { AssigneeDialogFormSchema } from './assignee-form-dialog.validation'
import { defineAssigneeDialogItem } from './assignee-form-dialog.utils'

export const AssigneeDialog: FC<IAssigneeDialog> = memo(({ onClose, open, onConfirm, assigneeData }) => {
  const { handleSubmit, control } = useForm<IAssigneeDialogForm>({
    defaultValues: defineAssigneeDialogItem(assigneeData),
    resolver: yupResolver(AssigneeDialogFormSchema),
    mode: 'onBlur',
  })

  const onSaveForm: (formData: IAssigneeDialogForm) => void = (formData) => {
    const { username, assigned_date, role } = formData
    if (!role || !username) {
      return
    }
    onConfirm({ username: username.id, role: role.id, assigned_date })
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm, (error) => console.error(error))
    void eventHandler(e)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={submitForm} className={styles.assigneeDialog}>
        <FormXtAutocomplete
          control={control}
          name={AssigneeFormField.Username}
          label={AssigneeFormLabel.Username}
          loadOptions={loadUserOptions}
        />
        <FormXtAutocomplete
          control={control}
          name={AssigneeFormField.Role}
          label={AssigneeFormLabel.Role}
          loadOptions={loadCRMContactRoleOptions}
        />
        <FormDatePicker name={AssigneeFormField.Assigned} label={AssigneeFormLabel.Assigned} control={control} />
        <div className={styles.assigneeDialogButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton type="submit" label="Save" />
        </div>
      </form>
    </Dialog>
  )
})
