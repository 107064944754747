import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { Modal, Slide } from '@material-ui/core'
import { XtButton } from '../../../components/xtButton/XtButton'
import { IRoutingItemsDialog, IRoutingTableItem, IRoutingItemsState } from './RoutingItemsDialog.types'
import styles from './RoutingItemsDialog.module.scss'
import { XtTable } from '../../../components/table/table'
import { routingDialogColumns } from './routing-dialog.constants'
import { convertBooItems, convertToOperation } from './routing-dialog.utils'

const slideAnimationTime = 430

export const RoutingItemsDialog: FC<IRoutingItemsDialog> = ({ open, onClose, items, bomItemNumber, bomUom }) => {
  const [state, setState] = useState<IRoutingItemsState>({ selected: null, items: convertBooItems(items) })

  const onCancel: (value?: string) => void = (value) => {
    setState((prevState) => ({ ...prevState, selected: null }))
    onClose(value)
  }

  const onSelect: VoidFunction = () => {
    onCancel(convertToOperation(state.selected))
  }

  const onRowClick = useCallback<(item: IRoutingTableItem) => void>(
    (selectedItem) => {
      const updatedItems = state.items.map((item) => ({ ...item, selected: item.id === selectedItem.id }))
      setState({ items: updatedItems, selected: selectedItem })
    },
    [state]
  )

  return (
    <Modal open={open}>
      <Slide timeout={slideAnimationTime} in={open}>
        <div className={styles.dialog}>
          <div className={styles.topBar}>
            <h1>{bomItemNumber}</h1>
            <p>{bomUom}</p>
            <div className={styles.topBarButton}>
              <XtButton onClick={onCancel} label="Cancel" />
              <XtButton disabled={!state?.selected} onClick={onSelect} label="Select" />
            </div>
          </div>
          <XtTable onRowClick={onRowClick} columns={routingDialogColumns} rows={state.items} />
        </div>
      </Slide>
    </Modal>
  )
}
