import { AxiosInstance } from 'axios'
import { IAttachedDocumentWithFile, DocumentType, IDocumentsService, DocumentCreationError } from 'documents/documents.types'
import { DocumentsService } from 'documents/documents.service'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from '../common/common.types'
import { CustomerPayload, ICustomer, ICustomerFilters, ISalesRep } from './customers.types'
import api from '../common/api'

export interface ICustomersService {
  getAll(paginationParams: IPaginationParams, filters?: ICustomerFilters): Promise<IPaginationData<ICustomer>>
  get(customer: string): Promise<ICustomer>
  getSalesReps(paginationParams: IPaginationParams): Promise<IPaginationData<ISalesRep>>
  delete(customer_number: string): Promise<void>
  update(data: CustomerPayload): Promise<void>
  create(data: CustomerPayload, documents: IAttachedDocumentWithFile[]): Promise<void>
}

class Service implements ICustomersService {
  constructor(private readonly httpClient: AxiosInstance, private readonly documentsService: IDocumentsService) {}

  public async getAll(paginationParams: IPaginationParams, filters?: ICustomerFilters): Promise<IPaginationData<ICustomer>> {
    const params: Record<string, number | boolean | string> = prepareRequestParams(paginationParams, filters)

    const response = await this.httpClient.get<IPaginationResponse<ICustomer>>('/customer', { params })
    const {
      data: { data, status },
    } = response
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(customer: string): Promise<ICustomer> {
    const response = await this.httpClient.get<IResponse<ICustomer>>(`/customer/${customer}`)
    return response.data.data
  }

  public async delete(customerId: string): Promise<void> {
    const body = { data: { customer_number: customerId } }
    await this.httpClient.post('/customer/delete', body)
  }

  public async getSalesReps({ limit, page }: IPaginationParams): Promise<IPaginationData<ISalesRep>> {
    const params = { limit, page: page === 0 ? 1 : page }
    const response = await this.httpClient.get<IPaginationResponse<ISalesRep>>('/salesrep', { params })
    const {
      data: { data, status },
    } = response
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async update(data: CustomerPayload): Promise<void> {
    const body = { data }
    await this.httpClient.post('/customer/update', body)
  }

  public async create(data: CustomerPayload, documents: IAttachedDocumentWithFile[]): Promise<void> {
    const body = { data }
    await this.httpClient.post('/customer/create', body)
    await this.createDocumentsForCustomer(data.customer_number, documents)
  }

  private async createDocumentsForCustomer(number: string, documents: IAttachedDocumentWithFile[]): Promise<void> {
    if (!documents.length) {
      return
    }
    try {
      await this.documentsService.createFilesForSource(documents, DocumentType.Customer, number)
    } catch (e) {
      throw new DocumentCreationError()
    }
  }
}

export const CustomersService = new Service(api, DocumentsService)
