import React, { FC, useEffect, useState, memo } from 'react'
import { format } from 'date-fns'
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Checkbox, IconButton, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router'
import { ErrorHandler } from 'services/ErrorService'
import { SvgIcon } from 'components/svgIcon/SvgIcon'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { cls } from 'common/utils'
import { XtButton } from 'components/xtButton/XtButton'
import LoadingSpinner from 'components/LoadingSpinner'
import { DateTypeUtils } from 'common/typeUtils'
import * as styles from './tasks-card.module.scss'
import { ITasksState, ITasksCard } from './tasks-card.types'
import { TaskService } from '../../tasks.service'
import { ITask } from '../../tasks.types'
import { characteristicsToPayload } from '../../../characteristics/characteristic.utils'

const defaultState = { tasks: { data: [], total: 0 }, completed: new Map(), loading: false, updatingTaskId: null }
const defaultTasksLimit = 10
export const XtTasksCard: FC<ITasksCard> = memo(({ date, overdue }) => {
  const [{ tasks, completed, loading, updatingTaskId }, setState] = useState<ITasksState>(defaultState)

  const history = useHistory()

  async function init(): Promise<void> {
    try {
      const variantDateParams = overdue ? 'duebefore' : 'duedate'
      setState((prevState) => ({ ...prevState, loading: true }))
      const tasksData = await TaskService.getAll({ limit: defaultTasksLimit, page: 1 }, { [variantDateParams]: date })
      setState((prevState) => ({ ...prevState, tasks: tasksData, loading: false }))
    } catch (error) {
      ErrorHandler.handleError(error)
      setState((prevState) => ({ ...prevState, loading: false }))
    }
  }

  useEffect(() => void init(), [])

  const handleCheckboxChange = async ({ target }: React.ChangeEvent<HTMLInputElement>, task: ITask) => {
    try {
      const { checked } = target
      setState((prevState) => ({ ...prevState, updatingTaskId: +target.id }))
      if (target.checked) {
        await TaskService.complete({ id: +target.id, completed_date: new Date() })
      } else {
        await TaskService.update({
          ...task,
          id: +target.id,
          completed_date: null,
          task_characteristics: characteristicsToPayload(task.task_characteristics ?? []),
        })
      }
      setState((prev) => ({
        ...prev,
        completed: new Map([...prev.completed.entries(), [Number(target.id), checked]]),
        updatingTaskId: null,
      }))
    } catch (e) {
      ErrorHandler.handleError(e)
      setState((prev) => ({
        ...prev,
        updatingTaskId: null,
      }))
    }
  }

  const [day, year, month, dayOfWeek] = format(date, 'dd yyyy MMMM EEEE').split(' ')
  const currentDateTasks = `${dayOfWeek} ${day} ${month} ${year}`
  return (
    <Accordion
      defaultExpanded={!overdue && DateTypeUtils.compareDatesOnly(date, new Date()) === 0}
      className={styles.taskCards}
      classes={{ root: styles.muiAccordion, expanded: styles.muiAccordion }}
    >
      <AccordionSummary
        classes={{
          root: styles.muiAccordionSummary,
          content: styles.muiAccordionSummaryContent,
          expanded: styles.muiAccordionSummaryExpanded,
        }}
      >
        {overdue ? (
          <h4 className={styles.overdueTasksTitle}>Overdue Tasks</h4>
        ) : (
          <div className={styles.currentTasksHead}>
            <p className={styles.firstDate}>{day}</p>
            <span>
              <p>{`${month} ${year}`}</p>
              <p>{dayOfWeek}</p>
            </span>
          </div>
        )}
        {loading ? <LoadingSpinner /> : <span className={styles.tasksLength}>{tasks?.data.length}</span>}
      </AccordionSummary>

      <AccordionDetails classes={{ root: styles.muiAccordionDetails }}>
        <div className={styles.tasksContent}>
          {tasks.data.map((task) => (
            <div className={cls(styles.taskItem, !!completed.get(task.id) && styles.taskItemCompleted)} key={`${task.name}-${task.id}`}>
              <FormControlLabel
                control={
                  updatingTaskId === task.id ? (
                    <CircularProgress className={styles.loader} />
                  ) : (
                    <Checkbox
                      color="primary"
                      checked={!!completed.get(task.id) ?? false}
                      classes={{ checked: styles.muiCheckboxChecked }}
                      id={task.id.toString()}
                      onChange={(e) => handleCheckboxChange(e, task)}
                    />
                  )
                }
                label={task.name}
                disabled={updatingTaskId === task.id}
              />
              <IconButton
                onClick={() => {
                  history.push(`/tasks/${task.id}/edit`)
                }}
                disabled={updatingTaskId === task.id}
              >
                <SvgIcon iconId={SvgIconIds.CREATE} />
              </IconButton>
            </div>
          ))}
          {overdue ? (
            <span hidden={Boolean(tasks.data.length)} className={styles.noDataAvailable}>
              No overdue tasks
            </span>
          ) : (
            <span hidden={Boolean(tasks.data.length)} className={styles.noDataAvailable}>
              No tasks due on:
              <br />
              {currentDateTasks}
            </span>
          )}
          <div hidden={tasks?.total < defaultTasksLimit} className={styles.buttonSection}>
            <XtButton onClick={() => history.push(`/tasks`)} hidden={!tasks} label="View More" />
            {/* TODO disabled  */}
            <XtButton hidden label="Save" loading={loading} />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
})
