import { IAttachedDocument, IAttachedDocumentWithFile, UsedOnValue } from 'documents/documents.types'

export interface IAttachedDocumentDialog {
  open: boolean
  onClose: () => void
  onCreate: (newDocument: IAttachedDocumentWithFile) => Promise<boolean>
  editedDocument: IAttachedDocument
  usedOnFilter: UsedOnValue
  isMobile: boolean
}

export interface IAttachedDocumentDialogState {
  isLoading: boolean
  newFile: File | null
}

export enum DocumentDialogFormLabel {
  Notes = 'Notes',
  Name = 'Name',
}
