import * as React from 'react'
import { FC, useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import * as Icon from 'react-feather'
import { useXtForm } from 'common/hooks/form/form'
import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { renderOption, getInputId } from 'common/utils/autocomplete.utils'
import { FormCheckboxLabel, FormField, FormXtAutocomplete } from '../../../../common/utils/form/form.components'
import {
  ILineItemAdditionalSectionForm,
  ILineItemsAdditionSectionParams,
  LineItemAdditionalSectionField,
} from './sales-order-line-items-additional-section.types'
import {
  convertFormData,
  defineFormData,
  loadCurrencies,
  loadGlAccounts,
  loadTaxTypes,
} from './sales-order-line-items-additional-section.utils'
import { salesOrderMainValidationSchema } from './sales-order-line-items-additional-section.validation'
import * as styles from './sales-order-line-items-additional-section.module.scss'

export const SalesOrderLineItemsAdditionalSection: FC<ILineItemsAdditionSectionParams> = ({
  currencyDisabled,
  data,
  isViewMode,
  onChange,
  validationObservable,
}) => {
  const { control, formState, reset, trigger, formChanges$, setValue } = useXtForm<ILineItemAdditionalSectionForm>({
    defaultValues: defineFormData(data),
    mode: 'onBlur',
    validationSchema: salesOrderMainValidationSchema,
  })

  useEffect(() => {
    reset(defineFormData(data))
  }, [data])

  useEffect(() => {
    const validationSub = validationObservable.subscribe(() => {
      void trigger()
    })

    const formChangeSub = formChanges$.subscribe(({ data: formData, state }) => onChange({ state, data: convertFormData(formData) }))

    return () => {
      validationSub.unsubscribe()
      formChangeSub.unsubscribe()
    }
  }, [validationObservable, trigger, formChanges$, onChange])

  const handleMiscChange = (key: LineItemAdditionalSectionField, option: IXtAutocompleteOption | string | number | null) => {
    setValue(key, option)
    void trigger([
      LineItemAdditionalSectionField.MiscCharge,
      LineItemAdditionalSectionField.MiscChargeSalesAccount,
      LineItemAdditionalSectionField.ChargeTaxType,
    ])
  }

  return (
    <Accordion component="section">
      <AccordionSummary
        classes={{ root: formState.isValid ? '' : 'xt-invalid' }}
        expandIcon={<Icon.ChevronDown />}
        aria-controls="panel1a-content"
      >
        <Typography>Freight, Tax and Totals</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.lineItemsAdditionalSection}>
          <div className={styles.lineItemsAdditionalSectionForm}>
            <FormCheckboxLabel
              disabled={isViewMode}
              label="Required sufficient Inventory"
              control={control}
              name={LineItemAdditionalSectionField.RequireSufficientInventory}
            />
            <FormField
              disabled={isViewMode}
              name={LineItemAdditionalSectionField.MiscChargeDescription}
              control={control}
              label="Misc. Charge Description"
            />
            <FormXtAutocomplete
              disabled={isViewMode}
              loadOptions={loadGlAccounts}
              renderOption={renderOption}
              getInputLabel={getInputId}
              name={LineItemAdditionalSectionField.MiscChargeSalesAccount}
              control={control}
              label="Misc. Charge Sales Account"
              onChange={(option) => handleMiscChange(LineItemAdditionalSectionField.MiscChargeSalesAccount, option)}
            />
            <FormXtAutocomplete
              disabled={isViewMode}
              loadOptions={loadTaxTypes}
              name={LineItemAdditionalSectionField.ChargeTaxType}
              control={control}
              label="Misc. Charge Tax Type"
            />
            <FormCheckboxLabel
              disabled={isViewMode}
              label="Misc. Charge is Discount"
              control={control}
              name={LineItemAdditionalSectionField.MiscChargeDiscount}
            />
            <FormField
              disabled
              type="number"
              name={LineItemAdditionalSectionField.FreightWeight}
              control={control}
              label="Freight Weight"
            />
            <FormXtAutocomplete
              disabled={isViewMode}
              loadOptions={loadTaxTypes}
              name={LineItemAdditionalSectionField.FreightTaxType}
              control={control}
              label="Freight Tax Type"
            />
            <FormField
              disabled
              name={LineItemAdditionalSectionField.AllocatedCreditMemoOrInvoice}
              control={control}
              label="Allocated Credit Memo/Invoice"
            />
            <FormField
              disabled
              name={LineItemAdditionalSectionField.OutstandingCredit}
              type="number"
              control={control}
              label="Outstanding Credit"
            />
            <FormField
              disabled
              name={LineItemAdditionalSectionField.AuthorizedCCPayments}
              type="number"
              control={control}
              label="Authorized CC Payments"
            />
          </div>
          <div className={styles.lineItemsAdditionalSectionForm}>
            {/* TODO update default data in new mode */}
            <FormXtAutocomplete
              disabled={isViewMode || !!data?.currency || currencyDisabled}
              loadOptions={loadCurrencies}
              name={LineItemAdditionalSectionField.Currency}
              control={control}
              label="Currency"
            />
            <FormField disabled name={LineItemAdditionalSectionField.AtShipping} type="number" control={control} label="At Shipping" />
            <FormField disabled name={LineItemAdditionalSectionField.Subtotal} type="number" control={control} label="Subtotal" />

            <FormField
              disabled={isViewMode}
              type="number"
              name={LineItemAdditionalSectionField.MiscCharge}
              control={control}
              label="Misc. Charge"
              onChange={(option) => handleMiscChange(LineItemAdditionalSectionField.MiscCharge, option)}
            />
            <FormField
              disabled={isViewMode}
              name={LineItemAdditionalSectionField.Freight}
              type="number"
              control={control}
              label="Freight"
            />
            <FormField disabled name={LineItemAdditionalSectionField.Tax} type="number" control={control} label="Tax" />
            <FormField disabled name={LineItemAdditionalSectionField.Total} type="number" control={control} label="Total" />
            <FormField disabled name={LineItemAdditionalSectionField.Balance} type="number" control={control} label="Balance" />
            <div className={styles.lineItemsAdditionalSectionFormVertical}>
              <FormField disabled name={LineItemAdditionalSectionField.Margin} type="number" control={control} label="Margin" />
              <FormField disabled name={LineItemAdditionalSectionField.MarginPercent} type="number" control={control} label="Margin %" />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
