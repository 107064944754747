import React from 'react'
import { useHistory, useParams } from 'react-router'
import { TaskDetails as TaskForm } from './task-details.component'
import { TaskDetailsMode } from './task-details.types'

export const TaskDetails = () => {
  const { id, mode } = useParams<{ id: string; mode: TaskDetailsMode }>()
  const history = useHistory()
  const onCancel = () => history.push('/tasks')
  return <TaskForm onCancel={onCancel} id={parseInt(id, 10)} mode={mode} className="xt-content xt-content-with-remarks" />
}
