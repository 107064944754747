import { AxiosInstance } from 'axios'
import api from 'common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { UsedOnValue } from 'documents/documents.types'
import { ICharacteristic } from './characteristics.types'

export interface ICharacteristicService {
  getAll({ limit, page }: IPaginationParams, usedOnFilter?: UsedOnValue): Promise<IPaginationData<ICharacteristic>>
  get(characteristic: string): Promise<ICharacteristic>
}

class Service implements ICharacteristicService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll({ limit, page }: IPaginationParams, usedOnFilter?: string): Promise<IPaginationData<ICharacteristic>> {
    const params: Record<string, number | string> = { limit, page: page === 0 ? 1 : page }
    if (usedOnFilter) {
      params.used_on = usedOnFilter
    }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICharacteristic>>('/characteristic', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(characteristic: string): Promise<ICharacteristic> {
    const response = await this.apiClient.get<IResponse<ICharacteristic>>(`/characteristic/${characteristic}`)
    return response.data.data
  }
}

export const CharacteristicService = new Service(api)
