import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { parseDate } from 'common/utils/date.utils'
import { globalConstants } from 'common/constants'
import {
  RoutingDetailsActionsEditMode,
  RoutingDetailsActionsNewMode,
  RoutingDetailsActionsViewMode,
  RoutingDetailsFormField,
} from './RoutingDetails.constants'
import { IRoutingItem, RoutingItemMode } from '../Routing.types'
import { IRoutingDetailsTableItem, RoutingDetailsFormData } from './RoutingDetails.types'

// TODO inside the typeUtils
const convertDate = (date: Date | string) => {
  if (typeof date === 'string') {
    return parseDate(date, globalConstants.serverDateFormat)
  }
  return date
}

export const defineFormData = (data: RoutingDetailsFormData | null) => ({
  [RoutingDetailsFormField.RevisionNumber]: data?.revision_number ?? '',
  [RoutingDetailsFormField.RevisionDate]: data?.revision_date ? convertDate(data.revision_date) : new Date(),
  [RoutingDetailsFormField.DocumentNumber]: data?.document_number ?? '',
  [RoutingDetailsFormField.FinalLocationSite]: data?.final_location_site ?? '',
  [RoutingDetailsFormField.FinalLocation]: data?.final_location ?? '',
  [RoutingDetailsFormField.CloseWorkorder]: data?.close_workorder ?? false,
  [RoutingDetailsFormField.RoutingItems]: data?.routing_items ?? [],
  [RoutingDetailsFormField.ItemNumber]: data?.item_number ?? '',
  [RoutingDetailsFormField.ItemDescription]: data?.item_description ?? '',
  [RoutingDetailsFormField.LeadTime]: data?.lead_time ?? 0,
  [RoutingDetailsFormField.RevisionStates]: data?.revision_status ?? '',
})

export const defaultRoutingItemDialogState = {
  open: false,
  routingItem: null,
  mode: RoutingItemMode.View,
}
export const defaulDeletionState = { itemId: null, confirmationOpen: false }

export function defineAvailableActions(newRouting: boolean, isViewMode: boolean, isMobile: boolean): IXtTableCellAction[] {
  if (isMobile) {
    return []
  }
  if (newRouting) {
    return RoutingDetailsActionsNewMode
  }
  if (isViewMode) {
    return RoutingDetailsActionsViewMode
  }
  return RoutingDetailsActionsEditMode
}
export const normalizeData = (data: IRoutingItem[]): IRoutingDetailsTableItem[] =>
  data.map((item) => ({
    ...item,
    id: item.sequence_number,
    description: item.routing_description2 ? `${item.routing_description1} - ${item.routing_description2}` : item.routing_description1,
  }))
