import {
  BillToAndShipToFormField,
  IBillToAndShipToForm,
} from './sales-order-bill-to-and-ship-to-form/sales-order-bill-to-and-ship-to-form.types'
import { ISalesOrderContactAndAddress } from './sales-order-bill-to-and-ship-to.types'
import { IFormStateChanges, IFormStateChangesNullable } from '../../../../common/hooks/form/form.types'
import { convertStateForRequest } from '../../../../common/utils/country-state.utils'

export function convertFormDataToBillTo(data: IBillToAndShipToForm): ISalesOrderContactAndAddress {
  const contact = data[BillToAndShipToFormField.Contact]
  return {
    address: {
      address1: data[BillToAndShipToFormField.Street1],
      address2: data[BillToAndShipToFormField.Street2],
      address3: data[BillToAndShipToFormField.Street3],
      city: data[BillToAndShipToFormField.City],
      state: convertStateForRequest(data[BillToAndShipToFormField.State]) ?? '',
      country: data[BillToAndShipToFormField.Country]?.id ?? '',
      postal_code: data[BillToAndShipToFormField.Postal],
    },
    contact,
  }
}

export function isValidForm(
  form: [IFormStateChangesNullable<ISalesOrderContactAndAddress>, IFormStateChangesNullable<ISalesOrderContactAndAddress>]
): form is [IFormStateChanges<ISalesOrderContactAndAddress>, IFormStateChanges<ISalesOrderContactAndAddress>] {
  const [billToState, shipToState] = form
  return !!billToState.data && !!shipToState.data
}
