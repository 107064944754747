import { ContactFormValues, IContact } from '../contacts.types'
import { IContactFormState } from './contact-dialog-form.types'

export const convertFromValues = (data: IContactFormState): Omit<IContact, 'contact_characteristics'> => ({
  [ContactFormValues.Number]: data?.contact_number ?? '',
  [ContactFormValues.Active]: data?.active ?? true,
  [ContactFormValues.Honorific]: data?.honorific ?? '',
  [ContactFormValues.FirstName]: data?.first_name ?? '',
  [ContactFormValues.MidName]: data?.middle_name ?? '',
  [ContactFormValues.LastName]: data?.last_name ?? '',
  [ContactFormValues.Suffix]: data?.suffix ?? '',
  [ContactFormValues.Company]: data?.company ?? '',
  [ContactFormValues.JobTitle]: data?.job_title ?? '',
  [ContactFormValues.Email]: data?.email ?? '',
  [ContactFormValues.Web]: data?.web ?? '',
  [ContactFormValues.EmailOptIn]: data?.email_opt_in ?? false,
  [ContactFormValues.Phones]: data.phones ?? [],
  [ContactFormValues.Comments]: data.comments ?? [],
  [ContactFormValues.Notes]: data.notes ?? '',
  [ContactFormValues.ContactAddress]: {
    [ContactFormValues.Address1]: data?.address1 ?? '',
    [ContactFormValues.Address2]: data?.address2 ?? '',
    [ContactFormValues.Address3]: data?.address3 ?? '',
    [ContactFormValues.City]: data?.city ?? '',
    [ContactFormValues.Country]: data?.country ?? '',
    [ContactFormValues.State]: data?.state ?? '',
    [ContactFormValues.Postalcode]: data?.postalcode ?? '',
    [ContactFormValues.Latitude]: data?.latitude || 0,
    [ContactFormValues.Longitude]: data?.longitude || 0,
    [ContactFormValues.Accuracy]: data?.accuracy || 0,
  },
})
