import { IPricingItem } from 'items/Items.types'
import { ISalesOrderLineItemPriceListItem } from './sales-order-line-item-price-list.types'

export function defineItemsPricesWithId(items?: IPricingItem[] | null, selectedId?: string | number): ISalesOrderLineItemPriceListItem[] {
  return (items ?? []).map((item, index) => ({
    ...item,
    price_in_base: item?.price_in_base ?? 0,
    id: index,
    selected: index === Number(selectedId),
  }))
}

export const defaultPriceListState = { selected: null }
