import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Tab, Tabs } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import http from "../common/api"
import Card from "../components/Card.jsx";
import LoadingSpinner from "../components/LoadingSpinner"
import FileUpload from "../components/FileUpload"

const Quote = (props) => {
  const [order, setOrder] = useState({order_number: '', order_items: []});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.match.params.orderNumber) {
      setLoading(true);
      http.get("/quote/" + props.match.params.orderNumber).then(
        (response) => {
          let dat = response.data.data;
          if (!dat.order_items) {
            dat.order_items = [];
          }
          setOrder(dat);
        },
        (error) => {
          const _msg =  (error.response && error.response.data && error.response.data.status.message) || 
                         error.message ||
                         error.toString();
          setMessage(_msg);
        }
      );
    }
    setLoading(false);
  }, [props.match.params.orderNumber]);

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <h4>Quote #{order.quote_number}: {order.customer_number}</h4>
            <Card
              title=""
              category={message}
              ctTableResponsive
              content={
                <>
                {loading ?
                  <LoadingSpinner /> :
                  <Tabs defaultActiveKey="details" id="ordertabs">
                    <Tab eventKey="details" title="Details">
                    <div className="row">
                      <div className="col-sm-12 col-md-4">
                        <div className="row">
                          <div className="col-12">
                            <p className="main-color d-block d-md-inline-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="order-date-label"><strong>Order Date</strong></p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <input type="text" id="order-date" className="form-control text-center text-md-left" placeholder="NA" value={order.quote_date} disabled />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-4">
                        <div className="row">
                          <div className="col-12">
                            <p className="main-color d-block d-md-inline-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="cust-number-label"><strong>Customer #</strong></p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <input type="text" id="cust-number" className="form-control text-center text-md-left" placeholder="NA" value={order.customer_number} disabled />
                          </div>
                        </div>
                      </div>
                    </div>
  
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="row">
                        <div className="col-12">
                          <p className="main-color d-block d-md-inline-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="order-number-label"><strong>Order #</strong></p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-12">
                          <input type="text" id="order-number" className="form-control text-center text-md-left" placeholder="NA" value={order.quote_number } disabled />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <div className="row">
                        <div className="col-12">
                          <p className="main-color d-block d-md-inline-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="cust-po-number-label"><strong>Customer PO #</strong></p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-12">
                          <input type="text" id="cust-po-number" className="form-control text-center text-md-left" placeholder="NA" value={ order.customer_po_number } disabled />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                      <div className="row">
                        <div className="col-12">
                          <p className="main-color d-block d-md-inline-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="ext-number-label"><strong>External Order #</strong></p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-12">
                          <input type="text" id="ext-number" className="form-control text-center text-md-left" placeholder="NA" value={order.external_number} disabled />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-12">
                      <hr/>
                    </div>
                  </div>

                  <div className="container-fluid pt-0 pb-4">
                    <div className="table-responsive" id="desktop-orders">
                      <table className="table table-hover table-striped table-sm table-bordered">
                        <thead className="main-color">
                          <tr>
                            <th className="text-center">Item #</th>
                            <th>Item</th>
                            <th className="text-right">Sched. Date</th>
                            <th className="text-right">Unit of Measure</th>
                            <th className="text-right">Qty Ordered</th>
                            <th className="text-right">Unit Price</th>
                          </tr>
                        </thead>
                        <tbody>
                        {order.order_items.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <td className="text-center">{prop['line_number']}</td>
                            <td>{prop['item_number']}</td>
                            <td className="text-right">{prop['scheduled_date']}</td>
                            <td className="text-right">{prop['price_uom']}</td>
                            <td className="text-right">{prop['qty_ordered']}</td>
                            <td className="text-right">{prop['net_unit_price']}</td>
                          </tr>
                        );
                      })}
                        </tbody>
                      </table>
                    </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-3">
                        <div className="row">
                          <div className="col-12">
                            <p className="main-color d-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="freight-charge-label"><strong>Freight</strong></p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <input type="text" id="freight-charge" className="form-control text-center" placeholder="NA" value={ order.currency_symbol+order.freight} disabled />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="row">
                          <div className="col-12 text-right">
                            <p className="main-color d-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="taxes-label"><strong>Taxes</strong></p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <input type="text" id="taxes" className="form-control text-center" placeholder="NA" value={order.currency_symbol+order.tax_total} disabled />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="row">
                          <div className="col-12 text-right">
                            <p className="main-color d-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="subtotal-label"><strong>Sales Total</strong></p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <input type="text" id="subtotal" className="form-control text-center" placeholder="NA" value={ order.currency_symbol+order.sales_total} disabled />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3">
                        <div className="row">
                          <div className="col-12 text-right">
                            <p className="main-color d-block pt-2 pb-2 pl-3 pr-3 mb-0 text-center" id="total-label"><strong>Total</strong></p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <input type="text" id="total" className="form-control text-center" placeholder="NA" value={ order.currency_symbol+order.order_total} disabled />
                          </div>
                        </div>
                      </div>
                    </div> 
                    </Tab>
                    <Tab eventKey="documents" title="Documents">
                      <FileUpload documentType="Q" documentNumber={order.quote_number}/>
                    </Tab>
                    <Tab eventKey="comments" title="Comments">
                      <p>Not yet implemented</p>
                    </Tab>
                  </Tabs>
                }
              </>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Quote;
