import { LineItemFormField, SalesOrderLineItemNewForm } from './sales-order-line-item-new.types'

export const defaultFormState: SalesOrderLineItemNewForm = {
  [LineItemFormField.Item]: null,
  [LineItemFormField.ItemDescription]: '',
  [LineItemFormField.ScheduledDate]: new Date(),
  [LineItemFormField.SellingUom]: '',
  [LineItemFormField.Site]: '',
  [LineItemFormField.QtyOrdered]: 1,
  [LineItemFormField.NetUnitPrice]: '',
}
