import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { IContact } from 'contacts/contacts.types'

export enum DialogFormField {
  AccountNumber = 'number',
  Role = 'role',
  Contact = 'contact',
  Active = 'active',
  Default = 'default',
}
export enum DialogFormLabel {
  AccountNumber = 'Account #',
  Role = 'Role',
  Contact = 'Contact',
  Active = 'Active',
  Default = 'Default',
}

export interface ISelectContactFormData {
  [DialogFormField.Contact]: IContact | null
  [DialogFormField.Role]: IXtAutocompleteOption | null
  [DialogFormField.Active]: boolean
  [DialogFormField.Default]: boolean
}

export interface ISelectContactDialog {
  open: boolean
  onClose(): void
  account_number: string
  account_name: string
  onConfirm(contact: IContact): void
  selectData: IContact | null
  openContactDialog(): void
}
