import { Overwrite } from 'utility-types'
import { SalesOrderMainInput } from '../components/sales-order-main/sales-order-main.types'
import { ISalesOrderHeaderInformationState } from '../components/sales-order-header-information/sales-order-header-information.types'
import { IAppliedCharacteristic } from '../../characteristics/characteristics.types'
import { ISalesOrder, ISalesOrderExtended } from '../sales-orders.types'
import { ISalesOrderLineItemsData } from '../components/sales-order-line-items/sales-order-line-items.types'
import { ISalesOrderShippingAndSalesInput } from '../components/sales-order-header-information/sales-order-shipping-and-sales/sales-order-shipping-and-sales.types'
import { IFormStateChanges, IFormStateChangesNullable } from '../../common/hooks/form/form.types'
import { ISalesOrderContactAndAddress } from '../components/sales-order-header-information/sales-order-bill-to-and-ship-to/sales-order-bill-to-and-ship-to.types'

export interface ISalesOrderParams {
  salesOrder: ISalesOrderExtended | null
  viewMode: boolean
  onCancel(): void
  onSubmit?(): void
}

export interface ISalesOrdersNewState {
  salesOrder: ISalesOrder | null
  loading: boolean
  isSubmitting: boolean
  mainFormState: IFormStateChangesNullable<SalesOrderMainInput>
  lineItemsState: IFormStateChangesNullable<ISalesOrderLineItemsData>
  headerInfoState: IFormStateChangesNullable<ISalesOrderHeaderInformationState>
  characteristics: IAppliedCharacteristic[]
  documents: null // TODO update me
  billTo: ISalesOrderContactAndAddress | null
  shipTo: ISalesOrderContactAndAddress | null
  shippingAndSales: ISalesOrderShippingAndSalesInput | null
}

export type SalesOrderNewStateFilled = Overwrite<
  ISalesOrdersNewState,
  {
    mainFormState: IFormStateChanges<SalesOrderMainInput>
    lineItemsState: IFormStateChanges<ISalesOrderLineItemsData>
    headerInfoState: IFormStateChanges<
      Overwrite<
        ISalesOrderHeaderInformationState,
        {
          shipTo: ISalesOrderContactAndAddress
          billTo: ISalesOrderContactAndAddress
          shippingAndSales: ISalesOrderShippingAndSalesInput
        }
      >
    >
  }
>

export enum SalesOrdersNewTab {
  LineItems = 'Line Items',
  HeaderInfo = 'Header Info',
  Documents = 'Documents',
  Remarks = 'Remarks',
  Characteristics = 'Characteristics',
}

export enum SalesOrderMode {
  View = 'view',
  Edit = 'edit',
}

export interface ISalesOrderPageParams {
  orderNumber: string
  mode: SalesOrderMode
}
