import React, { FC, memo, useMemo } from 'react'

import { XtRemarks } from 'components/xt-remarks/xt-remarks'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { XtCharacteristics } from 'characteristics/characteristics'
import { XtDocuments } from 'components/documents/documents'
import { UsedOnValue } from 'documents/documents.types'
import { AssigneeTab } from './assignee-tab/assignee-tab'
import { TaskDetailsTabsName, ITaskDetailsTabs } from './task-details-tabs.types'

export const TaskDetailsTabs: FC<ITaskDetailsTabs> = memo(
  ({ disabled, isMobile, onChange, assignees, remarks, characteristics, documents }) => {
    const tabs = useMemo(
      () => [
        {
          name: TaskDetailsTabsName.Assignee,
          template: <AssigneeTab assignees={assignees} isMobile={isMobile} disabled={disabled} onChange={onChange} />,
        },
        {
          name: TaskDetailsTabsName.Characteristics,
          template: (
            <XtCharacteristics
              usedOnFilter={UsedOnValue.Task}
              isViewMode={disabled}
              onCreate={characteristics.createCharacteristic}
              onUpdate={characteristics.updateCharacteristic}
              onDelete={characteristics.deleteCharacteristic}
              characteristics={characteristics.characteristics}
            />
          ),
        },
        {
          name: TaskDetailsTabsName.Documents,
          template: (
            <XtDocuments
              usedOnFilter={UsedOnValue.Task}
              isViewMode={isMobile || !documents.creationEnabled}
              onDocumentCreate={documents.saveDocument}
              onDocumentDelete={documents.deleteDocument}
              loadMore={documents.loadMore}
              canLoadMore={documents.canLoadMore}
              documents={documents.documents}
            />
          ),
        },
        {
          name: TaskDetailsTabsName.Remarks,
          template: (
            <XtRemarks
              loadMore={remarks.loadMore}
              canLoadMore={remarks.canLoadMore}
              total={remarks.total}
              textAreaOnChange={remarks.setNotes}
              textAreaValue={remarks.notes}
              textAreaName="Notes"
              onCommentsUpdate={remarks.updateComment}
              onCommentsSave={remarks.saveComment}
              comments={remarks.comments}
              username={remarks.username}
              disabled={disabled}
            />
          ),
        },
      ],
      [assignees, disabled, isMobile, onChange, remarks, characteristics, documents]
    )
    return <XtTabs tabs={tabs} />
  }
)
