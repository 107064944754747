import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { ContactAction } from './contact-list.types'
import { dateConverter } from '../../common/utils/date.utils'

export const ContactActionsEditMode: IXtTableCellAction[] = [
  {
    name: ContactAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: ContactAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: ContactAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const ContactActionsViewMode: IXtTableCellAction[] = [
  {
    name: ContactAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const contactColumns = [
  {
    id: 'first_name',
    field: 'first_name',
    headerName: 'First Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'last_name',
    field: 'last_name',
    headerName: 'Last Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active Contact',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'account_number',
    field: 'account_number',
    headerName: 'CRM Account(s)',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'company',
    field: 'company',
    headerName: 'Company',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'job_title',
    field: 'job_title',
    headerName: 'Title',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'office_phone',
    field: 'office_phone',
    headerName: 'Office #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'mobile_phone',
    field: 'mobile_phone',
    headerName: 'Mobile #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'fax_phone',
    field: 'fax_phone',
    headerName: 'Fax #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'email',
    field: 'email',
    headerName: 'Email',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'web',
    field: 'web',
    headerName: 'Web Address',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'address',
    field: 'address',
    headerName: 'Address',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'city',
    field: 'city',
    headerName: 'City',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'state',
    field: 'state',
    headerName: 'State',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'country',
    field: 'country',
    headerName: 'Country',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'postalcode',
    field: 'postalcode',
    headerName: 'Postal Code',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'created',
    field: 'created',
    headerName: 'Created',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'lastupdated',
    field: 'lastupdated',
    headerName: 'Updated',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
]
