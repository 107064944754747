import { Overwrite } from 'utility-types'
import { BomDetailsMode, IBom, IBomItem } from '../bom.types'
import { BomItemMode } from '../bomItem/BomItem.types'

export interface IBomState {
  bom: IBom | null
  initializing: boolean
}
export interface IItemDeletionState {
  itemId: number | null
  confirmationOpen: boolean
}
export interface IBomItemDialogState {
  open: boolean
  bomItem: IBomItem | null
  mode: BomItemMode
}

export interface IBomDetailsParams {
  mode: BomDetailsMode
  itemNumber: string
}

export enum BomDetailsFormField {
  ItemNumber = 'item_number',
  Revision = 'revision',
  RevisionDate = 'revision_date',
  DocumentNumber = 'document_number',
  BatchSize = 'batch_size',
}

export enum BomDetailsAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  Expire = 'Expire',
  Replace = 'Replace',
}

export type BomDetailFormInput = Overwrite<
  Pick<
    IBom,
    BomDetailsFormField.Revision | BomDetailsFormField.RevisionDate | BomDetailsFormField.DocumentNumber | BomDetailsFormField.BatchSize
  >,
  { [BomDetailsFormField.BatchSize]: number | null }
>

export type BomFormData = Overwrite<
  BomDetailFormInput,
  { [BomDetailsFormField.RevisionDate]: Date; [BomDetailsFormField.BatchSize]: number | string }
> & { item_number: string | null }
