export enum StageTaskFormOptions {
  New = 'New',
  Pending = 'Pending',
  InProces = 'In-Process',
  Deferred = 'Deferred',
  Completed = 'Completed',
}
export const statusOptions = Object.values(StageTaskFormOptions)

export const PERCENT_MIN = 0
export const PERCENT_MAX = 100
