import { ITask } from 'tasks/tasks.types'
import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from '../../components/xtAutocomplete/XtAutocomplete.types'
import { IComment } from '../../comments/comments.types'

export enum TaskDetailsMode {
  View = 'view',
  Edit = 'edit',
}
export interface ITaskDetailsState {
  loading: boolean
  task: ITask | null
  isCompletedTask: boolean
}
export interface ITaskDetails {
  id: number | null
  mode: TaskDetailsMode | null
  className?: string
  onCancel(): void
  onSubmit?(): void
}

export enum TaskFormField {
  Name = 'name',
  Description = 'description',
  Priority = 'priority',
  Status = 'status',
  Owner = 'owner',
  DueDate = 'due_date',
  PlannedStartDate = 'planned_start_date',
  CompletedDate = 'completed_date',
  Percent = 'percent_complete',
  StartedDate = 'actual_start_date',
}

export enum TaskFormLabel {
  Number = 'Task Number',
  Name = 'Task Name',
  Description = 'Description',
  Priority = 'Priority',
  Status = 'Stage',
  Owner = 'Owner',
  DueDate = 'Due Date',
  PlannedStartDate = 'Planned Start',
  CompletedDate = 'Completed Date',
  Completed = 'Mark Task as Completed',
  Notes = 'Notes',
  Percent = 'Percent',
  StartedDate = 'Started Date',
}

export interface IRemarksState {
  comments: IComment[]
  notes: string
}

export interface ITaskForm
  extends Overwrite<
    Pick<
      ITask,
      | TaskFormField.Name
      | TaskFormField.Description
      | TaskFormField.Priority
      | TaskFormField.Status
      | TaskFormField.Owner
      | TaskFormField.DueDate
      | TaskFormField.PlannedStartDate
      | TaskFormField.CompletedDate
      | TaskFormField.StartedDate
      | TaskFormField.Percent
    >,
    {
      [TaskFormField.Percent]: string | number
      [TaskFormField.DueDate]: Date
      [TaskFormField.PlannedStartDate]: Date | null
      [TaskFormField.CompletedDate]: Date | null
      [TaskFormField.StartedDate]: Date | null
      [TaskFormField.Owner]: IXtAutocompleteOption | null
      [TaskFormField.Priority]: IXtAutocompleteOption | null
    }
  > {}

export type MarkTaskAsComplete = Pick<ITaskForm, TaskFormField.CompletedDate | TaskFormField.Percent>
