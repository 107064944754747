import { AxiosInstance } from 'axios'
import api from '../common/api'
import {
  IDocument,
  DocumentType,
  IDocumentsService,
  IAttachedDocument,
  IDetachDocumentRequest,
  ICreateAttachedFileDocumentRequest,
  IAttachedDocumentWithFile,
} from './documents.types'
import { IPaginationData, IPaginationResponse, IPaginationParams, IResponse } from '../common/common.types'
import { prepareRequestParams } from '../common/utils/request.utils'
import { characteristicsToPayload } from '../characteristics/characteristic.utils'

class Service implements IDocumentsService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getDocuments(docType: DocumentType, paginationParams?: IPaginationParams): Promise<IPaginationData<IDocument>> {
    const params = prepareRequestParams(paginationParams)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IDocument>>(`/documentlist/${docType}`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getAttachedForSource(
    source: DocumentType,
    sourceNumber: string,
    paginationParams: IPaginationParams
  ): Promise<IPaginationData<IAttachedDocument>> {
    const params = prepareRequestParams(paginationParams)
    const response = await this.apiClient.get<IPaginationResponse<IAttachedDocument>>(`/documents/${source}/${sourceNumber}`, { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async detach(data: IDetachDocumentRequest): Promise<void> {
    await this.apiClient.post<IResponse<void>>('/documents/detach', { data })
  }

  public async createFile(data: ICreateAttachedFileDocumentRequest): Promise<void> {
    const formData = new FormData()
    formData.append('file', data.file, data.file_name ?? data.file.name)
    formData.append('document_type', data.document_type)
    formData.append('document_number', data.document_number)
    formData.append('document_notes', data.document_notes)
    if (data.document_characteristics) {
      formData.append('document_characteristics', JSON.stringify(data.document_characteristics))
    }
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    await this.apiClient.post<IResponse<void>>('/file/create', formData, config)
  }

  public async createFileForSource(document: IAttachedDocumentWithFile, sourceType: DocumentType, sourceNumber: string): Promise<void> {
    await this.createFile({
      file: document.file,
      document_type: sourceType,
      document_number: sourceNumber,
      document_notes: document.notes,
      file_name: document.name,
      document_characteristics: characteristicsToPayload(document.document_characteristics ?? []),
    })
  }

  public async createFilesForSource(documents: IAttachedDocumentWithFile[], sourceType: DocumentType, sourceNumber: string): Promise<void> {
    await Promise.all(
      documents.filter((document) => document.file).map((document) => this.createFileForSource(document, sourceType, sourceNumber))
    )
  }

  public async loadFileData(file_link: string): Promise<Blob> {
    const response = await this.apiClient.get<ArrayBuffer>(file_link, { responseType: 'arraybuffer' })
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    return blob
  }
}

export const DocumentsService = new Service(api)
