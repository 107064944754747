import * as React from 'react'
import { FC, memo, useEffect, useState, useCallback } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Controller, useFormContext } from 'react-hook-form'
import { processAutocompleteValue, loadStandardOperationOptions, loadWorkCenterOptions, loadOperationTypeOptions } from 'common/utils'
import { XtAutocomplete } from 'components/xtAutocomplete/XtAutocomplete'
import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { KeyboardDatePickerWithText } from 'components/KeyboardDatePickerWithText'
import './MuiRoutingItemForm.scss'
import { validationMessage } from 'common/validation/validation'

import { globalConstants } from 'common/constants'
import { StandardOperationService } from 'services/StandardOperationService'
import { ErrorHandler } from 'services/ErrorService'
import { OperationTypeService } from 'services/OperationTypeService'
import { defineStdOperationData } from '../RoutingItem.utils'
import { RoutingItemControlName, RoutingItemControlLabel, MIN_EXECUTION_DAY, MAX_EXECUTION_DAY, NONE_VALUE } from '../RoutingItem.constants'
import * as styles from './RoutingItemForm.module.scss'
import { FormField } from '../../../common/utils/form/form.components'
import { RoutingItemFormData } from '../RoutingItem.types'

export const renderOption: (option: IXtAutocompleteOption | null) => React.ReactNode = (option) => (
  <div key={option?.id}>{option?.label}</div>
)

export interface IRoutingItemForm {
  disabled: boolean
  initialOperationType: string | null
}

export const RoutingItemForm: FC<IRoutingItemForm> = memo(({ disabled, initialOperationType }) => {
  const { control: formControl, watch, reset, setValue, getValues } = useFormContext<RoutingItemFormData>()
  const [operationType, setOperationType] = useState<{ id: string; label: string } | null>()

  const [effective, expires, sequenceNumber, standardOperation] = watch([
    RoutingItemControlName.Effective,
    RoutingItemControlName.Expires,
    RoutingItemControlName.SequenceNumber,
    RoutingItemControlName.StandardOperation,
    RoutingItemControlName.OperationType,
  ])

  const getOperationType = async () => {
    if (initialOperationType) {
      try {
        const { description } = await OperationTypeService.get(initialOperationType)
        setOperationType({ id: initialOperationType, label: description })
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    } else {
      setOperationType(null)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getOperationType()
  }, [initialOperationType])

  const isStdOperation = standardOperation !== NONE_VALUE

  const resetValues = async (option: string) => {
    try {
      const standardOperationItem = await StandardOperationService.get(option)
      const type = standardOperationItem.operation_type
      const { code, description } = await OperationTypeService.get(type)
      const routingItemForm = {
        ...getValues(),
        ...defineStdOperationData(standardOperationItem),
        standard_operation: option,
        operation_type: code,
      }
      reset(routingItemForm)
      setOperationType({ id: type, label: description })
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const setStandardOperationValue = useCallback((value?: string) => {
    if (value && value !== NONE_VALUE) {
      return resetValues(value)
    }
    setValue(RoutingItemControlName.StandardOperation, NONE_VALUE)
    return NONE_VALUE
  }, [])

  const setOperationTypeValue = useCallback((option: IXtAutocompleteOption | null) => {
    setValue(RoutingItemControlName.OperationType, option ? option.id : '')
    setOperationType(option ?? null)
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={styles.mainContentForm}>
        <div className="MuiRoutingItemFields">
          <Controller
            name={RoutingItemControlName.StandardOperation}
            control={formControl}
            render={({ field: { value }, fieldState: { error } }) => (
              <XtAutocomplete
                disabled={disabled}
                error={error?.message}
                value={processAutocompleteValue(value)}
                placeholder={RoutingItemControlLabel.StandardOperation}
                onChange={(option) => setStandardOperationValue(option?.label)}
                loadOptions={loadStandardOperationOptions}
                renderOption={renderOption}
              />
            )}
          />
          <XtAutocomplete
            disabled={disabled || isStdOperation}
            value={operationType}
            placeholder={RoutingItemControlLabel.OperationType}
            onChange={(option) => setOperationTypeValue(option)}
            loadOptions={loadOperationTypeOptions}
            renderOption={renderOption}
          />
          <p className={styles.fieldTitle}>Description:</p>
          <FormField
            disabled={isStdOperation || disabled}
            name={RoutingItemControlName.RoutingDescription1}
            control={formControl}
            label="Description #1"
          />
          <FormField
            disabled={isStdOperation || disabled}
            name={RoutingItemControlName.RoutingDescription2}
            control={formControl}
            label="Description #2"
          />
          <Controller
            name={RoutingItemControlName.WorkCenter}
            control={formControl}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <XtAutocomplete
                disabled={disabled}
                error={error?.message}
                value={processAutocompleteValue(value)}
                placeholder={RoutingItemControlLabel.WorkCenter}
                onChange={(option) => onChange(option?.label)}
                loadOptions={loadWorkCenterOptions}
                renderOption={renderOption}
              />
            )}
          />
          <FormField
            label={RoutingItemControlLabel.ToolingReference}
            name={RoutingItemControlName.ToolingReference}
            control={formControl}
            disabled={disabled}
          />
          <p className={styles.fieldTitle}>{`Oper. Seq. #: ${sequenceNumber}`}</p>
          <FormField
            label={RoutingItemControlLabel.ExecutionDay}
            name={RoutingItemControlName.ExecutionDay}
            control={formControl}
            disabled={disabled}
            inputProps={{ min: MIN_EXECUTION_DAY, max: MAX_EXECUTION_DAY, type: 'number' }}
          />
          <Controller
            name={RoutingItemControlName.Expires}
            control={formControl}
            render={({ field: { onChange, value } }) => (
              <KeyboardDatePickerWithText
                value={value}
                disabled={disabled}
                autoOk
                setValue={(date) => onChange(date)}
                variant="inline"
                inputVariant="outlined"
                label={RoutingItemControlLabel.Expires}
                format={globalConstants.dateFormat}
                invalidDateValue="Never"
                minDate={effective}
                minDateMessage={validationMessage.minDate(RoutingItemControlLabel.Effective)}
              />
            )}
          />
          <Controller
            name={RoutingItemControlName.Effective}
            control={formControl}
            render={({ field: { onChange, value } }) => (
              <KeyboardDatePickerWithText
                disabled={disabled}
                autoOk
                value={value}
                setValue={(date) => onChange(date)}
                variant="inline"
                inputVariant="outlined"
                label={RoutingItemControlLabel.Effective}
                format={globalConstants.dateFormat}
                invalidDateValue="Always"
                maxDate={expires}
                maxDateMessage={validationMessage.maxDate(RoutingItemControlLabel.Expires)}
              />
            )}
          />
        </div>
      </div>
    </MuiPickersUtilsProvider>
  )
})
