import { IPhone, IContactAddress, PhoneRole, IContact } from 'contacts/contacts.types'
import { buildFullContactName } from 'contacts/contacts.utils'
import { ContactData } from './contact-search.types'

export const convertPhone = (role: PhoneRole, phones?: IPhone[]) => {
  if (!phones) {
    return ' '
  }
  return (phones ?? []).filter((item) => item.role === role)[0]?.number ?? ' '
}

export const convertAddress = (address?: IContactAddress | null) => {
  if (!address) {
    return ''
  }
  return [
    address.address1,
    address.address2,
    address.address3,
    address.address2,
    address.city,
    address.state,
    address.postalcode,
    address.country,
  ]
    .filter(Boolean)
    .join(', ')
}

export function convertContact(contact: IContact | null, isMobile: boolean, labelContact: string): ContactData[] {
  const baseContactData = [
    { label: 'Title', value: contact?.job_title ?? '' },
    { label: 'Phone', value: convertPhone(PhoneRole.Office, contact?.phones) },
    { label: 'Fax', value: convertPhone(PhoneRole.Fax, contact?.phones) },
    { label: 'Alternate', value: convertPhone(PhoneRole.Mobile, contact?.phones) },
    { label: 'Email', value: contact?.email ?? '' },
    { label: 'Web', value: contact?.web ?? '' },
    { label: 'Address', value: convertAddress(contact?.contact_address) },
  ]

  return isMobile
    ? [
        {
          label: labelContact,
          value: buildFullContactName(contact),
        },
        ...baseContactData,
      ]
    : baseContactData
}
