import { IXtAutocompleteOption } from '../../../../../components/xtAutocomplete/XtAutocomplete.types'
import { ISalesOrderAddress, ISalesOrderValidation } from '../../../../sales-orders.types'
import { IContact } from '../../../../../contacts/contacts.types'
import { IFormStateChanges } from '../../../../../common/hooks/form/form.types'
import { CountryOption } from '../../../../../common/utils/country-state.utils'

export interface IBillToAndShipToFormParams extends ISalesOrderValidation {
  disabled: boolean
  label: string
  customerNumber: string | null
  contact: IContact | null | undefined
  address: ISalesOrderAddress | null | undefined
  onChange(changes: IFormStateChanges<IBillToAndShipToForm>): void
}

export enum BillToAndShipToFormField {
  Street1 = 'address1',
  Street2 = 'address2',
  Street3 = 'address3',
  City = 'city',
  Country = 'county',
  State = 'state',
  Postal = 'postal_code',
  Contact = 'contact',
}
export interface ISalesOrderContactOption extends IXtAutocompleteOption, IContact {}

export interface IBillToAndShipToForm {
  [BillToAndShipToFormField.Street1]: string
  [BillToAndShipToFormField.Street2]: string
  [BillToAndShipToFormField.Street3]: string
  [BillToAndShipToFormField.City]: string
  [BillToAndShipToFormField.Country]: CountryOption | null
  [BillToAndShipToFormField.State]: IXtAutocompleteOption | null | string
  [BillToAndShipToFormField.Postal]: string
  [BillToAndShipToFormField.Contact]: ISalesOrderContactOption | null
}
