import * as React from 'react'
import { FC, useState, memo } from 'react'
import { AppBar, Tab, Tabs, MenuItem } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { cls, loadUomOptions } from 'common/utils'
import { loadDocumentListOptions } from 'common/utils/document.utils'
import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { throughputFormula } from '../RoutingItem.utils'
import { RoutingItemControlName, RoutingItemControlLabel, MIN_NUMBER_VALUE } from '../RoutingItem.constants'
import './MuiRoutingItemTabs.scss'
import * as styles from './RoutingItemTabs.module.scss'
import { RoutingItemFormData } from '../RoutingItem.types'
import {
  FormCheckboxLabel,
  FormField,
  FormSelectField,
  FormTextAreaField,
  FormXtAutocomplete,
} from '../../../common/utils/form/form.components'

export const renderWipLocationOption: (option: IXtAutocompleteOption | null) => React.ReactNode = (option) => (
  <div key={option?.id}>{`${option?.label}-${option?.id}`}</div>
)
const renderOption: (option: IXtAutocompleteOption | null) => React.ReactNode = (option) => <div key={option?.id}>{option?.label}</div>

const appBarTabs = ['General', 'Instructions']
const ReportRunCostAs = [{ name: 'Direct Labor' }, { name: 'Overhead' }, { name: 'None' }]
export interface IRoutingItemTabs {
  disabled: boolean
}
export interface IRenderOption {
  name: string
  number?: string
  id?: number
}
const renderOptions = (item: IRenderOption, mapKey: number) => {
  const { name, number } = item
  const filterName = number && name ? `${name}-${number}` : name || ''
  return (
    <MenuItem key={`${filterName}_${mapKey}`} value={filterName}>
      {filterName}
    </MenuItem>
  )
}

export const RoutingItemTabs: FC<IRoutingItemTabs> = memo(({ disabled }) => {
  const { control, watch } = useFormContext<RoutingItemFormData>()
  const [per, runtime, productionRatio] = watch([
    RoutingItemControlName.Per,
    RoutingItemControlName.RunTime,
    RoutingItemControlName.ProductionRatio,
  ])

  const initialThroughputValues = {
    per: per ?? 0,
    runTime: runtime ?? 0,
    uomRatio: productionRatio ?? 0,
  }

  const perMinuteValue = throughputFormula(initialThroughputValues, true)
  const minPerValue = throughputFormula(initialThroughputValues)
  const [valueTab, setValueTab] = useState(0)

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue)
  }

  return (
    <div className={cls('muiTabsContent', styles.routingItemTabs)}>
      <AppBar position="static" color="transparent">
        <Tabs indicatorColor="primary" textColor="primary" variant="fullWidth" value={valueTab} onChange={handleChange}>
          {appBarTabs.map((label) => (
            <Tab key={`tab_${label}`} label={label} />
          ))}
        </Tabs>
      </AppBar>
      <div className={styles.tabsContent}>
        <div hidden={valueTab !== 0} className="GeneralTabMui">
          <div className={styles.content}>
            <div className={cls('GeneralTabMuiTopContent', styles.topContent)}>
              <div className={styles.fieldCol}>
                <FormField
                  className="muiTypeNumber"
                  label={RoutingItemControlLabel.SetupTime}
                  name={RoutingItemControlName.SetupTime}
                  control={control}
                  disabled={disabled}
                  inputProps={{ min: MIN_NUMBER_VALUE, type: 'number' }}
                />
                <FormField
                  className="muiTypeNumber RoutingGeneralTabField"
                  label={RoutingItemControlLabel.RunTime}
                  name={RoutingItemControlName.RunTime}
                  control={control}
                  disabled={disabled}
                  inputProps={{ min: MIN_NUMBER_VALUE, type: 'number' }}
                />
                <FormField
                  className="muiTypeNumber RoutingGeneralTabField"
                  label={RoutingItemControlLabel.Per}
                  name={RoutingItemControlName.Per}
                  control={control}
                  disabled={disabled}
                  inputProps={{ min: MIN_NUMBER_VALUE, type: 'number' }}
                />
              </div>
              <div className={styles.fieldCol}>
                <div className={cls('RoutingGeneralTabReportSection', styles.reportSection)}>
                  <FormSelectField
                    name={RoutingItemControlName.ReportSetupCostAs}
                    control={control}
                    label={RoutingItemControlLabel.ReportSetupCostAs}
                    disabled={disabled}
                    options={ReportRunCostAs.map(renderOptions)}
                  />
                  <FormCheckboxLabel
                    name={RoutingItemControlName.ReportSetupTime}
                    control={control}
                    disabled={disabled}
                    label={RoutingItemControlLabel.ReportSetupTime}
                  />
                </div>
                <div className={cls('RoutingGeneralTabReportSection', styles.reportSection)}>
                  <FormSelectField
                    name={RoutingItemControlName.ReportRunCostAs}
                    control={control}
                    className="RoutingGeneralTabField"
                    label={RoutingItemControlLabel.ReportRunCostAs}
                    disabled={disabled}
                    options={ReportRunCostAs.map(renderOptions)}
                  />
                  <FormCheckboxLabel
                    name={RoutingItemControlName.ReportRunTime}
                    control={control}
                    disabled={disabled}
                    label={RoutingItemControlLabel.ReportRunTime}
                  />
                </div>
              </div>
            </div>
            <div className={cls('RoutingGeneralTabBottomContent', styles.bottomContent)}>
              <FormXtAutocomplete
                name={RoutingItemControlName.ProductionUOM}
                control={control}
                disabled={disabled}
                className={cls(styles.fieldCol, 'RoutingGeneralTabField')}
                label={RoutingItemControlLabel.ProductionUOM}
                loadOptions={loadUomOptions}
                renderOption={renderOption}
              />
            </div>
          </div>
          <div className={cls(styles.content, styles.checkboxContent)}>
            <div className="RoutingGeneralTabCheckbox">
              <FormCheckboxLabel
                name={RoutingItemControlName.ReceiveInventory}
                control={control}
                disabled={disabled}
                label={RoutingItemControlLabel.ReceiveInventory}
              />
              <FormCheckboxLabel
                name={RoutingItemControlName.IssueComponents}
                control={control}
                disabled={disabled}
                label={RoutingItemControlLabel.IssueComponents}
              />
              <FormCheckboxLabel
                name={RoutingItemControlName.AllowOverlap}
                control={control}
                disabled={disabled}
                label={RoutingItemControlLabel.AllowOverlap}
              />
            </div>
            <FormXtAutocomplete
              name={RoutingItemControlName.WIPLocation}
              control={control}
              disabled={disabled}
              className={styles.fieldCol}
              label={RoutingItemControlLabel.WIPLocation}
              loadOptions={loadDocumentListOptions}
              renderOption={renderWipLocationOption}
            />
          </div>
          <span className={styles.throughput}>Throughput:</span>
          <div className={cls(styles.content, styles.throughputContent)}>
            <p>{`${perMinuteValue} per Minute`}</p>
            <p>{`(min.) per ${minPerValue}`}</p>
          </div>
        </div>

        <FormTextAreaField
          name={RoutingItemControlName.Instructions}
          control={control}
          hidden={valueTab !== 1}
          className={styles.routingItemTabInstructions}
          label={appBarTabs[0]}
          disabled={disabled}
        />
      </div>
    </div>
  )
})
