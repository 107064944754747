import { DocumentType, IDocument } from 'documents/documents.types'
import { IXtAutocompleteOption, XtAutocompleteLoadOptionsFunc } from 'components/xtAutocomplete/XtAutocomplete.types'
import { DocumentsService } from 'documents/documents.service'
import { IPaginationData } from '../common.types'

type DocumentMapper = (doc: IDocument) => { id: string; label: string }

const makeDocumentLoader = (
  type: DocumentType,
  mapper: DocumentMapper = ({ number }) => ({ id: number, label: number })
): XtAutocompleteLoadOptionsFunc => async (page, limit) => {
  const { total, data } = await DocumentsService.getDocuments(type, { limit, page })
  return {
    total,
    data: data.map(mapper),
  }
}

const nameMapper = ({ name }: { name: string }): IXtAutocompleteOption => ({ id: name, label: name })

export const loadDocumentListOptions: XtAutocompleteLoadOptionsFunc = makeDocumentLoader(DocumentType.List, ({ name, number }) => ({
  id: number,
  label: name,
}))
export const loadCustomerTypeOptions: XtAutocompleteLoadOptionsFunc = makeDocumentLoader(DocumentType.CustomerType, nameMapper)
export const loadDocumentPriorityOptions = makeDocumentLoader(DocumentType.Priority, nameMapper)
export const loadDocumentWarehouseOptions = makeDocumentLoader(DocumentType.Warehouse)
export const loadDocumentOppSourceOptions = makeDocumentLoader(DocumentType.OppSource)
export const loadDocumentTaxZoneOptions = makeDocumentLoader(DocumentType.TaxZone)
export const loadHonorificOptions = makeDocumentLoader(DocumentType.Honorific, nameMapper)

export async function loadShippingZones(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.ShipZone, { page, limit })
  return {
    data: data.map(({ number, name }) => ({ id: number, label: name })),
    total,
  }
}

export async function loadTaxZones(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.TaxZone, { page, limit })
  return {
    data: data.map(({ number, description }) => ({ id: number, label: description })),
    total,
  }
}

export async function loadShippingForm(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.ShipForm, { page, limit })
  return {
    data: data.map(({ number, name }) => ({ id: number, label: name })),
    total,
  }
}

export async function loadShippingCharges(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.ShipCharges, { page, limit })
  return {
    data: data.map(({ number, description }) => ({ id: number, label: description })),
    total,
  }
}

export async function loadSiteOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.Warehouse, { page, limit })
  return {
    data: data.map(({ number }) => ({ id: number, label: number })),
    total,
  }
}

export async function loadSaleTypes(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.SaleType, { page, limit })
  return {
    data: data.map(({ number, description }) => ({ id: number, label: description })),
    total,
  }
}

export async function loadItemTypes(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.ItemTypes, { page, limit })
  return {
    data: data.map(({ name, description }) => ({ id: name, label: description })),
    total,
  }
}

export async function loadItemGroups(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await DocumentsService.getDocuments(DocumentType.ItemGroups, { page, limit })
  return {
    data: data.map(({ number }) => ({ id: number, label: number })),
    total,
  }
}
