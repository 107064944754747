import { FC, memo, useCallback, useEffect, useRef } from 'react'
import * as React from 'react'
import { combineLatest, Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import {
  ISalesOrderHeaderInformationParams,
  SalesOrderHeaderInformationBillToAndShipToChange,
  SalesOrderHeaderInformationShippingAndSalesChange,
} from './sales-order-header-information.types'
import { SalesOrderShippingAndSales } from './sales-order-shipping-and-sales/sales-order-shipping-and-sales'
import { SalesOrderBillToAndShipTo } from './sales-order-bill-to-and-ship-to/sales-order-bill-to-and-ship-to'
import { ISalesOrderShippingAndSalesChange } from './sales-order-shipping-and-sales/sales-order-shipping-and-sales.types'
import * as styles from './sales-order-header-information.module.scss'
import { SalesOrderBillToAndShipToChange } from './sales-order-bill-to-and-ship-to/sales-order-bill-to-and-ship-to.types'
import { globalConstants } from '../../../common/constants'

export const SalesOrderHeaderInformation: FC<ISalesOrderHeaderInformationParams> = memo(
  ({ billTo, shipTo, shippingAndSales, viewMode, hidden = false, customer, onChange, validationObservable }) => {
    const billToAndShipToSubject = useRef<Subject<SalesOrderHeaderInformationBillToAndShipToChange>>(
      new Subject<SalesOrderHeaderInformationBillToAndShipToChange>()
    )
    const shippingAndSalesSubject = useRef<Subject<SalesOrderHeaderInformationShippingAndSalesChange>>(
      new Subject<SalesOrderHeaderInformationShippingAndSalesChange>()
    )

    useEffect(() => {
      const sub = combineLatest([shippingAndSalesSubject.current.asObservable(), billToAndShipToSubject.current.asObservable()])
        .pipe(debounceTime(globalConstants.formChangeDebounce))
        .subscribe(([shippingAndSalesState, billToAndShipToState]) => {
          onChange({
            data: {
              shippingAndSales: shippingAndSalesState.data,
              shipTo: billToAndShipToState.data.shipTo,
              billTo: billToAndShipToState.data.billTo,
            },
            state: {
              isValid: shippingAndSalesState.state.isValid && billToAndShipToState.state.isValid,
              isDirty: shippingAndSalesState.state.isDirty || billToAndShipToState.state.isDirty,
              touched: shippingAndSalesState.state.touched || billToAndShipToState.state.touched,
            },
          })
        })
      return () => sub.unsubscribe()
    }, [onChange])

    const onBillToAndShipToChange = useCallback<SalesOrderBillToAndShipToChange>((change) => billToAndShipToSubject.current.next(change), [
      billTo,
      shipTo,
    ])

    const onShippingAndSalesChange = useCallback<ISalesOrderShippingAndSalesChange>(({ data, state }) => {
      shippingAndSalesSubject.current.next({ data, state })
    }, [])

    return (
      <div className={styles.salesOrderHeaderInfo} hidden={hidden}>
        <SalesOrderShippingAndSales
          data={shippingAndSales}
          validationObservable={validationObservable}
          viewMode={viewMode}
          onChange={onShippingAndSalesChange}
        />
        <SalesOrderBillToAndShipTo
          validationObservable={validationObservable}
          shipTo={shipTo}
          billTo={billTo ?? null}
          viewMode={viewMode}
          onChange={onBillToAndShipToChange}
          customer={customer}
        />
      </div>
    )
  }
)
