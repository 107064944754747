import { SalesOrderShippingAndSalesFormDefaultValues } from './sales-order-shipping-and-sales.types'

export const defaultShippingAndSalesOptions: SalesOrderShippingAndSalesFormDefaultValues = {
  shipping_charges: null,
  shipping_zone: null,
  shipping_form: null,
  sales_rep: null,
  tax_zone: null,
  terms: null,
  sale_type: null,
}
