import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'

import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'

export enum ShipmentAction {
  Edit = 'Edit',
  View = 'View',
  Delete = 'Delete',
}
export const ShipToActionEditMode: IXtTableCellAction[] = [
  {
    name: ShipmentAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: ShipmentAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: ShipmentAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]
export const ShipToActionViewMode: IXtTableCellAction[] = [
  {
    name: ShipmentAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const shipToColumns = [
  {
    id: 'default_flag',
    field: 'default_flag',
    headerName: 'Default',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'shipto_number',
    field: 'shipto_number',
    headerName: 'Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'name',
    field: 'name',
    headerName: 'Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'address1',
    field: 'address1',
    headerName: 'Address',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'city_state_zip',
    field: 'city_state_zip',
    headerName: 'City, State, Zip',
    flex: '1 0 200px',
    width: 200,
  },
]
