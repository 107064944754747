function isValue<TData>(value: Record<string, TData> | TData): value is TData {
  return value instanceof Date || typeof value !== 'object'
}

function extractValue<TData>(object: Record<string, TData | Record<string, TData>>, fields: string[], defaultValue?: TData): TData | null {
  const [field, ...otherFields] = fields
  if (!field && !isValue(object)) {
    return defaultValue ?? null
  }
  const value = object[field]
  return isValue(value) ? value : extractValue(value, otherFields, defaultValue)
}

/**
 * Function returns a nested object value
 * @param object - object used to get value from
 * @param path - path that defines nested property location. Should be in format 'x.y.z'
 * @param defaultValue - default value used if nested property is not found
 */
export function resolvePath<TData>(object: object, path: string, defaultValue?: TData): TData | null {
  const fields = path.split('.')
  if (!fields.length || isValue(object)) {
    return defaultValue ?? null
  }
  return extractValue(object, fields, defaultValue)
}
