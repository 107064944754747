import React, { useState, useEffect } from 'react'
import { Line, Bar } from 'react-chartjs-2'
import { DashboardService, FilterGroup, FilterLabelColumn } from 'services/DashboardService'
import { ErrorHandler } from 'services/ErrorService'
import { XtTasksCard } from 'tasks/components/task-card/tasks-card'
import { getIntervalDate } from 'tasks/components/task-card/task-card.utils'
import Card from '../components/Card'
import { IDashboardState } from './home.types'
import { converDeliveryData, convertSalesData } from './home.utils'
import styles from './home.module.scss'

export const Home = () => {
  const [state, setState] = useState<IDashboardState>()
  async function init() {
    try {
      const [byItem, byCust, delivery] = await Promise.all([
        DashboardService.getSales(FilterGroup.ByItem, FilterLabelColumn.Item),
        DashboardService.getSales(FilterGroup.ByCust, FilterLabelColumn.Cust),
        DashboardService.getDelivery(),
      ])
      setState({
        byItem: convertSalesData(byItem),
        byCust: convertSalesData(byCust),
        delivery: converDeliveryData(delivery),
      })
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }
  useEffect(() => void init(), [])

  const chartData = [
    {
      title: 'Sales by Item',
      data: state?.byItem,
    },
    {
      title: 'Sales by Customer',
      data: state?.byCust,
    },
    {
      title: 'On-Time Delivery',
      data: state?.delivery,
      line: true,
    },
  ]
  return (
    <section className={styles.homeContent}>
      <div className={styles.col}>
        {' '}
        <XtTasksCard overdue date={new Date()} />
        {getIntervalDate(4).map((date) => (
          <XtTasksCard date={date} />
        ))}
      </div>
      <div className={styles.chartContent}>
        {chartData.map(({ title, data, line }) => {
          const Chart = line ? Line : Bar
          return (
            <div key={title} className={styles.col}>
              <Card
                statsIcon="far fa-clock"
                title={title}
                category="This Year"
                stats="Updated Now"
                content={
                  <div id="chartPreferences" className="chart">
                    {data && <Chart data={data} />}
                  </div>
                }
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}
