import * as React from 'react'
import { FC, useCallback, useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useMediaQuery, Checkbox, FormControlLabel } from '@material-ui/core'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorHandler } from 'services/ErrorService'
import { ToastService } from 'services/ToasterService'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { IItemDeletionState, IRoutingDetailsTableItem, RoutingDetailsAction } from 'routing/routingDetails/RoutingDetails.types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { confirmationMessages, xsMq } from '../../common/constants'
import { XtButton } from '../../components/xtButton/XtButton'
import { SvgIcon } from '../../components/svgIcon/SvgIcon'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { IItemsSearchOption, ItemsSearch } from '../../components/itemsSearch/ItemsSearch'
import { IRoutingItemsFilters, RoutingService } from '../RoutingService'
import { IRouting, RoutingItemMode } from '../Routing.types'
import { RoutingDetailsForm } from '../routingDetails/routingDetailsForm/RoutingDetailsForm'
import { RoutingItem } from '../routingItem/RoutingItem'
import { cls } from '../../common/utils'
import * as styles from '../routingDetails/RoutingDetails.module.scss'
import { IRoutingItemDialogState, IRoutingItemData, IResetData } from './RoutingNew.types'

import { routingDetailsColumns, RoutingDetailsFormField } from '../routingDetails/RoutingDetails.constants'
import { routingDetailsValidationSchema } from '../routingDetails/validation-schema'
import {
  defineFormData,
  defaultRoutingItemDialogState,
  defineAvailableActions,
  defaulDeletionState,
  normalizeData,
} from '../routingDetails/RoutingDetails.utils'
import { useTable } from '../../common/hooks/useTable'
import { XtList } from '../../components/list/list'
import { FormCheckboxLabel } from '../../common/utils/form/form.components'

// TODO redirect to routing-list if mobile version
export const RoutingNew: FC = () => {
  const isMobile = useMediaQuery(xsMq)
  const history = useHistory()
  // TODO add loadingItems
  const formMethods = useForm({
    defaultValues: defineFormData(null),
    resolver: yupResolver(routingDetailsValidationSchema),
    mode: 'onBlur',
  })
  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    getValues,
  } = formMethods
  const [itemNumber] = watch([RoutingDetailsFormField.ItemNumber])

  const isEditField = Boolean(itemNumber)
  const [routingState, setRoutingState] = useState<IRouting | null>(null)
  const [routingItemDialogState, setRoutingItemDialogState] = useState<IRoutingItemDialogState>(defaultRoutingItemDialogState)
  const [deletionState, setDeletionState] = useState<IItemDeletionState>(defaulDeletionState)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaulDeletionState), [])
  const closeRoutingItemModal = useCallback(() => setRoutingItemDialogState(defaultRoutingItemDialogState), [])

  const fetchRoutingItems = useCallback(
    async (filters: IRoutingItemsFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IRoutingDetailsTableItem>> => {
      const selectedItemNumber = getValues(RoutingDetailsFormField.ItemNumber)
      if (selectedItemNumber) {
        const { total, data } = await RoutingService.getAllItems(selectedItemNumber, filters, paginationParams)
        return {
          data: normalizeData(data),
          total,
        }
      }
      return {
        data: [],
        total: 0,
      }
    },
    [getValues]
  )

  const { state, setLoading, refresh, filter, pagination, setData } = useTable({ showExpired: false, showFuture: false }, fetchRoutingItems)

  const openRoutingItem = (itemId: number, selectedMode: RoutingItemMode) => {
    const routingItem = state.data.find(({ id, sequence_number }) => sequence_number ?? id === itemId)
    setRoutingItemDialogState({ open: true, routingItem: routingItem ?? null, mode: selectedMode })
  }

  const resetData = (data: IResetData) => {
    setRoutingState(data?.routing ?? null)
    reset(data.form)
    void filter({ showExpired: false, showFuture: false })
  }

  const onItemChange = useCallback<(item: IItemsSearchOption | null) => Promise<void>>(
    async (item) => {
      const formData = { ...defineFormData(null), item_number: item?.item_number ?? '' }
      if (!item?.item_number) {
        resetData({ form: formData })
        return
      }
      try {
        setLoading(true)
        const routingData = await RoutingService.get(item.item_number, false, false)
        resetData({ routing: routingData, form: defineFormData(routingData) })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        resetData({ form: formData })
      }
    },
    [reset, setValue]
  )

  useEffect(() => void refresh(), [itemNumber])

  const deleteRoutingItem = async () => {
    closeConfirmationDialog()
    if (!routingState?.item_number || !deletionState.itemId) {
      const itemIndex = state.data.findIndex((item) => item.id === deletionState.itemId)
      setData([...state.data.slice(0, itemIndex), ...state.data.slice(itemIndex + 1)])
      return
    }
    try {
      setLoading(true)
      await RoutingService.deleteItem(itemNumber, deletionState.itemId)
      await refresh()
      setLoading(false)
      ToastService.showSuccess(`Routing Item has been deleted.`)
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }
  const expireRoutingItem = async (id: number) => {
    if (!routingState?.item_number) {
      return
    }
    try {
      setLoading(true)
      await RoutingService.expireItem(itemNumber, id)
      ToastService.showSuccess(`Routing ${itemNumber} has been updated.`)
      await refresh()
      setLoading(false)
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }

  const handleAction = useCallback<(item: IRoutingDetailsTableItem, action: RoutingDetailsAction) => void>(
    ({ id }, action: RoutingDetailsAction) => {
      switch (action) {
        case RoutingDetailsAction.View:
          return openRoutingItem(id, RoutingItemMode.View)
        case RoutingDetailsAction.Edit:
          return openRoutingItem(id, RoutingItemMode.Edit)
        case RoutingDetailsAction.Expire:
          return expireRoutingItem(id)
        case RoutingDetailsAction.Delete:
          return setDeletionState({ itemId: id, confirmationOpen: true })

        default:
          return openRoutingItem(id, RoutingItemMode.View)
      }
    },
    [state.data, routingState, itemNumber, routingItemDialogState.routingItem]
  )
  const openNewRoutingItemDialog = useCallback(
    () => setRoutingItemDialogState({ open: true, routingItem: null, mode: RoutingItemMode.New }),
    []
  )

  async function onFiltersChange({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    await filter({ ...state.filters, [target.name]: target.checked })
  }

  const onCancel = (): void => {
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }
    history.push('/products/routing')
  }

  const onRoutingSubmit = async (formValues: IRouting) => {
    try {
      setLoading(true)
      const values = {
        ...formValues,
        routing_items: formValues.routing_items ?? [],
      }
      if (routingState?.item_number) {
        const updatedValue = { ...routingState, ...values }
        await RoutingService.update(updatedValue)
        ToastService.showSuccess(`Routing ${itemNumber} has been updated.`)
      } else {
        const createValue = {
          ...defineFormData(null),
          ...values,
          item_number: itemNumber,
          routing_items: state.data.map(({ id: _, ...other }) => ({ ...other })) ?? [],
        }
        await RoutingService.create(createValue)
        ToastService.showSuccess(`Routing Item has been created.`)
      }
      setLoading(false)
      history.push('/products/routing')
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }

  const createRoutingItem = (entry: IRoutingItemData) => {
    const newItem = { ...entry, id: state.data.length ?? 0 }
    setData(normalizeData([...state.data, newItem]))
    closeRoutingItemModal()
  }
  const updateRoutingItem = (entry: IRoutingItemData) => {
    const itemIndex = state.data.findIndex(({ id }) => id === entry.id)
    setData(normalizeData([...state.data.slice(0, itemIndex), entry, ...state.data.slice(itemIndex + 1)]))
    closeRoutingItemModal()
  }

  const handleActionRequest = async (routingItemData: IRoutingItemData, action: RoutingItemMode) => {
    try {
      setLoading(true)
      if (action === RoutingItemMode.Edit) {
        await RoutingService.updateItem(itemNumber, routingItemData)
        ToastService.showSuccess(`Routing Item has been updated.`)
      } else if (action === RoutingItemMode.New) {
        await RoutingService.createItem(itemNumber, routingItemData)
        ToastService.showSuccess(`Routing Item has been created.`)
      }
      await refresh()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      ErrorHandler.handleError(error)
    }
    closeRoutingItemModal()
  }

  const onRoutingItemSubmit = (routingItemData: IRoutingItemData, modeRoutingItem: RoutingItemMode) => {
    if (routingState?.item_number) {
      return handleActionRequest(routingItemData, modeRoutingItem)
    }
    switch (modeRoutingItem) {
      case RoutingItemMode.New:
        return createRoutingItem(routingItemData)
      case RoutingItemMode.Edit:
        return updateRoutingItem(routingItemData)
      default:
        return null
    }
  }

  const handleRoutingItemClick = useCallback<(item: IRoutingDetailsTableItem) => void>(
    ({ id }) => openRoutingItem(id, RoutingItemMode.View),
    [itemNumber]
  )

  const actions = useMemo(() => defineAvailableActions(!routingState, false, false), [routingState])

  return (
    <main className={cls(styles.routingDetailsContent, 'xt-content')}>
      <div>
        <XtConfirmationDialog
          open={deletionState.confirmationOpen}
          message={confirmationMessages.deleted}
          title="Delete Routing Item"
          confirmationButtonLabel="Delete"
          onConfirm={deleteRoutingItem}
          onClose={closeConfirmationDialog}
        />
        <RoutingItem
          loading={state.loading}
          onSubmit={onRoutingItemSubmit}
          itemNumber={itemNumber}
          close={closeRoutingItemModal}
          state={routingItemDialogState}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onRoutingSubmit)}>
            <div className={cls(styles.routingDetailsHeaderSection, 'xt-section-border')}>
              <Controller
                name={RoutingDetailsFormField.ItemNumber}
                control={control}
                render={({ fieldState: { error } }) => (
                  <ItemsSearch
                    error={error?.message}
                    disabled={state.loading}
                    onChange={onItemChange}
                    className={styles.routingNewSearch}
                  />
                )}
              />
              <div className={styles.routingDetailsButtonSection}>
                <SvgIcon hidden={isMobile} iconId={SvgIconIds.PRINT} />
                <XtButton disabled={isSubmitting} label="Cancel" onClick={onCancel} />
                <XtButton
                  hidden={isMobile}
                  label="Save"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isDirty || isMobile || !itemNumber}
                />
              </div>
            </div>
            <RoutingDetailsForm leadTime={routingState?.lead_time ?? 0} disabled={isMobile || !isEditField} />
            <div hidden={isMobile} className={styles.routingDetailsTableFilters}>
              <FormControlLabel
                disabled={isSubmitting || isMobile || !isEditField || !routingState?.item_number}
                control={
                  <Checkbox
                    color="primary"
                    checked={state.filters.showExpired}
                    disabled={!routingState?.item_number}
                    onChange={onFiltersChange}
                    name={RoutingDetailsFormField.ShowExpired}
                  />
                }
                label="Show Expired Operations"
              />
              <FormControlLabel
                disabled={isSubmitting || isMobile || !isEditField || !routingState?.item_number}
                control={
                  <Checkbox
                    color="primary"
                    checked={state.filters.showFuture}
                    onChange={onFiltersChange}
                    name={RoutingDetailsFormField.ShowFuture}
                  />
                }
                label="Show Future Operations"
              />
              <FormCheckboxLabel
                label="Close work order when full qty. posted"
                disabled={isSubmitting || isMobile || !isEditField}
                name={RoutingDetailsFormField.CloseWorkorder}
                control={control}
              />

              <div hidden={isMobile} className={styles.routingDetailsAddItemButtonContainer}>
                <XtButton
                  onClick={openNewRoutingItemDialog}
                  disabled={!isEditField || state.loading}
                  icon={SvgIconIds.ADD_CIRCLE}
                  label=""
                  className={styles.routingDetailsAddItemButton}
                />
              </div>
            </div>
          </form>
        </FormProvider>
        <XtList
          className={styles.routingDetailsTable}
          pagination={routingState ? pagination : undefined}
          actions={actions}
          isMobile={isMobile}
          data={state.data}
          loading={state.loading}
          onAction={handleAction}
          onRowClick={handleRoutingItemClick}
          columns={routingDetailsColumns}
        />
      </div>
    </main>
  )
}
