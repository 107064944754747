import { Observable } from 'rxjs'
import { IShipment } from 'shipments/shipments.types'
import { ICustomer } from '../../../customers/customers.types'
import { IXtAutocompleteOption } from '../../../components/xtAutocomplete/XtAutocomplete.types'
import { HoldTypeField as HoldTypeEnum, ISalesOrder, ISalesOrderValidation } from '../../sales-orders.types'
import { IFormStateChanges } from '../../../common/hooks/form/form.types'

export type ShipmentOption = IXtAutocompleteOption & IShipment & { addressLine1: string; addressLine2: string }

export interface ISalesOrderMainParams extends ISalesOrderValidation {
  data: SalesOrderMainInput | null
  disabled: boolean
  customerDisabled: boolean
  resetObservable: Observable<void>
  onChange(changes: IFormStateChanges<SalesOrderMainInput>): void
}

export enum SalesOrderMainField {
  OrderNumber = 'order_number',
  OrderDate = 'order_date',
  HoldType = 'hold_type',
  Customer = 'customer',
  CustomerName = 'customer_name',
  CustomerPONumber = 'customer_po_number',
  ShipTo = 'ship_to_option',
  ShipToNumber = 'ship_to_number',
  ShipToName = 'ship_to_name',
  ShipVia = 'ship_via',
  Site = 'site',
}

export type SalesOrderMainInput = Pick<
  ISalesOrder,
  | SalesOrderMainField.OrderNumber
  | SalesOrderMainField.OrderDate
  | SalesOrderMainField.HoldType
  | SalesOrderMainField.CustomerPONumber
  | SalesOrderMainField.CustomerName
  | SalesOrderMainField.ShipVia
  | SalesOrderMainField.Site
> & {
  [SalesOrderMainField.ShipTo]: ShipmentOption | null
  [SalesOrderMainField.ShipToName]: string | null
  [SalesOrderMainField.Customer]: CustomerData | null
  customer_number?: string
}

export interface ISalesOrderMainForm {
  [SalesOrderMainField.OrderNumber]: string | null
  [SalesOrderMainField.OrderDate]: Date
  [SalesOrderMainField.HoldType]: HoldTypeEnum
  [SalesOrderMainField.CustomerPONumber]: string | null
  [SalesOrderMainField.CustomerName]: string | null
  [SalesOrderMainField.ShipToName]: string | null
  [SalesOrderMainField.Customer]: CustomerOption | null
  [SalesOrderMainField.ShipTo]: ShipmentOption | null
  [SalesOrderMainField.ShipVia]: IXtAutocompleteOption | null
  [SalesOrderMainField.Site]: IXtAutocompleteOption | null
}

export type CustomerData = Pick<
  ICustomer,
  'customer_number' | 'customer_name' | 'billing_contact' | 'correspond_contact' | 'allow_free_form_shipto' | 'allow_free_form_billto'
>

export type CustomerOption = IXtAutocompleteOption & CustomerData
