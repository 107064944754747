import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { XtTable } from '../table/table'
import { XtCardList } from '../card-list/card-list'
import { IActionTableParams, TableActionHandler } from '../../common/types/table.types'
import { IObjectWithId } from '../../common/common.types'
import { ICardData } from '../card-list/card-list.types'
import { IXtTableColumn } from '../table/table-head/table-head.types'
import { calculateActionsWidth, IXtTableCellAction, XtTableCellActions } from '../xtTableCellActions/XtTableCellActions'
import { getFieldValue } from '../table/table.utils'
import { ITableRow } from '../table/table.types'
import { ITask } from '../../tasks/tasks.types'
import { TaskListAction } from '../../tasks/task-list/task-list.types'

function convertColumnsToMobileData<TData extends ITableRow>(items: TData[], columns: IXtTableColumn<TData>[]): ICardData<TData>[] {
  return items.map((item) => ({
    data: item,
    fields: columns
      .filter(({ field }) => !!field)
      .map(({ field, headerName, converter }) => {
        const value = getFieldValue<TData>(item, field!, converter)
        return { value, label: headerName ?? '' }
      }),
  }))
}

function shouldDisplayActions<TData, Action>(onAction?: TableActionHandler<TData, Action>, actions?: IXtTableCellAction[]): boolean {
  return typeof onAction === 'function' && !!actions?.length
}

function chooseActionsToDisplay(actions?: IXtTableCellAction[], item?: ITask): IXtTableCellAction[] {
  return item?.status === 'Completed'
    ? actions!.filter((action: IXtTableCellAction) => action.name !== TaskListAction.MarkAsCompleted)
    : actions!
}

function createActionColumn<TData extends IObjectWithId | ITask, Action>(
  onAction?: TableActionHandler<TData, Action>,
  actions?: IXtTableCellAction[]
): IXtTableColumn<TData> | null {
  if (!shouldDisplayActions(onAction, actions)) {
    return null
  }
  return {
    id: 'actions',
    renderCell: ({ item }) => (
      <XtTableCellActions
        className="xt-table-cell-actions"
        actions={chooseActionsToDisplay(actions, item as ITask)}
        onClick={(action) => onAction!(item, (action as unknown) as Action)} // TODO fix type casting
      />
    ),
    flex: `0 0 ${calculateActionsWidth(actions!.length)}px`,
    width: calculateActionsWidth(actions!.length),
    headerName: 'Action',
    sticky: true,
    align: 'center',
  }
}

export function XtList<TData extends ITableRow, Action>({
  data,
  loading = false,
  pagination,
  isMobile = false,
  onRowClick,
  columns,
  onAction,
  actions,
  convertToMobileData,
  className,
}: IActionTableParams<TData, Action>): React.ReactElement {
  const actionColumns = useMemo<IXtTableColumn<TData>[]>(() => {
    const actionColumn = createActionColumn(onAction, actions)
    return actionColumn ? [...columns, actionColumn] : [...columns]
  }, [columns, onAction, actions])

  const loadMoreData = useCallback<() => Promise<void>>(async () => pagination?.loadMore(pagination.page + 1), [pagination])

  const mobileData = useMemo(
    () => (typeof convertToMobileData === 'function' ? convertToMobileData(data) : convertColumnsToMobileData(data, columns)),
    [data, columns, convertToMobileData]
  )

  return isMobile ? (
    <XtCardList
      canLoadMore={pagination?.canLoadMore ?? false}
      loading={loading}
      loadMore={loadMoreData}
      onCardClick={onRowClick}
      data={mobileData}
    />
  ) : (
    <XtTable
      loading={loading}
      onRowClick={onRowClick}
      rows={data}
      columns={actionColumns}
      pagination={pagination}
      className={className}
      rowClassName="xt-grid-table-button-cell"
    />
  )
}
