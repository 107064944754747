import { dateConverter } from 'common/utils/date.utils'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { FilterType, IFilter } from 'components/filter/filter.types'
import { FilterFieldName } from 'sales-orders/sales-orders.types'
import { SalesOrderMode } from 'sales-orders/sales-orders-new/sales-orders-new.types'
import { SalesOrderRow, SalesOrdersListAction } from './sales-orders-list.types'

export const tableColumns: IXtTableColumn<SalesOrderRow>[] = [
  {
    id: 'order_number',
    field: 'order_number',
    headerName: 'Order #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'sale_type',
    field: 'sale_type',
    headerName: 'Sale Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'ship_to',
    field: 'ship_to',
    headerName: 'Ship To',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'customer_po_number',
    field: 'customer_po_number',
    headerName: 'Customer Purchase #',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'order_date',
    field: 'order_date',
    headerName: 'Ordered',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },

  {
    id: 'scheduled_date',
    field: 'scheduled_date',
    headerName: 'Scheduled Date',
    flex: '1 0 200px',
    width: 200,
    converter: dateConverter,
  },
  {
    id: 'order_total',
    field: 'order_total',
    headerName: 'Total',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'ordermargin',
    field: 'ordermargin',
    headerName: 'Margin',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'status',
    field: 'status', // TODO use the correct field
    headerName: 'Status',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'hold_type',
    field: 'hold_type',
    headerName: 'Hold Type',
    flex: '1 0 160px',
    width: 160,
  },
]

export const salesOrderActions: IXtTableCellAction[] = [
  {
    name: SalesOrdersListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: SalesOrdersListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: SalesOrdersListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: SalesOrdersListAction.Copy,
    label: 'Copy',
    icon: SvgIconIds.COMPARE_ARROW, // TODO use the correct icon
  },
  {
    name: SalesOrdersListAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]

export const salesOrderActionsView: IXtTableCellAction[] = [
  {
    name: SalesOrdersListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]
export const orderFilters: IFilter[] = [
  {
    type: FilterType.Checkbox,
    label: 'Show Closed',
    fieldName: FilterFieldName.ShowClosed,
  },
  {
    type: FilterType.Date,
    label: 'Start Date ',
    fieldName: FilterFieldName.StartDate,
  },
  {
    type: FilterType.Date,
    label: 'End Date',
    fieldName: FilterFieldName.EndDate,
  },
]

export const defaultOrderDialogState = { orderNumber: '', mode: SalesOrderMode.View, open: false }
