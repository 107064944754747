import { loadSalesReps, loadTerms } from 'common/utils'

import {
  ISalesOrderShippingAndSalesForm,
  ISalesOrderShippingAndSalesInput,
  SalesOrderShippingAndSalesFormDefaultValues,
  SalesOrderShippingAndSalesFormField,
} from './sales-order-shipping-and-sales.types'
import { IPaginationData } from '../../../../common/common.types'
import { IXtAutocompleteOption } from '../../../../components/xtAutocomplete/XtAutocomplete.types'
import { defaultShippingAndSalesOptions } from './sales-order-shipping-and-sales.constants'
import {
  loadSaleTypes,
  loadShippingCharges,
  loadShippingForm,
  loadShippingZones,
  loadTaxZones,
} from '../../../../common/utils/document.utils'
import { dateToServerFormat } from '../../../../common/utils/date.utils'

export function defineShippingAndSalesFormData(
  data: ISalesOrderShippingAndSalesInput | null,
  defaultOptions: SalesOrderShippingAndSalesFormDefaultValues
): ISalesOrderShippingAndSalesForm {
  const { shipping_form, shipping_zone, shipping_charges, tax_zone, sales_rep, terms, sale_type } = defaultOptions
  return {
    [SalesOrderShippingAndSalesFormField.ShippingForm]: data?.shipping_form
      ? { id: data.shipping_form, label: data.shipping_form }
      : shipping_form,
    [SalesOrderShippingAndSalesFormField.Incoterm]: data?.fob ?? '',
    [SalesOrderShippingAndSalesFormField.ShipComplete]: data?.ship_complete ?? false,
    [SalesOrderShippingAndSalesFormField.PackDate]: data?.pack_date ? new Date(data?.pack_date) : new Date(),
    [SalesOrderShippingAndSalesFormField.SaleType]: data?.sale_type ? { id: data.sale_type, label: data.sale_type } : sale_type,
    [SalesOrderShippingAndSalesFormField.Terms]: data?.terms ? { id: data.terms, label: data.terms } : terms,
    [SalesOrderShippingAndSalesFormField.ShippingCharges]: data?.shipping_charges
      ? { id: data.shipping_charges.toString(), label: data.shipping_charges.toString() }
      : shipping_charges,
    [SalesOrderShippingAndSalesFormField.SalesRep]: data?.sales_rep ? { id: data.sales_rep, label: data.sales_rep } : sales_rep,
    [SalesOrderShippingAndSalesFormField.ShippingZone]: data?.shipping_zone
      ? { id: data.shipping_zone, label: data.shipping_zone }
      : shipping_zone,
    [SalesOrderShippingAndSalesFormField.TaxZone]: data?.tax_zone ? { id: data.tax_zone, label: data.tax_zone } : tax_zone,
  }
}

export function convertShippingAndSalesFormData(data: ISalesOrderShippingAndSalesForm): ISalesOrderShippingAndSalesInput {
  return {
    pack_date: dateToServerFormat(data.pack_date),
    sales_rep: data.sales_rep?.id ?? '',
    sale_type: data.sale_type?.id ?? '',
    ship_complete: data.ship_complete,
    shipping_form: data.shipping_form?.id ?? '',
    shipping_charges: data.shipping_charges?.id ?? '',
    shipping_zone: data.shipping_zone?.id ?? '',
    terms: data.terms?.id ?? '',
    tax_zone: data.tax_zone?.id ?? '',
    fob: data.fob ?? '',
  }
}

// TODO move to common?
function retrieveDefaultOptionFromResponse(
  request: PromiseSettledResult<IPaginationData<IXtAutocompleteOption> | undefined>
): IXtAutocompleteOption | null {
  if (request?.status !== 'fulfilled' || !request.value?.data.length) {
    return null
  }
  return request.value.data[0]
}

async function requestOptions(): Promise<Array<IXtAutocompleteOption | null>> {
  const options = await Promise.allSettled([
    loadSalesReps(1, 1),
    loadTaxZones(1, 1),
    loadShippingCharges(1, 1),
    loadShippingForm(1, 1),
    loadShippingZones(1, 1),
    loadTerms(1, 1),
    loadSaleTypes(1, 1),
  ])
  return options.map(retrieveDefaultOptionFromResponse)
}

export async function requestDefaultValues(): Promise<SalesOrderShippingAndSalesFormDefaultValues> {
  try {
    const [sales_rep, tax_zone, shipping_charges, shipping_form, shipping_zone, terms, sale_type] = await requestOptions()
    return { sales_rep, tax_zone, shipping_charges, shipping_form, shipping_zone, terms, sale_type }
  } catch (e) {
    return defaultShippingAndSalesOptions
  }
}
