import { IContact, PhoneRole } from 'contacts/contacts.types'
import { retrievePhone } from 'contacts/contacts.utils'
import { IShipmentFormValues, ShipmentAddressFormField, ShipmentFormField } from './shipment-dialog.types'
import { IShipment, PartialShipment } from '../shipments.types'
import { convertStateForRequest, CountryStates } from '../../common/utils/country-state.utils'
import { defineAutocompleteOption, parseAutocompleteOption } from '../../common/utils'

// TODO global utils?

export function defineShipmentFormData(data: IShipment | null, countryStates?: CountryStates): IShipmentFormValues {
  return {
    [ShipmentFormField.Number]: data?.shipto_number ?? '',
    [ShipmentFormField.Name]: data?.name ?? '',
    [ShipmentFormField.Default]: data?.default_flag ?? false,
    [ShipmentFormField.SalesRep]: defineAutocompleteOption(data?.sales_rep),
    [ShipmentFormField.ShipZone]: defineAutocompleteOption(data?.shipping_zone),
    [ShipmentFormField.TaxZone]: defineAutocompleteOption(data?.tax_zone),
    [ShipmentFormField.ShipVia]: defineAutocompleteOption(data?.ship_via),
    [ShipmentFormField.ShippingCharges]: defineAutocompleteOption(data?.shipping_charges),
    [ShipmentFormField.Site]: defineAutocompleteOption(data?.preferred_selling_site),
    [ShipmentFormField.ShippingForm]: defineAutocompleteOption(data?.shipping_form),
    [ShipmentFormField.Active]: data?.active ?? false,
    [ShipmentAddressFormField.Address1]: data?.shipto_address?.address1 ?? '',
    [ShipmentAddressFormField.Address2]: data?.shipto_address?.address2 ?? '',
    [ShipmentAddressFormField.Address3]: data?.shipto_address?.address3 ?? '',
    [ShipmentAddressFormField.City]: data?.shipto_address?.city ?? '',
    [ShipmentAddressFormField.Country]: countryStates?.initialCountry ?? null,
    [ShipmentAddressFormField.State]: countryStates?.initialState ?? '',
    [ShipmentAddressFormField.PostalCode]: data?.shipto_address?.postalcode ?? '',
    [ShipmentAddressFormField.AllowMarketing]: data?.shipto_address?.allow_marketing ?? false,
  }
}

export function convertToContactValues(contact: IContact) {
  return {
    contact_number: contact.contact_number,
    phone: retrievePhone(contact, PhoneRole.Office),
    mobile: retrievePhone(contact, PhoneRole.Mobile),
    honorific: contact.honorific,
    first: contact.first_name,
    middle: contact.middle_name,
    last: contact.last_name,
    suffix: contact.suffix,
    job_title: contact.job_title,
    email: contact.email,
  }
}

export function convertToShipmentValues(data: IShipmentFormValues, contact: IContact | null): PartialShipment {
  return {
    [ShipmentFormField.SalesRep]: parseAutocompleteOption(data?.sales_rep),
    [ShipmentFormField.ShipZone]: parseAutocompleteOption(data?.shipping_zone),
    [ShipmentFormField.TaxZone]: parseAutocompleteOption(data?.tax_zone),
    [ShipmentFormField.ShipVia]: parseAutocompleteOption(data?.ship_via),
    [ShipmentFormField.ShippingCharges]: parseAutocompleteOption(data?.shipping_charges),
    [ShipmentFormField.ShippingForm]: parseAutocompleteOption(data?.shipping_form),
    [ShipmentFormField.Site]: parseAutocompleteOption(data?.preferred_selling_site),
    [ShipmentFormField.Name]: data?.name ?? '',
    [ShipmentFormField.Number]: data?.shipto_number ?? '',
    [ShipmentFormField.Active]: data?.active ?? '',
    [ShipmentFormField.Default]: data?.default_flag ?? '',
    shipto_contact: contact ? convertToContactValues(contact) : null,
    shipto_address: {
      [ShipmentAddressFormField.Country]: parseAutocompleteOption(data?.country),
      [ShipmentAddressFormField.State]: convertStateForRequest(data?.state),
      [ShipmentAddressFormField.Address1]: data?.address1 ?? '',
      [ShipmentAddressFormField.Address2]: data?.address2 ?? '',
      [ShipmentAddressFormField.Address3]: data?.address3 ?? '',
      [ShipmentAddressFormField.City]: data?.city ?? '',
      [ShipmentAddressFormField.PostalCode]: data?.postalcode ?? '',
      [ShipmentAddressFormField.AllowMarketing]: data?.allow_marketing ?? false,
    },
  }
}

export const initialContactState = {
  initialContact: null,
  contact: null,
  isDirty: false,
}
