import { useCallback, useEffect, useState } from 'react'
import { globalConstants } from 'common/constants'
import { filterByField } from 'common/utils/array.utils'
import { DocumentType, IAttachedDocument, IAttachedDocumentWithFile, IAttachedDocumentWithOptionalFile } from 'documents/documents.types'
import { IXtAttachedDocumentsPaginationParams } from 'components/documents/documents.types'
import { DocumentsService } from 'documents/documents.service'
import { IPaginationParams } from 'common/common.types'
import { ErrorHandler } from 'services/ErrorService'

export interface IDocumentsHookState {
  documents: IAttachedDocumentWithOptionalFile[]
  loading: boolean
  meta: {
    page: number
    limit: number
    total: number
  }
  isDirty: boolean
}

export interface IDocumentsHook extends IXtAttachedDocumentsPaginationParams {
  saveDocument(document: IAttachedDocumentWithFile): Promise<boolean>
  deleteDocument(document: IAttachedDocument): Promise<void>
  setDocuments(documents: IAttachedDocument[]): void
  getUnsavedDocuments: () => IAttachedDocumentWithFile[]
  loadMore(): Promise<void>
  canLoadMore(): boolean
  documents: IAttachedDocumentWithOptionalFile[]
  username: string
  creationEnabled: boolean
  isDirty: boolean
}

const defaultDocumentsHookState = {
  documents: [],
  loading: false,
  meta: { page: 1, limit: globalConstants.paginationLimit, total: 0 },
  newDocuments: [],
  isDirty: false,
}

export function useDocuments(
  source: DocumentType,
  sourceNumber: string | null | undefined,
  creationEnabled: boolean = true
): IDocumentsHook {
  const [state, setState] = useState<IDocumentsHookState>(defaultDocumentsHookState)

  const requestDocuments = useCallback<(paginationParams: IPaginationParams) => Promise<void>>(
    async ({ limit, page }) => {
      if (!sourceNumber) {
        setState(defaultDocumentsHookState)
        return
      }
      try {
        setState((prevState) => ({ ...prevState, loading: true }))
        const { data, total } = await DocumentsService.getAttachedForSource(source, sourceNumber, { page, limit })
        setState((prevState) => ({
          ...prevState,
          documents: filterByField([...prevState.documents, ...data], 'id'),
          meta: {
            total,
            page,
            limit,
          },
          loading: false,
        }))
      } catch (e) {
        ErrorHandler.handleError(e)
        setState((prevState) => ({ ...prevState, loading: false }))
      }
    },
    [source, sourceNumber]
  )

  const saveDocument = useCallback(
    async (document: IAttachedDocumentWithFile): Promise<boolean> => {
      if (!sourceNumber) {
        const postponedDocument = { ...document, name: document.name || document.file.name }
        setState((prev) => ({
          ...prev,
          documents: [...prev.documents, postponedDocument],
          isDirty: true,
        }))
        return true
      }

      setState((prevState) => ({ ...prevState, loading: true }))
      try {
        await DocumentsService.createFileForSource(document, source, sourceNumber!)

        const { limit, page } = state.meta
        const itemsCount = page * limit + 1
        const { data } = await DocumentsService.getAttachedForSource(source, sourceNumber!, { limit: itemsCount, page: 1 })
        setState((prevState) => ({ ...prevState, documents: data }))
        return true
      } catch (e) {
        ErrorHandler.handleError(e)
        setState((prevState) => ({ ...prevState, loading: false }))
        return false
      }
    },
    [source, sourceNumber, state]
  )

  const deleteDocument = useCallback(
    async (document: IAttachedDocument): Promise<void> => {
      if (!sourceNumber) {
        setState((prevState) => {
          const updatedDocuments = prevState.documents.filter((iteratedDocument) => iteratedDocument.id !== document.id)
          return {
            ...prevState,
            documents: updatedDocuments,
            isDirty: updatedDocuments.length > 0, // TODO: improve isDirty handling
          }
        })
      } else {
        setState((prevState) => ({ ...prevState, loading: true }))
        try {
          await DocumentsService.detach({ assignments: [{ target_type: document.target_type, id: document.id }] })
          setState((prevState) => ({ ...prevState, loading: false, documents: state.documents.filter((doc) => doc.id !== document.id) }))
        } catch (e) {
          ErrorHandler.handleError(e)
          setState((prevState) => ({ ...prevState, loading: false }))
        }
      }
    },
    [sourceNumber, state]
  )

  const canLoadMore = useCallback<() => boolean>(() => {
    const { limit, page, total } = state.meta
    return limit * page < total
  }, [state])

  const loadMore = useCallback<() => Promise<void>>(async () => {
    const { limit, page } = state.meta
    if (state.loading || !canLoadMore()) {
      return
    }
    await requestDocuments({ limit, page: page + 1 })
  }, [state, canLoadMore, requestDocuments])

  const setDocuments: (comments: IAttachedDocument[]) => void = (newDocuments) =>
    setState((prevState) => ({ ...prevState, documents: newDocuments }))

  useEffect(() => {
    void requestDocuments({ limit: state.meta.limit, page: 1 })
  }, [source, sourceNumber])

  const getUnsavedDocuments = useCallback(() => state.documents.filter((document) => document.file) as IAttachedDocumentWithFile[], [
    state.documents,
  ])

  return {
    saveDocument,
    deleteDocument,
    setDocuments,
    loadMore,
    canLoadMore,
    documents: state.documents,
    username: '',
    creationEnabled,
    total: 1,
    isDirty: state.isDirty,
    getUnsavedDocuments,
  }
}
