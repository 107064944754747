import * as React from 'react'
import { FC, useState, useCallback } from 'react'
import { saveAs } from 'file-saver'
import { Dialog } from '@material-ui/core'
import { IAttachedDocument } from 'documents/documents.types'
import { DocumentsService } from 'documents/documents.service'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import { IAttachedDocumentsDescription, IAttachedDocumentsState } from './documents.types'
import { AttachedDocumentAction } from './documents-list/document-list.constants'
import { SvgIconIds } from '../svgIcon/SvgIcon.types'
import styles from './documents.module.scss'
import { XtDocumentList } from './documents-list/document-list'
import { XtDocumentDialog } from './document-dialog/document-dialog'
import { makeNewDocumentState } from './documents.utils'

export const XtDocuments: FC<IAttachedDocumentsDescription> = React.memo(
  ({ documents, onDocumentCreate, onDocumentDelete, loadMore, canLoadMore, isViewMode, usedOnFilter, isMobile = false }) => {
    const [state, setState] = useState<IAttachedDocumentsState>({
      editedDocument: null,
      isDialogOpen: false,
      isLoading: false,
      previewBlobUrl: null,
    })

    const onCloseDialog = useCallback(() => setState({ ...state, editedDocument: null }), [setState, state])

    const removeDocument = useCallback(
      async (document: IAttachedDocument): Promise<void> => {
        setState({ ...state, isLoading: true })
        try {
          await onDocumentDelete(document)
        } catch (error) {
          ErrorHandler.handleError(error)
        }
        setState({ ...state, isLoading: false })
      },
      [setState, state, onDocumentDelete]
    )

    const attachNewDocument = useCallback((): void => {
      setState(makeNewDocumentState())
    }, [setState])

    const previewDocument = async (file_link: string): Promise<void> => {
      // TODO handle file loading in the UI (show preloader)
      const documentBlob = await DocumentsService.loadFileData(file_link)
      const url = URL.createObjectURL(documentBlob)
      setState((prev) => ({ ...prev, previewBlobUrl: url }))
    }

    const downloadDocument = async (filename: string, file_link: string): Promise<void> => {
      const document = await DocumentsService.loadFileData(file_link)
      saveAs(document, filename)
    }

    const onPreviewClose = useCallback(() => {
      if (state.previewBlobUrl) {
        URL.revokeObjectURL(state.previewBlobUrl)
      }
      setState((prev) => ({ ...prev, previewBlobUrl: null }))
    }, [state])

    const onAction = useCallback(
      (item: IAttachedDocument, action: AttachedDocumentAction): void => {
        switch (action) {
          case AttachedDocumentAction.Delete:
            void removeDocument(item)
            break
          case AttachedDocumentAction.Preview:
            void previewDocument(item.file_link!)
            break
          case AttachedDocumentAction.Download:
            void downloadDocument(item.name, item.file_link!)
            break
          default:
            throw new Error('Incorrect action type')
        }
      },
      [removeDocument]
    )

    return (
      <div>
        {state.previewBlobUrl && (
          <Dialog open={Boolean(state.previewBlobUrl)} onClose={onPreviewClose}>
            <img src={state.previewBlobUrl} alt="Preview" />
          </Dialog>
        )}
        {state.editedDocument && (
          <XtDocumentDialog
            isMobile={isMobile}
            usedOnFilter={usedOnFilter}
            open={state.isDialogOpen}
            onClose={onCloseDialog}
            onCreate={onDocumentCreate}
            editedDocument={state.editedDocument}
          />
        )}
        <XtButton
          label="Attach New File"
          hidden={isViewMode}
          onClick={attachNewDocument}
          className={styles.documentsAddButton}
          icon={SvgIconIds.ATTACH}
        />
        <XtDocumentList
          documents={documents}
          loadMore={loadMore}
          canLoadMore={canLoadMore}
          isMobile={isMobile}
          isLoading={state.isLoading}
          onAction={onAction}
        />
      </div>
    )
  }
)
