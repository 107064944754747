import { useCallback, useEffect, useRef } from 'react'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { useTable } from '../../../../common/hooks/useTable'
import { ISalesOrderLineItemsFilter, SalesOrderLineItem } from '../sales-order-line-items.types'
import { convertToLineItem, fetchLineItems, generateLineNumber } from '../sales-order-line-items.utils'
import { ISalesOrder } from '../../../sales-orders.types'
import { SalesOrdersService } from '../../../sales-orders.service'
import { ErrorHandler } from '../../../../services/ErrorService'
import { SalesOrderLineItemNewForm } from '../sales-order-line-item-new/sales-order-line-item-new.types'
import { convertToLineItemPayload, processQuickAddItem } from '../sales-order-line-item-new/sales-order-line-item-new.utils'
import { ISalesOrderLineItemDetailsForm } from '../sales-order-line-item-details/sales-order-line-item-details.types'
import {
  convertDetailsDataToLineItemPayload,
  convertDetailsToSalesOrderItem,
} from '../sales-order-line-item-details/sales-order-line-item-details.utils'
import { filterByField } from '../../../../common/utils/array.utils'
import { ISalesOrderLineItemsHook, ISalesOrderLineItemsHookChange } from './sales-order-line-items-hook.types'

export function useSalesOrderLineItems(
  salesOrder: ISalesOrder | undefined | null,
  validationObservable: Observable<void>
): ISalesOrderLineItemsHook {
  const isDirtySubject = useRef<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false))

  const tableState = useTable<SalesOrderLineItem, ISalesOrderLineItemsFilter>(
    { showCancelledLines: false, orderNumber: salesOrder?.order_number ?? null },
    fetchLineItems
  )

  const {
    state: { data: items, filters },
    setData,
    filter,
    onChange$,
  } = tableState

  const stateObservable$ = useRef<Observable<ISalesOrderLineItemsHookChange>>(
    combineLatest([onChange$, isDirtySubject.current.asObservable()]).pipe(
      map(([lineItems, isDirty]) => ({
        isDirty,
        isValid: !!lineItems.length,
        items: lineItems,
      }))
    )
  )

  const init: (orderNumber: string | undefined) => Promise<void> = async (orderNumber) => {
    if (!orderNumber) {
      return
    }
    await filter({ showCancelledLines: false, orderNumber })
  }

  useEffect(() => void init(salesOrder?.order_number), [salesOrder])

  useEffect(() => {
    const sub = validationObservable.subscribe(() => isDirtySubject.current.next(true))

    return () => sub.unsubscribe()
  }, [validationObservable])

  const remove = useCallback<(itemId: string) => Promise<void>>(
    async (itemId) => {
      try {
        if (!salesOrder) {
          const itemIndex = items.findIndex(({ id }) => id === itemId)
          setData([...items.slice(0, itemIndex), ...items.splice(itemIndex + 1)])
          return
        }
        await SalesOrdersService.deleteItem(salesOrder.order_number, itemId)
        isDirtySubject.current.next(true)
        await filter({ ...filters, orderNumber: salesOrder?.order_number ?? null })
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [items, setData, filter, salesOrder, filters]
  )

  const save = useCallback<(values: SalesOrderLineItemNewForm) => Promise<void>>(
    async (formValues) => {
      if (!salesOrder) {
        const lineItemNumber = generateLineNumber(items.length)
        const lineItem = processQuickAddItem(lineItemNumber, formValues)
        const lineItemRow = convertToLineItem(lineItem)
        setData([...items, lineItemRow])
      } else {
        const payload = convertToLineItemPayload(formValues)
        await SalesOrdersService.createItem(salesOrder.order_number, payload)
        await filter({ ...filters, orderNumber: salesOrder?.order_number ?? null })
      }
      isDirtySubject.current.next(true)
    },
    [items, filters, filter, setData, salesOrder]
  )

  const saveOrUpdate = useCallback<(values: ISalesOrderLineItemDetailsForm, lineNumber?: string) => Promise<void>>(
    async (formValues, lineNumber) => {
      try {
        if (!salesOrder) {
          const lineItemNumber = lineNumber ?? generateLineNumber(items.length)
          const lineItem = convertDetailsToSalesOrderItem(lineItemNumber, formValues)
          const lineItemRow = convertToLineItem(lineItem)
          setData(filterByField([...items, lineItemRow], 'line_number'))
          isDirtySubject.current.next(true)
          return
        }
        const itemPayload = convertDetailsDataToLineItemPayload(formValues)
        if (lineNumber) {
          await SalesOrdersService.updateItem(salesOrder.order_number, { ...itemPayload, line_number: lineNumber })
        } else {
          await SalesOrdersService.createItem(salesOrder.order_number, itemPayload)
        }
        isDirtySubject.current.next(true)
        await filter({ ...filters, orderNumber: salesOrder?.order_number ?? null })
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [items, filters, filter, setData, salesOrder]
  )

  const filterItems = useCallback<(showCancelled: boolean) => Promise<void>>(
    (showCancelledLines) => filter({ showCancelledLines, orderNumber: salesOrder?.order_number ?? null }),
    [filter, salesOrder]
  )

  const cancel: (orderNumber: string, lineNumber: string) => Promise<void> = async (orderNumber, lineNumber) => {
    try {
      await SalesOrdersService.cancelItem(orderNumber, lineNumber)
      await filterItems(filters.showCancelledLines)
      isDirtySubject.current.next(true)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const markAsDirty = useCallback<VoidFunction>(() => isDirtySubject.current.next(true), [])

  return {
    ...tableState,
    save,
    saveOrUpdate,
    remove,
    filterItems,
    cancel,
    markAsDirty,
    onStateChange$: stateObservable$.current,
  }
}
