import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { ErrorHandler } from 'services/ErrorService'
import { AuthService } from 'auth/auth.service'
import { useTable } from 'common/hooks/useTable'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { CheckboxLabel } from 'common/utils/form/form.components'
import { ITaskListFilters, TaskService } from 'tasks/tasks.service'
import { TaskDetailsMode } from 'tasks/task-details/task-details.types'
import { ToastService } from 'services/ToasterService'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { confirmationMessages, xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { XtQuickAddButton } from 'components/quickAddButton/QuickAddButton'
import { defaulDeletionState, TaskListActionsEditMode, TaskListActionsViewMode } from 'tasks/task-list/task-list.constants'
import { IDeletionState, TaskListAction } from 'tasks/task-list/task-list.types'
import { cls } from 'common/utils'
import { ITaskTableItem } from './task-list.types'
import { taskColumns } from './task-list.constants'
import * as styles from './task-list.module.scss'
import { normalizeData } from './task-list.utils'
import { TaskDetailsDialog } from '../task-details-dialog/task-details-dialog'

const fetchTasks = async (filters: ITaskListFilters, paginationParams: IPaginationParams): Promise<IPaginationData<ITaskTableItem>> => {
  const { total, data } = await TaskService.getAll(paginationParams, filters)

  return {
    data: normalizeData(data),
    total,
  }
}
export const defaultTaskDetailsDialogState = { id: null, mode: null, open: false }
export interface ITaskDetailsDialogState {
  id: number | null
  mode: TaskDetailsMode | null
  open: boolean
}
export interface ITaskList {
  disabled: boolean
  account_number?: string
  prospect_number?: string
}
export const TaskList: FC<ITaskList> = ({ disabled: disabledByInput, account_number, prospect_number }) => {
  const authUser = AuthService.getCurrentUser()
  const assignedto = authUser?.username ?? null
  const disabled = disabledByInput || (!account_number && !prospect_number)
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaulDeletionState)
  const [taskDetailsDialog, setTaskDetailsDialog] = useState<ITaskDetailsDialogState>(defaultTaskDetailsDialogState)
  const { state, setLoading, refresh, filter, pagination } = useTable(
    { assignedto, showcompleted: false, prospect: prospect_number, account_number },
    fetchTasks
  )

  const onShowCompletedChange = async (checked: boolean): Promise<void> => {
    await filter({ ...state.filters, showcompleted: checked })
  }

  const handleRowClick = useCallback<(item: ITaskTableItem) => void>(
    (item) => setTaskDetailsDialog({ id: item.id, open: true, mode: TaskDetailsMode.View }),
    []
  )

  const markAsCompletedAction = useCallback(
    async (item: ITaskTableItem) => {
      await TaskService.complete({ id: item.id, completed_date: new Date() })
      await refresh()
    },
    [refresh]
  )

  const handleAction = useCallback<(item: ITaskTableItem, action: TaskListAction) => void>(
    (item, action) => {
      switch (action) {
        case TaskListAction.Edit:
          return setTaskDetailsDialog({ id: item.id, open: true, mode: TaskDetailsMode.Edit })
        case TaskListAction.View:
          return setTaskDetailsDialog({ id: item.id, open: true, mode: TaskDetailsMode.View })
        case TaskListAction.Delete:
          return !state.loading && setDeletionState({ task: { id: Number(item.id), name: item.name }, confirmationOpen: true })
        case TaskListAction.MarkAsCompleted:
          return markAsCompletedAction(item)
        default:
          return null
      }
    },
    [markAsCompletedAction, state.loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<VoidFunction>(() => setDeletionState(defaulDeletionState), [])

  const closeTaskDetailsDialog = useCallback<VoidFunction>(() => {
    setTaskDetailsDialog(defaultTaskDetailsDialogState)
  }, [])

  const onSubmitTaskDetailsDialog = useCallback<VoidFunction>(async () => {
    closeTaskDetailsDialog()
    await refresh()
  }, [])

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeConfirmationDialog()
    if (deletionState.task.id) {
      try {
        setLoading(true)
        await TaskService.delete(deletionState.task.id)
        ToastService.showSuccess(`Task ${deletionState.task.name} has been deleted.`)
        await refresh()
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState.task, setLoading, refresh])

  const openTaskNew = () => setTaskDetailsDialog({ id: null, open: true, mode: null })

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Task"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <TaskDetailsDialog
        onSubmit={onSubmitTaskDetailsDialog}
        open={taskDetailsDialog.open}
        mode={taskDetailsDialog.mode}
        id={taskDetailsDialog.id}
        onCancel={closeTaskDetailsDialog}
      />
      <div className={cls(styles.filter, 'MuiFormControlLabel')}>
        <CheckboxLabel
          disabled={state.loading}
          value={Boolean(state.filters.showcompleted)}
          onChange={onShowCompletedChange}
          label="Show Completed"
        />
      </div>
      <div>
        <div hidden={isMobile} className={styles.addItemButtonContainer}>
          <XtQuickAddButton disabled={disabled} className={styles.addItemButton} onClick={openTaskNew} />
        </div>
        <div className={styles.table}>
          <XtList
            actions={disabled ? TaskListActionsViewMode : TaskListActionsEditMode}
            onRowClick={handleRowClick}
            onAction={handleAction}
            isMobile={isMobile}
            pagination={pagination}
            loading={state.loading}
            data={state.data}
            columns={taskColumns}
          />
        </div>
      </div>
    </div>
  )
}
