import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { SalesOrder } from './sales-orders-new'
import { ISalesOrderPageParams, SalesOrderMode } from './sales-orders-new.types'
import { ErrorHandler } from '../../services/ErrorService'
import { ISalesOrderExtended } from '../sales-orders.types'
import { SalesOrdersService } from '../sales-orders.service'

export const SalesOrderPage: FC = () => {
  const { orderNumber, mode } = useParams<ISalesOrderPageParams>()
  const [salesOrder, setSalesOrder] = useState<ISalesOrderExtended | null>(null)
  const viewMode = mode === SalesOrderMode.View
  const history = useHistory()

  const requestSalesOrder: (salesOrderNumber: string | null) => Promise<void> = async (number) => {
    try {
      if (!number) {
        setSalesOrder(null)
        return
      }
      const order = await SalesOrdersService.getExtended(number)
      setSalesOrder(order)
    } catch (e) {
      setSalesOrder(null)
      ErrorHandler.handleError(e)
    }
  }

  useEffect(() => {
    void requestSalesOrder(orderNumber)
  }, [orderNumber])

  const onCancel = () => history.push('/salesorder')

  return <SalesOrder onCancel={onCancel} salesOrder={salesOrder} viewMode={viewMode} />
}
