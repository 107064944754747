import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core'
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { ErrorHandler } from 'services/ErrorService'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { IRoutingListFilters, RoutingService } from '../RoutingService'
import { normalizeData } from '../utils'
import { cls } from '../../common/utils'

import { XtButton } from '../../components/xtButton/XtButton'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { IPaginationData, IPaginationParams } from '../../common/common.types'
import { confirmationMessages, xsMq } from '../../common/constants'
import './RoutingList.scss'
import { RoutingDetailsMode } from '../Routing.types'
import * as styles from './RoutingList.module.scss'
import { IRoutingListTableRow, RoutingListAction } from './RoutingList.types'
import { RoutingListActionsEditMode, routingListColumns } from './RoutingList.constants'
import { useTable } from '../../common/hooks/useTable'
import { XtList } from '../../components/list/list'

const fetchRoutings = async (
  filters: IRoutingListFilters,
  paginationParams: IPaginationParams
): Promise<IPaginationData<IRoutingListTableRow>> => {
  const { total, data } = await RoutingService.getAll(filters, paginationParams)
  return {
    data: normalizeData(data),
    total,
  }
}

export interface IItemDeletionState {
  itemId: string | null
  confirmationOpen: boolean
}
export const defaulDeletionState = { itemId: null, confirmationOpen: false }

// TODO user roles
export const RoutingList: FC = () => {
  const history = useHistory()
  const isMobile = useMediaQuery(xsMq)
  const { path } = useRouteMatch()
  const [deletionState, setDeletionState] = useState<IItemDeletionState>(defaulDeletionState)
  const { state, setLoading, refresh, filter, pagination } = useTable(
    { showActiveBOOsOnly: false, showActiveItemsOnly: false },
    fetchRoutings
  )

  const onShowFilterChange: SwitchBaseProps['onChange'] = async ({ target }) => {
    await filter({ ...state.filters, [target.name]: target.checked })
  }
  const openRoutingItem = (itemNumber: string, mode: RoutingDetailsMode): void => history.push(`${path}/${itemNumber}/${mode}`)
  const handleAction = useCallback<(item: IRoutingListTableRow, action: RoutingListAction) => void>(
    ({ item_number }, action: RoutingListAction) => {
      // TODO integration api
      switch (action) {
        case RoutingListAction.View:
          return openRoutingItem(item_number, RoutingDetailsMode.View)
        case RoutingListAction.Edit:
          return openRoutingItem(item_number, RoutingDetailsMode.Edit)
        case RoutingListAction.Delete:
          return setDeletionState({ itemId: item_number, confirmationOpen: true })
        case RoutingListAction.Print:
          return null
        default:
          return null
      }
    },
    []
  )

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaulDeletionState), [])
  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.itemId) {
      try {
        setLoading(true)
        await RoutingService.delete(deletionState.itemId)
        await refresh()
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState.itemId, setLoading, refresh, closeConfirmationDialog])
  const navigateToRoutingItem = useCallback(({ item_number }) => openRoutingItem(item_number, RoutingDetailsMode.View), [])

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Routing"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <div className={cls('routingListMuiOverrides', 'xt-content', styles.routingListContent)}>
        <h1 className={cls('xt-page-title', 'xt-section-border', styles.RoutingListHeader)}>List of Routings</h1>
        <div hidden={isMobile} className={styles.routingListFilters}>
          <FormControlLabel
            disabled={state.loading}
            control={
              <Checkbox
                color="primary"
                checked={state.filters.showActiveBOOsOnly}
                onChange={onShowFilterChange}
                name="showActiveBOOsOnly"
              />
            }
            label="Show Inactive Routings"
          />
          <FormControlLabel
            disabled={state.loading}
            control={
              <Checkbox
                color="primary"
                checked={state.filters.showActiveItemsOnly}
                onChange={onShowFilterChange}
                name="showActiveItemsOnly"
              />
            }
            label="Show Inactive Items"
          />
          <XtButton
            disabled={state.loading}
            label="New Item"
            className={styles.newButton}
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push('./routing/new')}
          />
        </div>
        <XtList
          actions={RoutingListActionsEditMode}
          onRowClick={navigateToRoutingItem}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          columns={routingListColumns}
          loading={state.loading}
          data={state.data}
        />
      </div>
    </div>
  )
}
