import * as React from 'react'
import * as Icon from 'react-feather'
import { Shop } from '@material-ui/icons'
import { RouteComponentProps } from 'react-router-dom'
import { Home } from 'home/home'
import { TaskList } from 'tasks/task-list/task-list'
import { ItemsList } from 'items/items-list/items-list'
import { CustomersList } from 'customers/customers-list/customers-list'
import { CRMAccountsList } from 'crm-accounts/crm-accounts-list/crm-accounts-list'
import { CRMProspectsList } from 'crm-prospects/crm-prospects-list/crm-prospects-list'
import { CRMProspectDetails } from 'crm-prospects/crm-prospect-details/crm-prospect-details'
import { ItemForm } from 'items/item-form/item-form'
import { TaskDetails } from 'tasks/task-details/task-details'
import { CustomerDetails } from 'customers/customer-details/customer-details'
import CRMAccountForm from './views/CRMAccountForm'
import Quote from './views/Quote'
import { QuoteList } from './quotes/quotes-list/quote-list'
import { SalesOrdersList } from './sales-orders/sales-orders-list/sales-orders-list'
import Settings from './views/Settings'
import UserTokens from './views/UserTokens'
import { Products } from './views/Products'
import { SvgIcon } from './components/svgIcon/SvgIcon'
import { productNavbarRoutes } from './productNavbarRoutes'
import { crmNavbarRoutes } from './crm-navbar-routes'
import { SvgIconIds } from './components/svgIcon/SvgIcon.types'
import { RoutingList } from './routing/routingList/RoutingList'
import { RoutingDetails } from './routing/routingDetails/RoutingDetails'
import { RoutingNew } from './routing/routingNew/RoutingNew'
import { BomList } from './bom/bomList/BomList'
import { BomDetails } from './bom/bomDetails/BomDetails'
import { BomNew } from './bom/bomNew/BomNew'
import { SalesOrderPage } from './sales-orders/sales-orders-new/sales-order-page'
import { projectVersion } from './common/constants'

export interface INavbarMenu {
  path: string
  name: string
}
export interface IRoute {
  settings?: boolean
  path: string
  name: string
  icon: React.ReactNode // TODO update me
  component: React.FunctionComponent<RouteComponentProps>
  layout: string
  sidebar: boolean
  navbarRoutes?: INavbarMenu[]
}

const dashboardRoutes: IRoute[] = [
  {
    path: '',
    name: 'Home',
    icon: <Icon.Home className="feather" />,
    component: Home,
    layout: '/',
    sidebar: true,
  },
  {
    path: 'crmaccount/create',
    name: 'Create CRM Account',
    icon: <Icon.Users className="feather" />,
    component: CRMAccountForm,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'crm',
    name: 'CRM',
    icon: <Icon.User className="feather" />,
    component: CRMAccountsList,
    navbarRoutes: crmNavbarRoutes.products,
    layout: '/',
    sidebar: true,
  },
  {
    path: 'crm/accounts',
    name: 'CRM Accounts',
    icon: <Icon.Users className="feather" />,
    component: CRMAccountsList,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'crm/customers/new',
    name: 'Customer New',
    icon: <Icon.User className="feather" />,
    component: CustomerDetails,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'crm/customers/:id/:mode',
    name: 'Create Customer',
    icon: <Icon.User className="feather" />,
    component: CustomerDetails,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'crm/customers',
    name: 'Customers',
    icon: <Icon.User className="feather" />,
    component: CustomersList,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'crm/prospects',
    name: 'CRM Prospects',
    icon: <Icon.Users className="feather" />,
    component: CRMProspectsList,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'crm/prospects/new',
    name: 'Create Prospect',
    icon: <Icon.Users className="feather" />,
    component: CRMProspectDetails,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'crm/prospects/:id/:mode',
    name: 'Create Prospect',
    icon: <Icon.Users className="feather" />,
    component: CRMProspectDetails,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/items/:itemNumber',
    name: 'Items',
    icon: <Shop />,
    component: ItemForm,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/items',
    name: 'Items',
    icon: <Shop />,
    component: ItemsList,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'tasks/new',
    name: 'Task New',
    icon: <Icon.PlusSquare className="feather" />,
    component: TaskDetails,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'tasks/:id/:mode',
    name: 'Task',
    icon: <Icon.Activity className="feather" />,
    component: TaskDetails,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products',
    name: 'Products',
    icon: <SvgIcon className="product-icon" iconId={SvgIconIds.PRODUCTS} />,
    component: Products,
    navbarRoutes: productNavbarRoutes.products,
    layout: '/',
    sidebar: true,
  },
  {
    path: 'tasks',
    name: 'Tasks',
    icon: <Icon.List className="feather" />,
    component: TaskList,
    layout: '/',
    sidebar: true,
  },
  {
    path: 'quote/:orderNumber',
    name: 'Quote',
    icon: <Icon.MessageSquare className="feather" />,
    component: Quote,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'quotes',
    name: 'Quotes',
    icon: <Icon.MessageSquare className="feather" />,
    component: QuoteList,
    layout: '/',
    sidebar: true,
  },
  {
    path: 'salesorder/new',
    name: 'Sales Order New',
    icon: <Icon.ShoppingBag className="feather" />,
    component: SalesOrderPage,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'salesorder/:orderNumber/:mode',
    name: 'Sales Order',
    icon: <Icon.ShoppingBag className="feather" />,
    component: SalesOrderPage,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'salesorder',
    name: 'Sales Orders',
    icon: <Icon.ShoppingBag className="feather" />,
    component: SalesOrdersList,
    layout: '/',
    sidebar: true,
  },
  {
    path: 'token',
    name: 'API Tokens',
    icon: <Icon.Unlock className="feather" />,
    component: UserTokens,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/routing/new',
    name: 'Routing New',
    icon: <Icon.Unlock className="feather" />,
    component: RoutingNew,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/routing/:itemNumber/:mode',
    name: 'Routing Details',
    icon: <Icon.Unlock className="feather" />,
    component: RoutingDetails,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/routing',
    name: 'Routing',
    icon: <Icon.Unlock className="feather" />,
    component: RoutingList,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/bom',
    name: 'BOM List',
    icon: <Icon.Unlock className="feather" />,
    component: BomList,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/bom/new',
    name: 'BOM New',
    icon: <Icon.Unlock className="feather" />,
    component: BomNew,
    layout: '/',
    sidebar: false,
  },
  {
    path: 'products/bom/:itemNumber/:mode',
    name: 'BOM Details',
    icon: <Icon.Unlock className="feather" />,
    component: BomDetails,
    layout: '/',
    sidebar: false,
  },
  {
    settings: true,
    path: 'settings',
    name: 'Settings',
    icon: <Icon.Settings className="feather" />,
    component: Settings,
    layout: '/',
    sidebar: true,
  },
  {
    settings: true,
    path: 'version',
    name: 'Version',
    icon: null,
    component: () => <div style={{ height: '100vh' }}>{projectVersion}</div>,
    layout: '/',
    sidebar: false,
  },
]

// TODO fix eslint issue
// eslint-disable-next-line import/no-default-export
export default dashboardRoutes
