import { IPaginationData, IPaginationParams } from 'common/common.types'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { XtButton } from 'components/xtButton/XtButton'
import React, { FC, useCallback, useState } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { ContactCreatePayload, IContact } from 'contacts/contacts.types'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase'
import { cls } from 'common/utils'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { confirmationMessages } from 'common/constants'
import { IAttachedDocumentWithFile } from 'documents/documents.types'
import { ToastService } from 'services/ToasterService'
import { ErrorHandler } from 'services/ErrorService'
import { ContactService, IContactFilters } from 'contacts/contacts.service'
import styles from 'components/tasks/task-list/task-list.module.scss'
import { ContactActionsEditMode, ContactActionsViewMode, contactColumns } from './contact-list.constants'
import { ContactDialogForm } from '../contact-dialog-form/contact-dialog-form'
import { SelectContactDialog } from '../select-contact-dialog/select-contact-dialog'
import { normalizeData, defaultSelectDialogState, defaultContactDialogFormState, defaulDeletionState } from './contact-list.utils'
import {
  ContactAction,
  IContactTableItem,
  IContactList,
  ISelectDialogState,
  IContactDialogFormState,
  IDeletionState,
} from './contact-list.types'
import { characteristicsToPayload } from '../../characteristics/characteristic.utils'

export const ContactList: FC<IContactList> = ({ account_number, account_name, isMobile, disabled }) => {
  const [initialData, setInitialData] = useState<null | IContact[]>(null)
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaulDeletionState)
  const [selectDialogState, setSelectDialogState] = useState<ISelectDialogState>(defaultSelectDialogState)
  const [contactDialogFormState, setContactDialogFormState] = useState<IContactDialogFormState>(defaultContactDialogFormState)

  const fetchContacts = useCallback<
    (filters: IContactFilters, paginationParams: IPaginationParams) => Promise<IPaginationData<IContactTableItem>>
  >(
    async (filters: IContactFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IContactTableItem>> => {
      const { total, data } = await ContactService.getAll(paginationParams, filters)
      setInitialData(data)
      return {
        data: normalizeData(data, account_number),
        total,
      }
    },
    [account_number]
  )
  const { state, filter, refresh, pagination, setLoading } = useTable({ showinactive: false, account_number }, fetchContacts)

  const onInactiveFilterChange: SwitchBaseProps['onChange'] = async ({ target }) => {
    await filter({ account_number, showinactive: !target.checked })
  }
  const handleAction = useCallback<(item: IContactTableItem, action: ContactAction) => void>(
    (item, action) => {
      const itemFind = initialData?.find(({ contact_number }) => contact_number?.toString() === item.id) ?? null

      if (action === ContactAction.Edit || action === ContactAction.View) {
        return setContactDialogFormState({
          open: true,
          selectData: itemFind,
          mode: action,
        })
      }
      return setDeletionState({ contact: itemFind, open: true })
    },
    [initialData]
  )
  const handleRowClick = useCallback<(item: IContactTableItem) => void>((item) => handleAction(item, ContactAction.View), [handleAction])

  const openSelectDialog = useCallback<VoidFunction>(() => {
    setSelectDialogState({ open: true, selectData: null })
  }, [])

  const closeSelectDialog = useCallback<VoidFunction>(() => {
    setSelectDialogState(defaultSelectDialogState)
  }, [])

  const confirmSelectDialog = useCallback<(contact: IContact) => Promise<void>>(async (contact) => {
    closeSelectDialog()
    if (!account_number || !account_name) {
      return
    }
    // add contact for current customer
    await ContactService.update({ ...contact, contact_characteristics: characteristicsToPayload(contact.contact_characteristics ?? []) })
    ToastService.showSuccess(`Contact has been added.`)
    await refresh()
  }, [])

  const openContactDialogForm = useCallback<VoidFunction>(() => {
    setContactDialogFormState({ open: true, selectData: null, mode: null })
  }, [])

  const closeContactDialogForm = useCallback<VoidFunction>(() => {
    setContactDialogFormState(defaultContactDialogFormState)
  }, [])

  const confirmContactDialogForm = useCallback(
    async (payload: ContactCreatePayload, unsavedDocuments: IAttachedDocumentWithFile[], isEditMode: boolean): Promise<void> => {
      try {
        if (!isEditMode) {
          try {
            const contact = await ContactService.create(payload, unsavedDocuments)
            setSelectDialogState((prev) => ({ ...prev, selectData: contact }))
            ToastService.showSuccess(`Contact has been created.`)
            closeContactDialogForm()
          } catch (e) {
            setSelectDialogState((prev) => ({ ...prev, selectData: null }))
            ErrorHandler.handleError(e)
          }
          return
        }
        await ContactService.update(payload)
        await refresh()
        setContactDialogFormState(defaultContactDialogFormState)
        ToastService.showSuccess(`Contact has been updated.`)
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    },
    []
  )

  const closeDeletionDialog = useCallback<VoidFunction>(() => setDeletionState(defaulDeletionState), [])

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeDeletionDialog()
    if (deletionState.contact?.contact_number) {
      try {
        setLoading(true)
        await ContactService.delete(deletionState.contact.contact_number.toString())
        await refresh()
        ToastService.showSuccess(`Contact has been deleted.`)
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState.contact, setLoading, refresh, closeDeletionDialog])

  return (
    <div>
      <div>
        <XtConfirmationDialog
          open={deletionState.open}
          message={confirmationMessages.deleted}
          title="Delete Contact"
          confirmationButtonLabel="Delete"
          onConfirm={handleDeletion}
          onClose={closeDeletionDialog}
        />
        <ContactDialogForm
          isMobile={isMobile}
          onSaveForm={confirmContactDialogForm}
          onCancel={closeContactDialogForm}
          selectData={contactDialogFormState.selectData}
          open={contactDialogFormState.open}
          mode={contactDialogFormState.mode}
        />
        <SelectContactDialog
          openContactDialog={openContactDialogForm}
          onConfirm={confirmSelectDialog}
          onClose={closeSelectDialog}
          account_number={account_number}
          account_name={account_name}
          open={selectDialogState.open}
          selectData={selectDialogState.selectData}
        />
        <div className={cls(styles.filter, 'MuiFormControlLabel')}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!account_number}
                checked={!state.filters.showinactive}
                onChange={onInactiveFilterChange}
                color="primary"
              />
            }
            label="Show Active Contacts Only"
          />
        </div>
        <div>
          <div hidden={isMobile} className={styles.addItemButtonContainer}>
            <XtButton
              onClick={openSelectDialog}
              disabled={disabled || !account_number}
              icon={SvgIconIds.ADD_CIRCLE}
              label=""
              className={styles.addItemButton}
            />
          </div>
          <div className={styles.table}>
            <XtList
              actions={account_number && !disabled ? ContactActionsEditMode : ContactActionsViewMode}
              onRowClick={handleRowClick}
              onAction={handleAction}
              isMobile={isMobile}
              pagination={pagination}
              columns={contactColumns}
              loading={state.loading}
              data={state.data}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
