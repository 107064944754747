import React, { FC } from 'react'

import { ISalesDetails } from 'customers/customers.types'
// TODO global
import styles from 'contacts/contact-search/contact-search.module.scss'
import { convertDate } from 'common/utils/date.utils'

interface ISummaryTab {
  salesDetails: ISalesDetails | null
}
export const SummaryTab: FC<ISummaryTab> = ({ salesDetails }) => {
  const salesData = [
    { label: 'First Sales Date', value: convertDate(salesDetails?.first_sales_date ?? null) },
    { label: 'Last Sales Date', value: convertDate(salesDetails?.last_sales_date ?? null) },
    { label: 'Backlog', value: salesDetails?.backlog?.toFixed(2) ?? 0 },
    { label: 'Last Year Sales', value: salesDetails?.last_year_sales ?? '' },
    { label: 'Year To Date Sales', value: salesDetails?.ytd_sales?.toFixed(2) ?? 0 },
    { label: 'Open Balance', value: salesDetails?.balance?.toFixed(2) ?? 0 },
    { label: 'Late Balance', value: salesDetails?.late_balance?.toFixed(2) ?? 0 },
  ]
  return (
    <div>
      {salesData.map(({ label, value }) => (
        <span key={label} className={styles.itemContent}>
          <p className={styles.label}>{label}: &nbsp;</p>
          <p className={styles.value} title={value.toString()}>
            {value}
          </p>
        </span>
      ))}
    </div>
  )
}
