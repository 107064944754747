import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'
import { IItemsTableItem, ItemsAction } from './items-list.types'

export const defaultDeletionState = {
  itemNumber: '',
  confirmationOpen: false,
}

export const ItemsListActionsEditMode: IXtTableCellAction[] = [
  /*  {
    name: ItemsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  }, */
  {
    name: ItemsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  // TODO delete?
  {
    name: ItemsAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]

export const itemColumns: IXtTableColumn<IItemsTableItem>[] = [
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'description1',
    field: 'description1',
    headerName: 'Description',
    flex: '1 0 250px',
    width: 250,
  },
  {
    id: 'class_code',
    field: 'class_code',
    headerName: 'Class Code',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_type',
    field: 'item_type',
    headerName: 'Item Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'inventory_uom_name',
    field: 'inventory_uom_name',
    headerName: 'UOM',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'product_category',
    field: 'product_category',
    headerName: 'Product Category',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'freight_class',
    field: 'freight_class',
    headerName: 'Freight Class',
    flex: '1 0 160px',
    width: 160,
  },
]

export const filterDefaultValues = {
  showinactive: false,
  productCategoryPattern: '',
  productCategory: '',
  itemType: '',
  itemGroup: '',
  itemDescription: '',
  itemNumberPattern: '',
  freightClassPattern: '',
  freightClass: '',
  classCodePattern: '',
  classCode: '',
}
