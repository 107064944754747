import { DeepPartial, UnpackNestedValue, useForm, UseFormReset } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'
import { DefaultValues, KeepStateOptions, UseFormTrigger } from 'react-hook-form/dist/types/form'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { BehaviorSubject, Observable } from 'rxjs'
import { FieldPath } from 'react-hook-form/dist/types/utils'
import * as Yup from 'yup'
import { Resolver } from 'react-hook-form/dist/types/resolvers'
import { ValidationError } from 'yup'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { buildFormStateObservable } from './form.utils'
import { IFormHook, IFormProperties, IFormStateBase, IFormStateChanges } from './form.types'

function resolveErrors<TFormValues extends FieldValues>(errors: ValidationError[]): FieldErrors<TFormValues> {
  return errors.reduce(
    (allErrors, currentError) => ({
      ...allErrors,
      [currentError.path]: { type: currentError.type ?? 'validation', message: currentError.message },
    }),
    {}
  )
}

function validationResolver<TFormValues extends FieldValues>(
  validationSchema: Yup.ObjectSchema,
  disabledRef: MutableRefObject<boolean>
): Resolver<TFormValues> {
  return async (data) => {
    try {
      const disabled = disabledRef.current

      if (disabled) {
        return { values: {}, errors: {} }
      }

      const values = await validationSchema.validate(data, {
        abortEarly: false,
      })

      return {
        values: values ?? {},
        errors: {},
      }
    } catch (error) {
      if (!(error instanceof ValidationError)) {
        throw new Error('Invalid Yup error.')
      }
      return {
        values: {},
        errors: resolveErrors(error.inner),
      }
    }
  }
}

export function useXtForm<TFieldValues extends FieldValues>({
  mode,
  defaultValues,
  validationSchema,
}: IFormProperties<TFieldValues>): IFormHook<TFieldValues> {
  const disabledRef = useRef<boolean>(false)

  const methods = useForm<TFieldValues>({
    mode,
    defaultValues: defaultValues as UnpackNestedValue<DeepPartial<TFieldValues>>,
    resolver: validationSchema ? validationResolver(validationSchema, disabledRef) : undefined,
  })

  const { formState, watch, trigger: formTrigger, reset: formReset } = methods

  const formStateSubject = useRef<BehaviorSubject<IFormStateBase>>(
    new BehaviorSubject<IFormStateBase>({
      isDirty: formState.isDirty,
      isValid: formState.isValid,
    })
  )

  const touchedSubject = useRef<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false))

  const formValueSubject = useRef<BehaviorSubject<TFieldValues>>(new BehaviorSubject<TFieldValues>(defaultValues))

  const formChanges$ = useMemo<Observable<IFormStateChanges<TFieldValues>>>(
    () => buildFormStateObservable(formStateSubject.current, touchedSubject.current, formValueSubject.current),
    []
  )

  useEffect(() => {
    const { isDirty, isValid, touchedFields } = formState
    formStateSubject.current.next({ isDirty, isValid })
    if (Object.keys(touchedFields).length) {
      touchedSubject.current.next(true)
    }
  }, [formState])

  useEffect(() => {
    const watchSub = watch((value) => {
      formValueSubject.current.next(value as TFieldValues)
    })

    return () => {
      watchSub.unsubscribe()
    }
  }, [])

  const trigger = useCallback<UseFormTrigger<TFieldValues>>(
    (name?: FieldPath<TFieldValues> | FieldPath<TFieldValues>[]) => {
      touchedSubject.current.next(true) // we should mark the form as touched
      return formTrigger(name)
    },
    [formTrigger]
  )

  const reset = useCallback<UseFormReset<TFieldValues>>(
    (values?: DefaultValues<TFieldValues>, keepStateOptions?: KeepStateOptions) => {
      if (!keepStateOptions?.keepTouched) {
        touchedSubject.current.next(false) // we should mark the form as untouched
      }
      return formReset(values, keepStateOptions)
    },
    [formReset]
  )

  const markAsTouched = useCallback<VoidFunction>(() => touchedSubject.current.next(true), [])

  // TODO we should handle disabled state changes for every control in the form, so we can remove a control from the validation schema.
  const setDisabled = useCallback<(disabled: boolean) => void>((disabled) => {
    disabledRef.current = disabled
  }, [])

  return {
    ...methods,
    trigger,
    reset,
    formState: { ...formState, touched: touchedSubject.current.value },
    markAsTouched,
    formChanges$,
    setDisabled,
  }
}
