import React, { FC } from 'react'

import { IXtCommentsPaginationParams, XtComments } from 'comments/comments/comments'
import { TextField } from '@material-ui/core'
import { XtAccordion } from 'components/xt-accordion/xt-accordion'
import styles from './xt-remarks.module.scss'
import { CommentItem } from '../../comments/comment/comment.types'

export interface IXtRemarks extends IXtCommentsPaginationParams {
  disabled: boolean
  /**
   * The function returns true if a comment is saved successfully. Otherwise it returns false.
   * @param comments
   */
  onCommentsSave(comments: CommentItem): boolean | Promise<boolean>
  onCommentsUpdate(comment: CommentItem): void | Promise<void>
  comments: CommentItem[]
  textAreaOnChange(value: string): void
  textAreaValue: string
  textAreaName: string
  username: string
  extendedTextAreaOnChange?(value: string): void
  extendedTextAreaValue?: string
  extendedTextAreaName?: string
}
/* TODO integration api */
export const XtRemarks: FC<IXtRemarks> = ({
  disabled,
  onCommentsSave,
  onCommentsUpdate,
  comments,
  textAreaValue,
  textAreaOnChange,
  textAreaName,
  username,
  extendedTextAreaOnChange,
  extendedTextAreaValue,
  extendedTextAreaName,
  canLoadMore,
  loadMore,
  total,
}) => (
  <div>
    <div className={styles.accordionTabs}>
      <XtAccordion expanded summary="Comments">
        <XtComments
          canLoadMore={canLoadMore}
          loadMore={loadMore}
          total={total}
          creationEnabled
          username={username}
          onUpdate={onCommentsUpdate}
          onAdd={onCommentsSave}
          comments={comments}
          disabled={disabled}
        />
      </XtAccordion>
    </div>
    <div className={styles.accordionTabs}>
      <XtAccordion summary={textAreaName}>
        <TextField
          multiline
          variant="outlined"
          rows={11}
          className={styles.textAreaTabs}
          placeholder={textAreaName}
          disabled={disabled}
          value={textAreaValue}
          onChange={(e) => textAreaOnChange(e.target.value)}
        />
      </XtAccordion>
    </div>
    {extendedTextAreaName && (
      <div className={styles.accordionTabs}>
        <XtAccordion summary={extendedTextAreaName}>
          <TextField
            multiline
            variant="outlined"
            rows={11}
            className={styles.textAreaTabs}
            placeholder={extendedTextAreaName}
            disabled={disabled}
            value={extendedTextAreaValue}
            onChange={(e) => extendedTextAreaOnChange!(e.target.value)}
          />
        </XtAccordion>
      </div>
    )}
  </div>
)
