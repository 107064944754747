import { AxiosInstance } from 'axios'
import { DocumentCreationError, DocumentType, IAttachedDocumentWithFile, IDocumentsService } from 'documents/documents.types'
import { DocumentsService } from 'documents/documents.service'
import api from 'common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import {
  ISalesOrder,
  ISalesOrderCreateResponse,
  ISalesOrderDetails,
  ISalesOrderExtended,
  ISalesOrderFilters,
  ISalesOrderItem,
  ISalesOrderItemsFilters,
  ISalesOrderLineItemCreatePayload,
  ISalesOrderLineItemEditPayload,
  SalesOrderCreateInput,
  SalesOrderUpdateInput,
} from './sales-orders.types'
import { CustomersService, ICustomersService } from '../customers/customers.service'
import { CommentsCreationError, NewComment } from '../comments/comments.types'
import { convertNewComments } from '../comments/comments.utils'
import { CommentsService, ICommentsService } from '../comments/comments.service'
import { prepareRequestParams } from '../common/utils/request.utils'

export interface ISalesOrderService {
  getAll(pagination: IPaginationParams, showDetail?: boolean, filters?: ISalesOrderFilters): Promise<IPaginationData<ISalesOrder>>
  get(orderNumber: string): Promise<ISalesOrder>
  getDetails(orderNumber: string, currency: string): Promise<ISalesOrderDetails>
  getExtended(orderNumber: string): Promise<ISalesOrderExtended>
  getOrderItems(
    orderNumber: string,
    pagination: IPaginationParams,
    filters?: ISalesOrderItemsFilters
  ): Promise<IPaginationData<ISalesOrderItem>>
  getOrderItem(orderNumber: string, lineNumber: string): Promise<ISalesOrderItem>
  create(data: SalesOrderCreateInput, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<void>
  copySalesOrder(orderNumber: string): Promise<void>
  update(data: SalesOrderUpdateInput): Promise<void>
  delete(orderNumber: string): Promise<void>
  createItem(orderNumber: string, item: ISalesOrderLineItemCreatePayload): Promise<void>
  updateItem(orderNumber: string, item: ISalesOrderLineItemEditPayload): Promise<void>
  deleteItem(orderNumber: string, lineNumber: string): Promise<void>
  cancelItem(orderNumber: string, lineNumber: string): Promise<void>
  openItem(orderNumber: string, lineNumber: string): Promise<void>
  closeItem(orderNumber: string, lineNumber: string): Promise<void>
}

class Service implements ISalesOrderService {
  constructor(
    private readonly httpClient: AxiosInstance,
    private readonly customersService: ICustomersService,
    private commentsService: ICommentsService,
    private readonly documentsService: IDocumentsService
  ) {}

  public async getAll(
    paginationParams: IPaginationParams,
    showDetail = true,
    filters?: ISalesOrderFilters
  ): Promise<IPaginationData<ISalesOrder>> {
    const params: Record<string, number | boolean | string | Date> = prepareRequestParams(paginationParams, filters)

    if (showDetail) {
      params.showDetail = showDetail
    }
    const response = await this.httpClient.get<IPaginationResponse<ISalesOrder>>('/salesorder', { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(orderNumber: string): Promise<ISalesOrder> {
    const response = await this.httpClient.get<IResponse<ISalesOrder>>(`/salesorder/${orderNumber}`)
    if (!Object.keys(response.data.data).length) {
      throw new Error(`Sales Order with number: ${orderNumber} is not found.`)
    }
    return response.data.data
  }

  public async getDetails(orderNumber: string, currency: string): Promise<ISalesOrderDetails> {
    const params = { effective: new Date().toISOString(), currency }
    const response = await this.httpClient.get<IResponse<ISalesOrderDetails>>(`/salesorder/${orderNumber}/details`, { params })
    if (!Object.keys(response.data.data).length) {
      throw new Error(`Sales Order Details data for Order with number: ${orderNumber} is not found.`)
    }
    return response.data.data
  }

  public async getExtended(orderNumber: string): Promise<ISalesOrderExtended> {
    const {
      data: { data },
    } = await this.httpClient.get<IResponse<ISalesOrder>>(`/salesorder/${orderNumber}`)
    if (!Object.keys(data).length) {
      throw new Error(`Sales Order with number: ${orderNumber} is not found.`)
    }
    const customer = await this.customersService.get(data.customer_number)
    return { ...data, customer }
  }

  public async getOrderItems(
    orderNumber: string,
    paginationParams: IPaginationParams,
    filters?: ISalesOrderItemsFilters
  ): Promise<IPaginationData<ISalesOrderItem>> {
    const params = prepareRequestParams(paginationParams, filters)

    const response = await this.httpClient.get<IPaginationResponse<ISalesOrderItem>>(`/salesorder/${orderNumber}/orderitem`, { params })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getOrderItem(orderNumber: string, lineNumber: string): Promise<ISalesOrderItem> {
    const {
      data: { data },
    } = await this.httpClient.get<IResponse<ISalesOrderItem>>(`/salesorder/${orderNumber}/orderitem/${lineNumber}`)

    if (!data || !Object.keys(data).length) {
      throw new Error(`Sales Order Item is not found. Order Number: ${orderNumber}, Line Number: ${lineNumber}`)
    }

    return data
  }

  public async create(data: SalesOrderCreateInput, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<void> {
    const response = await this.httpClient.post<IResponse<ISalesOrderCreateResponse>>(`/salesorder/create`, { data })
    const {
      data: {
        data: { order_number },
      },
    } = response
    await this.createCommentsForSalesOrder(order_number, comments)
    await this.createDocumentsForSalesOrder(order_number, documents)
  }

  public async update(data: SalesOrderUpdateInput): Promise<void> {
    await this.httpClient.post<IResponse<string>>(`/salesorder/update`, { data })
  }

  public async delete(orderNumber: string): Promise<void> {
    const data = { order_number: orderNumber }
    await this.httpClient.post<IResponse<string>>(`/salesorder/delete`, { data })
  }

  public async copySalesOrder(orderNumber: string): Promise<void> {
    const data = { order_number: orderNumber }
    await this.httpClient.post('/salesorder/copy', { data })
  }

  public async createItem(orderNumber: string, item: ISalesOrderLineItemCreatePayload): Promise<void> {
    const data = [item]
    await this.httpClient.post<IResponse<string>>(`/salesorder/${orderNumber}/orderitem/create`, { data })
  }

  public async updateItem(orderNumber: string, item: ISalesOrderLineItemEditPayload): Promise<void> {
    const data = [item]
    await this.httpClient.post<IResponse<string>>(`/salesorder/${orderNumber}/orderitem/update`, { data })
  }

  public async deleteItem(orderNumber: string, lineNumber: string): Promise<void> {
    await this.httpClient.post<IResponse<string>>(`/salesorder/${orderNumber}/orderitem/${lineNumber}/delete`)
  }

  public async cancelItem(orderNumber: string, lineNumber: string): Promise<void> {
    await this.httpClient.post<IResponse<string>>(`/salesorder/${orderNumber}/orderitem/${lineNumber}/cancel`)
  }

  public async openItem(orderNumber: string, lineNumber: string): Promise<void> {
    await this.httpClient.post<IResponse<string>>(`/salesorder/${orderNumber}/orderitem/${lineNumber}/open`)
  }

  public async closeItem(orderNumber: string, lineNumber: string): Promise<void> {
    await this.httpClient.post<IResponse<string>>(`/salesorder/${orderNumber}/orderitem/${lineNumber}/close`)
  }

  // TODO remove it after the API changes
  private async createCommentsForSalesOrder(orderNumber: string, comments: NewComment[]): Promise<void> {
    if (!comments.length) {
      return
    }
    try {
      const commentsPayload = convertNewComments(comments, DocumentType.SalesOrder, orderNumber)
      await this.commentsService.createAll(commentsPayload)
    } catch (e) {
      throw new CommentsCreationError()
    }
  }

  private async createDocumentsForSalesOrder(number: string, documents: IAttachedDocumentWithFile[]): Promise<void> {
    if (!documents.length) {
      return
    }
    try {
      await this.documentsService.createFilesForSource(documents, DocumentType.SalesOrder, number)
    } catch (e) {
      throw new DocumentCreationError()
    }
  }
}

export const SalesOrdersService: ISalesOrderService = new Service(api, CustomersService, CommentsService, DocumentsService)
