import { useEffect, useMemo, useRef, useState } from 'react'
import { Observable, Subject } from 'rxjs'
import { IObjectWithId, IPaginationData, IPaginationParams } from '../common.types'
import { globalConstants } from '../constants'
import { ITablePagination } from '../types/table.types'
import { ErrorHandler } from '../../services/ErrorService'

interface IFiltersState<TFilters extends {}> {
  filters: TFilters
}

type TPaginationRequestFn<TData, TFilters extends {}> = (
  filters: IFiltersState<TFilters>['filters'],
  pagination: IPaginationParams,
  data?: TData[]
) => Promise<IPaginationData<TData>>

export interface ITableMeta extends IPaginationParams {
  total: number
}

const defaultMeta: ITableMeta = {
  page: 0,
  limit: globalConstants.paginationLimit,
  total: 0,
}

export interface ITableState<TData extends IObjectWithId, TFilters extends {}> {
  data: TData[]
  loading: boolean
  meta: ITableMeta
  filters: TFilters
}

export interface ITable<TData extends IObjectWithId, TFilters extends {}> {
  state: ITableState<TData, TFilters>
  filter(filters: TFilters): Promise<void>
  sort(): Promise<void>
  refresh(): Promise<void>
  setLoading(loading: boolean): void
  pagination: ITablePagination
  setData(data: TData[]): void
  onChange$: Observable<TData[]>
}

export type TableRequestFn<TData, TFilters> = TPaginationRequestFn<TData, TFilters>

export function useTable<TData extends IObjectWithId, TFilters extends {}>(
  initialFilters: TFilters,
  requestFn: TableRequestFn<TData, TFilters>,
  defaultLimit: number = globalConstants.paginationLimit,
  throwError: boolean = false
): ITable<TData, TFilters> {
  const changeSubject = useRef<Subject<TData[]>>(new Subject<TData[]>())

  const [state, setState] = useState<ITableState<TData, TFilters>>({
    filters: initialFilters,
    meta: { ...defaultMeta, limit: defaultLimit },
    data: [],
    loading: false,
  })

  function handleError(error: Error): void {
    ErrorHandler.handleError(error)
    if (throwError) {
      throw error
    }
  }

  async function requestData(data: TData[], filterParams?: TFilters | null, paginationParams?: IPaginationParams): Promise<TData[]> {
    try {
      const pagination = paginationParams ?? { page: state.meta.page, limit: state.meta.limit }
      const filters = filterParams ?? state.filters
      setState((prevState) => ({
        ...prevState,
        filters,
        loading: true,
        meta: {
          limit: pagination.limit,
          total: prevState.meta.total,
          page: pagination.page,
        },
      }))
      // we keep zero-based index to work with Material UI table, but the server counts pages from 1
      const serverPagination = { ...pagination, page: pagination.page + 1 }
      const { data: newData, total } = await requestFn(filters, serverPagination, state.data)
      const tableData = [...data, ...newData]
      setState((prevState) => ({
        ...prevState,
        loading: false,
        data: tableData,
        meta: {
          ...prevState.meta,
          total,
        },
      }))
      changeSubject.current.next(tableData)
      return data
    } catch (e) {
      setState((prevState) => ({ ...prevState, loading: false }))
      handleError(e)
      return []
    }
  }

  function canLoadMore(page: number): boolean {
    return page * state.meta.limit <= state.meta.total
  }

  async function loadMore(page: number): Promise<void> {
    if (canLoadMore(page)) {
      await requestData(state.data, state.filters, { page, limit: state.meta.limit })
    }
  }

  async function changePage(page: number): Promise<void> {
    if (canLoadMore(page)) {
      await requestData([], state.filters, { page, limit: state.meta.limit })
    }
  }

  async function changeLimit(limit: number): Promise<void> {
    await requestData([], state.filters, { limit, page: 0 })
  }

  async function filter(filters: TFilters): Promise<void> {
    await requestData([], filters, { limit: state.meta.limit, page: 0 })
  }

  async function refresh(): Promise<void> {
    await requestData([])
  }

  // TODO implement me
  async function sort(): Promise<void> {
    await requestData([])
  }

  function setLoading(loading: boolean): void {
    setState((prevState) => ({ ...prevState, loading }))
  }

  function setData(data: TData[]): void {
    setState((prevState) => ({ ...prevState, data }))
    changeSubject.current.next(data)
  }

  /**
   * Data initialization
   */
  useEffect(() => void requestData([]), [])

  const pagination: ITablePagination = {
    count: state.meta.total,
    rowsPerPage: state.meta.limit,
    page: state.meta.page,
    onChangePage: changePage,
    onChangeRowsPerPage: changeLimit,
    loadMore,
    canLoadMore: canLoadMore(state.meta.page + 1),
  }

  return useMemo(
    () => ({
      filter,
      sort,
      refresh,
      setLoading,
      setData,
      state,
      pagination,
      onChange$: changeSubject.current.asObservable(),
    }),
    [state]
  )
}
