import { AxiosInstance } from 'axios'
import api from 'common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { CommentsService, ICommentsService } from 'comments/comments.service'
import { DocumentsService } from 'documents/documents.service'
import { DocumentCreationError, DocumentType, IAttachedDocumentWithFile, IDocumentsService } from 'documents/documents.types'
import { CommentsCreationError, NewComment } from 'comments/comments.types'
import { convertNewComments } from 'comments/comments.utils'
import { ITask, ITaskCreatePayload, ITaskCreateResponse, ITaskUpdatePayload } from './tasks.types'

export interface ITaskListFilters {
  taskid?: string | null
  number?: string | null
  name?: string | null // regexp
  lastupdated?: Date | null
  assignedto?: string | null
  owner?: string | null
  user?: string | null
  showcompleted?: boolean
  milestoneonly?: boolean
  status?: string | null
  duedate?: Date
  duebefore?: Date
  dueafter?: Date
  plannedstartbefore?: Date
  plannedstartafter?: Date
  startbefore?: Date
  startafter?: Date
  account_number?: string | null
  accountgroup?: string | null
  incident?: string | null
  opportunity?: string | null
  prospect?: string | null
  project?: string | null
  showDetail?: boolean
}

export interface ITaskService {
  getAll: (pagination: IPaginationParams, filters?: ITaskListFilters) => Promise<IPaginationData<ITask>>
  get(taskId: number): Promise<ITask>
  delete(id: number): Promise<void>
  create(data: ITaskCreatePayload, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<ITask>
  update(data: ITaskUpdatePayload): Promise<void>
  complete(completeData: { id: number; completed_date?: string | Date }): Promise<void>
}

class Service implements ITaskService {
  constructor(
    private readonly apiClient: AxiosInstance,
    private readonly commentsService: ICommentsService,
    private readonly documentsService: IDocumentsService
  ) {}

  public async getAll(paginationParams: IPaginationParams, filters?: ITaskListFilters): Promise<IPaginationData<ITask>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ITask>>('/task', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(taskId: number): Promise<ITask> {
    const params = { showcompleted: true }
    const response = await this.apiClient.get<IResponse<ITask>>(`/task/${taskId}`, { params })
    if (!Object.keys(response.data.data).length) {
      throw new Error(`Task: ${taskId} not found.`)
    }
    return response.data.data
  }

  public async delete(id: number): Promise<void> {
    const body = { data: { id } }
    await this.apiClient.post('/task/delete', body)
  }

  public async complete(completeData: { id: number; completed_date: Date }): Promise<void> {
    const body = { data: completeData }
    await this.apiClient.post('/task/complete', body)
  }

  public async update(data: ITaskUpdatePayload): Promise<void> {
    const body = { data }
    await this.apiClient.post('/task/update', body)
  }

  public async create(data: ITaskCreatePayload, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<ITask> {
    const body = { data }
    const {
      data: {
        data: { task_id },
      },
    } = await this.apiClient.post<IResponse<ITaskCreateResponse>>('/task/create', body)
    const task = await this.get(task_id)
    const { number } = task
    await this.createCommentsForTask(number, comments)
    await this.createDocumentsForTask(number, documents)
    return task
  }

  private async createCommentsForTask(number: string, comments: NewComment[]): Promise<void> {
    if (!comments.length) {
      return
    }
    try {
      const commentsPayload = convertNewComments(comments, DocumentType.Task, number)
      await this.commentsService.createAll(commentsPayload)
    } catch (e) {
      throw new CommentsCreationError()
    }
  }

  private async createDocumentsForTask(number: string, documents: IAttachedDocumentWithFile[]): Promise<void> {
    if (!documents.length) {
      return
    }
    try {
      await this.documentsService.createFilesForSource(documents, DocumentType.Task, number)
    } catch (e) {
      throw new DocumentCreationError()
    }
  }
}

export const TaskService = new Service(api, CommentsService, DocumentsService)
