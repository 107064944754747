import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const Footer = (props) => {
    return (
      <footer className="footer text-muted">
        <Container fluid>
          <nav className="float-left">
            <ul>
              <li>
              <Link to={"/"}>
                Home
              </Link>
              </li>
              <li>
                <a href="https://www.xtuple.com">xTuple</a>
              </li>
              <li>
                <a href="https://www.xtuple.com/support">Support</a>
              </li>
              <li>
                <a href="https://portal.xtuple.com">Customer Portal</a>
              </li>
              <li>
                <a href="https://forums.xtuple.com">Forums</a>
              </li>
            </ul>
          </nav>
          <p className="copyright float-right">
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://www.xtuple.com">
              xTuple
            </a>
          </p>
        </Container>
      </footer>
    );
}

export default Footer;
