import React, { FC, useCallback } from 'react'

import { IContact } from 'contacts/contacts.types'

import { ContactSearch } from 'contacts/contact-search/contact-search'

import styles from './contact-section.module.scss'
import { IContactSection } from './contact-section.types'

export const ContactSection: FC<IContactSection> = ({ onChange, customer, isMobile, disabled }) => {
  const defineBillingData = customer?.billing_contact ?? null
  const defineCorrespondData = customer?.correspond_contact ?? null

  const setContactBilling = useCallback<(contact: IContact, isDirty: boolean, isValid: boolean) => void>(
    (contact: IContact, isDirty, isValid) => {
      onChange({ state: { billing_contact: contact }, isDirty, isValid })
    },
    [onChange]
  )

  const setContactCorrespond = useCallback(
    (contact: IContact, isDirty, isValid) => {
      onChange({ state: { correspond_contact: contact }, isDirty, isValid })
    },
    [onChange]
  )
  return (
    <div className={styles.contactSection}>
      <ContactSearch
        disabled={disabled}
        labelContact="Billing Contact"
        isMobile={isMobile}
        initialData={defineBillingData}
        onChange={setContactBilling}
      />
      <hr className={styles.divDer} />
      <ContactSearch
        disabled={disabled}
        labelContact="Correspond Contact"
        isMobile={isMobile}
        initialData={defineCorrespondData}
        onChange={setContactCorrespond}
      />
    </div>
  )
}
