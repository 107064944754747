import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { ITaskTableItem } from './task-list.types'
import { dateConverter } from '../../../common/utils/date.utils'

export const taskColumns: IXtTableColumn<ITaskTableItem>[] = [
  {
    id: 'type',
    field: 'type',
    headerName: 'Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'priority',
    field: 'priority',
    headerName: 'Priority',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'assignedto',
    field: 'assignedto',
    headerName: 'Assigned To',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'number',
    field: 'number',
    headerName: 'Number',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'name',
    field: 'name',
    headerName: 'Name',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Stage',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'due_date',
    field: 'due_date',
    headerName: 'Due date',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'account_number',
    field: 'account_number',
    headerName: 'Account Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'parent_number',
    field: 'parent_number',
    headerName: 'Parent',
    flex: '1 0 160px',
    width: 160,
  },
]
