import React, { useState, useEffect } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import UploadService from "../services/FileUploadService";
import http from "../common/api"

const FileUpload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [fileInfos, setFileInfos] = useState([]);
  const [loading, setLoading] = useState(false);

   useEffect(() => {
    retrieveFileList();
  }, [props.documentType, props.documentNumber]);

  const retrieveFileList = () => {
    setMessage("");
    if (props.documentNumber && props.documentType) {
      http.get("/doc/" + props.documentType + "/" + props.documentNumber).then(
        (response) => {
          setFileInfos(response.data.data);
        },
        (error) => {
          const _msg =  (error.response && error.response.data && error.response.data.status.message) || 
                        error.message ||
                        error.toString();
          setMessage(_msg);
        }
      );
    }
    setLoading(false);
  }

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    UploadService.upload(currentFile, props.documentType, props.documentId, props.documentNumber, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        setMessage(response.data.message);
        setCurrentFile(null);
        retrieveFileList();
      });

    setSelectedFiles(undefined);
  };

  return (
    <div>
      {loading ?
        <LoadingSpinner />: 
        <div className="card">
          {message &&
            <div className="alert alert-light" role="alert">
              {message}
            </div>
          }
          <ul className="list-group list-group-flush">
            {fileInfos &&
              fileInfos.map((file, index) => (
                <li className="list-group-item" key={index}>
                  <a href={"http://10.131.167.237:5000/v0/file/"+file.name}>{file.name}</a>
                </li>
              ))}
          </ul>
          {currentFile && (
            <div className="progress">
              <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}
          <label className="btn btn-default text-left">
            <input type="file" onChange={selectFile} />
          </label>
            <button 
              className="btn btn-success"
              disabled={!selectedFiles}
              onClick={upload}
            > Upload
            </button>
        </div>
      }
    </div>
  );
};

export default FileUpload;
