import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { XtButton } from 'components/xtButton/XtButton'
import { useHistory, useRouteMatch } from 'react-router'
import { cls } from 'common/utils'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { ToastService } from 'services/ToasterService'
import { XtList } from 'components/list/list'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { useTable } from 'common/hooks/useTable'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ErrorHandler } from 'services/ErrorService'
import { XtFilter } from 'components/filter/filter'
import { useFilter } from 'common/hooks/filter'

import { confirmationMessages, xsMq } from '../../common/constants'
import { CRMProspectActionsEditMode, crmProspectColumns, defaultDeletionState, defaultFilterValues } from './crm-prospects-list.constants'
import { CRMProspectAction, ICRMProspectTableItem, IDeletionState } from './crm-prospects-list.types'
import { CRMProspectDetailsMode } from '../crm-prospect-details/crm-prospect-details.types'
import { CRMProspectService, ICRMProspectFilters } from '../crm-prospects.service'
import { buildFilters, normalizeData } from './crm-prospects-list.utils'
import * as styles from './crm-prospects-list.module.scss'

const fetchCRMProspects = async (
  filters: ICRMProspectFilters,
  paginationParams: IPaginationParams
): Promise<IPaginationData<ICRMProspectTableItem>> => {
  const { total, data } = await CRMProspectService.getAll(paginationParams, filters)
  return {
    data: normalizeData(data),
    total,
  }
}

export const CRMProspectsList: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  const [deletionState, setDeletionState] = useState<IDeletionState>(defaultDeletionState)
  const { state, refresh, pagination, filter } = useTable({}, fetchCRMProspects)

  const { openFilters, closeFilters, onFiltersSubmit, filterOpen, filters } = useFilter(buildFilters, filter)

  const openCRMProspectDetails = useCallback<(item: ICRMProspectTableItem, mode?: CRMProspectDetailsMode) => void>(
    ({ id }, mode: CRMProspectDetailsMode = CRMProspectDetailsMode.View) => history.push(`${path}/${id}/${mode}`),
    [path, history]
  )

  const handleRowClick = useCallback<(item: ICRMProspectTableItem) => void>(
    (item) => openCRMProspectDetails(item, CRMProspectDetailsMode.View),
    [openCRMProspectDetails]
  )

  const handleAction = useCallback<(item: ICRMProspectTableItem, action: CRMProspectAction) => void>(
    (item, action) => {
      switch (action) {
        case CRMProspectAction.Edit:
          return openCRMProspectDetails(item, CRMProspectDetailsMode.Edit)
        case CRMProspectAction.View:
          return openCRMProspectDetails(item, CRMProspectDetailsMode.View)
        case CRMProspectAction.Delete:
          return !state.loading && setDeletionState({ CRMProspectNumberId: item.number, confirmationOpen: true })
        default:
          return null
      }
    },
    [state.loading, openCRMProspectDetails, setDeletionState]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.CRMProspectNumberId) {
      try {
        await CRMProspectService.delete(deletionState.CRMProspectNumberId)
        await refresh()
        ToastService.showSuccess('Prospect has been deleted.')
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    }
  }, [deletionState.CRMProspectNumberId, refresh, closeConfirmationDialog])

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Prospect"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      {filters.length && (
        <XtFilter
          open={filterOpen}
          label="Filters"
          onClose={closeFilters}
          onSubmit={onFiltersSubmit}
          filters={filters}
          defaultValues={defaultFilterValues}
        />
      )}
      <div className={cls('xt-content', styles.listContent)}>
        <h1 className="xt-page-header">CRM Propsects</h1>
        <div hidden={isMobile} className={styles.headerContent}>
          <XtButton label="Filters" onClick={openFilters} loading={!filters.length} />

          <XtButton
            className={styles.newButton}
            label="Create CRM Prospect"
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push(`${path}/new`)}
          />
        </div>
        <XtList
          actions={CRMProspectActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data}
          columns={crmProspectColumns}
        />
      </div>
    </div>
  )
}
