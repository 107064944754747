import { AxiosInstance } from 'axios'
import api from 'common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { ICRMAccount } from './crm-accounts.types'

export interface ICRMAccountFilters {
  assignedto?: string | null
  showDetail?: boolean // TODO: check if this really belongs to filters (it controls whether more data will be passed)
  lastupdated?: string
  deletedsince?: string
  showInactive?: boolean
  customer_number_pattern?: string
  customer_name?: string
  customer_type?: string
  contact_name?: string
  city?: string
  state?: string
  postalcode?: string
  country?: string
  sales_rep?: string
  hold_type?: string
}

export interface ICRMAccountService {
  getAll: (pagination: IPaginationParams, filters?: ICRMAccountFilters) => Promise<IPaginationData<ICRMAccount>>
  get(crmaccount: string): Promise<ICRMAccount>
  delete(crmaccountId: string): Promise<void>
}

class Service implements ICRMAccountService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll(paginationParams: IPaginationParams, filters?: ICRMAccountFilters): Promise<IPaginationData<ICRMAccount>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICRMAccount>>('/crmaccount', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(crmaccount: string): Promise<ICRMAccount> {
    const response = await this.apiClient.get<IResponse<ICRMAccount>>(`/crmaccount/${crmaccount}`)
    if (!Object.keys(response.data.data).length) {
      throw new Error(`CRM Account: ${crmaccount} not found.`)
    }
    return response.data.data
  }

  public async delete(crmaccountId: string): Promise<void> {
    const body = { data: { number: crmaccountId } }
    await this.apiClient.post('/crmaccount/delete', body)
  }
}

export const CRMAccountService = new Service(api)
