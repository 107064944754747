import { AxiosInstance } from 'axios'
import { IResponse } from 'common/common.types'
import api from '../common/api'

export type DataDashboard = Array<string | number | object | Date>
export interface IDashboard {
  data: DataDashboard
  labels: string[]
}
export enum FilterLabelColumn {
  Item = 'item_number',
  Cust = 'cust_number',
}
export enum FilterGroup {
  ByItem = 'byItem',
  ByCust = 'byCustomer',
}

export interface IDashboardService {
  getSales(group: FilterGroup, labelcolumn: FilterLabelColumn): Promise<IDashboard>
  getDelivery(): Promise<IDashboard>
}

class Service implements IDashboardService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getSales(group: string, labelcolumn: string): Promise<IDashboard> {
    const params: Record<string, true | number | string> = {
      labelcolumn,
      timeframe: 'year',
    }
    if (group) {
      params.group = group
    }
    const response = await this.apiClient.get<IResponse<IDashboard>>(`/dashboard/summarizedsaleshistory`, { params })

    return {
      data: response.data.data.data,
      labels: response.data.data.labels,
    }
  }

  public async getDelivery(): Promise<IDashboard> {
    const response = await this.apiClient.get<IResponse<IDashboard>>(`/dashboard/ontimedelivery`)

    return {
      data: response.data.data.data,
      labels: response.data.data.labels,
    }
  }
}

export const DashboardService = new Service(api)
