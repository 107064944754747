import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as styles from './comments.module.scss'
import { XtComment } from '../comment/comment'
import { DocumentType } from '../../documents/documents.types'
import { XtQuickAddButton } from '../../components/quickAddButton/QuickAddButton'
import { CommentItem, CommentItemInput } from '../comment/comment.types'
import { cls } from '../../common/utils'
import LoadingSpinner from '../../components/LoadingSpinner'

export interface IXtCommentsPaginationParams {
  canLoadMore(): boolean
  loadMore(): Promise<void>
  total: number
}

export interface IXtCommentsParams extends IXtCommentsPaginationParams {
  username?: string
  comments: CommentItem[]
  hidden?: boolean
  creationEnabled?: boolean
  disabled?: boolean
  onUpdate: (comments: CommentItem) => void | Promise<void>
  onAdd: (comment: CommentItem) => boolean | Promise<boolean>
  className?: string
}

export interface IXtCommentsState {
  newComment: CommentItemInput | null
}

const createNewCommentPlaceholder: (username?: string) => CommentItemInput = (username) => ({
  id: new Date().getTime(),
  created: new Date().toISOString(),
  comment: '',
  comment_type: null,
  comment_source: '',
  source: DocumentType.Item,
  username: username ?? '',
  editable: true,
})

export const XtComments: FC<IXtCommentsParams> = React.memo(
  ({
    comments,
    username,
    onUpdate,
    onAdd,
    hidden = false,
    disabled = false,
    creationEnabled = false,
    canLoadMore,
    loadMore,
    className,
  }) => {
    const [state, setState] = useState<IXtCommentsState>({ newComment: null })

    const addNewComment = useCallback<() => void>(() => {
      setState({ newComment: createNewCommentPlaceholder(username) })
    }, [username])

    const saveNewComment = useCallback<(comment: CommentItem) => void | Promise<void>>(
      async (comment) => {
        if (typeof onAdd !== 'function') {
          throw new Error('XtComments: onAdd callback should be a function.')
        }
        const saved = await onAdd(comment)
        // After the comment is saved, "comments" param should be updated to add the created comment in the list. So, we should remove the placeholder from the list.
        if (saved) {
          setState({ newComment: null })
        }
      },
      [onAdd]
    )

    const onCommentUpdate = useCallback<(comment: CommentItem) => void | Promise<void>>(
      async (comment) => {
        if (typeof onUpdate !== 'function') {
          throw new Error('XtComments: onChange callback should be a function.')
        }
        await onUpdate(comment)
      },
      [onUpdate]
    )

    return (
      <div hidden={hidden} className={cls(styles.xtComments, className)} id="xt-comments-scrollable">
        <InfiniteScroll
          scrollableTarget="xt-comments-scrollable"
          className={styles.xtCommentsList}
          next={loadMore}
          hasMore={canLoadMore()}
          dataLength={comments.length}
          loader={<LoadingSpinner />}
        >
          {state.newComment && <XtComment key={state.newComment.id.toString()} comment={state.newComment} onUpdate={saveNewComment} />}
          {comments.map((comment) => (
            <XtComment
              disabled={disabled || username !== comment.username || !comment.editable}
              key={comment.id.toString()}
              comment={comment}
              onUpdate={onCommentUpdate}
            />
          ))}
        </InfiniteScroll>
        <div className={styles.stickyWrapper}>
          <XtQuickAddButton
            hidden={!creationEnabled}
            className={styles.xtCommentsAddButton}
            disabled={disabled || !!state.newComment}
            onClick={addNewComment}
          />
        </div>
      </div>
    )
  }
)
