import * as React from 'react'
import { FC } from 'react'
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import { ItemsList } from '../items/items-list/items-list'
import { ItemForm } from '../items/item-form/item-form'

export const Products: FC<RouteComponentProps> = ({ match }) => (
  <div className="content">
    <Switch>
      <Route exact path={`${match.path}/items/`}>
        <ItemsList />
      </Route>
      <Route exact path={`${match.path}/items/:itemNumber`}>
        <ItemForm />
      </Route>
      <Redirect to={`${match.path}/items/`} />
    </Switch>
  </div>
)
