import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { ErrorHandler } from 'services/ErrorService'
import { AuthService } from 'auth/auth.service'
import { XtButton } from 'components/xtButton/XtButton'
import { cls } from 'common/utils'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { useTable } from 'common/hooks/useTable'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { IFilterFormState } from 'components/filter/filter.types'
import { ITaskListFilters, TaskService } from 'tasks/tasks.service'
import { TaskDetailsMode } from 'tasks/task-details/task-details.types'
import { ToastService } from 'services/ToasterService'
import { XtFilter } from 'components/filter/filter'
import { XtFilterButton } from 'components/filter/filterButton'
import { useFilter } from 'common/hooks/filter'
import { CheckboxLabel } from 'common/utils/form/form.components'
import { IPaginationData, IPaginationParams } from '../../common/common.types'
import { IDeletionState, ITaskTableItem, TaskListAction } from './task-list.types'
import { confirmationMessages, xsMq } from '../../common/constants'
import { defaulDeletionState, taskColumns, taskFilterDefaultValues, taskFilters, TaskListActionsEditMode } from './task-list.constants'
import * as styles from './task-list.module.scss'
import { XtList } from '../../components/list/list'
import { normalizedData, postprocessFilters } from './task-list.utils'

const fetchTasks = async (filters: ITaskListFilters, paginationParams: IPaginationParams): Promise<IPaginationData<ITaskTableItem>> => {
  const { total, data } = await TaskService.getAll(paginationParams, filters)
  return {
    data: normalizedData(data),
    total,
  }
}

export const TaskList: FC = () => {
  const authUser = AuthService.getCurrentUser()
  const currentUser = authUser?.username ?? null

  const history = useHistory()
  const { path } = useRouteMatch()
  // TODO implement modes
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaulDeletionState)

  const { state, setLoading, refresh, filter, pagination } = useTable({ user: currentUser, showcompleted: false }, fetchTasks)

  const { openFilters, closeFilters, onFiltersSubmit, filterOpen, filters } = useFilter(async () => taskFilters, filter)

  const onShowCompletedChange = async (checked: boolean): Promise<void> => {
    await filter({ ...state.filters, showcompleted: checked })
  }
  const onOnlyMyTasksChange = async (checked: boolean): Promise<void> => {
    await filter({ ...state.filters, user: checked ? currentUser : null })
  }

  const openTaskDetails = useCallback<(item: ITaskTableItem, mode?: TaskDetailsMode) => void>(
    ({ id }, mode: TaskDetailsMode = TaskDetailsMode.View) => history.push(`${path}/${id}/${mode}`),
    [path, history]
  )

  const handleRowClick = useCallback<(item: ITaskTableItem) => void>((item) => openTaskDetails(item, TaskDetailsMode.View), [
    openTaskDetails,
  ])

  const markAsCompletedAction = useCallback(
    async (item: ITaskTableItem) => {
      await TaskService.complete({ id: item.id, completed_date: new Date() })
      await refresh()
    },
    [refresh]
  )

  const handleAction = useCallback<(item: ITaskTableItem, action: TaskListAction) => void>(
    (item, action) => {
      switch (action) {
        case TaskListAction.Edit:
          return openTaskDetails(item, TaskDetailsMode.Edit)
        case TaskListAction.View:
          return openTaskDetails(item, TaskDetailsMode.View)
        case TaskListAction.Delete:
          return !state.loading && setDeletionState({ task: { id: Number(item.id), name: item.name }, confirmationOpen: true })
        case TaskListAction.MarkAsCompleted:
          return markAsCompletedAction(item)
        default:
          return null
      }
    },
    [history, path, state.loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaulDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.task.id) {
      try {
        await TaskService.delete(deletionState.task.id)
        ToastService.showSuccess(`Task ${deletionState.task.name} has been deleted.`)
        await refresh()
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    }
  }, [deletionState.task, setLoading, refresh, closeConfirmationDialog])

  const filtersLoaded = Boolean(filters.length)

  const onFilterSubmitWithConversion = useCallback(
    async (filterState: IFilterFormState): Promise<void> => {
      onFiltersSubmit(postprocessFilters(filterState))
    },
    [onFiltersSubmit]
  )

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Task"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      {filtersLoaded && (
        <XtFilter
          open={filterOpen}
          label="Filters"
          onClose={closeFilters}
          onSubmit={onFilterSubmitWithConversion}
          filters={filters}
          defaultValues={taskFilterDefaultValues}
        />
      )}
      <div className={cls('xt-content', styles.taskListContent)}>
        <h1 className={cls('xt-page-title', 'xt-section-border', styles.taskListHeader)}>Tasks</h1>
        <div className={styles.taskListFilters}>
          {/* TODO implementation all filters */}
          <XtFilterButton isMobile={isMobile} loading={state.loading || !filtersLoaded} onClick={openFilters} />
          <CheckboxLabel
            value={state.filters.user === currentUser}
            label="Only Show My Tasks"
            disabled={state.loading}
            onChange={onOnlyMyTasksChange}
          />
          <CheckboxLabel
            value={Boolean(state.filters.showcompleted)}
            label="Show Completed"
            disabled={state.loading}
            onChange={onShowCompletedChange}
          />
          <XtButton
            hidden={isMobile}
            className={styles.newButton}
            label="New Task"
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push(`${path}/new`)}
          />
        </div>
        <XtList
          actions={TaskListActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data}
          columns={taskColumns}
        />
      </div>
    </div>
  )
}
