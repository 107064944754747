import { CSSProperties } from 'react'
import { IXtTableColumn, TableValueConverter } from './table-head/table-head.types'
import { ITableRow } from './table.types'
import { resolvePath } from '../../common/utils/object.utils'

export function definePositionStyles(isSticky: boolean = false): CSSProperties {
  return !isSticky ? {} : { position: 'sticky', right: 0, backgroundColor: 'inherit' }
}

export function calculateMinWidth<T extends ITableRow>(columns: IXtTableColumn<T>[]): number {
  const rowPadding = 30 // padding-left
  return columns.reduce((width, col) => width + (col.width ?? 0), rowPadding)
}

export function getFieldValue<TData extends ITableRow>(data: TData, field: string, converter?: TableValueConverter): string {
  const fieldValue = resolvePath<string>(data, field) ?? ''
  return typeof converter !== 'function' ? fieldValue : converter(fieldValue)
}
