import React from 'react'
import { FieldPath } from 'react-hook-form/dist/types'
import { IXtAutocompleteOption, XtAutocompleteLoadOptionsFunc } from '../xtAutocomplete/XtAutocomplete.types'

export type FilterValue = string | number | Date | boolean

export enum FilterType {
  Dropdown,
  DropdownMultiple,
  Checkbox,
  Radio,
  Date,
  Text,
  Autocomplete,
}

export type FilterMakerFunction = <TOption extends IXtAutocompleteOption>(filter: IFilter<TOption>) => JSX.Element

export interface IFilterAutocompleteProps<TOption extends IXtAutocompleteOption> {
  onChange?(option: TOption | null): void
  value?: TOption | null
  loadOptions: XtAutocompleteLoadOptionsFunc<TOption>
  renderOption?: (option: TOption) => React.ReactNode
  getInputLabel?(option: TOption | null): string
}

export interface IFilter<TOption extends IXtAutocompleteOption = IXtAutocompleteOption> {
  type: FilterType
  label: string
  fieldName: FieldPath<IFilterFormState> // TODO use more strict type
  options?: IFilterOption[]
  autocompleteProps?: IFilterAutocompleteProps<TOption>
}

export interface IFilterOption {
  label: string
  value: FilterValue
}

export interface IFilterDescription<TFilters> {
  filters: TFilters
  open: boolean
  label?: string
  onSubmit: (values: IFilterFormState) => void
  onClose: () => void
  defaultValues: IFilterFormState
}

export interface IFilterFormState {
  [key: string]: FilterValue | FilterValue[] | IFilterOption[] | IXtAutocompleteOption | null
}

export interface IMultipleFilterFormState {
  [key: string]: FilterValue[]
}
