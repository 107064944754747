import { NONE_VALUE } from 'routing/routingItem/RoutingItem.constants'
import { UomService } from 'services/UomService'
import { StandardOperationService } from 'services/StandardOperationService'
import { WorkCenterService } from 'services/WorkCenterService'
import { OperationTypeService } from 'services/OperationTypeService'
import { CRMRoleService, RoleAppliesto } from 'services/crm-role.service'
import { ContactService } from 'contacts/contacts.service'

import { DictionaryService } from 'services/dictionary.service'
import { UserService } from 'services/user.service'
import { CRMAccountService } from 'crm-accounts/crm-accounts.service'
import { CRMProspectService } from 'crm-prospects/crm-prospects.service'
import { XtAutocompleteLoadOptionsFunc, IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { buildFullContactName } from 'contacts/contacts.utils'
import { SalesRepService } from 'services/sales-rep.service'
import { ProjectService } from 'services/project.service'
import { IPaginationData } from './common.types'
import { CustomersService } from '../customers/customers.service'
import { ShipmentsService } from '../shipments/shipments.service'
import { TermsService } from '../services/terms.service'

export const cls = (...classes: (string | false | undefined)[]): string => classes.filter(Boolean).join(' ')

export const sleep = (ms: number): Promise<ReturnType<typeof setTimeout>> => new Promise((resolve) => setTimeout(resolve, ms))

export const reorderArray = <TData>(list: TData[], oldIndex: number, newIndex: number): TData[] => {
  const result = Array.from(list)
  const [removed] = result.splice(oldIndex, 1)
  result.splice(newIndex, 0, removed)

  return result
}

export const loadUomOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await UomService.getAll({ limit, page })
  return {
    total,
    data: data.map(({ name }) => ({ id: name, label: name })),
  }
}

export const loadStandardOperationOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await StandardOperationService.getAll({ limit, page })
  const customData = [...data, ...[{ number: NONE_VALUE }]]
  return {
    total,
    data: customData.map(({ number }) => ({ id: number, label: number })),
  }
}

export const loadWorkCenterOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await WorkCenterService.getAll({ limit, page })
  return {
    total,
    data: data.map(({ code }) => ({ id: code, label: code })),
  }
}

export const loadOperationTypeOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await OperationTypeService.getAll({ limit, page })
  return {
    total,
    data: data.map(({ description, code }) => ({ id: code, label: description })),
  }
}

export const loadUserOptions: XtAutocompleteLoadOptionsFunc = async (page, limit, propername_pattern) => {
  const { total, data } = await UserService.getAll({ limit, page }, propername_pattern)

  return {
    total,
    data: data.map(({ username, proper_name }) => ({ id: username, label: `${username}-${proper_name}` })),
  }
}

export const loadCRMContactRoleOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await CRMRoleService.getAll({ limit, page })
  const filterData = data.filter(({ appliesto }) => appliesto.includes(RoleAppliesto.Contact))
  return {
    total,
    data: filterData.map(({ name }) => ({ id: name, label: name })),
  }
}

export const loadSalesRepOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await SalesRepService.getAll({ limit, page })
  return {
    total,
    data: data.map(({ number }) => ({ id: number, label: `${number}` })),
  }
}

export const loadCurrencyOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await DictionaryService.getCurrencies({ limit, page })
  return {
    total,
    data: data.map(({ iso_code }) => ({ id: iso_code, label: `${iso_code}` })),
  }
}

export const loadCRMPhoneRoleOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await CRMRoleService.getAll({ limit, page })
  const filterData = data.filter(({ appliesto }) => appliesto.includes(RoleAppliesto.Phone))
  return {
    total,
    data: filterData.map(({ name }) => ({ id: name, label: name })),
  }
}

export const loadProjectOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await ProjectService.getAll({ limit, page })

  return {
    total,
    data: data.map((projects) => ({ ...projects, id: projects.number, label: projects.name })),
  }
}

export const loadContactOptions: XtAutocompleteLoadOptionsFunc = async (page, limit) => {
  const { total, data } = await ContactService.getAll({ limit, page })
  return {
    total,
    data: data.map((item) => ({
      id: item.contact_number,
      label: buildFullContactName(item),
      ...item,
    })),
  }
}

export const loadAccountOptions: XtAutocompleteLoadOptionsFunc = async (page, limit, customer_name) => {
  const { total, data } = await CRMAccountService.getAll({ limit, page }, { customer_name })
  return {
    total,
    data: data.map(({ number, name }) => ({ id: number, label: `${number}-${name}` })),
  }
}

export const loadStates: XtAutocompleteLoadOptionsFunc = async (page, limit, countryId) => {
  if (!countryId) {
    return { data: [], total: 0 }
  }

  const { total, data } = await DictionaryService.getStates(countryId, { limit, page })

  return {
    total,
    data: data.map(({ state_code, state }) => ({ id: state_code, label: state })),
  }
}

export const loadProspectOptions: XtAutocompleteLoadOptionsFunc = async (page, limit, prospect_number) => {
  const { total, data } = await CRMProspectService.getAll({ limit, page }, { prospect_number })
  return {
    total,
    data: data.map(({ number, name }) => ({ id: number, label: `${number}-${name}` })),
  }
}

export async function loadSalesReps(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await CustomersService.getSalesReps({ page, limit })
  return {
    data: data.map(({ number, name }) => ({ id: number, label: name })),
    total,
  }
}

export async function loadShipmentsVia(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await ShipmentsService.getShipmentsVia({ page, limit })
  return {
    data: data.map(({ code, description }) => ({ id: code, label: description })),
    total,
  }
}

export async function loadTerms(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  const { data, total } = await TermsService.getAll({ page, limit })
  return {
    data: data.map(({ code, description }) => ({ id: code, label: description })),
    total,
  }
}

export function processAutocompleteValue(value: string | null | undefined): IXtAutocompleteOption | null {
  return value ? { id: value, label: value } : null
}

export function defineAutocompleteOption(data?: string): IXtAutocompleteOption | null {
  return data ? { id: data, label: data } : null
}
export function parseAutocompleteOption(data: IXtAutocompleteOption | null): string {
  return data?.id ? data.id : ''
}
