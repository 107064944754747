import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { cls } from 'common/utils'
import { TaskService } from 'tasks/tasks.service'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import LoadingSpinner from 'components/LoadingSpinner'
import { ITaskCreatePayload } from 'tasks/tasks.types'
import { ToastService } from 'services/ToasterService'
import { useMediaQuery } from '@material-ui/core'
import { xsMq } from 'common/constants'
import { XtItemNumber } from 'components/item-number/item-number'
import { useCharacteristics } from 'common/hooks/characteristics'
import { useDocuments } from 'common/hooks/documents'
import { AdditionalDataCreationError } from 'common/common.types'
import { TaskFormSchema } from './task-details-form/task-details-form.validation'
import { ITaskDetails, ITaskDetailsState, ITaskForm } from './task-details.types'
import * as styles from './task-details.module.scss'
import { convertFormDataToTaskPayload, convertMode, defineFormState } from './task-details.utils'
import { TaskDetailsForm } from './task-details-form/task-details-form'
import { TaskDetailsTabs } from './task-details-tabs/task-details-tabs'
import { useRemarks } from '../../common/hooks/remarks'
import { DocumentType, IAttachedDocumentWithFile } from '../../documents/documents.types'
import { NewComment } from '../../comments/comments.types'
import { useAssignees } from './task-details-assignees.hook'

const defaultTaskState = {
  loading: false,
  task: null,
  isCompletedTask: false,
}

export const TaskDetails: FC<ITaskDetails> = ({ id, mode, className, onCancel, onSubmit: onSubmitDialog }) => {
  const isMobile = useMediaQuery(xsMq)

  const [isViewMode, isEditMode] = convertMode(mode, !id)
  const isNewMode = !id && !mode

  const [state, setState] = useState<ITaskDetailsState>(defaultTaskState)
  const remarksState = useRemarks(DocumentType.Task, state.task?.number, state.task?.notes)
  const characteristicsState = useCharacteristics([])
  const assigneesState = useAssignees([])
  const documentsState = useDocuments(DocumentType.Task, state.task?.number)

  const formMethods = useForm({
    defaultValues: defineFormState(null),
    resolver: yupResolver(TaskFormSchema),
    mode: 'all',
  })
  const {
    formState: { isDirty: formStateIsDirty, isSubmitting },
    reset,
    handleSubmit,
  } = formMethods

  const isDirty =
    formStateIsDirty || remarksState.isDirty || characteristicsState.isDirty || documentsState.isDirty || assigneesState.isDirty

  async function init(): Promise<void> {
    try {
      setState((prev) => ({ ...prev, loading: true, task: null }))
      if (!id || isNewMode) {
        setState(defaultTaskState)
        return
      }
      const taskData = await TaskService.get(id)
      reset(defineFormState(taskData))
      setState((prev) => ({
        ...prev,
        loading: false,
        task: taskData,
        isCompletedTask: !!taskData.completed_date,
      }))
      characteristicsState.reset(taskData.task_characteristics ?? [])
      assigneesState.reset(taskData.assignees ?? [])
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  useEffect(() => void init(), [isMobile])

  const saveTask = async (task: ITaskCreatePayload, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<void> => {
    try {
      await TaskService.create(task, comments, documents)
    } catch (e) {
      // We should notify user that the task is created, but the comments saving failed.
      if (e instanceof AdditionalDataCreationError) {
        ErrorHandler.handleError(e)
      } else {
        throw e
      }
    }
  }

  const onSubmitForm: (data: ITaskForm) => Promise<void> = async (formValues) => {
    try {
      const payload: ITaskCreatePayload = {
        ...convertFormDataToTaskPayload(
          formValues,
          assigneesState.assignees,
          remarksState.notes,
          remarksState.username,
          characteristicsState.characteristics
        ),
      }
      if (!state.task || !isEditMode) {
        await saveTask(payload, remarksState.comments, documentsState.getUnsavedDocuments())
        ToastService.showSuccess(`Task ${payload.name} has been created.`)
      } else {
        const updatePayload = { ...payload, id: state.task.id, number: state.task.number }
        await TaskService.update(updatePayload)
        ToastService.showSuccess(`Task ${updatePayload.name} has been updated.`)
        if (state.isCompletedTask && formValues.completed_date) {
          // TODO research the API to complete the task using Update endpoint
          await TaskService.complete({ id: updatePayload.id, completed_date: formValues.completed_date })
        }
      }
      onCancel()
      if (onSubmitDialog) {
        onSubmitDialog()
      }
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const onSubmit: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSubmitForm, (error) => console.error(error))
    void eventHandler(e)
  }
  const disabled = isViewMode || isSubmitting || isMobile

  const handleCompletedChange = useCallback<(checked: boolean) => void>((checked) => {
    setState((prev) => ({ ...prev, isCompletedTask: checked }))
  }, [])

  const onCancelTask = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }
    onCancel()
  }
  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formMethods}>
        {state.loading && <LoadingSpinner />}
        <form hidden={state.loading} onSubmit={onSubmit} className={className}>
          <div className={cls(styles.taskDetailsHeader, 'xt-section-border')}>
            <XtItemNumber itemNumber={!isNewMode ? `Task Number: ${state.task?.number}` : 'New Task'} />
            <div className={styles.taskDetailsHeaderButtons}>
              <XtButton label="Cancel" onClick={onCancelTask} />
              <XtButton hidden={isViewMode} disabled={disabled || !isDirty} loading={isSubmitting} label="Save" type="submit" />
            </div>
          </div>
          <div className={styles.taskDetailsContent}>
            <TaskDetailsForm
              isCompletedTask={state.isCompletedTask}
              setCompletedTask={handleCompletedChange}
              initialData={state.task}
              isViewMode={isViewMode}
            />
            <TaskDetailsTabs
              assignees={assigneesState.assignees}
              onChange={assigneesState.onChange}
              isMobile={isMobile}
              disabled={disabled}
              remarks={remarksState}
              characteristics={characteristicsState}
              documents={documentsState}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
