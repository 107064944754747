import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { BomListAction, IBomTableItem } from './BomList.types'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'

export const bomListActionsEditMode: IXtTableCellAction[] = [
  {
    name: BomListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: BomListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: BomListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: BomListAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]

export const bomListActionsViewMode: IXtTableCellAction[] = [
  {
    name: BomListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const bomListColumns: IXtTableColumn<IBomTableItem>[] = [
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'description',
    field: 'item_description',
    headerName: 'Description',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'revision',
    field: 'revision',
    headerName: 'Revision',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'revision_status',
    field: 'revision_status',
    headerName: 'Revision Status',
    flex: '1 0 180px',
    width: 180,
  },
]

export const defaultDeletionState = { itemId: null, confirmationOpen: false }
