import * as React from 'react'
import { Controller, Control } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { IFilterFormState, IFilter } from './filter.types'
import styles from './filter.module.scss'
import { IXtAutocompleteOption } from '../xtAutocomplete/XtAutocomplete.types'

export function makeRadioFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>
): JSX.Element {
  if (!filter.options) {
    throw new Error('Radio options for radio filter are undefined')
  }

  return (
    <Controller
      key={`filter-${filter.fieldName}-controller`}
      control={control}
      name={filter.fieldName}
      render={({ field: { onChange, onBlur, value } }) => (
        <RadioGroup classes={{ root: styles.muiRadioGroupRoot }} id={`filter-${filter.fieldName}`}>
          <p className={styles.radioLabel}>{filter.label}</p>
          {(filter.options ?? []).map((option, index) => (
            <FormControlLabel
              control={
                <Radio color="primary" classes={{ root: styles.muiRadioRoot, checked: styles.muiRadioChecked }} name={filter.fieldName} />
              }
              label={option.label}
              value={String(index)}
              key={`${String(option.value)}-${option.label}`}
              onChange={(e) => onChange((e.target as HTMLInputElement).value)}
              onBlur={onBlur}
              checked={value === String(index)}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}
