import { useCallback, useEffect, useState } from 'react'
import { ICommentsHook, useComments } from './comments'
import { DocumentType } from '../../documents/documents.types'

export interface IRemarksHook extends ICommentsHook {
  notes: string
  additionalNotes: string
  isDirty: boolean

  /**
   * Resets the remarks state. Should be used to reset isDirty state.
   * @param notes
   * @param additionalNotes
   */
  reset(notes: string, additionalNotes?: string): void
  setNotes(notes: string): void
  setAdditionalNotes(notes: string): void
}

interface IRemarksHookState {
  notes: string
  initialNotes: string
  additionalNotes: string
  initialAdditionalNotes: string
}

export function useRemarks(
  source: DocumentType,
  sourceNumber: string | null | undefined,
  initialNotes: string = '',
  initialAdditionalNotes: string = ''
): IRemarksHook {
  const commentsState = useComments(source, sourceNumber)
  const [state, setState] = useState<IRemarksHookState>({
    notes: initialNotes,
    initialNotes,
    additionalNotes: initialAdditionalNotes,
    initialAdditionalNotes,
  })

  const setNotes = useCallback<(notes: string) => void>((notes) => setState((prevState) => ({ ...prevState, notes })), [])

  const setAdditionalNotes = useCallback<(additionalNotes: string) => void>(
    (additionalNotes) => setState((prevState) => ({ ...prevState, additionalNotes })),
    []
  )

  const reset = useCallback<(notes: string, additionalNotes?: string) => void>(
    (notes, additionalNotes = '') =>
      setState((prevState) => ({ ...prevState, notes, additionalNotes, initialNotes: notes, initialAdditionalNotes: additionalNotes })),
    []
  )

  useEffect(() => {
    reset(initialNotes, initialAdditionalNotes)
  }, [initialNotes, initialAdditionalNotes])

  return {
    notes: state.notes,
    additionalNotes: state.additionalNotes,
    setNotes,
    setAdditionalNotes,
    reset,
    isDirty: state.notes !== state.initialNotes || state.additionalNotes !== state.initialAdditionalNotes,
    ...commentsState,
  }
}
