import * as React from 'react'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ToastService } from 'services/ToasterService'
import { ErrorHandler } from 'services/ErrorService'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { XtItemNumber } from 'components/item-number/item-number'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { BomDetailsSection } from '../components/bomDetailsSection/BomDetailsSection'
import { BomItemQuickAdd } from '../components/bomItemQuickAdd/BomItemQuickAdd'
import { XtQuickAddButton } from '../../components/quickAddButton/QuickAddButton'
import { BomService } from '../services/BomService'
import { cls } from '../../common/utils'
import { confirmationMessages, xsMq } from '../../common/constants'
import './BomDetailsMui.scss'
import * as styles from './BomDetails.module.scss'
import { SvgIcon } from '../../components/svgIcon/SvgIcon'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { XtButton } from '../../components/xtButton/XtButton'
import { BomDetailsAction, BomFormData, IBomDetailsParams, IBomItemDialogState, IBomState, IItemDeletionState } from './BomDetails.types'
import { BomDetailsMode, BomItemQuickAddInput, IBomItemsFilters, IBomItem } from '../bom.types'
import LoadingSpinner from '../../components/LoadingSpinner'
import { bomDetailsValidationSchema } from '../validation-schema'
import { BomDetailsForm } from '../components/bomDetailsForm/BomDetailsForm'
import { defineAvailableActions, defineFormData, findBomItem, submitBomItemDialog } from './BomDetails.utils'
import { BomItemService } from '../services/BomItemService'
import { BomItemMode } from '../bomItem/BomItem.types'
import { BomItem } from '../bomItem/BomItem'
import { bomDetailsTableColumns, defaulDeletionState, defaultBomItemDialogState, defaultBomState } from './BomDetails.constants'
import { NumberTypeUtils } from '../../common/typeUtils'
import { XtList } from '../../components/list/list'
import { useTable } from '../../common/hooks/useTable'

// TODO user role limitations
export const BomDetails: FC = () => {
  const [deletionState, setDeletionState] = useState<IItemDeletionState>(defaulDeletionState)
  const [isQuickAddOpen, setOpenQuickAdd] = useState(false)

  const [bomItemDialogState, setBomItemDialogState] = useState<IBomItemDialogState>(defaultBomItemDialogState)
  const { itemNumber, mode } = useParams<IBomDetailsParams>()
  const history = useHistory()
  const anchorEl = React.useRef(null)
  const isMobile = useMediaQuery(xsMq)

  const [bomData, setBomData] = useState<IBomState>(defaultBomState)

  const fetchBomItems = useCallback(
    (filters: IBomItemsFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IBomItem>> =>
      BomItemService.getAll(itemNumber, filters, paginationParams),
    [itemNumber]
  )

  const { state, setLoading, refresh, filter, pagination } = useTable({ showExpired: false, showFuture: false }, fetchBomItems)

  const {
    formState: { isSubmitting, isDirty },
    reset,
    control,
    handleSubmit,
  } = useForm<BomFormData>({
    defaultValues: defineFormData(null),
    resolver: yupResolver(bomDetailsValidationSchema),
    mode: 'onBlur',
  })

  const openQuickAddForm = useCallback(() => setOpenQuickAdd(true), [])
  const closeQuickAddForm = useCallback(() => setOpenQuickAdd(false), [])

  const onSubmit: (data: BomFormData) => Promise<void> = async (formValues) => {
    if (!bomData.bom) {
      return
    }
    try {
      const bomInput = {
        ...formValues,
        revision_date: formValues.revision_date.toISOString(),
        batch_size: NumberTypeUtils.parseString(formValues.batch_size),
        bom_items: state.data,
        item_number: bomData.bom.item_number,
      }
      await BomService.update(bomInput)
      ToastService.showSuccess(`BOM ${bomInput.item_number} has been updated.`)
      history.push('/products/bom')
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }
  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaulDeletionState), [])
  const openBomItem: (itemId: number | null, items: IBomItem[] | undefined, selectedMode: BomItemMode) => void = (
    itemId,
    items = [],
    selectedMode
  ) => {
    const bomItem = itemId ? findBomItem(itemId, items) : null
    setBomItemDialogState({ open: true, bomItem: bomItem ?? null, mode: selectedMode })
  }

  const isViewMode = useMemo(() => (Object.values(BomDetailsMode).includes(mode) ? mode === BomDetailsMode.View : true), [mode])
  const actions = useMemo(() => defineAvailableActions(false, isViewMode, isMobile), [isViewMode, isMobile])
  const handleRowClick = useCallback((itemId) => openBomItem(itemId, state.data, BomItemMode.View), [state.data])
  const handleAdvancedSearch = useCallback(() => openBomItem(null, state.data, BomItemMode.Search), [state.data])

  async function init(id: string, setFormState: (value: BomFormData) => void): Promise<void> {
    try {
      setBomData((prev) => ({ ...prev, initializing: true }))
      const bom = await BomService.get(id, false, false)
      setBomData({ bom, initializing: false })
      setFormState(defineFormData(bom))
    } catch (error) {
      ErrorHandler.handleError(error)
      setBomData((prev) => ({ ...prev, initializing: false }))
    }
  }

  useEffect(() => void init(itemNumber, reset), [reset, itemNumber])

  const cancel = useCallback(() => {
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }
    history.push('/products/bom')
  }, [isDirty, history])

  const closeBomItemDialog = useCallback(() => {
    setBomItemDialogState({ open: false, bomItem: null, mode: BomItemMode.View })
    closeQuickAddForm()
  }, [closeQuickAddForm])

  const addBomItem = useCallback(
    async (quickAddFormData: BomItemQuickAddInput) => {
      const newBomItem = {
        ...quickAddFormData,
        bom_item_inventory_uom: quickAddFormData.item.inventory_uom_name,
        bom_item_description: quickAddFormData.item.description1,
        bom_item_number: quickAddFormData.item.item_number,
      }

      try {
        setLoading(true)
        await BomItemService.quickAdd(itemNumber, newBomItem)
        setLoading(false)
        await refresh()
        ToastService.showSuccess(`BOM Item has been created.`)
        closeQuickAddForm()
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    },
    [closeQuickAddForm, itemNumber, filter]
  )

  async function onShowFilterChange({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    await filter({ ...state.filters, [target.name]: target.checked })
  }

  const deleteItem: () => Promise<void> = async () => {
    closeConfirmationDialog()
    if (!bomData.bom?.item_number || !deletionState.itemId) {
      return
    }
    const bomItem = findBomItem(deletionState.itemId, state.data)
    if (bomItem) {
      try {
        setLoading(true)
        await BomItemService.delete(bomData.bom.item_number, bomItem.sequence_number)
        await refresh()
        ToastService.showSuccess(`BOM Item has been deleted.`)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        ErrorHandler.handleError(error)
      }
    }
  }

  const expireItem = async (bomItemNumber: string, bomItem: IBomItem): Promise<void> => {
    try {
      setLoading(true)
      await BomItemService.expire(bomItemNumber, bomItem.sequence_number)
      await refresh()
      setLoading(false)
      ToastService.showSuccess(`BOM Item has been expired.`)
    } catch (error) {
      setLoading(false)
      ErrorHandler.handleError(error)
    }
  }

  const handleAction = useCallback(
    ({ id }: IBomItem, action: BomDetailsAction) => {
      switch (action) {
        case BomDetailsAction.View:
          return openBomItem(id, state.data, BomItemMode.View)
        case BomDetailsAction.Edit:
          return openBomItem(id, state.data, BomItemMode.Edit)
        case BomDetailsAction.Replace:
          return openBomItem(id, state.data, BomItemMode.Replace)
        case BomDetailsAction.Expire: {
          const item = findBomItem(id, state.data)
          return bomData.bom && item && bomData.bom.item_number && expireItem(bomData.bom.item_number, item)
        }
        case BomDetailsAction.Delete:
          return setDeletionState({ itemId: id, confirmationOpen: true })
        default:
          return openBomItem(id, state.data, BomItemMode.View)
      }
    },
    [bomData.bom?.item_number, state.data, filter]
  )

  const onBomItemSubmit = useCallback(
    async (parentItemNumber, bomItem, newItem, dialogMode) => {
      try {
        setLoading(true)
        await submitBomItemDialog(parentItemNumber, bomItem, newItem, dialogMode)
        await refresh()
        setLoading(false)
        ToastService.showSuccess(`BOM ${parentItemNumber} has been updated.`)
        closeBomItemDialog()
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
        closeBomItemDialog()
      }
    },
    [closeBomItemDialog, filter]
  )

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete BOM"
        confirmationButtonLabel="Delete"
        onConfirm={deleteItem}
        onClose={closeConfirmationDialog}
      />
      <main className={cls('xt-content')}>
        {bomData.initializing && <LoadingSpinner />}
        <form hidden={bomData.initializing} onSubmit={handleSubmit(onSubmit)}>
          {!isMobile && bomItemDialogState.open && (
            <BomItem
              parentItemNumber={itemNumber}
              bomItem={bomItemDialogState.bomItem}
              open={bomItemDialogState.open}
              onSubmit={onBomItemSubmit}
              onClose={closeBomItemDialog}
              mode={bomItemDialogState.mode}
            />
          )}
          <div className={cls(styles.bomDetailsHeaderSection, 'xt-section-border')}>
            <XtItemNumber
              itemNumber={bomData.bom?.item_number}
              uom={bomData.bom?.item_inventory_uom}
              description={bomData.bom?.item_description}
            />
            <SvgIcon hidden={isMobile || isViewMode} iconId={SvgIconIds.PRINT} className={styles.bomDetailsPrintIcon} />
            <XtButton hidden={isMobile} className={styles.bomDetailsCancelButton} disabled={isSubmitting} label="Cancel" onClick={cancel} />
            <XtButton
              hidden={isMobile}
              className={styles.bomDetailsSubmitButton}
              label="Save"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !isDirty || isMobile || isViewMode}
            />
          </div>
          <BomDetailsForm disabled={isMobile || isViewMode} control={control} />
          <div hidden={isMobile} className={styles.bomDetailsTableFilters}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={state.filters.showExpired}
                  disabled={state.loading}
                  onChange={onShowFilterChange}
                  name="showExpired"
                />
              }
              label="Show Expired"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={state.filters.showFuture}
                  disabled={state.loading}
                  onChange={onShowFilterChange}
                  name="showFuture"
                />
              }
              label="Show Future"
            />
            <div className={styles.bomQuickAddContainer}>
              <XtQuickAddButton
                disabled={state.loading || isViewMode}
                className={styles.bomQuickAddButton}
                onClick={openQuickAddForm}
                ref={anchorEl}
              />
              {isQuickAddOpen && (
                <BomItemQuickAdd
                  itemNumber={itemNumber}
                  onAdvancedSearch={handleAdvancedSearch}
                  anchorEl={anchorEl.current}
                  onClose={closeQuickAddForm}
                  open={isQuickAddOpen}
                  onAddItem={addBomItem}
                />
              )}
            </div>
          </div>
          <XtList
            actions={actions}
            onRowClick={handleRowClick}
            onAction={handleAction}
            isMobile={isMobile}
            pagination={pagination}
            loading={state.loading}
            data={state.data}
            columns={bomDetailsTableColumns}
            className={styles.bomTableContainer}
          />
          <BomDetailsSection data={bomData.bom?.bom_details} isMobile={isMobile} />
        </form>
      </main>
    </div>
  )
}
