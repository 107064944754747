import React, { FC, useState, useCallback, memo, useMemo } from 'react'

import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { XtButton } from 'components/xtButton/XtButton'
import { cls } from 'common/utils'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { confirmationMessages } from 'common/constants'

import { XtList } from 'components/list/list'
import { ToastService } from 'services/ToasterService'
import { AssigneeDialog } from './assignee-form-dialog/assignee-form-dialog'
import { AssigneeAction, assigneeActionsEditMode, assigneeColumns } from './assignee-tab.constants'
import { IAssigneeTab, IAssigneeItemState, IDeletionDialog, IAssigneeTableItem } from './assignee-tab.types'
import {
  normalizeDataNoId,
  normalizeDataWithId,
  defaultAssigneeItemState,
  defaultDeletionState,
  convertDialogItem,
} from './assignee-tab.utils'
import styles from './assignee-tab.module.scss'
import { IAssigneeDialogOutput } from './assignee-form-dialog/assignee-form-dialog.types'

export const AssigneeTab: FC<IAssigneeTab> = memo(({ disabled, isMobile, assignees, onChange }) => {
  const assigneesWithId = useMemo(() => normalizeDataWithId(assignees), [assignees])

  const [assigneeItemState, setAssigneeItemState] = useState<IAssigneeItemState>(defaultAssigneeItemState)
  const [deletionState, setDeletionDialog] = useState<IDeletionDialog>(defaultDeletionState)

  const closeAssigneeDialog = useCallback(() => setAssigneeItemState(defaultAssigneeItemState), [])
  const closeConfirmationDialog = useCallback(() => setDeletionDialog(defaultDeletionState), [])

  const handleItemsChange = useCallback<(items: IAssigneeTableItem[]) => void>((items) => onChange(normalizeDataNoId(items)), [onChange])

  const confirmAssigneeDialog = useCallback<(item: IAssigneeDialogOutput) => void>(
    (item) => {
      closeAssigneeDialog()
      const id = assigneeItemState.itemDialog?.id
      const tableItem = convertDialogItem(id, item)
      if (!assigneeItemState.isEdit) {
        const updatedAssignees = [...assigneesWithId, tableItem]
        handleItemsChange(updatedAssignees)
        return
      }
      const updatedAssignees = [
        ...assigneesWithId.slice(0, Number(tableItem.id)),
        tableItem,
        ...assigneesWithId.slice(Number(tableItem.id) + 1),
      ]
      handleItemsChange(updatedAssignees)
    },
    [assigneeItemState.isEdit, assigneeItemState.itemDialog, assigneesWithId, closeAssigneeDialog, handleItemsChange]
  )

  const addAssigneeItem = useCallback(() => setAssigneeItemState((prev) => ({ ...prev, open: true, itemDialog: null, isEdit: false })), [])

  const handleAction = useCallback<(item: IAssigneeTableItem, action: AssigneeAction) => void>((item, action) => {
    if (action === AssigneeAction.Edit) {
      setAssigneeItemState((prev) => ({ ...prev, open: true, itemDialog: item, isEdit: true }))
      return
    }
    setDeletionDialog({ item, open: true })
  }, [])

  const handleDeletion = useCallback(() => {
    closeConfirmationDialog()
    if (!deletionState.item) {
      return
    }
    ToastService.showSuccess(`Assingee ${deletionState?.item?.username} has been deleted.`)
    const updateAssignee = assigneesWithId.filter(({ id }) => id !== deletionState.item!.id)
    handleItemsChange(updateAssignee)
  }, [deletionState.item, assigneesWithId, closeConfirmationDialog, handleItemsChange])

  const handleRowClick: (item: IAssigneeTableItem) => void = (item) => {
    if (disabled) {
      return
    }
    setAssigneeItemState((prev) => ({ ...prev, open: true, itemDialog: item, isEdit: true }))
  }

  return (
    <div className={styles.assigneeTab}>
      <XtConfirmationDialog
        open={deletionState.open}
        message={confirmationMessages.deleted}
        title="Delete Assignee"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      {assigneeItemState.open && (
        <AssigneeDialog
          assigneeData={assigneeItemState.itemDialog}
          onConfirm={confirmAssigneeDialog}
          open={assigneeItemState.open}
          onClose={closeAssigneeDialog}
        />
      )}
      <XtButton
        disabled={disabled}
        onClick={addAssigneeItem}
        icon={SvgIconIds.ADD_CIRCLE}
        className={styles.assigneeTabAddButton}
        label="Add Assignee"
      />
      <div className={cls(styles.assigneeTable, isMobile && styles.assigneeCardScroll)}>
        <XtList
          actions={disabled ? [] : assigneeActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          data={assigneesWithId}
          columns={assigneeColumns}
        />
      </div>
    </div>
  )
})
