import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../common/common.types'

export interface IOperationType {
  code: string
  description: string
  system_type: boolean
}

export interface IOperationTyperService {
  getAll: (params: IPaginationParams) => Promise<IPaginationData<IOperationType>>
}

class Service implements IOperationTyperService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<IOperationType>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IOperationType>>('/operationtype', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(option: string): Promise<IOperationType> {
    const response = await this.apiClient.get(`/operationtype/${option}`)

    return response.data.data
  }
}

export const OperationTypeService = new Service(api)
