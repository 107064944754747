import * as React from 'react'
import { useEffect } from 'react'
import { cls } from 'common/utils'
import { IconButton, Modal, Slide } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { globalConstants } from 'common/constants'
import { XtButton } from 'components/xtButton/XtButton'
import { XtButtonVariant } from 'components/xtButton/XtButton.types'
import { FilterMakerFunction, FilterType, IFilter, IFilterDescription, IFilterFormState } from './filter.types'
import { makeCheckboxFilter } from './filter.checkbox'
import { makeDropdownFilter } from './filter.dropdown'
import { makeRadioFilter } from './filter.radio'
import { makeDateFilter } from './filter.date'
import { makeTextFilter } from './filter.text'
import styles from './filter.module.scss'
import { makeAutocompleteFilter } from './filter.autocomplete'
import { SvgIcon } from '../svgIcon/SvgIcon'
import { SvgIconIds } from '../svgIcon/SvgIcon.types'
import { IXtAutocompleteOption } from '../xtAutocomplete/XtAutocomplete.types'
import { makeValidationSchema } from './filter.validation'

/** A component for specifying arbitrary filters.

 It accepts a list of filter descriptions in the filters attribute and creates a modal sliding window.

 Here is example filter description:

 `<XtFilter open={true}
 defaultValues={defaultFilterValues}
 label="Filters"
 onClose={()=> setOpen(false)}
 onSubmit={onSubmit}
 filters={filterFields} />


 const defaultFilterValues = {
    FieldAutocomplete: '',
    FieldCheckbox: false,
    FieldText: '',
    FieldDate: new Date() | '',
    FieldDropdown: 'value1' | '',// required
    FieldDropdownMultiple: ['value'] | [''],// required
    FieldRadio: 0 | 1 | 3, // [{radio 1},{radio 2},{radio 3}]
  }

 const filterFields = [
 {
      type: FilterType.Autocomplete,
      label: 'LabelAutocomplete',
      fieldName: 'FieldAutocomplete',
      autocompleteProps: {
      loadOptions: loadOptions, // src/common/utils
      },
    },
 {
      type: FilterType.Checkbox,
      label: 'LabelCheckbox',
      fieldName: 'FieldCheckbox',
    },
 {
      type: FilterType.Text,
      label: 'LabelText',
      fieldName: 'FieldText',
    },
 {
      type: FilterType.Date,
      label: 'LabelDate',
      fieldName: 'FieldDate',
    },
 {
      type: FilterType.Dropdown,
      label: 'tLabelDropdown',
      fieldName: 'FieldDropdown',
      options: ['value1', 'value2', 'value3'],
    },
 {
      type: FilterType.DropdownMultiple,
      label: 'LabelDropdownMultiple',
      fieldName: 'FieldDropdownMultiple',
      options: ['value1', 'value2', 'value3'],
  },
 {
      type: FilterType.Radio,
      label: 'LabelRadio ',
      fieldName: 'FieldRadio',
      radioOptions: [
          { label: 'radio 1', value: false },
          { label: 'radio 2', value: false },
      ],
    },
 ]` */

function FilterComponent<TOption extends IXtAutocompleteOption, TFilters extends Array<IFilter<TOption>>>({
  filters,
  open,
  label = 'Filters',
  onSubmit,
  onClose,
  defaultValues,
}: IFilterDescription<TFilters>) {
  const formMethods = useForm<IFilterFormState>({
    defaultValues: {},
    resolver: yupResolver(yup.object().shape(makeValidationSchema<TOption, TFilters>(filters))),
    mode: 'onBlur',
  })
  const { control, reset, getValues, handleSubmit } = formMethods
  useEffect(() => reset(defaultValues), [])
  /** Creates a filter. The type of the filter is detemined by the type property. */
  const makeFilter: FilterMakerFunction = (filter) => {
    switch (filter.type) {
      case FilterType.DropdownMultiple:
        return makeDropdownFilter(filter, control, true) // true === multiple
      case FilterType.Autocomplete:
        return makeAutocompleteFilter(filter, control)
      case FilterType.Dropdown:
        return makeDropdownFilter(filter, control)
      case FilterType.Checkbox:
        return makeCheckboxFilter(filter, control)
      case FilterType.Radio:
        return makeRadioFilter(filter, control)
      case FilterType.Date:
        return makeDateFilter(filter, control)
      case FilterType.Text:
        return makeTextFilter(filter, control)
      default:
        throw new Error('Impossible filter type')
    }
  }

  if (!filters || !filters.length) {
    throw new Error('XtFilter cannot be used without any filters defined')
  }

  const submitForm: () => void = () => {
    if (onSubmit) {
      onSubmit({ ...defaultValues, ...getValues() })
    }
    return null
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
        <form className={cls(styles.filtersFormContent, 'xt-content', 'xt-dialog-scrollable')}>
          <IconButton className={styles.closeIcon} onClick={onClose}>
            <SvgIcon className={styles.closeIconSize} iconId={SvgIconIds.CLOSE_ICON} />
          </IconButton>
          <h2 className={styles.mainHeader}>{label}</h2>
          <div className={styles.filtersContent}>
            {filters.map(makeFilter)}
            <div className={styles.buttonContainer}>
              <XtButton label="Clear Filters" key="button-clear" variant={XtButtonVariant.Secondary} onClick={() => reset(defaultValues)} />
              <XtButton label="Apply filters" key="button-apply" variant={XtButtonVariant.Primary} onClick={handleSubmit(submitForm)} />
            </div>
          </div>
        </form>
      </Slide>
    </Modal>
  )
}

export const XtFilter = React.memo(FilterComponent)
