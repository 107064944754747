import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { ICustomer } from 'customers/customers.types'
import React, { FC, useMemo } from 'react'
import { OrdersList } from './order/order-list/sales-orders-list'
import styles from './sales-tabs.module.scss'
import { SummaryTab } from './summary/summary'
import { QuotesList } from './quotes/quotes-list'

interface ISalesTabs {
  customer: ICustomer | null
  disabled: boolean
  isMobile: boolean
}

enum SalesTabName {
  Summary = 'Summary',
  Quotes = 'Quotes',
  Orders = 'Orders',
}

export const SalesTabs: FC<ISalesTabs> = ({ customer, disabled, isMobile }) => {
  // TODO implement quotes
  const tabs = useMemo(
    () => [
      {
        name: SalesTabName.Summary,
        template: <SummaryTab salesDetails={customer?.sales_details ?? null} />,
      },
      {
        name: SalesTabName.Quotes,
        template: <QuotesList disabled={disabled} isMobile={isMobile} customerNumber={customer?.customer_number} />,
      },
      {
        name: SalesTabName.Orders,
        template: <OrdersList disabled={disabled} isMobile={isMobile} customerNumber={customer?.customer_number} />,
      },
    ],
    [customer, disabled, isMobile]
  )
  return <XtTabs className={styles.xtTabs} tabs={tabs} />
}
