import React, { FC, useMemo } from 'react'

import { XtRemarks } from 'components/xt-remarks/xt-remarks'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { XtDocuments } from 'components/documents/documents'
import { UsedOnValue } from 'documents/documents.types'
import { ICustomer } from 'customers/customers.types'
import { XtCharacteristics } from 'characteristics/characteristics'
import { IRemarksHook } from 'common/hooks/remarks'
import { IDocumentsHook } from 'common/hooks/documents'
import { ICharacteristicsHook } from 'common/hooks/characteristics'
import styles from './customer-details-tabs.module.scss'
import { ICustomerFormState } from '../customer-details.types'
import { AddressTab } from './addresses-tab/address-tab'
import { CRMTabs } from './crm-tab/crm-tabs'
import { SalesTabs } from './sales-tab/sales-tabs'

interface IItemDetailsTabs {
  disabled: boolean
  customer: ICustomer | null
  onChange(state: ICustomerFormState): void
  isMobile: boolean
  remarks: IRemarksHook
  characteristics: ICharacteristicsHook
  documents: IDocumentsHook
}

enum ItemDetailsTabName {
  Addresses = 'Addresses',
  Characteristics = 'Characteristics',
  CRM = 'CRM',
  Sales = 'Sales',
  Documents = 'Documents',
  Remarks = 'Remarks',
}

export const CustomerDetailsTabs: FC<IItemDetailsTabs> = ({
  disabled,
  customer,
  onChange,
  isMobile,
  remarks,
  characteristics,
  documents,
}) => {
  // TODO update
  // TODO implemented, documents, integration api(characteristics, remarks)
  // TODO handle isDirty changes

  const tabsIsEditMode = useMemo(
    () => [
      {
        name: ItemDetailsTabName.Addresses,
        template: <AddressTab disabled={disabled} isMobile={isMobile} customer={customer} onChange={onChange} />,
      },
      {
        name: ItemDetailsTabName.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.Customer}
            isViewMode={disabled}
            onCreate={characteristics.createCharacteristic}
            onUpdate={characteristics.updateCharacteristic}
            onDelete={characteristics.deleteCharacteristic}
            characteristics={characteristics.characteristics}
          />
        ),
      },
      {
        name: ItemDetailsTabName.CRM,
        template: <CRMTabs isMobile={isMobile} disabled={disabled} customer={customer} />,
      },
      {
        name: ItemDetailsTabName.Sales,
        template: <SalesTabs isMobile={isMobile} disabled={disabled} customer={customer} />,
      },
      {
        name: ItemDetailsTabName.Documents,
        template: (
          <XtDocuments
            usedOnFilter={UsedOnValue.Customer}
            isViewMode={isMobile || !documents.creationEnabled}
            onDocumentCreate={documents.saveDocument}
            onDocumentDelete={documents.deleteDocument}
            loadMore={documents.loadMore}
            canLoadMore={documents.canLoadMore}
            documents={documents.documents}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Remarks,
        template: (
          <XtRemarks
            canLoadMore={remarks.canLoadMore}
            loadMore={remarks.loadMore}
            total={remarks.total}
            onCommentsUpdate={remarks.updateComment}
            onCommentsSave={remarks.saveComment}
            comments={remarks.comments}
            username={remarks.username}
            textAreaOnChange={remarks.setNotes}
            textAreaValue={remarks.notes}
            textAreaName="Notes"
            disabled={disabled}
          />
        ),
      },
    ],
    [customer, disabled, onChange, remarks, isMobile]
  )

  const tabsIsNewMode = useMemo(
    () => [
      {
        name: ItemDetailsTabName.Addresses,
        template: <AddressTab disabled={disabled} isMobile={isMobile} customer={customer} onChange={onChange} />,
      },
    ],
    [disabled, customer, onChange, isMobile]
  )
  const isNewMode = !customer?.customer_number
  const tabs = isNewMode ? tabsIsNewMode : tabsIsEditMode
  return <XtTabs className={isNewMode ? styles.xtTabs : ''} tabs={tabs} />
}
