import * as React from 'react'
import { FC, useCallback, useEffect, useState } from 'react'
import { loadShipmentsVia } from 'common/utils'
import { CustomerOption, ISalesOrderMainParams, SalesOrderMainField, ISalesOrderMainForm, ShipmentOption } from './sales-order-main.types'
import * as styles from './sales-order-main.module.scss'

import { IXtAutocompleteOption, XtAutocompleteLoadOptionsFunc } from '../../../components/xtAutocomplete/XtAutocomplete.types'
import { salesOrderMainValidationSchema } from './sales-order-main.validation'
import { FormDatePicker, FormField, FormSelectField, FormXtAutocomplete } from '../../../common/utils/form/form.components'
import { getInlineLabel, renderOption } from '../../../common/utils/autocomplete.utils'
import { useXtForm } from '../../../common/hooks/form/form'
import { ErrorHandler } from '../../../services/ErrorService'
import {
  convertFormDataToMainInput,
  convertMainInputToFormData,
  holdTypeOptions,
  loadCustomers,
  loadShipments,
  renderCustomerOption,
  renderShipmentOption,
} from './sales-order-main.utils'
import { loadSiteOptions } from '../../../common/utils/document.utils'

export const SalesOrderMain: FC<ISalesOrderMainParams> = React.memo(
  ({ data, onChange, disabled = false, validationObservable, customerDisabled }) => {
    const { control, setValue, watch, reset, trigger, formChanges$ } = useXtForm<ISalesOrderMainForm>({
      defaultValues: convertMainInputToFormData(data),
      mode: 'onBlur',
      validationSchema: salesOrderMainValidationSchema,
    })

    const [shipments, setShipments] = useState<ShipmentOption[]>([])

    const initOptions: (customerId: string) => Promise<void> = async (customerId) => {
      try {
        const options = await loadShipments(customerId)
        setShipments(options)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    useEffect(() => {
      reset(convertMainInputToFormData(data))
      if (data?.customer_number) {
        void initOptions(data.customer_number)
      }
    }, [data])

    const customer = watch(SalesOrderMainField.Customer)

    useEffect(() => {
      const validationSubscription = validationObservable.subscribe(() => {
        void trigger()
      })
      const sub = formChanges$.subscribe(({ data: formData, state }) =>
        onChange({
          data: convertFormDataToMainInput(formData),
          state,
        })
      )

      return () => {
        validationSubscription.unsubscribe()
        sub.unsubscribe()
      }
    }, [validationObservable, trigger, formChanges$, onChange])

    const handleShipmentChange = useCallback<(option: ShipmentOption | null, shouldDirty?: boolean) => void>(
      (shipmentOption, shouldDirty = true) => {
        setValue(
          SalesOrderMainField.Site,
          shipmentOption?.preferred_selling_site
            ? { id: shipmentOption.preferred_selling_site, label: shipmentOption.preferred_selling_site }
            : null,
          { shouldValidate: true, shouldDirty }
        )
        setValue(SalesOrderMainField.ShipTo, shipmentOption, { shouldValidate: true, shouldDirty })
        setValue(SalesOrderMainField.ShipToName, shipmentOption?.name ?? '', { shouldValidate: true, shouldDirty })
        setValue(
          SalesOrderMainField.ShipVia,
          shipmentOption?.ship_via ? { label: shipmentOption?.ship_via, id: shipmentOption?.ship_via } : null,
          { shouldValidate: true, shouldDirty }
        )
      },
      [setValue]
    )

    const updateShipments = useCallback<(customer: CustomerOption | null) => Promise<void>>(
      async (option) => {
        if (!option) {
          handleShipmentChange(null)
          return
        }
        try {
          const options = await loadShipments(option.id)
          const selectedOption = options.length ? options[0] : null
          setShipments(options)
          handleShipmentChange(selectedOption)
        } catch (e) {
          ErrorHandler.handleError(e)
        }
      },
      [handleShipmentChange]
    )

    const handleCustomerChange = useCallback<(option: CustomerOption | null) => Promise<void>>(
      async (option) => {
        setValue(SalesOrderMainField.Customer, option, { shouldValidate: true, shouldDirty: true })
        setValue(SalesOrderMainField.CustomerName, option?.customer_name ?? '', { shouldValidate: true, shouldDirty: true })
        await updateShipments(option)
      },
      [setValue, updateShipments]
    )

    const handleShipmentViaChange = useCallback<(option: IXtAutocompleteOption | null) => void>(
      (shipmentVia) => {
        setValue(SalesOrderMainField.ShipVia, shipmentVia, { shouldValidate: true, shouldDirty: true })
      },
      [setValue]
    )

    const handleShipmentNameChange = useCallback<(value: string | number) => void>(
      (value) => {
        setValue(SalesOrderMainField.ShipToName, value?.toString())
        setValue(SalesOrderMainField.ShipTo, null)
      },
      [setValue]
    )

    const loadShipmentOptions = useCallback<XtAutocompleteLoadOptionsFunc<ShipmentOption>>(
      (_) =>
        Promise.resolve({
          data: shipments.map((shipment) => ({ ...shipment, label: shipment.shipto_number })),
          total: shipments.length,
        }),
      [shipments]
    )

    return (
      <div className={styles.salesOrderMain}>
        <FormField name={SalesOrderMainField.OrderNumber} control={control} disabled label="Order #" />
        <FormDatePicker name={SalesOrderMainField.OrderDate} control={control} label="Order Date" disabled={disabled} />
        <FormXtAutocomplete
          name={SalesOrderMainField.Customer}
          control={control}
          loadOptions={loadCustomers}
          renderOption={renderCustomerOption}
          disabled={disabled || customerDisabled}
          onChange={handleCustomerChange}
          label="Customer Number"
        />
        <FormField name={SalesOrderMainField.CustomerName} control={control} disabled label="Customer Name" />
        <FormField name={SalesOrderMainField.CustomerPONumber} control={control} disabled={disabled} label="Customer PO #" />
        <FormSelectField
          name={SalesOrderMainField.HoldType}
          control={control}
          disabled={disabled}
          label="Hold Type"
          options={holdTypeOptions}
        />
        <div className={styles.salesOrderMainShipment}>
          <FormXtAutocomplete
            name={SalesOrderMainField.ShipTo}
            control={control}
            loadOptions={loadShipmentOptions}
            renderOption={renderShipmentOption}
            disabled={disabled || !customer}
            onChange={handleShipmentChange}
            label="Ship To Address"
          />
          <FormField
            name={SalesOrderMainField.ShipToName}
            control={control}
            onChange={handleShipmentNameChange}
            disabled={disabled}
            label="Ship To Name"
          />
          <FormXtAutocomplete
            name={SalesOrderMainField.ShipVia}
            control={control}
            loadOptions={loadShipmentsVia}
            renderOption={renderOption}
            getInputLabel={getInlineLabel}
            disabled={disabled}
            onChange={handleShipmentViaChange}
            label="Ship Via"
          />
          <FormXtAutocomplete
            name={SalesOrderMainField.Site}
            control={control}
            loadOptions={loadSiteOptions}
            disabled={disabled || !customer}
            label="Site"
          />
        </div>
      </div>
    )
  }
)
