import * as React from 'react'
import { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useParams, useHistory } from 'react-router'
import { useMediaQuery, Checkbox, FormControlLabel } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ToastService } from 'services/ToasterService'
import { confirmationMessages, xsMq } from 'common/constants'
import { XtButton } from 'components/xtButton/XtButton'
import { SvgIcon } from 'components/svgIcon/SvgIcon'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { cls } from 'common/utils'
import { ErrorHandler } from 'services/ErrorService'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import LoadingSpinner from 'components/LoadingSpinner'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { IRoutingItemsFilters, RoutingService } from '../RoutingService'
import { RoutingItemMode, IRoutingItem, RoutingDetailsMode, IRouting } from '../Routing.types'
import { RoutingDetailsForm } from './routingDetailsForm/RoutingDetailsForm'
import { RoutingItem } from '../routingItem/RoutingItem'
import * as styles from './RoutingDetails.module.scss'
import {
  IItemDeletionState,
  IRoutingDetailsTableItem,
  IRoutingItemDialogState,
  IRoutingState,
  RoutingDetailsAction,
} from './RoutingDetails.types'

import { routingDetailsColumns, RoutingDetailsFormField } from './RoutingDetails.constants'
import { routingDetailsValidationSchema } from './validation-schema'
import {
  defineFormData,
  defaultRoutingItemDialogState,
  defineAvailableActions,
  defaulDeletionState,
  normalizeData,
} from './RoutingDetails.utils'
import { useTable } from '../../common/hooks/useTable'
import { XtList } from '../../components/list/list'
import { FormCheckboxLabel } from '../../common/utils/form/form.components'

export const RoutingDetails: FC = () => {
  const { itemNumber, mode } = useParams<{ itemNumber: string; mode: RoutingDetailsMode }>()
  const history = useHistory()
  const isMobile = useMediaQuery(xsMq)
  const isEditMode = Object.values(RoutingDetailsMode).includes(mode) ? mode === RoutingDetailsMode.Edit : false
  const [routingItemDialogState, setRoutingItemDialogState] = useState<IRoutingItemDialogState>(defaultRoutingItemDialogState)
  const [deletionState, setDeletionState] = useState<IItemDeletionState>(defaulDeletionState)
  const [routingState, setRoutingState] = useState<IRoutingState>({
    routing: null,
    initializing: false,
  })

  const fetchRoutingItems = useCallback(
    async (filters: IRoutingItemsFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IRoutingDetailsTableItem>> => {
      const { total, data } = await RoutingService.getAllItems(itemNumber, filters, paginationParams)
      return {
        data: normalizeData(data),
        total,
      }
    },
    [itemNumber]
  )
  const { state, setLoading, refresh, filter, pagination } = useTable({ showExpired: false, showFuture: false }, fetchRoutingItems)

  const openRoutingItem = (itemId: number, selectedMode: RoutingItemMode) => {
    const routingItem = state.data.find(({ sequence_number }) => sequence_number === itemId)
    setRoutingItemDialogState({ open: true, routingItem: routingItem ?? null, mode: selectedMode })
  }
  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaulDeletionState), [])
  const closeRoutingItemModal = useCallback(() => setRoutingItemDialogState(defaultRoutingItemDialogState), [])

  const formMethods = useForm({
    defaultValues: defineFormData(null),
    resolver: yupResolver(routingDetailsValidationSchema),
    mode: 'onBlur',
  })

  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    control,
    reset,
  } = formMethods

  const deleteRoutingItem = async () => {
    if (!routingState.routing?.item_number || !deletionState.itemId) {
      return
    }
    try {
      setLoading(true)
      await RoutingService.deleteItem(routingState.routing.item_number, deletionState.itemId)
      await refresh()
      setLoading(false)
      ToastService.showSuccess(`Routing Item has been deleted.`)
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }
  const expireRoutingItem = async (id: number) => {
    if (!routingState.routing?.item_number || !deletionState.itemId) {
      return
    }
    try {
      setLoading(true)
      await RoutingService.expireItem(routingState.routing?.item_number ?? itemNumber, id)
      setLoading(false)
      ToastService.showSuccess(`Routing Item has been expired.`)
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }
  const handleAction = useCallback<(item: IRoutingDetailsTableItem, action: RoutingDetailsAction) => void>(
    ({ id }, action: RoutingDetailsAction) => {
      switch (action) {
        case RoutingDetailsAction.View:
          return openRoutingItem(id, RoutingItemMode.View)
        case RoutingDetailsAction.Edit:
          return openRoutingItem(id, RoutingItemMode.Edit)
        case RoutingDetailsAction.Expire:
          return routingState.routing?.item_number && expireRoutingItem(id)
        case RoutingDetailsAction.Delete:
          return setDeletionState({ itemId: id, confirmationOpen: true })
        default:
          return openRoutingItem(id, RoutingItemMode.View)
      }
    },
    [state.data]
  )
  const newRoutingItem = () => setRoutingItemDialogState({ open: true, routingItem: null, mode: RoutingItemMode.New })

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        setRoutingState((prev) => ({ ...prev, initializing: true }))
        const routing = await RoutingService.get(itemNumber, false, false)
        setRoutingState({ routing, initializing: false })
        reset(defineFormData(routing))
      } catch (error) {
        ErrorHandler.handleError(error)
        setRoutingState((prev) => ({ ...prev, initializing: false }))
      }
    }

    void getData()
  }, [itemNumber])

  async function onFiltersChange({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    await filter({ ...state.filters, [target.name]: target.checked })
  }

  const onCancel = (): void => {
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }
    history.push('/products/routing')
  }
  const onSubmit = async (formValues: IRouting) => {
    if (!routingState.routing) {
      return
    }
    try {
      const values = {
        ...routingState.routing,
        ...formValues,
        routing_items: formValues.routing_items ?? [],
      }
      await RoutingService.update(values)
      ToastService.showSuccess(`Routing ${itemNumber} has been updated.`)
      history.push(`/products/routing`)
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }
  const routingItemSubmit = async (IRoutingItemData: IRoutingItem, action: RoutingItemMode) => {
    try {
      setLoading(true)
      if (action === RoutingItemMode.Edit) {
        await RoutingService.updateItem(itemNumber, IRoutingItemData)
        ToastService.showSuccess(`Routing Item has been updated.`)
      } else if (action === RoutingItemMode.New) {
        await RoutingService.createItem(itemNumber, IRoutingItemData)
        ToastService.showSuccess(`Routing Item has been created.`)
      }
      await refresh()
      setLoading(false)
      closeRoutingItemModal()
    } catch (error) {
      setLoading(false)
      ErrorHandler.handleError(error)
    }
  }
  const handleRoutingItemClick = useCallback(() => ({ id }: IRoutingDetailsTableItem) => openRoutingItem(id, RoutingItemMode.View), [])
  const actions = useMemo(() => defineAvailableActions(false, !isEditMode, isMobile), [!isEditMode, isMobile])

  return (
    <main className={cls(styles.routingDetailsContent, 'xt-content')}>
      {routingState.initializing && <LoadingSpinner />}
      <div hidden={routingState.initializing}>
        <XtConfirmationDialog
          open={deletionState.confirmationOpen}
          message={confirmationMessages.deleted}
          title="Delete Routing"
          confirmationButtonLabel="Delete"
          onConfirm={deleteRoutingItem}
          onClose={closeConfirmationDialog}
        />
        <RoutingItem
          loading={state.loading}
          onSubmit={routingItemSubmit}
          itemNumber={routingState.routing?.item_number ?? itemNumber}
          close={closeRoutingItemModal}
          state={routingItemDialogState}
        />

        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={cls(styles.routingDetailsHeaderSection, 'xt-section-border')}>
              <h3 className="xt-page-title" title={routingState.routing?.item_number}>
                {routingState.routing?.item_number}
              </h3>
              <div className={styles.routingDetailsButtonSection}>
                <SvgIcon hidden={isMobile} iconId={SvgIconIds.PRINT} />
                <XtButton disabled={isSubmitting} label="Cancel" onClick={onCancel} />
                <XtButton
                  hidden={isMobile}
                  label="Save"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isDirty || isMobile || !isEditMode}
                />
              </div>
            </div>
            <RoutingDetailsForm leadTime={routingState.routing?.lead_time ?? 0} disabled={isMobile || !isEditMode} />
            <div hidden={isMobile} className={styles.routingDetailsTableFilters}>
              <FormControlLabel
                disabled={isSubmitting || isMobile || !isEditMode}
                control={
                  <Checkbox
                    color="primary"
                    checked={state.filters.showExpired}
                    disabled={state.loading}
                    onChange={onFiltersChange}
                    name={RoutingDetailsFormField.ShowExpired}
                  />
                }
                label="Show Expired Operations"
              />
              <FormControlLabel
                disabled={isSubmitting || isMobile || !isEditMode}
                control={
                  <Checkbox
                    color="primary"
                    checked={state.filters.showFuture}
                    disabled={state.loading}
                    onChange={onFiltersChange}
                    name={RoutingDetailsFormField.ShowFuture}
                  />
                }
                label="Show Future Operations"
              />
              <FormCheckboxLabel
                disabled={isSubmitting || isMobile || !isEditMode}
                name={RoutingDetailsFormField.CloseWorkorder}
                control={control}
                label="Close work order when full qty. posted"
              />
              <div hidden={isMobile} className={styles.routingDetailsAddItemButtonContainer}>
                <XtButton
                  onClick={newRoutingItem}
                  disabled={!isEditMode}
                  icon={SvgIconIds.ADD_CIRCLE}
                  label=""
                  className={styles.routingDetailsAddItemButton}
                />
              </div>
            </div>
          </form>
        </FormProvider>
        <XtList
          className={styles.routingDetailsTable}
          pagination={pagination}
          isMobile={isMobile}
          actions={actions}
          data={state.data}
          loading={state.loading}
          onAction={handleAction}
          onRowClick={handleRoutingItemClick}
          columns={routingDetailsColumns}
        />
      </div>
    </main>
  )
}
