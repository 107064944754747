import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { IUom, IUomConversion } from '../services/UomService'

// TODO update me
export enum ItemType {
  Manufactured = 'Manufactured',
}
export interface ISite {
  item_number: string
  site: string
  active: boolean
  wo_supplied_at_site: boolean
  create_wos: boolean
  po_supplied_at_site: boolean
  create_prs: boolean
  create_soprs: boolean
  create_sopos: boolean
  dropship: boolean
  sold_from_site: boolean
  ranking: number
  cost_method: string
  control_method: string
  planner_code: string
  cost_category_code: string
  cost_category: string
  stocked: boolean
  abc_class: string
  allow_automatic_updates: boolean
  cycl_count_freq: number
  event_fence: number
  multiple_location_control: boolean
  location: string
  receive_location: string
  issue_location: string
  auto_distr_location: boolean
  auto_distr_receive_location: boolean
  auto_distr_issue_location: boolean
  user_defined_location: string
  location_comment: string
  disallow_blank_wip_locations: boolean
  enforce_order_parameters: boolean
  reorder_level: number
  order_up_to: number
  minimum_order: number
  maximum_order: number
  order_multiple: number
  enforce_on_manual_orders: boolean
  planning_system: string
  group_mps_mrp_orders: boolean
  first_group: boolean
  mps_time_fence: number
  lead_time: number
  safety_stock: number
  supplied_from_site: string
  notes: string
  perishable: boolean
  require_warranty: boolean
  auto_register: boolean
  total_on_hand_qty: number
  available_qty: number
  nonuseable_available_qty: number
  netable_qty: number
  nonnetable_available_qty: number
  allocated_qty: number
  ordered_qty: number
  requested_qty: number
  created: string
  lastupdated: string
}

export interface IItem {
  line_number: string
  item_number: string
  active: boolean
  description1: string
  description2: string
  item_type: string
  maximum_desired_cost: number
  class_code: string
  inventory_uom_name: string
  inventory_uom: IUom
  pick_list_item: boolean
  fractional: boolean
  configured: boolean
  freight_class: string
  item_is_sold: boolean
  product_category: string
  exclusive: boolean
  list_price: number
  list_cost: number
  list_price_uom: IUom
  warranty_days: number
  sites: ISite[]
  upc_code: string
  product_weight: number
  packaging_weight: number
  notes: string
  ext_description: string
  item_characteristics: IAppliedCharacteristic[] | null
  created: string
  lastupdated: string
  // TODO update
  uom_conversions: IUomConversion[]
  currency: string
  wholesale_price: string
  list_price_uom_name: string
}

export interface IInventoryAvailability {
  site: string
  item_number: string
  item_description1: string
  item_description2: string
  uom: string
  abc_class: string
  lead_time: number
  quantity_on_hand: number
  quantity_allocated: number
  quantity_unallocated: number
  allocated_orders: IInventoryAvailabilityOrder[]
  on_order: number
  onorder_orders: IInventoryAvailabilityOrder[]
  requests: number
  request_orders: IInventoryAvailabilityOrder[]
  reorder_level: number
  out_level: number
  available: number
  reserved: number
  vendor: string
  details: IInventoryAvailabilityDetails[]
}

export interface IInventoryAvailabilityOrder {
  order_type: string
  order_number: string
  order_date: string // TODO update me
  order_qty: number
  order_url: string
}

export interface IInventoryAvailabilityDetails {
  qoh: number
  site: string
  usable: boolean
  netable: boolean
  warranty: string
  lotserial: string
  expiration: string
  location_name: string
}

export interface IPricingItem {
  schedule_name: string
  source: string
  qty_break: number
  qty_uom: string
  price: number
  price_uom: string
  percent: number
  fixed_amount?: string
  pricing_type?: string
  currency: string
  price_in_base: number
}

export interface IItemPrice {
  customer_price: number
  item_number: string
  site: string
  quantity: number
  list_price: number
  wholesale_price: number
  unit_cost: number
  discount_from_list: number
  pricing_items: IPricingItem[]
}

export interface IOrderBomAvailability {
  reorder_level: number
  sequence_number: number
  item_number: string
  item_description: string
  uom: string
  pending_allocation: number
  on_order: number
  available_qoh: number
  total_allocations: number
  total_availability: number
}

export interface IOrderAvailabilityItem {
  available_qoh: number
  allocated: number
  unallocated: number
  on_order: number
  total_availability: number
  reserved: number
  reservable: number
  itemsite_leadtime: number
  bom_availability: IOrderBomAvailability[]
}
