import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'

export function checkAndCreateFiltersObject<TValue extends { [T in keyof TValue]: TValue[T] }>(filter?: TValue): Partial<TValue> {
  const valueIsAutocompleteOption = (value: unknown): boolean =>
    typeof value === 'object' && value !== null && 'id' in value && 'label' in value

  return Object.entries(filter ?? {}).reduce((res, [key, value]) => {
    if (value) {
      const effectiveValue = valueIsAutocompleteOption(value) ? (value as IXtAutocompleteOption).id : value
      res[key as keyof TValue] = effectiveValue as TValue[keyof TValue]
    }
    return res
  }, {} as TValue)
}
