import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { Overwrite } from 'utility-types'
import { IContact } from '../contacts/contacts.types'

export interface ISalesDetails {
  backlog: number
  balance: number
  first_sales_date: string
  last_sales_date: string
  last_year_sales: number
  late_balance: number
  ytd_sales: number
}

export interface ICustomer {
  customer_number: string
  customer_type: string
  customer_name: string
  active: boolean
  sales_rep: string
  commission: number
  ship_via: string
  ship_form: string
  shipping_charges: string
  accepts_backorders: boolean
  accepts_partial_shipments: boolean
  allow_free_form_shipto: boolean
  allow_free_form_billto: boolean
  preferred_selling_site: string
  taxzone_code: string
  terms_code: string
  default_discount: number
  default_currency: string
  credit_limit_currency: string
  credit_limit: number
  alternate_grace_days: number
  credit_rating: string
  credit_status: string
  credit_status_exceed_warn: boolean
  credit_status_exceed_hold: boolean
  uses_purchase_orders: boolean
  uses_blanket_pos: boolean
  notes: string
  created: string
  lastupdated: string
  allow_marketing: boolean
  sales_details: ISalesDetails | null
  billing_contact: IContact | null
  correspond_contact: IContact | null
  customer_characteristics: IAppliedCharacteristic[] | null
}

export interface ISalesRep {
  number: string
  name: string
  active: boolean
  account: string
  commission: number
  created: string
  lastupdated: string
}
export enum FilterFieldName {
  ShowInactive = 'showInactive',
  CustomerNumberPattern = 'customer_number_pattern',
  CustomerNamePattern = 'customer_name',
  CustomerType = 'customer_type',
  ContactNamePattern = 'contact_name',
  CityPattern = 'city',
  StatePattern = 'state',
  SalesRep = 'salesRep',
  ShowDetail = 'showDetail',
}

export interface ICustomerFilters {
  [FilterFieldName.ShowInactive]?: string
  [FilterFieldName.CustomerNumberPattern]?: string
  [FilterFieldName.CustomerNamePattern]?: string
  [FilterFieldName.CustomerType]?: string
  [FilterFieldName.ContactNamePattern]?: string
  [FilterFieldName.CityPattern]?: string
  [FilterFieldName.StatePattern]?: string
  [FilterFieldName.SalesRep]?: string
  [FilterFieldName.ShowDetail]?: boolean
}

export type CustomerPayload = Overwrite<ICustomer, { customer_characteristics: IAppliedCharacteristicNew[] }>
