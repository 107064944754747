import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { IComment } from 'comments/comments.types'
import { Overwrite } from 'utility-types'

export interface IRoles {
  customer: boolean
  prospect: boolean
  vendor: boolean
  tax_authority: boolean
  user: boolean
  employee: boolean
  sales_rep: boolean
}
export interface IContactAddress {
  active?: boolean
  city: string
  state: string
  country: string
  accuracy?: number | null
  address1: string
  address2: string
  address3: string
  geocoded?: boolean
  latitude?: number | null
  longitude?: number | null
  incomplete?: boolean
  postalcode: string
  address_change?: string
  address_number?: number | string
  formatted_address?: string
  allow_marketing?: boolean
}
export enum PhoneRole {
  Office = 'Office',
  Mobile = 'Mobile',
  Fax = 'Fax',
}

export interface IPhone {
  role: string
  number: string
}
export interface IAccount {
  account_number: string
  account_name: string
  crm_role?: string
  active: boolean
  default: boolean
}

export interface IContact {
  contact_number: string
  active?: boolean
  honorific: string
  first_name: string
  middle_name: string
  last_name: string
  suffix?: string
  job_title: string
  phones: IPhone[]
  accounts?: IAccount[]
  email: string
  email_opt_in: boolean
  web: string
  company: string
  contact_address?: IContactAddress | null
  owner?: string
  notes?: string
  contact_characteristics: IAppliedCharacteristic[] | null
  comments?: Partial<IComment>[] | null
  created?: string | null
  lastupdated?: string | null
}

export enum ContactFormValues {
  Active = 'active',
  Honorific = 'honorific',
  FirstName = 'first_name',
  MidName = 'middle_name',
  LastName = 'last_name',
  Company = 'company',
  JobTitle = 'job_title',
  Email = 'email',
  Web = 'web',
  EmailOptIn = 'email_opt_in',
  Address1 = 'address1',
  Address2 = 'address2',
  Address3 = 'address3',
  City = 'city',
  Country = 'country',
  State = 'state',
  Postalcode = 'postalcode',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Accuracy = 'accuracy',
  AllowMarketing = 'allow_marketing',
  AddressChange = 'address_change',
  AddressNumber = 'address_number',
  Voice = 'office_phone',
  Alternate = 'mobile_phone',
  Fax = 'fax_phone',
  Suffix = 'suffix',
  Number = 'contact_number',
  Change = 'contact_change',
  Phones = 'phones',
  Accounts = 'accounts',
  Notes = 'notes',
  Comments = 'comments',
  ContactAddress = 'contact_address',
}

export type ContactCreatePayload = Overwrite<
  IContact,
  {
    contact_characteristics: IAppliedCharacteristicNew[]
  }
>
