import { FC, useCallback } from 'react'
import * as React from 'react'
import { IItemsFilters } from 'items/items-list/items-list.types'
import { ItemsService } from '../../items/items.service'
import { IItem } from '../../items/Items.types'
import { XtAutocomplete } from '../xtAutocomplete/XtAutocomplete'
import { IXtAutocompleteOption, XtAutocompleteLoadOptionsFunc } from '../xtAutocomplete/XtAutocomplete.types'
import * as styles from './ItemsSearch.module.scss'
import { ErrorHandler } from '../../services/ErrorService'

export interface IItemsSearchParams {
  error?: string
  value?: IItem | null
  onChange: (item: IItemsSearchOption | null) => void
  disabled?: boolean
  className?: string
  itemsFilter?: (item: IItem) => boolean
  filters?: IItemsFilters
}

const defaultItemsFilter = (item: IItem) => !!item

export interface IItemsSearchOption extends IXtAutocompleteOption, IItem {}

function processValue(value: IItem | null | undefined): IItemsSearchOption | null {
  return value ? { id: value.item_number, label: value.item_number, ...value } : null
}

export const ItemsSearch: FC<IItemsSearchParams> = ({
  value,
  onChange,
  disabled = false,
  className,
  itemsFilter = defaultItemsFilter,
  error,
  filters,
}) => {
  const loadItems = useCallback<XtAutocompleteLoadOptionsFunc<IItemsSearchOption>>(
    async (page, limit, itemNumberPattern) => {
      try {
        const { data, total } = await ItemsService.getAll({ limit, page }, { itemNumberPattern, ...filters })
        const processedData = data.filter(itemsFilter).map((item) => ({
          id: item.item_number,
          label: item.item_number,
          ...item,
        }))

        return { data: processedData, total }
      } catch (e) {
        ErrorHandler.handleError(e)
        return { data: [], total: 0 }
      }
    },
    [itemsFilter]
  )

  const renderOption: (option: IItemsSearchOption) => React.ReactNode = (option: IItemsSearchOption) => (
    <div className={styles.xtItemSearchLabelContainer} key={option?.id}>
      <div className={styles.xtItemSearchLabel} title={option?.item_number?.toString()}>
        {option?.item_number}
      </div>
      <div className={styles.xtItemSearchLabel} title={option?.description1}>
        {option?.description1}
      </div>
    </div>
  )

  const onSelect: (option: IItemsSearchOption | null) => void = (option) => {
    onChange(option)
  }

  return (
    <XtAutocomplete
      value={processValue(value)}
      onChange={onSelect}
      loadOptions={loadItems}
      renderOption={renderOption}
      placeholder="Item #"
      disabled={disabled}
      className={className}
      error={error}
    />
  )
}
