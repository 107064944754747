import { IObjectWithId } from '../../common/common.types'
import { ICRMProspect } from '../crm-prospects.types'

export interface IDeletionState {
  CRMProspectNumberId: string
  confirmationOpen: boolean
}

export enum CRMProspectAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'view',
}

export interface ICRMProspectTableItem extends IObjectWithId, Pick<ICRMProspect, 'number' | 'name' | 'owner' | 'assigned_user'> {}
