import { CommentsService } from '../comments.service'
import { ErrorHandler } from '../../services/ErrorService'
import { IPaginationData } from '../../common/common.types'
import { IXtAutocompleteOption } from '../../components/xtAutocomplete/XtAutocomplete.types'

export async function loadCommentTypes(page: number): Promise<IPaginationData<IXtAutocompleteOption>> {
  try {
    const { data, total } = await CommentsService.getCommentTypes(page)
    const commentTypes = data.map((type) => ({ id: type.code, label: type.name }))
    return { data: commentTypes, total }
  } catch (e) {
    ErrorHandler.handleError(e)
    return { data: [], total: 0 }
  }
}
