import { ICRMAccount } from 'crm-accounts/crm-accounts.types'
import { ICRMAccountTableItem } from './crm-accounts.types'

export const normalizeData = (data: ICRMAccount[]): ICRMAccountTableItem[] =>
  data.map((account) => {
    const contact = account.contacts && account.contacts[0]?.contact
    const firstName = contact ? contact.first_name : ''
    const lastName = contact ? contact.last_name : ''
    const phone = Array.isArray(contact?.phones) && contact.phones[0] ? `${contact.phones[0].role}: ${contact.phones[0].number}` : ''
    return {
      ...account,
      id: account.number,
      first_name: firstName,
      last_name: lastName,
      phone,
    }
  })
