import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { IPhoneTableItem } from '../phone-list.types'

export interface IPhoneDialog {
  open: boolean
  onClose(): void
  onConfirm(item: IPhoneTableItem): void
  phoneData: IPhoneTableItem | null
}
export enum PhoneFormField {
  Number = 'number',
  Role = 'role',
}
export interface IPhoneFormValues {
  [PhoneFormField.Role]: IXtAutocompleteOption | null
  [PhoneFormField.Number]: string
}

export enum PhoneFormLabel {
  Role = 'Type',
  Number = 'Value',
}
