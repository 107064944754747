import * as React from 'react'
import { Control } from 'react-hook-form'
import { MenuItem } from '@material-ui/core'
import { IFilterFormState, IFilter } from './filter.types'
import { FormSelectField } from '../../common/utils/form/form.components'
import { IXtAutocompleteOption } from '../xtAutocomplete/XtAutocomplete.types'

export function makeDropdownFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>,
  multiple?: boolean
): JSX.Element {
  if (!filter.options || filter.options.length < 1) {
    throw new Error('No options are defined for a dropdown filter')
  }
  const items = filter.options.map((option) => (
    <MenuItem value={option.value.toString()} key={`${option.label}-${option.value}`}>
      {option.label}
    </MenuItem>
  ))
  return (
    <FormSelectField
      label={filter.label}
      key={`filter-${filter.fieldName}-controller`}
      control={control}
      name={filter.fieldName}
      options={items}
      multiple={multiple}
      clearable
    />
  )
}
