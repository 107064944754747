import { Overwrite } from 'utility-types'
import { IItem } from '../items/Items.types'

export interface IBomItemsFilters {
  showExpired: boolean
  showFuture: boolean
}

export interface IBom {
  item_number: string
  item_description: string
  item_inventory_uom: string
  document_number: string
  batch_size: number
  revision: string
  revision_date: string | null
  revision_status: string
  bom_items: IBomItem[]
  bom_details?: IBomDetails
}
export interface IBomDetails {
  actual_cost: number
  max_cost: number
  non_picklist_number: number
  non_picklist_qtyper: number
  picklist_number: number
  picklist_qtyper: number
  standard_cost: number
  total_number: number
  total_qtyper: number
}

export interface IBomItem {
  id: number
  item: IItem
  bom_item_number: string
  bom_item_description: string
  bom_item_inventory_uom: string
  sequence_number: number
  effective: string
  expires: string
  qty_fxd: number
  qty_per: number
  issue_uom: string
  scrap: number
  create_child_wo: boolean
  issue_method: string
  used_at: string | null
  schedule_at_wo_operation: boolean
  ecn_number: string
  notes: string
  reference: string
  substitutions: string
  characteristic: string
  characteristic_value: string
}

export enum BomDetailsMode {
  View = 'view',
  Edit = 'edit',
}

export type BomUpdateInput = Omit<IBom, 'revision_status' | 'item_description' | 'item_inventory_uom'>
export type BomCreateInput = Overwrite<BomUpdateInput, { bom_items: Omit<IBomItem, 'id'>[] }>
export type BomItemUpdateRequest = Omit<IBomItem, 'substitutions' | 'characteristic' | 'characteristic_value' | 'bom_item_description'>
export type BomItemCreateRequest = Omit<BomItemUpdateRequest, 'id'>

export type BomItemQuickAddInput = Pick<IBomItem, 'bom_item_number' | 'issue_uom' | 'qty_fxd' | 'item'>
