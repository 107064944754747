import React from 'react'
import { Card, CardContent, CardHeader, Checkbox } from '@material-ui/core'
import * as styles from './item-cards.module.scss'
import { ICardData, IItemCards, IViewCardsData } from './item-cards.types'

const renderItemsCard = ({ value, label }: ICardData, _: number) => (
  <div className={styles.itemContent} key={label}>
    <span className={styles.label}>{label}: &nbsp;</span>
    <div className={styles.value}>
      {typeof value === 'boolean' ? <Checkbox classes={{ root: styles.muiCheckboxCard }} disabled checked={value} /> : value}
    </div>
  </div>
)
const renderViewCards = ({ title, data }: IViewCardsData, _: number) => (
  <div key={title} className={styles.cardContentContainer}>
    <CardHeader classes={{ root: styles.muiCardHeaderRoot, title: styles.muiCardHeaderTitle }} title={title} />
    <Card elevation={0} classes={{ root: styles.card }}>
      <CardContent>{data.map(renderItemsCard)}</CardContent>
    </Card>
  </div>
)

export const ItemCards = ({ viewCardsData }: IItemCards) => (
  <div className={styles.cardsContainer}>{viewCardsData.map(renderViewCards)}</div>
)
