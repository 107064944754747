import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { IRoutingListTableRow, RoutingListAction } from './RoutingList.types'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'

export const RoutingListActionsEditMode: IXtTableCellAction[] = [
  {
    name: RoutingListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: RoutingListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: RoutingListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: RoutingListAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]

export const RoutingListActionsViewMode: IXtTableCellAction[] = [
  {
    name: RoutingListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const confirmationDialogMessage = 'The item will be deleted. Are you sure?'

export const routingListColumns: IXtTableColumn<IRoutingListTableRow>[] = [
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'revision_number',
    field: 'revision_number',
    headerName: 'Revision',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'revision_status',
    field: 'revision_status',
    headerName: 'Rev. Status',
    flex: '1 0 160px',
    width: 160,
  },
]
