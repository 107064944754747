import { IAssignee } from 'tasks/tasks.types'
import { IAssigneeTableItem } from './assignee-tab.types'
import { dateToServerFormat } from '../../../../common/utils/date.utils'
import { IAssigneeDialogOutput } from './assignee-form-dialog/assignee-form-dialog.types'

export const normalizeDataWithId = (data: IAssignee[] = []): IAssigneeTableItem[] =>
  data.map((item, id) => ({
    ...item,
    id,
    role: item?.role ?? 'Primary',
  }))

export const normalizeDataNoId = (data: IAssigneeTableItem[] = []): IAssignee[] => data.map(({ id: _, ...other }) => ({ ...other }))

export function convertDialogItem(
  id: number | null | undefined,
  { assigned_date, username, role }: IAssigneeDialogOutput
): IAssigneeTableItem {
  return {
    role,
    username,
    id: id ?? new Date().getTime(),
    assigned_date: dateToServerFormat(assigned_date),
  }
}

export const defaultDeletionState = {
  open: false,
  item: null,
}

export const defaultAssigneeItemState = { open: false, itemDialog: null, isEdit: null }
