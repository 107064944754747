import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Toast as ToastProvider } from './ToastProvider'
import './assets/css/xt.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import "bootswatch/dist/flatly/bootstrap.min.css";
// import "bootswatch/dist/darkly/bootstrap.min.css";
import './assets/sass/xtuple-react.scss?v=0.3.0'
import './assets/css/fa-all.min.css'
import Main from './layouts/Main'
import PrivateRoute from './components/PrivateRoute'
import Login from './components/Login'

ReactDOM.render(
  <ToastProvider>
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/" component={Main} />
      </Switch>
    </BrowserRouter>
  </ToastProvider>,
  document.getElementById('root')
)
