import * as React from 'react'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FormField } from 'common/utils/form/form.components'
import { TextField, MenuItem, Modal, Slide } from '@material-ui/core'
import { XtButton } from 'components/xtButton/XtButton'
import { XtFileUpload } from 'components/file-upload/file-upload'
import { XtCharacteristics } from 'characteristics/characteristics'
import { useCharacteristics } from 'common/hooks/characteristics'
import { cls } from 'common/utils'
import { globalConstants } from 'common/constants'
import { DocumentDialogFormLabel, IAttachedDocumentDialog, IAttachedDocumentDialogState } from './document-dialog.types'
import styles from './document-dialog.module.scss'
import { defaultDocumentFormValues } from './document-dialog.constants'
import { DocumentDialogFormField, IDocumentDialogFormState } from '../documents.types'

export const XtDocumentDialog = React.memo(({ onClose, onCreate, open, editedDocument, usedOnFilter }: IAttachedDocumentDialog) => {
  const [state, setState] = React.useState<IAttachedDocumentDialogState>({
    isLoading: false,
    newFile: null,
  })
  const { handleSubmit, control, watch } = useForm<IDocumentDialogFormState>({
    defaultValues: defaultDocumentFormValues,
    mode: 'onBlur',
  })
  const name = watch(DocumentDialogFormField.Name)

  const characteristicsState = useCharacteristics([])

  const onSaveClick = useCallback(
    async (data: IDocumentDialogFormState) => {
      setState((prev) => ({ ...prev, isLoading: true }))
      await onCreate({
        ...editedDocument,
        ...state,
        ...data,
        document_characteristics: characteristicsState.characteristics,
        file: state.newFile!,
      })
      setState((prevState) => ({ ...prevState, isLoading: false }))
      onClose()
    },
    [state, setState, onClose, onCreate, editedDocument, characteristicsState.characteristics]
  )

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveClick, (error) => console.error(error))
    void eventHandler(e)
  }

  const onFileChange = (changedFile: File | null): void => {
    setState((prev) => ({ ...prev, newFile: changedFile }))
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
        <form onSubmit={submitForm}>
          <div className={styles.documentDialogContent}>
            <div className={cls(styles.documentsHeader, 'xt-section-border')}>
              <h3 title={name} hidden={!name}>
                {name}
              </h3>
              <div className={styles.documentsHeaderButtons}>
                <XtButton key="cancel" label="Cancel" onClick={onClose} disabled={state.isLoading} />
                <XtButton type="submit" key="save" label="Save" disabled={state.isLoading && !state.newFile} loading={state.isLoading} />
              </div>
            </div>
            <div className={styles.documentContent}>
              <div className={styles.formContent}>
                <TextField key="related-to" select label="Related to" value="file" variant="outlined" onChange={() => {}} disabled>
                  <MenuItem value="file" key="File">
                    File
                  </MenuItem>
                </TextField>
                <FormField control={control} name={DocumentDialogFormField.Name} label={DocumentDialogFormLabel.Name} />
                <FormField control={control} name={DocumentDialogFormField.Notes} label={DocumentDialogFormLabel.Notes} />
                <XtFileUpload file={state.newFile} onChange={onFileChange} />
              </div>
              <XtCharacteristics
                characteristics={characteristicsState.characteristics}
                usedOnFilter={usedOnFilter}
                onCreate={characteristicsState.createCharacteristic}
                onDelete={characteristicsState.deleteCharacteristic}
                onUpdate={characteristicsState.updateCharacteristic}
                isViewMode={false}
              />
            </div>
          </div>
        </form>
      </Slide>
    </Modal>
  )
})
