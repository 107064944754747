import { ICustomer } from '../customers.types'
import { IXtAutocompleteOption } from '../../components/xtAutocomplete/XtAutocomplete.types'

export interface ICustomerDetailsState {
  loading: boolean
  customer: ICustomer | null
  formState: ICustomerFormState
}

export enum ICustomeDetailsMode {
  View = 'view',
  Edit = 'edit',
}
export enum CustomerFormField {
  Number = 'customer_number',
  Name = 'customer_name',
  Type = 'customer_type',
  Active = 'active',
  Notes = 'notes',
  BillingContact = 'billing_contact',
  CorrespondContact = 'correspond_contact',
}

export enum CustomerFormLabel {
  Number = 'Customer #',
  Name = 'Customer Name',
  Type = 'Customer Type',
  Active = 'Active',
}

export enum CorrespondFormFIeld {
  Number = 'correspond_contact_number',
  Honorific = 'correspond_contact_honorific',
  First = 'correspond_contact_first',
  Middle = 'correspond_contact_middle',
  Last = 'correspond_contact_last',
  Suffix = 'correspond_contact_suffix',
  JobTtitle = 'correspond_contact_job_title',
  Voice = 'correspond_contact_voice',
  Alternate = 'correspond_contact_alternate',
  Fax = 'correspond_contact_fax',
  Email = 'correspond_contact_email',
  Web = 'correspond_contact_web',
  Change = 'correspond_contact_change',
  AddressNumber = 'correspond_contact_address_number',
  Address1 = 'correspond_contact_address1',
  Address2 = 'correspond_contact_address2',
  Address3 = 'correspond_contact_address3',
  City = 'correspond_contact_city',
  State = 'correspond_contact_state',
  Postalcode = 'correspond_contact_postalcode',
  Country = 'correspond_contact_country',
  AddressChange = 'correspond_contact_address_change',
}

export enum BillingFormFIeld {
  Number = 'billing_contact_number',
  Honorific = 'billing_contact_honorific',
  First = 'billing_contact_first',
  Middle = 'billing_contact_middle',
  Last = 'billing_contact_last',
  Suffix = 'billing_contact_suffix',
  JobTtitle = 'billing_contact_job_title',
  Voice = 'billing_contact_voice',
  Alternate = 'billing_contact_alternate',
  Fax = 'billing_contact_fax',
  Email = 'billing_contact_email',
  Web = 'billing_contact_web',
  Change = 'billing_contact_change',
  AddressNumber = 'billing_contact_address_number',
  Address1 = 'billing_contact_address1',
  Address2 = 'billing_contact_address2',
  Address3 = 'billing_contact_address3',
  City = 'billing_contact_city',
  State = 'billing_contact_state',
  Postalcode = 'billing_contact_postalcode',
  Country = 'billing_contact_country',
  AddressChange = 'billing_contact_address_change',
}
export interface ICustomerFormData extends Omit<Pick<ICustomer, CustomerFormField>, CustomerFormField.Type> {
  [CustomerFormField.Type]: IXtAutocompleteOption | null
}

export type CustomerFormValues = Pick<ICustomer, CustomerFormField>

export interface ICustomerFormState {
  state: Partial<CustomerFormValues> | null
  isDirty?: boolean
  isValid?: boolean
  isSubmitting?: boolean
}
