import { CheckboxView, IShipmentTableItem } from './shipments-list.types'
import { IShipment } from '../shipments.types'

const convertToViewCheckbox = (checked: boolean) => (checked ? CheckboxView.IsTrue : CheckboxView.isFalse)
const parseCheckboxValue = (checked: CheckboxView) => checked === CheckboxView.IsTrue

export const normalizeData = (data: IShipment[]): IShipmentTableItem[] =>
  data?.map((item) => ({
    ...item,
    id: item?.shipto_number,
    active: convertToViewCheckbox(item?.active),
    default_flag: convertToViewCheckbox(item?.default_flag),
    city_state_zip: [item?.shipto_address?.city, item?.shipto_address?.state, item?.shipto_address?.postalcode].filter(Boolean).join(', '),
    address1: item?.shipto_address?.address1,
  }))
export const convertToShipmentData = (item: IShipmentTableItem): IShipment => ({
  ...item,
  active: parseCheckboxValue(item.active),
  default_flag: parseCheckboxValue(item.default_flag),
})
export const defaultDialogState = { item: null, open: false, mode: null }
export const defaultDeletionState = { id: null, open: false }
export const defaultShipmentState = {
  deletionState: defaultDeletionState,
  shipmentDialogState: defaultDialogState,
  tableState: { loading: false, items: [] },
}
