import { useCallback, useEffect, useState } from 'react'
import { CommentCreateRequest } from '../../comments/comments.types'
import { DocumentType } from '../../documents/documents.types'
import { ErrorHandler } from '../../services/ErrorService'
import { CommentsService } from '../../comments/comments.service'
import { filterByField, updateByFieldValue } from '../utils/array.utils'
import { CommentItem } from '../../comments/comment/comment.types'
import { AuthService } from '../../auth/auth.service'
import { IPaginationParams } from '../common.types'
import { globalConstants } from '../constants'
import { IXtCommentsPaginationParams } from '../../comments/comments/comments'

export interface ICommentsHookState {
  comments: CommentItem[]
  loading: boolean
  meta: {
    page: number
    limit: number
    total: number
  }
}
const initialCommentsHookState = {
  comments: [],
  loading: false,
  meta: { page: 1, limit: globalConstants.paginationLimit, total: 0 },
}
export interface ICommentsHook extends IXtCommentsPaginationParams {
  /**
   * The function returns true if a comment is saved successfully. Otherwise it returns false.
   * @param comment
   */
  saveComment(comment: CommentItem): Promise<boolean>
  saveComments(comments: CommentItem[]): Promise<void>
  updateComment(comment: CommentItem): Promise<void>
  setComments(comments: CommentItem[]): void
  loadMore(): Promise<void>
  canLoadMore(): boolean
  comments: CommentItem[]
  username: string
  creationEnabled: boolean
}

function convertCommentItemToCreatePayload(commentItem: CommentItem, source: DocumentType, sourceNumber: string): CommentCreateRequest {
  const { comment, comment_type } = commentItem
  return {
    comment,
    comment_type,
    comment_source_number: sourceNumber,
    source,
  }
}

export function useComments(source: DocumentType, sourceNumber: string | null | undefined, creationEnabled: boolean = true): ICommentsHook {
  const [state, setState] = useState<ICommentsHookState>(initialCommentsHookState)

  const requestSalesOrders = useCallback<(paginationParams: IPaginationParams) => Promise<void>>(
    async ({ limit, page }) => {
      if (!sourceNumber) {
        setState(initialCommentsHookState)
        return
      }
      try {
        setState((prevState) => ({ ...prevState, loading: true }))
        const { data, total } = await CommentsService.getForSource(source, sourceNumber, { page, limit })
        setState((prevState) => ({
          comments: filterByField([...prevState.comments, ...data], 'id'),
          meta: {
            total,
            page,
            limit,
          },
          loading: false,
        }))
      } catch (e) {
        ErrorHandler.handleError(e)
        setState((prevState) => ({ ...prevState, loading: false }))
      }
    },
    [source, sourceNumber]
  )

  const saveComment = useCallback<(comment: CommentItem) => Promise<boolean>>(
    async (comment) => {
      if (!sourceNumber) {
        setState((prevState) => ({ ...prevState, comments: [comment, ...prevState.comments] }))
        return true
      }
      try {
        const payload = convertCommentItemToCreatePayload(comment, source, sourceNumber)
        await CommentsService.create(payload)
        // TODO CommentsService.create(comment) should return the list of the created comments, so we don't need to request all the comments here
        const { limit, page } = state.meta
        const itemsCount = page * limit + 1
        const { data } = await CommentsService.getForSource(source, sourceNumber, { limit: itemsCount, page: 1 })
        setState((prevState) => ({ ...prevState, comments: data }))
        return true
      } catch (e) {
        ErrorHandler.handleError(e)
        return false
      }
    },
    [source, sourceNumber, state]
  )

  const saveComments = useCallback<(comments: CommentItem[]) => Promise<void>>(
    async (newComments) => {
      try {
        if (!sourceNumber) {
          setState((prevState) => ({ ...prevState, comments: filterByField([...newComments, ...prevState.comments], 'id') }))
          return
        }
        const payload = newComments.map((comment) => convertCommentItemToCreatePayload(comment, source, sourceNumber))
        await CommentsService.createAll(payload)
        // TODO CommentsService.create(comment) should return the list of the created comments, so we don't need to request all the comments here
        const { limit, page } = state.meta
        const itemsCount = page * limit + newComments.length
        const { data } = await CommentsService.getForSource(source, sourceNumber, { limit: itemsCount, page: 1 })
        setState((prevState) => ({ ...prevState, comments: data }))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [source, sourceNumber, state]
  )

  const updateComment = useCallback<(comment: CommentItem) => Promise<void>>(
    async (comment) => {
      try {
        if (!sourceNumber) {
          setState((prevState) => ({ ...prevState, comments: updateByFieldValue(prevState.comments, 'id', comment) }))
          return
        }
        await CommentsService.update({ ...convertCommentItemToCreatePayload(comment, source, sourceNumber), id: comment.id })
        // TODO CommentsService.create(comment) should return the list of the created comments, so we don't need to request all the comments here
        const { limit, page } = state.meta
        const itemsCount = page * limit
        const { data } = await CommentsService.getForSource(source, sourceNumber, { limit: itemsCount, page: 1 })
        setState((prevState) => ({ ...prevState, comments: data }))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [source, sourceNumber, state]
  )

  const canLoadMore = useCallback<() => boolean>(() => {
    const { limit, page, total } = state.meta
    return limit * page < total
  }, [state])

  const loadMore = useCallback<() => Promise<void>>(async () => {
    const { limit, page } = state.meta
    if (state.loading || !canLoadMore()) {
      return
    }
    await requestSalesOrders({ limit, page: page + 1 })
  }, [state, canLoadMore, requestSalesOrders])

  const setComments: (comments: CommentItem[]) => void = (newComments) => setState((prevState) => ({ ...prevState, comments: newComments }))

  useEffect(() => {
    void requestSalesOrders({ limit: state.meta.limit, page: 1 })
  }, [source, sourceNumber])

  return {
    setComments,
    saveComment,
    updateComment,
    saveComments,
    loadMore,
    canLoadMore,
    total: state.meta.total,
    comments: state.comments,
    username: AuthService.getUsername() ?? 'Unknown',
    creationEnabled,
  }
}
