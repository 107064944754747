import { IStandardOperation } from 'services/StandardOperationService'
import { RoutingItemControlName, MIN_NUMBER_VALUE, NONE_VALUE } from './RoutingItem.constants'
import { RoutingItemFormData, IThroughputData } from './RoutingItem.types'
import { IRoutingItem } from '../Routing.types'

export const throughputFormula = (data: IThroughputData, perMinute?: boolean): number | string => {
  const { per, runTime, uomRatio } = data
  const perNum = Number(per)
  const runTimeNum = Number(runTime)
  const uomRatioNum = Number(uomRatio)

  if (perNum > 0 && runTimeNum > 0 && uomRatioNum > 0) {
    if (perMinute) {
      // per Minute Formula: per / Production/Inventory Ratio / Run Time (min.)
      return (perNum / uomRatioNum / runTimeNum).toFixed(2)
    }
    // (min.) per Formula: Production/Inventory Ratio * Run Time (min.) / per
    return ((uomRatioNum * runTimeNum) / perNum).toFixed(2)
  }
  return '0.00'
}
export const defineRoutingItemFormData = (data: IRoutingItem | null): RoutingItemFormData => ({
  [RoutingItemControlName.StandardOperation]: NONE_VALUE,
  // TODO add OperationTypeService.get(data.operation_type)
  [RoutingItemControlName.OperationType]: data?.operation_type ?? '',
  [RoutingItemControlName.RoutingDescription1]: data?.routing_description1 ?? '',
  [RoutingItemControlName.RoutingDescription2]: data?.routing_description2 ?? '',
  [RoutingItemControlName.WorkCenter]: data?.work_center ?? '',
  [RoutingItemControlName.ToolingReference]: data?.tooling_reference ?? '',
  [RoutingItemControlName.ExecutionDay]: data?.execution_day ?? 1,
  // { TODO use formatDate
  [RoutingItemControlName.Effective]: data?.effective ?? 'Always',
  [RoutingItemControlName.Expires]: data?.expires ?? 'Never',
  // TODO use formatDate }
  [RoutingItemControlName.Instructions]: data?.instructions ?? '',
  [RoutingItemControlName.SequenceNumber]: data?.sequence_number ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.InventoryUom]: data?.inventory_uom ?? '',
  [RoutingItemControlName.SetupTime]: data?.setup_time ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.RunTime]: data?.run_time ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.Per]: data?.run_qty_per ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.ReportSetupCostAs]: data?.report_setup_as ?? '',
  [RoutingItemControlName.ReportSetupTime]: data?.report_setup ?? false,
  [RoutingItemControlName.ReportRunCostAs]: data?.report_runtime_as ?? '',
  [RoutingItemControlName.ReportRunTime]: data?.report_runtime ?? false,
  [RoutingItemControlName.ProductionUOM]: data?.production_uom ? { id: data.production_uom, label: data.production_uom } : null,
  [RoutingItemControlName.ProductionRatio]: data?.production_inventory_ratio ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.ReceiveInventory]: data?.receive_inventory ?? false,
  [RoutingItemControlName.IssueComponents]: data?.issue_components ?? false,
  [RoutingItemControlName.AllowOverlap]: data?.allow_overlap ?? false,
  [RoutingItemControlName.WIPLocation]: data?.wip_location
    ? { id: data.wip_location, label: `${data.wip_location_site}-${data.wip_location}` }
    : null,
})

export const defineStdOperationData = (standardOperations: IStandardOperation | null) => ({
  [RoutingItemControlName.OperationType]: standardOperations?.operation_type ?? '',
  [RoutingItemControlName.RoutingDescription1]: standardOperations?.operation_description1 ?? '',
  [RoutingItemControlName.RoutingDescription2]: standardOperations?.operation_description2 ?? '',
  [RoutingItemControlName.WorkCenter]: standardOperations?.work_center ?? '',
  [RoutingItemControlName.ToolingReference]: standardOperations?.tooling_reference ?? '',
  [RoutingItemControlName.Instructions]: standardOperations?.instructions ?? '',
  [RoutingItemControlName.SetupTime]: standardOperations?.setup_time ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.RunTime]: standardOperations?.run_time ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.Per]: standardOperations?.qty_per ?? MIN_NUMBER_VALUE,
  [RoutingItemControlName.ReportRunCostAs]: standardOperations?.report_run_as ?? '',
  [RoutingItemControlName.ReportRunTime]: standardOperations?.report_run ?? false,
  [RoutingItemControlName.ReportSetupCostAs]: standardOperations?.report_setup_as ?? '',
  [RoutingItemControlName.ReportSetupTime]: standardOperations?.report_setup ?? false,
  [RoutingItemControlName.ProductionUOM]: standardOperations?.production_uom
    ? { id: standardOperations?.production_uom, label: standardOperations?.production_uom }
    : null,
  [RoutingItemControlName.ProductionRatio]: standardOperations?.inventory_production_ratio ?? MIN_NUMBER_VALUE,
})
