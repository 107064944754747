import { FilterType, IFilter, IFilterOption } from 'components/filter/filter.types'
import { ICRMProspect } from 'crm-prospects/crm-prospects.types'
import { DocumentsService } from 'documents/documents.service'
import { DocumentType } from 'documents/documents.types'
import { UserService } from 'services/user.service'
import { ICRMProspectTableItem } from './crm-prospects-list.types'

export const normalizeData = (data: ICRMProspect[]): ICRMProspectTableItem[] =>
  data.map((prospect) => ({
    ...prospect,
    id: prospect.number,
  }))

export const makeFilters = (userOptions: IFilterOption[], sourceOptions: IFilterOption[]): IFilter[] => [
  {
    type: FilterType.Text,
    label: 'Prospect Number Pattern',
    fieldName: 'prospect_number',
  },
  {
    type: FilterType.Text,
    label: 'Prospect Name Pattern',
    fieldName: 'prospect_name',
  },
  {
    type: FilterType.Dropdown,
    label: 'Owner',
    fieldName: 'owner',
    options: userOptions,
  },
  {
    type: FilterType.Dropdown,
    label: 'Assigned To',
    fieldName: 'assignedto',
    options: userOptions,
  },
  {
    type: FilterType.Dropdown,
    label: 'Source',
    fieldName: 'source',
    options: sourceOptions,
  },
  {
    type: FilterType.Text,
    label: 'Contact Name Pattern',
    fieldName: 'contact_name',
  },
  {
    type: FilterType.Text,
    label: 'City Pattern',
    fieldName: 'city',
  },
  {
    type: FilterType.Text,
    label: 'State Pattern',
    fieldName: 'state',
  },
  {
    type: FilterType.Checkbox,
    label: 'Show Inactive',
    fieldName: 'showInactive',
  },
  {
    type: FilterType.Date,
    label: 'Last Updated',
    fieldName: 'lastupdated',
  },
]

export const buildFilters = async (): Promise<IFilter[]> => {
  // TODO: once Vadim's PR #88 is merged, remove a hardcoded limit
  const { data: userData } = await UserService.getAll({ limit: 1000, page: 1 })
  const usersForFilters = userData.map((user) => ({ label: `${user.username}-${user.proper_name}`, value: user.username }))

  // TODO: once Vadim's PR #88 is merged, remove a hardcoded limit
  const { data: sourceData } = await DocumentsService.getDocuments(DocumentType.Warehouse, { limit: 1000, page: 1 })
  const sourcesForFilters = sourceData.map((warehouse) => ({ label: `${warehouse.number}`, value: warehouse.number }))

  return makeFilters(usersForFilters, sourcesForFilters)
}
