import React, { memo, FC, useCallback, useEffect } from 'react'

import { Dialog, TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/xtButton/XtButton'
import { IContact } from 'contacts/contacts.types'
import { loadCRMContactRoleOptions } from 'common/utils'
import { FormCheckboxLabel, FormXtAutocomplete } from 'common/utils/form/form.components'
import { ContactSearch } from 'contacts/contact-search/contact-search'
import { XtQuickAddButton } from 'components/quickAddButton/QuickAddButton'
import styles from './select-contact-dialog.module.scss'
import { DialogFormField, DialogFormLabel, ISelectContactDialog, ISelectContactFormData } from './select-contact-dialog.types'
import { SelectDialogFormSchema } from './select-contact-dialog.validation'
import { defineSelectDialogItem } from './select-contact-dialog.utils'

export const SelectContactDialog: FC<ISelectContactDialog> = memo(
  ({ onClose, open, onConfirm, selectData, account_name, account_number, openContactDialog }) => {
    const { control, watch, setValue, register, reset, handleSubmit } = useForm<ISelectContactFormData>({
      defaultValues: defineSelectDialogItem(selectData),
      resolver: yupResolver(SelectDialogFormSchema),
      mode: 'onBlur',
    })
    register(DialogFormField.Contact)

    useEffect(() => void reset(defineSelectDialogItem(selectData)), [selectData])

    const values = watch()

    const setContact = useCallback<(contactOption: IContact) => void>((contactOption: IContact) => {
      setValue(DialogFormField.Contact, contactOption)
    }, [])

    const onCancel = () => {
      reset(defineSelectDialogItem(null))
      onClose()
    }

    const confirmationDialog = () => {
      if (!values.contact) {
        return
      }
      const account = { account_number, account_name, crm_role: values.role?.label ?? '', active: values.active, default: values.default }
      const accounts = [...(values.contact?.accounts ?? []), account]
      const convertedContact = { ...values.contact, accounts }
      onConfirm(convertedContact)
      onCancel()
    }

    const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
      e.stopPropagation() // To prevent submitting parent forms
      const eventHandler = handleSubmit(confirmationDialog, (error) => console.error(error))
      void eventHandler(e)
    }

    return (
      <Dialog fullWidth classes={{ paperWidthSm: styles.muiPaperWidthSm }} open={open} onClose={onCancel}>
        <form onSubmit={submitForm} className={styles.selectContactDialog}>
          <div className={styles.selectContactDialogHeaderForm}>
            <TextField variant="outlined" disabled value={account_number} label={DialogFormLabel.AccountNumber} />
            <FormXtAutocomplete
              control={control}
              name={DialogFormField.Role}
              label={DialogFormLabel.Role}
              loadOptions={loadCRMContactRoleOptions}
            />
            <div className={styles.checkboxGroup}>
              <FormCheckboxLabel control={control} name={DialogFormField.Active} label={DialogFormLabel.Active} />
              <FormCheckboxLabel control={control} name={DialogFormField.Default} label={DialogFormLabel.Default} />
            </div>
            <div className={styles.addButtonPosition}>
              <XtQuickAddButton onClick={openContactDialog} />
            </div>
          </div>
          <div className={styles.selectContactDialogContent}>
            <ContactSearch initialData={selectData} labelContact={DialogFormLabel.Contact} onChange={setContact} />
          </div>
          <div className={styles.selectContactDialogButtons}>
            <XtButton label="Cancel" onClick={onCancel} />
            <XtButton disabled={!values.contact} type="submit" label="Save" />
          </div>
        </form>
      </Dialog>
    )
  }
)
