import React from "react";

const Settings = () => {
  return (
    <div className="content">
    </div>
  );
}

export default Settings;
