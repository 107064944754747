import { IXtTableCellAction } from '../../components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from '../../components/svgIcon/SvgIcon.types'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'
import { CRMAccountAction, ICRMAccountTableItem } from './crm-accounts.types'

export const defaulDeletionState = {
  CRMAccountNumberId: '',
  confirmationOpen: false,
}

export const CRMAccountActionsEditMode: IXtTableCellAction[] = [
  {
    name: CRMAccountAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: CRMAccountAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: CRMAccountAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const crmAccountColumns: IXtTableColumn<ICRMAccountTableItem>[] = [
  {
    id: 'number',
    field: 'number',
    headerName: 'Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'name',
    field: 'name',
    headerName: 'Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'assigned_user',
    field: 'assigned_user',
    headerName: 'Assignee',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'first_name',
    field: 'first_name',
    headerName: 'First Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'last_name',
    field: 'last_name',
    headerName: 'Last Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'phone',
    field: 'phone',
    headerName: 'Phone Number',
    flex: '1 0 260px',
    width: 260,
  },
]
