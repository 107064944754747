import { parseDate } from 'common/utils/date.utils'
import { IBom } from '../bom.types'
import { globalConstants } from '../../common/constants'
import { BomFormData } from '../bomDetails/BomDetails.types'

// TODO inside the typeUtils
const convertDate = (date: Date | string): Date => {
  if (typeof date === 'string') {
    return parseDate(date, globalConstants.serverDateFormat)
  }
  return date
}
export const processBom: (bom: IBom | null) => BomFormData = (bom) => ({
  batch_size: bom?.batch_size ?? '',
  document_number: bom?.document_number ?? '',
  item_number: bom?.item_number ?? null,
  revision: bom?.revision ?? '',
  revision_date: bom?.revision_date ? convertDate(bom.revision_date) : new Date(),
})
