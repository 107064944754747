import * as Yup from 'yup'
import { maxNumber, validationMessage } from '../../../../common/validation/validation'
import { LineItemFormField } from './sales-order-line-item-new.types'

export const validationSchema = Yup.object().shape({
  [LineItemFormField.Item]: Yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [LineItemFormField.SellingUom]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [LineItemFormField.QtyOrdered]: Yup.number()
    .required(validationMessage.isRequired)
    .integer()
    .moreThan(0, validationMessage.min(0))
    .lessThan(maxNumber, validationMessage.max(maxNumber))
    .typeError(validationMessage.invalidNumber),
  [LineItemFormField.NetUnitPrice]: Yup.number()
    .required(validationMessage.isRequired)
    .min(0, validationMessage.min(0))
    .lessThan(maxNumber, validationMessage.max(maxNumber))
    .typeError(validationMessage.invalidNumber),
  [LineItemFormField.Site]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [LineItemFormField.ScheduledDate]: Yup.date().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
})
