import { useCallback, useEffect, useState } from 'react'
import { IFilter, IFilterFormState } from '../../components/filter/filter.types'
import { ErrorHandler } from '../../services/ErrorService'

export interface IFilterHookState {
  filterOpen: boolean
  filters: IFilter[]
}

export interface IFilterHook extends IFilterHookState {
  onFiltersSubmit(filters: IFilterFormState): void
  openFilters: VoidFunction
  closeFilters: VoidFunction
}

export function useFilter(initializer: () => Promise<IFilter[]>, filter: (filters: IFilterFormState) => Promise<void>): IFilterHook {
  const [state, setState] = useState<IFilterHookState>({ filterOpen: false, filters: [] })

  const openDialog: VoidFunction = () => setState((prevState) => ({ ...prevState, filterOpen: true }))
  const closeDialog: VoidFunction = () => setState((prevState) => ({ ...prevState, filterOpen: false }))

  const onFilter = useCallback<(filters: IFilterFormState) => void>(
    async (filters) => {
      setState((prevState) => ({ ...prevState, filterOpen: false }))
      try {
        await filter(filters)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [filter]
  )

  const initFilters = useCallback<() => Promise<void>>(async () => {
    try {
      const filters = await initializer()
      setState((prevState) => ({ ...prevState, filters }))
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }, [initializer])

  useEffect(() => {
    void initFilters()
  }, [])

  return {
    ...state,
    onFiltersSubmit: onFilter,
    openFilters: openDialog,
    closeFilters: closeDialog,
  }
}
