import React, { FC, useMemo } from 'react'

import { XtRemarks } from 'components/xt-remarks/xt-remarks'
import { IRemarksHook } from 'common/hooks/remarks'
import { XtCharacteristics } from 'characteristics/characteristics'
import { ICharacteristicsHook } from 'common/hooks/characteristics'
import { XtDocuments } from 'components/documents/documents'
import { IDocumentsHook } from 'common/hooks/documents'
import { UsedOnValue } from 'documents/documents.types'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { IItem } from 'items/Items.types'
import { ViewDetailsTab } from './details-tab/details-tab'

interface IIemDetailsTabs {
  disabled: boolean
  data: IItem | null
  remarks: IRemarksHook
  characteristics: ICharacteristicsHook
  documents: IDocumentsHook
}
enum ItemDetailsTabName {
  Details = 'Details',
  Documents = 'Documents',
  Characteristics = 'Characteristics',
  Remarks = 'Remarks',
}

export const ItemTabs: FC<IIemDetailsTabs> = ({ disabled, data, remarks, characteristics, documents }) => {
  const tabs = useMemo(
    () => [
      {
        name: ItemDetailsTabName.Details,
        template: <ViewDetailsTab data={data} />,
      },
      {
        name: ItemDetailsTabName.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.Item}
            isViewMode={disabled}
            onCreate={characteristics.createCharacteristic}
            onUpdate={characteristics.updateCharacteristic}
            onDelete={characteristics.deleteCharacteristic}
            characteristics={characteristics.characteristics}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Documents,
        template: (
          <XtDocuments
            usedOnFilter={UsedOnValue.Item}
            isViewMode={disabled || !documents.creationEnabled}
            onDocumentCreate={documents.saveDocument}
            onDocumentDelete={documents.deleteDocument}
            loadMore={documents.loadMore}
            canLoadMore={documents.canLoadMore}
            documents={documents.documents}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Remarks,
        template: (
          <XtRemarks
            loadMore={remarks.loadMore}
            canLoadMore={remarks.canLoadMore}
            total={remarks.total}
            textAreaOnChange={remarks.setNotes}
            textAreaValue={remarks.notes}
            textAreaName="Notes"
            onCommentsUpdate={remarks.updateComment}
            onCommentsSave={remarks.saveComment}
            comments={remarks.comments}
            username={remarks.username}
            disabled={disabled}
            extendedTextAreaOnChange={remarks.setAdditionalNotes}
            extendedTextAreaValue={remarks.additionalNotes}
            extendedTextAreaName="Extended Description"
          />
        ),
      },
    ],
    [data, disabled, remarks]
  )
  return <XtTabs tabs={tabs} />
}
