import * as React from 'react'
import { FC, useCallback, useState } from 'react'

import { ErrorHandler } from 'services/ErrorService'
import { cls } from 'common/utils'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'

import { confirmationMessages } from 'common/constants'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { ToastService } from 'services/ToasterService'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { FilterFieldName, IQuote } from 'quotes/quotes.types'
import { QuotesService } from 'quotes/quotes.service'
import { ICopyDialogState, IQuotesTableItem } from 'quotes/quotes-list/quote-list.types'
import { defaultCopyQuoteDialogState, QuotesAction, QuotesListActionsViewMode } from 'quotes/quotes-list/quote-list.constants'
import { CopyQuoteDialog } from 'quotes/quotes-list/copy-quote-dialog/copy-quote-dialog'
import { CheckboxLabel } from 'common/utils/form/form.components'
import { XtQuickAddButton } from 'components/quickAddButton/QuickAddButton'
import * as styles from './quotes-list.module.scss'
import { fetchQuotes } from './quotes-list.utils'
import { QuotesColumns, QuotesListActionsEditMode } from './quotes-list.constants'

export const QuotesList: FC<{
  disabled: boolean
  isMobile: boolean
  customerNumber?: string
}> = ({ disabled, isMobile, customerNumber }) => {
  const { itemId: itemIdToDelete, open: confirmationDialogOpen, openDialog, closeDialog } = useConfirmationDialog<number>()
  const { state, setLoading, refresh, pagination, filter } = useTable(
    { [FilterFieldName.CustomerNumber]: customerNumber, [FilterFieldName.ShowConverted]: false, [FilterFieldName.ShowExpired]: false },
    fetchQuotes
  )

  const [copyDialogState, setCopyDialogState] = useState<ICopyDialogState>(defaultCopyQuoteDialogState)

  const handleRowClick = useCallback<(item: IQuotesTableItem) => void>(() => {}, [])

  const requestActionQuote = async (quote: IQuote, action: QuotesAction) => {
    try {
      if (action === QuotesAction.Copy) {
        await QuotesService.copyQuote(quote.quote_number)
        ToastService.showSuccess(`Quote ${quote.quote_number} has been copied.`)
      }
      if (action === QuotesAction.ConvertToSalesOrder) {
        await QuotesService.convertQuoteToSalesOrder(quote.quote_number)
        ToastService.showSuccess(`Quote ${quote.quote_number} has been converted.`)
      }
      await refresh()
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const handleAction = useCallback<(item: IQuotesTableItem, action: QuotesAction) => void>((item, action) => {
    switch (action) {
      case QuotesAction.Edit: {
        return null
      }

      case QuotesAction.View: {
        return null
      }
      case QuotesAction.Delete: {
        return openDialog(Number(item.id))
      }
      case QuotesAction.Copy: {
        return requestActionQuote(item, action)
      }
      case QuotesAction.ConvertToSalesOrder: {
        return requestActionQuote(item, action)
      }
      case QuotesAction.CopyTo: {
        // TODO: delete check(null) and update CopyDialogState
        return null && setCopyDialogState({ open: true, quote: item })
      }
      default:
        return null
    }
  }, [])

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeDialog()
    if (itemIdToDelete) {
      try {
        setLoading(true)
        await QuotesService.delete(itemIdToDelete)
        await refresh()
        ToastService.showSuccess(`Quote ${itemIdToDelete} has been deleted.`)
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [closeDialog, itemIdToDelete])

  const onCloseCopyDialog = useCallback<VoidFunction>(() => setCopyDialogState(defaultCopyQuoteDialogState), [])
  const onShowExpiredChange = async (checked: boolean): Promise<void> => {
    await filter({ ...state.filters, [FilterFieldName.ShowExpired]: checked })
  }
  const onShowConvertedChange = async (checked: boolean): Promise<void> => {
    await filter({ ...state.filters, [FilterFieldName.ShowConverted]: checked })
  }
  return (
    <div>
      <CopyQuoteDialog
        onClose={onCloseCopyDialog}
        onConfirm={() => {
          /* TODO integration api onConfirm CopyQuoteDialog */
        }}
        open={copyDialogState.open}
        quoteData={copyDialogState.quote}
      />

      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.deleted}
        title="Delete Quote"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />
      <div>
        <div className={cls(styles.filters, 'MuiFormControlLabel')}>
          <CheckboxLabel
            disabled={state.loading}
            value={Boolean(state.filters[FilterFieldName.ShowExpired])}
            onChange={onShowExpiredChange}
            label="Show Expired"
          />
          <CheckboxLabel
            disabled={state.loading}
            value={Boolean(state.filters[FilterFieldName.ShowConverted])}
            onChange={onShowConvertedChange}
            label="Show Converted"
          />
        </div>
        <div hidden={isMobile} className={styles.addItemButtonContainer}>
          <XtQuickAddButton
            disabled
            className={styles.addItemButton}
            onClick={() => {
              /* TODO OPEN Quote Details in New Mode */
            }}
          />
        </div>
        <div className={styles.table}>
          <XtList
            actions={!disabled ? QuotesListActionsEditMode : QuotesListActionsViewMode}
            onRowClick={handleRowClick}
            onAction={handleAction}
            isMobile={isMobile}
            pagination={pagination}
            loading={state.loading}
            data={state.data}
            columns={QuotesColumns}
          />
        </div>
      </div>
    </div>
  )
}
