import { IItem } from 'items/Items.types'

export interface IItemDetailsState {
  loading: boolean
  item: IItem | null
}

export enum ItemDetailsFormField {
  ItemNumber = 'item_number',
  Active = 'active',
  Description1 = 'description1',
  Description2 = 'description2',
  ItemType = 'item_type',
  ClassCode = 'class_code',
  InventoryUOM = 'inventory_uom_name',
  PickList = 'pick_list_item',
  Fractional = 'fractional',
  Configured = 'configured',
  MaximumDesiredCost = 'maximum_desired_cost',
  FreightClass = 'freight_class',
  BarCode = 'upc_code',
}
export enum ItemDetailsFormLabel {
  ItemType = 'Item Type',
  ClassCode = 'Class Code',
  InventoryUOM = 'Inventory UOM',
  PickList = 'Pick List',
  Fractional = 'Fractional',
  Configured = 'Configured',
  MaximumDesiredCost = 'Maximum Desired Cost',
  FreightClass = 'Freight Class',
  BarCode = 'Bar Code',
}

export enum ItemSoldFormField {
  ItemIsSold = 'item_is_sold',
  ProductCategory = 'product_category',
  ListPrice = 'list_price',
  // TODO update selling_uom
  InventoryUOM = 'inventory_uom',
  SellingUOM = 'uom_conversions',
  Warranty = 'warranty_days',
  // TODO update wholesale_price
  WholesalePrice = 'wholesale_price',
  Exclusive = 'exclusive',
}

export enum ItemSoldFormLabel {
  ItemIsSold = 'Item is Sold',
  ProductCategory = 'Product Category',
  ListPrice = 'List Price',
  SellingUOM = 'Selling UOM',
  Warranty = 'Warranty',
  WholesalePrice = 'Wholesale Price',
  Exclusive = 'Exclusive',
}

export enum ItemWeightFormField {
  Product = 'product_weight',
  Packaging = 'packaging_weight',
}
export enum ItemWeightFormLabel {
  Product = 'Product',
  Packaging = 'Packaging',
}
