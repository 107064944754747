import { Overwrite } from 'utility-types'

import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { IContact, IContactAddress } from 'contacts/contacts.types'
import { IShipment, PartialShipment } from '../shipments.types'
import { CountryOption } from '../../common/utils/country-state.utils'

export interface IDialogState {
  item: IShipment | null
  open: boolean
  mode: ShipmentDialogMode | null
}
export enum ShipmentDialogMode {
  Edit = 'Edit',
  View = 'View',
  New = 'New',
}

export type ShipmentConfirmDialog = { shipTo: PartialShipment; mode: ShipmentDialogMode | null }
export interface IShipmentDialog {
  onClose(): void
  state: IDialogState
  onConfirm(data: ShipmentConfirmDialog): void
  isMobile: boolean
}

export enum ShipmentFormField {
  Number = 'shipto_number',
  Name = 'name',
  Default = 'default_flag',
  SalesRep = 'sales_rep',
  ShipZone = 'shipping_zone',
  TaxZone = 'tax_zone',
  ShipVia = 'ship_via',
  ShippingCharges = 'shipping_charges',
  ShippingForm = 'shipping_form',
  Site = 'preferred_selling_site',
  Active = 'active',
}

export enum ShipmentAddressFormField {
  AllowMarketing = 'allow_marketing',
  City = 'city',
  Address1 = 'address1',
  Address2 = 'address2',
  Address3 = 'address3',
  Country = 'country',
  State = 'state',
  PostalCode = 'postalcode',
}
export enum ShipmentFormLabel {
  Number = 'Ship-to #',
  Name = 'Name',
  Active = 'Active',
  Default = 'Default',
  Address1 = 'Street1',
  Address2 = 'Street2',
  Address3 = 'Street3',
  City = 'City',
  Country = 'Country',
  State = 'State',
  PostalCode = 'Postal',
  AllowMarketing = 'Allow Marketing',
  SalesRep = 'Sales Rep.',
  ShipZone = 'Ship Zone',
  TaxZone = 'Tax Zone',
  ShipVia = 'Ship Via',
  ShippingForm = 'Shipping Form',
  ShippingCharges = 'Shipping Charges',
  Site = 'Site',
}

export type XtAutocompleteOption = IXtAutocompleteOption | null

export interface IShipmentFormValues
  extends Overwrite<
      Pick<IContactAddress, ShipmentAddressFormField>,
      { [ShipmentAddressFormField.Country]: CountryOption | null; [ShipmentAddressFormField.State]: XtAutocompleteOption | string }
    >,
    Overwrite<
      Pick<IShipment, ShipmentFormField>,
      {
        [ShipmentFormField.ShipZone]: XtAutocompleteOption
        [ShipmentFormField.SalesRep]: XtAutocompleteOption
        [ShipmentFormField.TaxZone]: XtAutocompleteOption
        [ShipmentFormField.ShipVia]: XtAutocompleteOption
        [ShipmentFormField.ShippingCharges]: XtAutocompleteOption
        [ShipmentFormField.ShippingForm]: XtAutocompleteOption
        [ShipmentFormField.Site]: XtAutocompleteOption
      }
    > {}

export interface IContactState {
  initialContact: IContact | null
  contact: IContact | null
  isDirty: boolean
}
