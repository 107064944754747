import * as React from 'react'
import { FC, useCallback, useEffect, useRef, useState, memo } from 'react'
import { TextField } from '@material-ui/core'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { debounceTime, filter as filterRx } from 'rxjs/operators'
import { cls } from 'common/utils'
import {
  ISalesOrderLineItemDialog,
  ISalesOrderLineItemsParams,
  SalesOrderLineItem,
  SalesOrderLineItemAction,
} from './sales-order-line-items.types'
import { defineTableActions, isValidLineItemsState, requestAdditionalSectionData } from './sales-order-line-items.utils'
import { defaultItemDialogState, salesOrderLineItemsColumns } from './sales-order-line-items.constants'
import { TableActionHandler } from '../../../common/types/table.types'
import * as styles from './sales-order-line-items.module.scss'
import { XtQuickAddButton } from '../../../components/quickAddButton/QuickAddButton'
import { SalesOrderLineItemNew } from './sales-order-line-item-new/sales-order-line-item-new'
import { useConfirmationDialog } from '../../../common/hooks/confirmation-dialog'
import { confirmationMessages, globalConstants } from '../../../common/constants'
import { XtConfirmationDialog } from '../../../components/xtConfirmationDialog/XtConfirmationDialog'
import { SalesOrderLineItemNewForm } from './sales-order-line-item-new/sales-order-line-item-new.types'
import { SalesOrderLineItemDetails } from './sales-order-line-item-details/sales-order-line-item-details'
import { XtList } from '../../../components/list/list'
import { CheckboxLabel } from '../../../common/utils/form/form.components'
import { SalesOrderLineItemsAdditionalSection } from './sales-order-line-items-additional-section/sales-order-line-items-additional-section'
import {
  LineItemAdditionalSectionData,
  LineItemAdditionalSectionInput,
} from './sales-order-line-items-additional-section/sales-order-line-items-additional-section.types'
import { ISalesOrder, SalesOrderItem } from '../../sales-orders.types'
import { ErrorHandler } from '../../../services/ErrorService'
import { ISalesOrderLineItemDetailsForm } from './sales-order-line-item-details/sales-order-line-item-details.types'
import { useSalesOrderLineItems } from './sales-order-line-items-hook/sales-order-line-items-hook'
import { FormStateChangeCallback, IFormStateChangesNullable } from '../../../common/hooks/form/form.types'

export const SalesOrderLineItems: FC<ISalesOrderLineItemsParams> = memo(
  ({ salesOrder, viewMode, isMobile, disabled = false, hidden = false, onChange, validationObservable, customerNumber }) => {
    const additionalSectionSubject = useRef<BehaviorSubject<IFormStateChangesNullable<LineItemAdditionalSectionData>>>(
      new BehaviorSubject<IFormStateChangesNullable<LineItemAdditionalSectionData>>({
        data: null,
        state: { isValid: false, isDirty: false, touched: false },
      })
    )

    const [additionalSectionData, setAdditionalSectionData] = useState<LineItemAdditionalSectionInput | null>(null)
    const [quickAddDialogState, setQuickAddDialogOpen] = useState(defaultItemDialogState)
    const [detailsDialogState, setDetailsDialogState] = useState<ISalesOrderLineItemDialog<SalesOrderItem>>(defaultItemDialogState)
    const { itemId: itemIdToDelete, open: confirmationDialogOpen, openDialog, closeDialog } = useConfirmationDialog<string>()

    const { state, pagination, onStateChange$, cancel, save, saveOrUpdate, filterItems, remove } = useSalesOrderLineItems(
      salesOrder,
      validationObservable
    )

    const init: (salesOrder: ISalesOrder | null) => Promise<void> = async (order) => {
      if (!order) {
        return
      }
      const data = await requestAdditionalSectionData(order)
      setAdditionalSectionData(data)
    }

    useEffect(() => void init(salesOrder), [salesOrder])

    useEffect(() => {
      const sub = combineLatest([additionalSectionSubject.current.asObservable(), onStateChange$])
        .pipe(debounceTime(globalConstants.formChangeDebounce), filterRx(isValidLineItemsState))
        .subscribe(([additionalSectionState, lineItemsState]) => {
          onChange({
            data: {
              additionalSection: additionalSectionState.data,
              lineItems: lineItemsState.items,
            },
            state: {
              isValid: additionalSectionState.state.isValid && lineItemsState.isValid,
              isDirty: additionalSectionState.state.isDirty || lineItemsState.isDirty,
              touched: additionalSectionState.state.touched || lineItemsState.isDirty,
            },
          })
        })
      return () => sub.unsubscribe()
    }, [onChange, onStateChange$])

    const handleRowClick = useCallback<(item: SalesOrderLineItem) => void>(
      (item) => {
        setDetailsDialogState({ open: true, item, viewMode }) // TODO finish me
      },
      [viewMode]
    )

    const onAction = useCallback<TableActionHandler<SalesOrderLineItem, SalesOrderLineItemAction>>(
      (item, action) => {
        switch (action) {
          case SalesOrderLineItemAction.View: {
            setDetailsDialogState({ open: true, item, viewMode: true })
            break
          }
          case SalesOrderLineItemAction.Edit: {
            setDetailsDialogState({ open: true, item, viewMode: false })
            break
          }
          case SalesOrderLineItemAction.Cancel: {
            if (salesOrder) {
              void cancel(salesOrder?.order_number, item.line_number)
            }
            break
          }

          default:
            setDetailsDialogState({ item, open: true, viewMode: true })
        }
      },
      [salesOrder, cancel]
    )

    const openNewLineItemDialog = useCallback<VoidFunction>(() => setQuickAddDialogOpen((prevState) => ({ ...prevState, open: true })), [])

    const closeNewLineItemDialog = useCallback<VoidFunction>(
      () => setQuickAddDialogOpen((prevState) => ({ ...prevState, open: false })),
      []
    )

    const closeDetailsLineItemDialog = useCallback<VoidFunction>(
      () => setDetailsDialogState((prevState) => ({ ...prevState, open: false })),
      []
    )

    const onDeletionConfirm = useCallback<() => Promise<void>>(async () => {
      if (!itemIdToDelete) {
        return
      }
      await remove(itemIdToDelete)
      closeDialog()
      closeDetailsLineItemDialog()
    }, [closeDialog, itemIdToDelete, closeDetailsLineItemDialog, remove])

    const openAdvancedEntry = useCallback(() => {
      setDetailsDialogState({ open: true, item: null, viewMode: false })
      closeNewLineItemDialog()
    }, [closeNewLineItemDialog])

    const onQuickItemAddSubmit = useCallback<(values: SalesOrderLineItemNewForm, shouldReset: boolean) => Promise<void>>(
      async (formValues, shouldReset) => {
        try {
          await save(formValues)
          if (!shouldReset) {
            setQuickAddDialogOpen({ open: false, item: null, viewMode: false })
          }
        } catch (e) {
          ErrorHandler.handleError(e)
        }
      },
      [save]
    )

    const onItemAddSubmit = useCallback<(values: ISalesOrderLineItemDetailsForm, shouldReset: boolean) => Promise<void>>(
      async (formValues, shouldReset) => {
        const lineNumber = detailsDialogState.item?.line_number
        await saveOrUpdate(formValues, lineNumber)
        setDetailsDialogState((prevState) => ({ ...prevState, item: shouldReset ? null : prevState.item, open: shouldReset }))
      },
      [saveOrUpdate, detailsDialogState]
    )

    const onAdditionalSectionChange = useCallback<FormStateChangeCallback<LineItemAdditionalSectionData>>((formState) => {
      additionalSectionSubject.current.next(formState)
    }, [])

    return (
      <div hidden={hidden} className={styles.salesOrderLineItems}>
        <XtConfirmationDialog
          open={confirmationDialogOpen}
          message={confirmationMessages.deleted}
          title="Delete Line Item"
          confirmationButtonLabel="Delete"
          onConfirm={onDeletionConfirm}
          onClose={closeDialog}
        />
        <SalesOrderLineItemNew
          customerNumber={customerNumber}
          open={quickAddDialogState.open}
          onClose={closeNewLineItemDialog}
          onAdvancedEntryClick={openAdvancedEntry}
          onSubmit={onQuickItemAddSubmit}
          currency={additionalSectionSubject.current.value.data?.currency ?? ''}
        />
        {customerNumber && (
          <SalesOrderLineItemDetails
            customerNumber={customerNumber}
            currency={additionalSectionSubject.current.value.data?.currency ?? ''}
            onDeleteItem={openDialog}
            viewMode={detailsDialogState.viewMode}
            lineItem={detailsDialogState.item}
            open={detailsDialogState.open}
            onClose={closeDetailsLineItemDialog}
            onSubmit={onItemAddSubmit}
            orderNumber={salesOrder?.order_number}
          />
        )}
        <div className={styles.salesOrderLineItemsContent}>
          <CheckboxLabel label="Show Canceled Line Items" value={state.filters.showCancelledLines} onChange={filterItems} />
          <div className={styles.salesOrderLineItemsTable}>
            <XtQuickAddButton
              hidden={isMobile}
              disabled={state.loading || viewMode || disabled || !customerNumber}
              className={styles.salesOrderLineItemsNewButton}
              onClick={openNewLineItemDialog}
            />
            <div className={styles.xtTable}>
              <XtList
                onRowClick={handleRowClick}
                data={state.data}
                loading={state.loading}
                actions={defineTableActions(viewMode, salesOrder)}
                onAction={onAction}
                isMobile={isMobile}
                pagination={pagination}
                columns={salesOrderLineItemsColumns}
              />
            </div>
          </div>
          <TextField
            value={additionalSectionData?.subtotal ?? '0.00'}
            disabled
            variant="outlined"
            label="Total"
            className={cls('MuiFormField', styles.salesOrderLineItemsTotal)}
          />
          <SalesOrderLineItemsAdditionalSection
            currencyDisabled={Boolean(state.data.length)}
            validationObservable={validationObservable}
            onChange={onAdditionalSectionChange}
            data={additionalSectionData}
            isViewMode={viewMode}
          />
        </div>
      </div>
    )
  }
)
