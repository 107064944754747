import { parse } from 'date-fns'
import { globalConstants } from '../../common/constants'
import { BomItemCreateRequest, IBomItem } from '../bom.types'
import { BomItemFormField, IBomItemForm } from './BomItem.types'
import { NumberTypeUtils, StringTypeUtils } from '../../common/typeUtils'
import { IssueMethod } from '../../common/common.types'
import { IRoutingItem } from '../../routing/Routing.types'
import { RoutingService } from '../../routing/RoutingService'
import { minCounterValue } from './bomItemCounter/BomItemCounter'
import { IItem } from '../../items/Items.types'
import { IItemsSearchOption } from '../../components/itemsSearch/ItemsSearch'

export const isStringDateRepresentation: (date: string) => boolean = (date) => date === 'Always' || date === 'Never'

export const formatDate: (date: string) => string | Date = (date: string) => {
  if (!isStringDateRepresentation(date)) {
    return parse(date, globalConstants.serverDateFormat, new Date())
  }
  return date
}

export function defineFormState(data: IBomItem | null): IBomItemForm {
  // TODO need add item_type to this api
  return {
    [BomItemFormField.Item]: data?.item ?? null,
    [BomItemFormField.Effective]: data?.effective ? formatDate(data.effective) : 'Always',
    [BomItemFormField.Expires]: data?.expires ? formatDate(data.expires) : 'Never',
    [BomItemFormField.ScheduleOperation]: data?.schedule_at_wo_operation || false,
    [BomItemFormField.CreateChild]: data?.create_child_wo || false,
    [BomItemFormField.IssueChild]: true, // TODO need add value to api
    [BomItemFormField.FixedQty]: data?.qty_fxd ?? '',
    [BomItemFormField.QtyPer]: data?.qty_per ?? '',
    [BomItemFormField.IssueUom]: data?.item.inventory_uom_name ?? '',
    [BomItemFormField.IssueMethod]: data?.issue_method ?? IssueMethod.Mixed,
    [BomItemFormField.EcnNumber]: data?.ecn_number ?? '',
    [BomItemFormField.Scrap]: data?.scrap ?? '',
    [BomItemFormField.UsedAt]: data?.used_at ?? '',
    [BomItemFormField.SequenceNumber]: data?.sequence_number ?? minCounterValue,
    [BomItemFormField.Reference]: data?.reference ?? '',
    [BomItemFormField.Notes]: data?.notes ?? '',
  }
}

export function convertToBomItem(bomItem: IBomItem | null, formState: IBomItemForm): BomItemCreateRequest {
  const { effective, expires, scrap, qty_per, qty_fxd, used_at, item, ...other } = formState
  if (!item) {
    throw new Error('Bom Item form exception: no item has been selected!')
  }
  return {
    ...(bomItem ?? {}),
    effective: StringTypeUtils.parseDate(effective),
    expires: StringTypeUtils.parseDate(expires),
    scrap: NumberTypeUtils.parseString(scrap),
    qty_per: NumberTypeUtils.parseString(qty_per),
    qty_fxd: NumberTypeUtils.parseString(qty_fxd),
    used_at: used_at || null,
    item,
    bom_item_number: item?.item_number,
    bom_item_inventory_uom: item?.inventory_uom_name,
    ...other,
  }
}

export async function requestRoutingItems(itemNumber: string): Promise<IRoutingItem[]> {
  try {
    const { routing_items } = await RoutingService.get(itemNumber, false, false)
    return routing_items
  } catch (e) {
    console.error(e)
    return []
  }
}

export function convertToItemSearchOption(item: IItem | null): IItemsSearchOption | null {
  return item ? { ...item, id: item.item_number, label: item.item_number } : null
}
