/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { Table, TableBody, TableContainer, TablePagination, TablePaginationProps } from '@material-ui/core'
import { useCallback } from 'react'
import { XtTableHead } from './table-head/table-head'
import { IXtTableType } from './table.types'
import { XtTableRow } from './table-row/table-row'
import * as styles from './table.module.scss'
import { cls } from '../../common/utils'
import { calculateMinWidth } from './table.utils'

const tableContainerRootClasses = { root: styles.muiTableContainerRoot }
const tableRootClasses = { root: styles.muiTableRoot }
const tableBodyClasses = { root: styles.MuiTableBody }
const tablePaginationRootClasses = { root: styles.muiTablePaginationRoot }

// TODO check out dnd on small screen width
// for performance issues search for react beautiful dnd optimize performance
export const XtTable: IXtTableType = React.memo(
  ({ columns, rows, loading = false, onRowClick, withDnd = false, onRowPositionChanged, rowClassName, pagination, className }) => {
    if (withDnd && typeof onRowPositionChanged !== 'function') {
      throw Error('onRowPositionChanged should be provided for proper dnd feature usage.')
    }

    const onDragEnd = useCallback(
      (result: DropResult, _: ResponderProvided) => {
        if (!withDnd || typeof onRowPositionChanged !== 'function') {
          return
        }

        const { destination, source, draggableId, reason } = result

        if (!destination || reason === 'CANCEL') {
          return
        }
        const oldPosition = source.index
        const newPosition = destination.index
        if (oldPosition === newPosition && destination.droppableId === source.droppableId) {
          return
        }

        onRowPositionChanged({
          newPosition,
          oldPosition,
          rowId: draggableId,
        })
      },
      [withDnd, onRowPositionChanged]
    )

    const onChangePage = useCallback<TablePaginationProps['onChangePage']>((_, newPage) => pagination?.onChangePage(newPage), [pagination])

    const onChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = useCallback(
      ({ target: { value } }) => pagination?.onChangeRowsPerPage(parseInt(value, 10)),
      [pagination]
    )

    return (
      <div className={cls(styles.table, className)}>
        {loading && <div className="xt-overlay" />}
        <TableContainer classes={tableContainerRootClasses}>
          <Table component="div" classes={tableRootClasses} style={{ minWidth: calculateMinWidth(columns) }}>
            <XtTableHead loading={loading} columns={columns} />
            <div hidden={loading || rows.length !== 0} className={styles.noDataContainer}>
              No data available.
            </div>
            {(rows.length > 0 || loading) && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable isDropDisabled={!withDnd} droppableId="droppable">
                  {(provided) => (
                    <TableBody classes={tableBodyClasses} component="div" {...provided.droppableProps} ref={provided.innerRef}>
                      {rows.map((row, index) => (
                        <XtTableRow
                          key={row.id}
                          rowClassName={rowClassName}
                          withDnd={withDnd}
                          onClick={onRowClick}
                          item={row}
                          columns={columns}
                          index={index}
                          selected={row.selected}
                        />
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            classes={tablePaginationRootClasses}
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </div>
    )
  }
)
