import { AttachedDocumentPurpose, AttachedDocumentTargetType } from 'documents/documents.types'
import { IAttachedDocumentsState } from './documents.types'

export const makeNewDocumentState = (): IAttachedDocumentsState => ({
  editedDocument: {
    id: -new Date().getTime(),
    name: '',
    file_link: '',
    notes: '',
    target_number: '',
    target_id: -1,
    description: '',
    canview: true,
    canedit: true,
    purpose: AttachedDocumentPurpose.Sibling,
    target_type: AttachedDocumentTargetType.File,
    document_characteristics: null,
  },
  isLoading: false,
  isDialogOpen: true,
})
