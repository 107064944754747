import { combineLatest, Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'
import { deepEqual } from 'fast-equals'
import { IFormStateBase, IFormStateChanges } from './form.types'
import { globalConstants } from '../../constants'

export function convertToFormStateObservable(subject: Subject<IFormStateBase>): Observable<IFormStateBase> {
  return subject.asObservable().pipe(distinctUntilChanged((prev, current) => deepEqual(prev, current)))
}

export function convertToFormTouchedObservable(subject: Subject<boolean>): Observable<boolean> {
  return subject.asObservable().pipe(distinctUntilChanged())
}

export function buildFormStateObservable<TValues>(
  stateSubject: Subject<IFormStateBase>,
  touchedSubject: Subject<boolean>,
  valuesSubject: Subject<TValues>
): Observable<IFormStateChanges<TValues>> {
  return combineLatest([
    convertToFormStateObservable(stateSubject),
    convertToFormTouchedObservable(touchedSubject),
    valuesSubject.asObservable(),
  ]).pipe(
    debounceTime(globalConstants.formChangeDebounce),
    map(([state, touched, data]) => ({ state: { ...state, touched }, data }))
  )
}
