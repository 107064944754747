import * as React from 'react'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal, Slide } from '@material-ui/core'
import { globalConstants } from '../../common/constants'
import { cls } from '../../common/utils'
import { StringTypeUtils } from '../../common/typeUtils'
import { RoutingItemForm } from './routingItemForm/RoutingItemForm'
import { RoutingItemTabs } from './routingItemTabs/RoutingItemTabs'
import { XtButton } from '../../components/xtButton/XtButton'
import { defineRoutingItemFormData } from './RoutingItem.utils'
import { routingItemValidationSchema } from './RoutingItem.validation-schema'
import { RoutingItemFormData, IRoutingItemComponent } from './RoutingItem.types'
import { NONE_VALUE, RoutingItemControlName } from './RoutingItem.constants'
import './MuiRoutingItem.scss'
import * as styles from './RoutingItem.module.scss'
import { RoutingItemMode } from '../Routing.types'

function registerHiddenFields(register: (name: keyof RoutingItemFormData) => void, fields: (keyof RoutingItemFormData)[]): void {
  fields.forEach(register)
}
export const RoutingItem: FC<IRoutingItemComponent> = ({ close, state, itemNumber, onSubmit, loading }) => {
  const { routingItem, open, mode } = state
  const isNewMode = RoutingItemMode.New === mode
  const isEditMode = RoutingItemMode.Edit === mode || isNewMode
  const formMethods = useForm<RoutingItemFormData>({
    defaultValues: defineRoutingItemFormData(isNewMode ? null : routingItem),
    resolver: yupResolver(routingItemValidationSchema),
    mode: 'onBlur',
  })

  const {
    watch,
    formState: { isSubmitting },
    handleSubmit,
    register,
    reset,
  } = formMethods

  useEffect(() => {
    reset(defineRoutingItemFormData(routingItem))
  }, [open])

  const [standardOperation] = watch([RoutingItemControlName.StandardOperation])
  registerHiddenFields(register, [RoutingItemControlName.OperationType])
  const disabled = !isEditMode || isSubmitting || loading

  const save = (formData: RoutingItemFormData) => {
    if (!formData) {
      return
    }
    const convertFormData = {
      ...routingItem,
      ...formData,
      standard_operation: standardOperation === NONE_VALUE ? null : standardOperation,
      effective: StringTypeUtils.parseDate(formData.effective),
      expires: StringTypeUtils.parseDate(formData.expires),
      sequence_number: formData.sequence_number ?? null,
      wip_location: formData?.wip_location?.id ?? null,
      wip_location_site: formData?.wip_location?.label ?? null,
      production_uom: formData?.production_uom?.id ?? '',
    }
    onSubmit(convertFormData, mode)
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(save)
    void eventHandler(e)
  }

  return (
    <div>
      <Modal className={styles.routingItemModal} open={open}>
        <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
          <div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <FormProvider {...formMethods}>
              <form onSubmit={submitForm} className={cls(styles.routingItemDialog, 'MuiRoutingItem', 'xt-dialog-scrollable')}>
                <div>
                  <div className={styles.itemHeaderBlock}>
                    <h3 className={styles.itemNumberText} title={itemNumber}>
                      {itemNumber}
                    </h3>
                    <div className={styles.uomText}>UOM: {routingItem?.inventory_uom}</div>
                  </div>
                  <div className={styles.routingItemContent}>
                    <div className={styles.leftSideForm}>
                      <RoutingItemForm initialOperationType={routingItem?.operation_type ?? null} disabled={disabled} />
                      <div className={cls(styles.buttons, styles.field)}>
                        <XtButton onClick={close} label="Cancel" />
                        <XtButton loading={loading} label="Save" type="submit" disabled={disabled} />
                      </div>
                    </div>
                    <RoutingItemTabs disabled={disabled} />
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </Slide>
      </Modal>
    </div>
  )
}
