import * as breakpoints from '../assets/sass/xt/breakpoints.module.scss'

export interface IGlobalConstants {
  apiUrl: string
  dateFormat: string
  serverDateFormat: string
  paginationLimit: number
  inputDebounce: number
  formChangeDebounce: number
  dialogAnimationTime: number
  dialogAnimationFadeTime: number
}
export interface IConfirmationMessages {
  deleted: string
}

export const confirmationMessages: IConfirmationMessages = {
  deleted: 'The item will be deleted. Are you sure?',
}

export const globalConstants: IGlobalConstants = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  dateFormat: 'MM/dd/yyyy',
  serverDateFormat: 'yyyy-MM-dd',
  paginationLimit: 10,
  inputDebounce: 700,
  dialogAnimationTime: 700,
  dialogAnimationFadeTime: 400,
  formChangeDebounce: 300,
}

export const projectVersion: string = process.env.REACT_APP_VERSION ?? ''

export const xsMq = `(max-width:${breakpoints.xs})`
export const smMq = `(max-width:${breakpoints.sm})`
export const mdMq = `(max-width:${breakpoints.md})`
export const lgMq = `(max-width:${breakpoints.lg})`
