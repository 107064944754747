import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { ContactCreatePayload, IContact, IContactAddress, IPhone } from 'contacts/contacts.types'
import { IAttachedDocumentWithFile } from 'documents/documents.types'
import { Observable } from 'rxjs'
import { Overwrite } from 'utility-types'
import { ContactAction } from '../contact-list/contact-list.types'
import { ContactFormValues } from '../contacts.types'

export enum ContactFormLabel {
  Active = 'Active',
  FirstName = 'First Name',
  MidName = 'Mid Name',
  LastName = 'Last Name',
  Honorific = 'Honorific',
  Company = 'Company',
  JobTitle = 'Job Title',
  Email = 'Email',
  EmailOptIn = 'Opt In',
  Web = 'Web',
  Address1 = 'Address1',
  Address2 = 'Address2',
  Address3 = 'Address3',
  City = 'City',
  Country = 'Country',
  State = 'State',
  Postalcode = 'Postal',
  Latitude = 'Latitude',
  Longitude = 'Longitude',
  Accuracy = 'Accuracy',
  AllowMarketing = 'Allow Marketing',
  Suffix = 'Suffix',
}

export interface IContactDialogForm {
  selectData?: IContact | null
  mode?: ContactAction | null
  onSaveForm(formState: ContactCreatePayload, unsavedDocuments: IAttachedDocumentWithFile[], isEditMode: boolean): void
  onCancel(): void
  open: boolean
  isMobile?: boolean
}

export interface IContactFormValues
  extends Pick<
      IContact,
      | ContactFormValues.Active
      | ContactFormValues.FirstName
      | ContactFormValues.MidName
      | ContactFormValues.LastName
      | ContactFormValues.Honorific
      | ContactFormValues.Company
      | ContactFormValues.JobTitle
      | ContactFormValues.Email
      | ContactFormValues.EmailOptIn
      | ContactFormValues.Web
      | ContactFormValues.Number
      | ContactFormValues.Accounts
      | ContactFormValues.Suffix
      | ContactFormValues.Notes
      | ContactFormValues.Comments
    >,
    Pick<IContactAddress, ContactFormValues.AllowMarketing> {}

export type IContactFormState = Partial<IContactFormValues & IContactAddress & { phones: IPhone[] }>
export interface IContactFormChangeValues
  extends Overwrite<IContactFormValues, { [ContactFormValues.Honorific]: IXtAutocompleteOption | null }> {}

export type IContactDialogFormValues = { state: IContactFormState | null; isDirty: boolean; isSubmitting?: boolean; isValid?: boolean }

export interface IValidationObservable {
  validationObservable: Observable<void>
}
