import * as React from 'react'
import { FC, useCallback } from 'react'
import { X as CloseIcon } from 'react-feather'
import { IconButton, Popover } from '@material-ui/core'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from '../../../components/xtButton/XtButton'
import { BomItemQuickAddFormState, BomItemQuickAddFormField, IBomItemForm } from './BomItemQuickAdd.types'
import { IItem } from '../../../items/Items.types'
import { SvgIconIds } from '../../../components/svgIcon/SvgIcon.types'
import * as styles from './BomItemQuickAdd.module.scss'
import { validationMessage } from '../../../common/validation/validation'
import { ItemsSearch } from '../../../components/itemsSearch/ItemsSearch'
import { FormField } from '../../../common/utils/form/form.components'

const QuickAddItemFormSchema = Yup.object().shape({
  [BomItemQuickAddFormField.Item]: Yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [BomItemQuickAddFormField.Quantity]: Yup.number()
    .min(0, validationMessage.min(0))
    .required(validationMessage.isRequired)
    .typeError(validationMessage.invalidNumber),
})

export const BomItemQuickAdd: FC<IBomItemForm> = ({ onAddItem, anchorEl, onClose, open, onAdvancedSearch, itemNumber }) => {
  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<BomItemQuickAddFormState>({
    defaultValues: {
      [BomItemQuickAddFormField.Item]: null,
      [BomItemQuickAddFormField.Quantity]: 0,
    },
    resolver: yupResolver(QuickAddItemFormSchema),
    mode: 'onBlur',
  })

  const onItemChange: (item: IItem | null) => void = (item) => {
    setValue(BomItemQuickAddFormField.Item, item ?? null, { shouldValidate: true, shouldDirty: true })
  }

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onAddItem, (error) => console.error(error))
    void eventHandler(e)
  }

  const filterItems = useCallback<(item: IItem) => boolean>((item) => item?.item_number !== itemNumber, [itemNumber])

  return (
    <Popover anchorEl={anchorEl} open={open}>
      <div className={styles.popoverContainer}>
        <div className={styles.closeForm}>
          <IconButton onClick={onClose} disabled={isSubmitting}>
            <CloseIcon />
          </IconButton>
        </div>
        <form onSubmit={submitForm} className={styles.formContainer}>
          <div className={styles.formTitle}>Quick Item Add</div>
          <Controller
            control={control}
            name={BomItemQuickAddFormField.Item}
            render={() => (
              <ItemsSearch
                className={styles.formField}
                onChange={onItemChange}
                error={errors[BomItemQuickAddFormField.Item]?.message}
                itemsFilter={filterItems}
              />
            )}
          />

          <FormField
            className={styles.formField}
            control={control}
            name={BomItemQuickAddFormField.Quantity}
            label="Quantity"
            inputProps={{ type: 'number', step: 'any' }}
          />
          <XtButton
            label="Add Item"
            type="submit"
            className={styles.addButton}
            labelClass={styles.addButtonLabel}
            icon={SvgIconIds.ADD_CIRCLE}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </form>
        <div className={styles.footer}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <button className={styles.advancedSearchLink} type="button" onClick={onAdvancedSearch}>
            Advanced search
          </button>
        </div>
      </div>
    </Popover>
  )
}
