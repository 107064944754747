import React, { FC } from 'react'

import { ShipmentsList } from 'shipments/shipments-list/shipments-list'
import { ContactSection } from './contact-section/contact-section'
import { IAddressTab } from './address-tab.types'

export const AddressTab: FC<IAddressTab> = ({ onChange, customer, isMobile, disabled }) => (
  <div>
    <ContactSection disabled={disabled} isMobile={isMobile} customer={customer} onChange={onChange} />
    <ShipmentsList disabled={disabled} isMobile={isMobile} accountNumber={customer?.customer_number ?? ''} />
  </div>
)
