import { FC, useCallback, useMemo } from 'react'
import * as React from 'react'
import { IconButton, MenuItem, Modal, Slide, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { X as CloseIcon } from 'react-feather'
import { yupResolver } from '@hookform/resolvers/yup'
import { IItem } from 'items/Items.types'
import { IItemsFilters } from 'items/items-list/items-list.types'
import { globalConstants } from '../../../../common/constants'
import { XtButton } from '../../../../components/xtButton/XtButton'
import * as styles from './sales-order-line-item-new.module.scss'
import { ItemsSearch } from '../../../../components/itemsSearch/ItemsSearch'
import { ISalesOrderLineItemNewParams, LineItemFormField, SalesOrderLineItemNewForm } from './sales-order-line-item-new.types'
import { defaultFormState } from './sales-order-line-item-new.constants'
import { SvgIconIds } from '../../../../components/svgIcon/SvgIcon.types'
import { validationSchema } from './sales-order-line-item-new.validation'
import { extractSites, extractUomOptions, getDefaultSite, renderOptions } from '../sales-order-line-items.utils'
import { cls } from '../../../../common/utils'
import { FormDatePicker, FormField, FormSelectField } from '../../../../common/utils/form/form.components'

export const SalesOrderLineItemNew: FC<ISalesOrderLineItemNewParams> = React.memo(
  ({ open, onClose, onSubmit, onAdvancedEntryClick, customerNumber, currency }) => {
    const {
      control,
      formState: { isSubmitting, errors },
      handleSubmit,
      watch,
      setValue,
      reset,
    } = useForm<SalesOrderLineItemNewForm>({
      defaultValues: defaultFormState,
      reValidateMode: 'onBlur',
      resolver: yupResolver(validationSchema),
    })
    const onCancel = () => {
      onClose()
      reset(defaultFormState)
    }
    const selectedItem = watch(LineItemFormField.Item)

    const uomOptions = extractUomOptions(selectedItem)
    const sites = extractSites(selectedItem)

    const saveItem: (shouldReset?: boolean) => (values: SalesOrderLineItemNewForm) => void = (shouldReset = false) => (values) => {
      if (shouldReset) {
        onSubmit(values, shouldReset)
        reset(defaultFormState)
        return
      }
      onCancel()
      onSubmit(values, shouldReset)
    }
    const onItemChange = useCallback<(item: IItem | null) => void>(
      (item) => {
        setValue(LineItemFormField.Item, item, { shouldValidate: true, shouldDirty: true })

        setValue(LineItemFormField.SellingUom, item?.list_price_uom_name ?? '', { shouldValidate: true })

        setValue(LineItemFormField.Site, getDefaultSite(item), { shouldValidate: true })
        setValue(LineItemFormField.NetUnitPrice, item?.list_price ?? 0, { shouldValidate: true })

        setValue(LineItemFormField.ItemDescription, item?.description1 ?? '')
      },
      [setValue]
    )
    const itemSearchFilters = useMemo<IItemsFilters>(() => ({ sold: true, customer_number: customerNumber ?? '', showDetail: true }), [
      customerNumber,
    ])
    return (
      <Modal open={open}>
        <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
          <div className={cls(styles.salesOrderLineItemNew, 'xt-dialog-scrollable')}>
            <IconButton onClick={onCancel} disabled={isSubmitting} classes={{ root: styles.closeButton }}>
              <CloseIcon />
            </IconButton>

            <form className={styles.salesOrderLineItemNewForm}>
              <h3 className={styles.salesOrderLineItemNewTitle}>Quick Item Add</h3>
              <Controller
                name={LineItemFormField.Item}
                control={control}
                render={({ field: { value } }) => (
                  <ItemsSearch
                    onChange={onItemChange}
                    value={value}
                    error={errors[LineItemFormField.Item]?.message}
                    filters={itemSearchFilters}
                  />
                )}
              />
              <FormField control={control} label="Item Description" name={LineItemFormField.ItemDescription} disabled />
              <FormField name={LineItemFormField.QtyOrdered} control={control} label="Qty Ordered" />
              <FormField label="Net Unit Price" name={LineItemFormField.NetUnitPrice} control={control} />
              <FormDatePicker label="Scheduled Date" name={LineItemFormField.ScheduledDate} control={control} />
              <div className={styles.salesOrderLineItemNewFormHorizontal}>
                <FormSelectField
                  name={LineItemFormField.SellingUom}
                  control={control}
                  disabled={!selectedItem}
                  options={renderOptions(uomOptions)}
                  label="UOM"
                />
                <TextField select disabled defaultValue={currency} variant="outlined">
                  <MenuItem value={currency}>{currency}</MenuItem>
                </TextField>
                <FormSelectField
                  name={LineItemFormField.Site}
                  control={control}
                  disabled={!selectedItem}
                  options={renderOptions(sites)}
                  label="Site"
                />
              </div>
              <XtButton
                label="Save Item"
                className={styles.addButton}
                labelClass={styles.addButtonLabel}
                icon={SvgIconIds.ADD_CIRCLE}
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit(saveItem(false))}
              />
              <XtButton
                label="Save and Add New"
                className={styles.addButton}
                labelClass={styles.addButtonLabel}
                icon={SvgIconIds.ADD_CIRCLE}
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleSubmit(saveItem(true))}
              />
              <button className="xt-advanced-search-link" type="button" onClick={onAdvancedEntryClick}>
                Advanced Entry
              </button>
            </form>
          </div>
        </Slide>
      </Modal>
    )
  }
)
