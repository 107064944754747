import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { RoutingItemControlName, MIN_EXECUTION_DAY, MAX_EXECUTION_DAY, MIN_NUMBER_VALUE } from './RoutingItem.constants'

export const routingItemValidationSchema = yup.object().shape({
  [RoutingItemControlName.WorkCenter]: yup.string().required(validationMessage.isRequired),
  [RoutingItemControlName.ExecutionDay]: yup
    .number()
    .min(MIN_EXECUTION_DAY, validationMessage.min(MIN_EXECUTION_DAY))
    .max(MAX_EXECUTION_DAY, validationMessage.max(MAX_EXECUTION_DAY))
    .required(validationMessage.isRequired),
  [RoutingItemControlName.SetupTime]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)),
  [RoutingItemControlName.ReportSetupCostAs]: yup.string().required(validationMessage.isRequired),
  [RoutingItemControlName.ReportRunCostAs]: yup.string().required(validationMessage.isRequired),
  [RoutingItemControlName.StandardOperation]: yup.string().required(validationMessage.isRequired),
  [RoutingItemControlName.RunTime]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)),
  [RoutingItemControlName.Per]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)),
  [RoutingItemControlName.ProductionRatio]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)),
})
