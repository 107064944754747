import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

//import NavbarLinks from "./NavbarLinks.jsx";

import logo from "../assets/img/x.png";

const Sidebar = (props) => {
  const [width, setWidth] = useState(1024);
  const activeRoute = (routeName) => {
    return props.location.pathname === routeName ? "active" : "";
  }

  const updateWindowSize = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
     }
  }, []);

  return (
    <div id="sidebar" className="sidebar">
      <div className="logo">
        <NavLink to="/" className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="xTuple Logo" />
          </div>
        </NavLink>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          {/* {width <= 991 ? <NavbarLinks /> : null} */}
          { props.routes ? 
            <li className={activeRoute("/")}>
            <NavLink to={props.routes[0].path}
                      className="nav-link"
                      activeClassName="active"
                      title={props.routes[0].name}
              >
              <div>
                {props.routes[0].icon}
                <p>{props.routes[0].name}</p>
              </div>
            </NavLink>
            </li>
          : null
          }
          {props.routes.map((prop, key) => {
            if (prop.sidebar && !prop.redirect && prop.path !== "")
              return (
                <li
                  className={
                    prop.settings
                      ? "settings "
                      : ""
                      + activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                    title={prop.name}
                  >
                  <div>
                    {prop.icon}
                    <p>{prop.name}</p>
                  </div>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </ul>
      </div>
    </div>
  );

}

export default Sidebar;
