import { IItem } from '../Items.types'
import { IItemsTableItem } from './items-list.types'
import { FilterType, IFilter, IFilterOption } from '../../components/filter/filter.types'
import { DocumentsService } from '../../documents/documents.service'
import { DocumentType, IDocument } from '../../documents/documents.types'
import { IPaginationData } from '../../common/common.types'
import { loadItemGroups, loadItemTypes } from '../../common/utils/document.utils'

export const normalizeData = (data: IItem[]): IItemsTableItem[] =>
  data.map((item, index) => ({ ...item, id: index.toString(), active: item.active ? 'yes' : 'no' }))

function retrieveDocumentsFromResponse(request: PromiseSettledResult<IPaginationData<IDocument>> | undefined): IFilterOption[] {
  if (!request || request.status !== 'fulfilled') {
    return []
  }
  return request.value.data.map((value) => ({
    label: `${value.number} - ${value.description}`,
    value: value.number,
  }))
}

async function requestOptions(): Promise<IFilterOption[][]> {
  const options = await Promise.allSettled([
    DocumentsService.getDocuments(DocumentType.ClassCode),
    DocumentsService.getDocuments(DocumentType.FreightClass),
    DocumentsService.getDocuments(DocumentType.ProdCategory),
  ])
  return options.map(retrieveDocumentsFromResponse)
}

export const buildFilters = async (): Promise<IFilter[]> => {
  const [classCodeOptions, freightClassOptions, productCategoryOptions] = await requestOptions()

  return [
    {
      type: FilterType.Dropdown,
      label: 'Class Code',
      fieldName: 'classCode',
      options: classCodeOptions,
    },
    {
      type: FilterType.Text,
      label: 'Class Code Pattern',
      fieldName: 'classCodePattern',
    },
    {
      type: FilterType.Dropdown,
      label: 'Freight Class',
      fieldName: 'freightClass',
      options: freightClassOptions,
    },
    {
      type: FilterType.Text,
      label: 'Freight Class Pattern',
      fieldName: 'freightClassPattern',
    },
    {
      type: FilterType.Text,
      label: 'Item Number Pattern',
      fieldName: 'itemNumberPattern',
    },
    {
      type: FilterType.Text,
      label: 'Item Description',
      fieldName: 'itemDescription',
    },
    {
      type: FilterType.Autocomplete,
      label: 'Item Group',
      fieldName: 'itemGroup',
      autocompleteProps: {
        loadOptions: loadItemGroups,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: 'Item Types',
      fieldName: 'itemType',
      autocompleteProps: {
        loadOptions: loadItemTypes,
      },
    },
    {
      type: FilterType.Dropdown,
      label: 'Product Category',
      fieldName: 'productCategory',
      options: productCategoryOptions,
    },
    {
      type: FilterType.Text,
      label: 'Product Category Pattern',
      fieldName: 'productCategoryPattern',
    },
    {
      type: FilterType.Checkbox,
      label: 'Show Inactive',
      fieldName: 'showinactive',
    },
  ]
}
