import { Overwrite } from 'utility-types'
import { IItem } from '../../../../items/Items.types'
import { SalesOrderItem, SalesOrderLineItemStatus } from '../../../sales-orders.types'

export interface ISalesOrderLineItemDetailsParams {
  customerNumber: string
  currency: string
  open: boolean
  onClose(): void
  onSubmit(item: ISalesOrderLineItemDetailsForm, shouldReset: boolean): Promise<void>
  lineItem: SalesOrderItem | null
  viewMode: boolean
  onDeleteItem(itemId: string): void
  orderNumber?: string
}

export interface ISalesOrderLineItemDetailsPriceListState {
  open: boolean
}

export enum LineItemFormField {
  Item = 'item',
  QtyOrdered = 'qty_ordered',
  NetUnitPrice = 'net_unit_price',
  ScheduledDate = 'scheduled_date',
  SellingUom = 'price_uom',
  Site = 'sold_from_site',
  CustomerPN = 'customer_pn',
  Substitute = 'substitute',
  SubstituteFor = 'substitute_for',
  ListPrice = 'list_price',
  ListPriceDiscount = 'discount_from_list',
  CustomerPrice = 'customer_price',
  CustomerPriceDiscount = 'discount_from_customer',
  QtyShipped = 'qty_shipped',
  ExtendedPrice = 'extended_price',
  PromiseDate = 'promise_date',
  Warranty = 'warranty',
  Status = 'status',
}

export interface ISalesOrderLineItemDetailsOverrides {
  [LineItemFormField.ScheduledDate]: Date
  [LineItemFormField.PromiseDate]: Date | null
  [LineItemFormField.QtyOrdered]: string | number
  [LineItemFormField.QtyShipped]: string | number
  [LineItemFormField.NetUnitPrice]: string | number
  [LineItemFormField.SellingUom]: string
  [LineItemFormField.Site]: string
  [LineItemFormField.CustomerPriceDiscount]: string | number
  [LineItemFormField.ListPriceDiscount]: string | number
  [LineItemFormField.ExtendedPrice]: string | number
}

export interface ISalesOrderLineItemDetailsBase
  extends Pick<
    SalesOrderItem,
    | LineItemFormField.QtyOrdered
    | LineItemFormField.NetUnitPrice
    | LineItemFormField.ScheduledDate
    | LineItemFormField.SellingUom
    | LineItemFormField.Site
    | LineItemFormField.PromiseDate
    | LineItemFormField.CustomerPN
    | LineItemFormField.QtyShipped
    | LineItemFormField.Warranty
    | LineItemFormField.Status
    | LineItemFormField.ListPriceDiscount
    | LineItemFormField.CustomerPriceDiscount
    | LineItemFormField.CustomerPrice
    | LineItemFormField.ListPrice
    | LineItemFormField.ExtendedPrice
  > {}

export interface ISalesOrderLineItemDetailsForm extends Overwrite<ISalesOrderLineItemDetailsBase, ISalesOrderLineItemDetailsOverrides> {
  [LineItemFormField.Item]: IItem | null
  [LineItemFormField.Substitute]: IItem | null
  [LineItemFormField.SubstituteFor]: boolean
  [LineItemFormField.Status]: SalesOrderLineItemStatus
}

export interface ISalesOrderLineItemDetailsPriceSubjectParams {
  quantity: number
  itemNumber: string
  site: string
}
