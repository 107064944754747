import { useState, useCallback } from 'react'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { areEqual } from '../utils/array.utils'

export interface ICharacteristicsHook {
  deleteCharacteristic(deletedCharacteristic: IAppliedCharacteristic): Promise<void>
  updateCharacteristic(updatedCharacteristic: IAppliedCharacteristic): Promise<void>
  createCharacteristic(newCharacteristic: IAppliedCharacteristic): Promise<void>
  characteristics: IAppliedCharacteristic[]
  setCharacteristics(characteristics: IAppliedCharacteristic[]): void
  isDirty: boolean

  /**
   * Resets the characteristics state. Should be used to reset isDirty state.
   * @param notes
   * @param additionalNotes
   */
  reset(characteristics: IAppliedCharacteristic[]): void
}

interface ICharacteristicsHookState {
  isDirty: boolean
  characteristics: IAppliedCharacteristic[]
  initialCharacteristics: IAppliedCharacteristic[]
}

export function useCharacteristics(initialCharacteristics: IAppliedCharacteristic[] = []): ICharacteristicsHook {
  const [state, setState] = useState<ICharacteristicsHookState>({
    characteristics: initialCharacteristics,
    initialCharacteristics,
    isDirty: false,
  })

  const deleteCharacteristic = useCallback(async (deletedCharacteristic: IAppliedCharacteristic): Promise<void> => {
    setState((prev) => {
      const characteristics = prev.characteristics.filter((char) => char.id !== deletedCharacteristic.id)
      const isDirty = !areEqual(prev.initialCharacteristics, characteristics)
      return { ...prev, characteristics, isDirty }
    })
  }, [])

  const updateCharacteristic = useCallback(async (updatedCharacteristic: IAppliedCharacteristic): Promise<void> => {
    setState((prev) => {
      const characteristics = prev.characteristics.map((char) => (char.id === updatedCharacteristic.id ? updatedCharacteristic : char))
      const isDirty = !areEqual(prev.initialCharacteristics, characteristics)
      return {
        ...prev,
        characteristics,
        isDirty,
      }
    })
  }, [])

  const createCharacteristic = useCallback(async (newCharacteristic: IAppliedCharacteristicNew): Promise<void> => {
    setState((prev) => {
      const characteristics = [...prev.characteristics, { id: -Date.now(), ...newCharacteristic }]
      const isDirty = !areEqual(prev.initialCharacteristics, characteristics)
      return { ...prev, characteristics, isDirty }
    })
  }, [])

  const reset = useCallback((characteristics: IAppliedCharacteristic[]): void => {
    setState((prevState) => ({ ...prevState, characteristics, initialCharacteristics: characteristics, isDirty: false }))
  }, [])

  const setCharacteristics = useCallback(
    (characteristics: IAppliedCharacteristic[]): void => setState((prev) => ({ ...prev, ...characteristics })),
    []
  )

  return {
    deleteCharacteristic,
    updateCharacteristic,
    createCharacteristic,
    characteristics: state.characteristics,
    setCharacteristics,
    isDirty: state.isDirty,
    reset,
  }
}
