import React, { FC, useEffect, useState } from 'react'

import { Modal, Slide } from '@material-ui/core'

import { globalConstants } from 'common/constants'
import { SalesOrder } from 'sales-orders/sales-orders-new/sales-orders-new'
import { ISalesOrderPageParams, SalesOrderMode } from 'sales-orders/sales-orders-new/sales-orders-new.types'

import { SalesOrdersService } from 'sales-orders/sales-orders.service'
import { ErrorHandler } from 'services/ErrorService'
import { ISalesOrderExtended } from 'sales-orders/sales-orders.types'
import styles from './order-details-dialog.module.scss'

interface IOrderDetailsDialog extends ISalesOrderPageParams {
  open: boolean
  onCancel(): void
  onSubmit(): void
}
export const OrderDetailsDialog: FC<IOrderDetailsDialog> = ({ open, onCancel, mode, orderNumber, onSubmit }) => {
  const [salesOrder, setSalesOrder] = useState<ISalesOrderExtended | null>(null)
  const viewMode = mode === SalesOrderMode.View
  // TODO: can avoid the query and pass tabular data from sales-order-list.tsx

  const requestSalesOrder: (salesOrderNumber: string | null) => Promise<void> = async (number) => {
    try {
      if (!number) {
        setSalesOrder(null)
        return
      }
      const order = await SalesOrdersService.getExtended(number)
      setSalesOrder(order)
    } catch (e) {
      setSalesOrder(null)
      ErrorHandler.handleError(e)
    }
  }

  useEffect(() => {
    void requestSalesOrder(orderNumber)
  }, [orderNumber])

  return (
    <Modal open={open}>
      <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
        <div className={styles.orderDetailsContent}>
          <SalesOrder onCancel={onCancel} salesOrder={salesOrder} viewMode={viewMode} onSubmit={onSubmit} />
        </div>
      </Slide>
    </Modal>
  )
}
