import { FormCheckboxLabel, FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import React, { FC, useEffect, memo } from 'react'
import { loadHonorificOptions } from 'common/utils/document.utils'
import { ContactFormValues } from '../../contacts.types'
import { ContactFormLabel, IContactFormChangeValues } from '../contact-dialog-form.types'
import styles from './contact-form.module.scss'
import { defineContactData, convertContactData } from './contact-form.utils'
import { IConctactMainForm } from './contact-form.types'
import { ContactFormValidationSchema } from './contact-form.validation'
import { useXtForm } from '../../../common/hooks/form/form'

export const ContactForm: FC<IConctactMainForm> = memo(({ disabled, onChange, data, validationObservable }) => {
  const { control, reset, trigger, formChanges$ } = useXtForm<IContactFormChangeValues>({
    defaultValues: defineContactData(null),
    mode: 'onBlur',
    validationSchema: ContactFormValidationSchema,
  })

  useEffect(() => void reset(defineContactData(data)), [data])

  useEffect(() => {
    const validationSub = validationObservable.subscribe(() => trigger())
    const obsSub = formChanges$.subscribe(({ state, data: value }) =>
      onChange({ state: convertContactData(value), isDirty: state.isDirty, isValid: state.isValid })
    )

    return () => {
      obsSub.unsubscribe()
      validationSub.unsubscribe()
    }
  }, [validationObservable, trigger, formChanges$, onChange])

  return (
    <form className={styles.contactDetailsForm}>
      <FormCheckboxLabel control={control} disabled={disabled} name={ContactFormValues.Active} label={ContactFormLabel.Active} />
      <FormXtAutocomplete
        disabled={disabled}
        label={ContactFormLabel.Honorific}
        control={control}
        name={ContactFormValues.Honorific}
        loadOptions={loadHonorificOptions}
      />
      <FormField control={control} disabled={disabled} name={ContactFormValues.FirstName} label={ContactFormLabel.FirstName} />
      <FormField control={control} disabled={disabled} name={ContactFormValues.MidName} label={ContactFormLabel.MidName} />
      <FormField control={control} disabled={disabled} name={ContactFormValues.LastName} label={ContactFormLabel.LastName} />
      <FormField control={control} disabled={disabled} name={ContactFormValues.Suffix} label={ContactFormLabel.Suffix} />
      <FormField control={control} disabled={disabled} name={ContactFormValues.Company} label={ContactFormLabel.Company} />
      <FormField control={control} disabled={disabled} name={ContactFormValues.JobTitle} label={ContactFormLabel.JobTitle} />
      {/* TODO email validation */}
      <FormField control={control} disabled={disabled} name={ContactFormValues.Email} label={ContactFormLabel.Email} />
      <FormField control={control} disabled={disabled} name={ContactFormValues.Web} label={ContactFormLabel.Web} />
      <div className={styles.checkboxGroup}>
        <FormCheckboxLabel control={control} disabled={disabled} name={ContactFormValues.EmailOptIn} label={ContactFormLabel.EmailOptIn} />
        <FormCheckboxLabel
          control={control}
          disabled={disabled}
          name={ContactFormValues.AllowMarketing}
          label={ContactFormLabel.AllowMarketing}
        />
      </div>
    </form>
  )
})
