import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import { cls } from 'common/utils'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'

import { useTable } from 'common/hooks/useTable'
import { ItemsService } from 'items/items.service'
import { XtFilter } from 'components/filter/filter'
import { XtFilterButton } from 'components/filter/filterButton'
import { buildFilters, normalizeData } from './items-list.utils'
import { IPaginationData, IPaginationParams } from '../../common/common.types'
import { confirmationMessages, xsMq } from '../../common/constants'
import { defaultDeletionState, filterDefaultValues, itemColumns, ItemsListActionsEditMode } from './items-list.constants'

import { IDeletionState, IItemsFilters, IItemsTableItem, ItemsAction } from './items-list.types'

import * as styles from './items-list.module.scss'
import { XtList } from '../../components/list/list'
import { useFilter } from '../../common/hooks/filter'

// TODO filters
const fetchItems = async (filters: IItemsFilters, paginationParams: IPaginationParams): Promise<IPaginationData<IItemsTableItem>> => {
  const { total, data } = await ItemsService.getAll(paginationParams, filters)
  const normalizedData = normalizeData(data)
  return {
    data: normalizedData,
    total,
  }
}

export const ItemsList: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  // TODO implement modes
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaultDeletionState)
  const { state, setLoading, refresh, pagination, filter } = useTable<IItemsTableItem, IItemsFilters>({}, fetchItems)
  const { openFilters, closeFilters, onFiltersSubmit, filterOpen, filters } = useFilter(buildFilters, filter)
  const handleRowClick = useCallback<(item: IItemsTableItem) => void>(({ item_number }) => history.push(`${path}/${item_number}`), [
    path,
    history,
  ])

  const handleAction = useCallback<(item: IItemsTableItem, action: ItemsAction) => void>(
    ({ item_number }, action) => {
      switch (action) {
        case ItemsAction.Delete:
          return setDeletionState({ itemNumber: item_number, confirmationOpen: true })
        case ItemsAction.Edit:
          return null // TODO edit mode
        case ItemsAction.Print:
          return null // TODO print
        case ItemsAction.View:
          return history.push(`/products/items/${item_number}`)
        default:
          return null
      }
    },
    [history, path, state.loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.itemNumber) {
      try {
        setLoading(true)
        await ItemsService.delete(deletionState.itemNumber)
        await refresh()
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [deletionState.itemNumber, setLoading, refresh, closeConfirmationDialog])

  return (
    <div>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Item"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      {filters.length && (
        <XtFilter
          defaultValues={filterDefaultValues}
          open={filterOpen}
          onClose={closeFilters}
          onSubmit={onFiltersSubmit}
          filters={filters}
        />
      )}
      <div className={cls('xt-content', styles.listContent)}>
        <h1 className={cls('xt-page-title', 'xt-section-border', styles.listHeader)}>Items</h1>
        <div className={styles.headerContent}>
          <XtFilterButton isMobile={isMobile} loading={state.loading} onClick={openFilters} />
          <XtButton
            disabled
            hidden={isMobile}
            className={styles.newButton}
            label="Create Item"
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push(`${path}/create`)}
          />
        </div>
        <XtList
          actions={ItemsListActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data}
          columns={itemColumns}
        />
      </div>
    </div>
  )
}
