import * as Yup from 'yup'
import { minDate, validationMessage } from '../../../../../common/validation/validation'
import { AssigneeFormField } from './assignee-form-dialog.types'

export const AssigneeDialogFormSchema = Yup.object().shape({
  [AssigneeFormField.Username]: Yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [AssigneeFormField.Role]: Yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [AssigneeFormField.Assigned]: Yup.date()
    .notRequired()
    .nullable()
    .typeError(validationMessage.invalidDate)
    .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
})
