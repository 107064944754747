import { AxiosInstance } from 'axios'
import { IPaginationResponse, IPaginationData, IPaginationParams, IResponse } from '../../common/common.types'
import api from '../../common/api'
import { BomItemCreateRequest, BomItemQuickAddInput, BomItemUpdateRequest, IBomItemsFilters, IBomItem } from '../bom.types'

import { IComment } from '../../comments/comments.types'
import { CommentsService, ICommentsService } from '../../comments/comments.service'
import { DocumentType } from '../../documents/documents.types'

export interface IBomItemsService {
  getAll(
    itemNumber: string,
    { showExpired, showFuture }: IBomItemsFilters,
    { limit, page }: IPaginationParams
  ): Promise<IPaginationData<IBomItem>>
  get(itemNumber: string, sequenceNumber: number): Promise<IBomItem>
  update(itemNumber: string, data: IBomItem): Promise<void>
  create(itemNumber: string, data: IBomItem): Promise<void>
  delete(itemNumber: string, sequenceNumber: number): Promise<void>
  replace(itemNumber: string, sequenceNumber: number, data: BomItemCreateRequest): Promise<void>
  getComments(itemNumber: string, sequenceNumber: number, { limit, page }: IPaginationParams): Promise<IPaginationData<IComment>>
  quickAdd(itemNumber: string, body: BomItemQuickAddInput): Promise<void>
}

class Service implements IBomItemsService {
  constructor(private readonly apiClient: AxiosInstance, private readonly commentsService: ICommentsService) {}

  public async getAll(
    itemNumber: string,
    { showExpired, showFuture }: IBomItemsFilters,
    { limit, page }: IPaginationParams
  ): Promise<IPaginationData<IBomItem>> {
    const params: Record<string, true | number> = { limit, page }

    if (showFuture) {
      params.showFuture = true
    }
    if (showExpired) {
      params.showExpired = true
    }
    // TODO get rid off if(s)

    const response = await this.apiClient.get<IPaginationResponse<IBomItem>>(`/item/${itemNumber}/bomitem`, { params })
    const {
      data: { data, status },
    } = response
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(itemNumber: string, sequenceNumber: number): Promise<IBomItem> {
    const response = await this.apiClient.get<IResponse<IBomItem>>(`/item/${itemNumber}/bom/${sequenceNumber}`)

    if (!response.data.data || !Object.keys(response.data.data).length) {
      throw new Error(`BOM Item not found. Sequence number: ${sequenceNumber}, parent item number: ${itemNumber}`)
    }
    return response.data.data
  }

  public async create(itemNumber: string, body: BomItemCreateRequest): Promise<void> {
    await this.apiClient.post(`/item/${itemNumber}/bomitem/create`, { data: [body] })
  }

  public async update(itemNumber: string, body: BomItemUpdateRequest): Promise<void> {
    await this.apiClient.post(`/item/${itemNumber}/bomitem/update`, { data: [body] })
  }

  public async delete(itemNumber: string, sequenceNumber: number): Promise<void> {
    const body = { sequence_number: sequenceNumber }
    await this.apiClient.post(`/item/${itemNumber}/bomitem/delete`, { data: [body] })
  }

  public async expire(item_number: string, sequence_number: number): Promise<void> {
    const body = { item_number, sequence_number }
    await this.apiClient.post(`/item/bom/expire`, { data: body })
  }

  public async replace(itemNumber: string, sequenceNumber: number, data: BomItemCreateRequest): Promise<void> {
    await this.create(itemNumber, data)
    await this.expire(itemNumber, sequenceNumber)
  }

  public async getComments(
    itemNumber: string,
    sequenceNumber: number,
    paginationParams: IPaginationParams
  ): Promise<IPaginationData<IComment>> {
    const sourceNumber: string = `${itemNumber}-${sequenceNumber}`
    return this.commentsService.getForSource(DocumentType.BomItem, sourceNumber, paginationParams)
  }

  public async quickAdd(itemNumber: string, body: BomItemQuickAddInput): Promise<void> {
    await this.apiClient.post(`/item/${itemNumber}/bomitem/create`, { data: [body] })
  }
}

export const BomItemService = new Service(api, CommentsService)
