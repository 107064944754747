import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import { cls } from 'common/utils'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'

import { confirmationMessages, xsMq } from 'common/constants'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtFilterButton } from 'components/filter/filterButton'
import { useFilter } from 'common/hooks/filter'
import { IFilter } from 'components/filter/filter.types'
import { ToastService } from 'services/ToasterService'
import {
  defaultFilterValues,
  defaultCopyQuoteDialogState,
  filterFields,
  QuotesAction,
  QuotesColumns,
  QuotesListActionsEditMode,
} from './quote-list.constants'
import { fetchQuotes } from './quote-list.utils'
import * as styles from './quote-list.module.scss'
import { useTable } from '../../common/hooks/useTable'
import { XtList } from '../../components/list/list'

import { ICopyDialogState, IQuotesTableItem } from './quote-list.types'
import { QuotesService } from '../quotes.service'

import { XtFilter } from '../../components/filter/filter'
import { IQuote } from '../quotes.types'
import { CopyQuoteDialog } from './copy-quote-dialog/copy-quote-dialog'

export const QuoteList: FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { itemId: itemIdToDelete, open: confirmationDialogOpen, openDialog, closeDialog } = useConfirmationDialog<number>()
  const { state, setLoading, refresh, filter, pagination } = useTable({}, fetchQuotes)
  // TODO update filterFields type
  const { openFilters, closeFilters, onFiltersSubmit, filterOpen, filters } = useFilter(async () => filterFields as IFilter[], filter)

  const [copyDialogState, setCopyDialogState] = useState<ICopyDialogState>(defaultCopyQuoteDialogState)

  const handleRowClick = useCallback<(item: IQuotesTableItem) => void>(({ id }) => history.push(`${path}/${id}`), [path, history])

  const copyQuote = async (quote: IQuote) => {
    try {
      await QuotesService.copyQuote(quote.quote_number)
      await refresh()
      ToastService.showSuccess(`Quote ${quote.quote_number} has been copied.`)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const handleAction = useCallback<(item: IQuotesTableItem, action: QuotesAction) => void>(
    (item, action) => {
      switch (action) {
        case QuotesAction.Copy: {
          return copyQuote(item)
        }
        case QuotesAction.Delete: {
          return openDialog(Number(item.id))
        }
        case QuotesAction.CopyTo: {
          return setCopyDialogState({ open: true, quote: item })
        }
        default:
          return null
      }
    },
    [history, path]
  )

  const isMobile = useMediaQuery(xsMq)

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeDialog()
    if (itemIdToDelete) {
      try {
        setLoading(true)
        await QuotesService.delete(itemIdToDelete)
        await refresh()
        ToastService.showSuccess(`Quote ${itemIdToDelete} has been deleted.`)
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [closeDialog, itemIdToDelete])

  const onCloseCopyDialog = useCallback<VoidFunction>(() => setCopyDialogState(defaultCopyQuoteDialogState), [])
  return (
    <div>
      <CopyQuoteDialog onClose={onCloseCopyDialog} onConfirm={() => {}} open={copyDialogState.open} quoteData={copyDialogState.quote} />

      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.deleted}
        title="Delete Quote"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />
      {filters.length && (
        <XtFilter
          defaultValues={defaultFilterValues}
          open={filterOpen}
          onClose={closeFilters}
          onSubmit={onFiltersSubmit}
          filters={filters}
        />
      )}
      <div className={cls('xt-content', styles.listContent)}>
        <h1 className={cls('xt-page-title', 'xt-section-border', styles.listHeader)}>Quotes</h1>

        <div className={styles.headerContent}>
          <XtFilterButton isMobile={isMobile} label="Filters" loading={state.loading} onClick={openFilters} />
          <XtButton
            // remove "true" when the Add New Quote functionality is implemented
            hidden={isMobile || true}
            className={styles.newButton}
            label="Quote New"
            icon={SvgIconIds.ADD_CIRCLE}
            iconClass={styles.newButtonIcon}
            labelClass={styles.newButtonLabel}
            onClick={() => history.push(`${path}/new`)}
          />
        </div>
        <XtList
          actions={QuotesListActionsEditMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data}
          columns={QuotesColumns}
        />
      </div>
    </div>
  )
}
