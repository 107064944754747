import * as React from 'react'
import { FC } from 'react'
import { Control } from 'react-hook-form'
import * as styles from './BomDetailsForm.module.scss'
import { BomDetailsFormField, BomFormData } from '../../bomDetails/BomDetails.types'
import { FormDatePicker, FormField } from '../../../common/utils/form/form.components'

interface IBomDetailsFormProps {
  disabled: boolean
  control: Control<BomFormData>
}

export const BomDetailsForm: FC<IBomDetailsFormProps> = ({ disabled, control }) => (
  <div className={styles.bomDetailsFormFields}>
    <FormField control={control} name={BomDetailsFormField.Revision} label="Revision" disabled />
    <FormDatePicker control={control} name={BomDetailsFormField.RevisionDate} label="Date" disabled={disabled} />
    <FormField control={control} name={BomDetailsFormField.DocumentNumber} label="Document #" disabled={disabled} />
    <FormField control={control} name={BomDetailsFormField.BatchSize} label="Batch Size" disabled={disabled} />
  </div>
)
