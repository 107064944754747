import { AdditionalDataCreationError } from 'common/common.types'
import { DocumentType } from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'

export interface IAssignments {
  source_name: string
  source_description: string
}

export interface ICommentType {
  id: number
  name: string
  description: string
  code: string
  system: string
  editable: boolean
  order: number | null
  assignments: IAssignments[]
}

export interface ICommentTypeOption extends IXtAutocompleteOption, Partial<Omit<ICommentType, 'id'>> {}

export interface IComment {
  id: number
  created: string
  username: string
  comment: string
  comment_type: string
  source: DocumentType
  comment_source: string
  comment_source_id: string
  comment_source_number: string
  comment_public: boolean
  editable: boolean
}

export interface ICommentsFilters {
  source?: string
  commentSourceNumber?: string
}

export type CommentCreateRequest = Pick<IComment, 'comment' | 'comment_type' | 'source' | 'comment_source_number'>
export type CommentUpdateRequest = CommentCreateRequest & { id: number }

export type NewComment = Pick<IComment, 'comment' | 'comment_type'>

export class CommentsCreationError extends AdditionalDataCreationError {
  public message = 'Comments saving failed. Please try to add comments later.'
}
