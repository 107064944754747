import * as React from 'react'
import { MenuItem } from '@material-ui/core'
import { ISalesOrderLineItemsFilter, SalesOrderLineItem } from './sales-order-line-items.types'
import { IItem } from '../../../items/Items.types'
import { IXtAutocompleteOption } from '../../../components/xtAutocomplete/XtAutocomplete.types'
import { IXtTableCellAction } from '../../../components/xtTableCellActions/XtTableCellActions'
import {
  SalesOrderLineItemsEditActions,
  SalesOrderLineItemsNewActions,
  SalesOrderLineItemsViewActions,
} from './sales-order-line-items.constants'
import {
  LineItemAdditionalSectionData,
  LineItemAdditionalSectionInput,
} from './sales-order-line-items-additional-section/sales-order-line-items-additional-section.types'
import { ErrorHandler } from '../../../services/ErrorService'
import { SalesOrdersService } from '../../sales-orders.service'
import { ISalesOrder, SalesOrderItem } from '../../sales-orders.types'
import { TableRequestFn } from '../../../common/hooks/useTable'
import { ISalesOrderLineItemsHookChange } from './sales-order-line-items-hook/sales-order-line-items-hook.types'
import { IFormStateChanges, IFormStateChangesNullable } from '../../../common/hooks/form/form.types'

export function convertToLineItem(item: SalesOrderItem): SalesOrderLineItem {
  return {
    ...item,
    id: item.line_number,
    extended: item.qty_ordered * item.net_unit_price,
    available_qoh: 0,
  }
}

export function generateLineNumber(index: number): string {
  return String(index + 1).padStart(3, '0')
}

export function getDefaultSite(item: IItem | null): string {
  const site = item && Array.isArray(item?.sites) && item.sites.length ? item.sites[0] : null
  return site?.site ?? ''
}

export function extractUomOptions(item: IItem | null): IXtAutocompleteOption[] {
  if (!item) {
    return []
  }
  const uomList = (item.inventory_uom?.uom_conversions ?? []).map<[string, IXtAutocompleteOption]>(({ uom_to }) => [
    uom_to,
    { id: uom_to, label: uom_to },
  ])
  const defaultOption = { id: item.list_price_uom_name, label: item?.list_price_uom_name }
  const listMap: Map<string, IXtAutocompleteOption> = new Map([[defaultOption.id, defaultOption], ...uomList])
  return Array.from(listMap.values())
}

export function extractSites(item: IItem | null): IXtAutocompleteOption[] {
  const sites = item?.sites ?? []
  return sites.map(({ site }) => ({ id: site, label: site }))
}

export function renderOptions(options: IXtAutocompleteOption[]): React.ReactElement[] {
  return options.map(({ id, label }) => (
    <MenuItem key={id} value={id}>
      {label}
    </MenuItem>
  ))
}

export function defineTableActions(viewMode: boolean, salesOrder: ISalesOrder | null): IXtTableCellAction[] {
  if (viewMode) {
    return SalesOrderLineItemsViewActions
  }
  return salesOrder ? SalesOrderLineItemsEditActions : SalesOrderLineItemsNewActions
}

export function isValidLineItemsState(
  state: [IFormStateChangesNullable<LineItemAdditionalSectionData>, ISalesOrderLineItemsHookChange]
): state is [IFormStateChanges<LineItemAdditionalSectionData>, ISalesOrderLineItemsHookChange] {
  const [additionalSectionState] = state
  return Boolean(additionalSectionState?.data)
}

export async function requestAdditionalSectionData(salesOrder: ISalesOrder): Promise<LineItemAdditionalSectionInput | null> {
  try {
    const salesOrderDetails = await SalesOrdersService.getDetails(salesOrder.order_number, salesOrder.currency)
    return {
      ...salesOrderDetails,
      ...salesOrder,
    }
  } catch (e) {
    ErrorHandler.handleError(e)
    return null
  }
}

export const fetchLineItems: TableRequestFn<SalesOrderLineItem, ISalesOrderLineItemsFilter> = async (
  { orderNumber, showCancelledLines },
  pagination,
  currentData = []
) => {
  if (!orderNumber) {
    return {
      data: currentData,
      total: currentData.length,
    }
  }
  const { data, total } = await SalesOrdersService.getOrderItems(orderNumber, pagination, { showCancelledLines, showClosedLines: true })
  return {
    data: data.map(convertToLineItem),
    total,
  }
}
