import { IItem } from 'items/Items.types'
import { ICardData } from './item-cards/item-cards.types'
import {
  ItemDetailsFormLabel,
  ItemDetailsFormField,
  ItemSoldFormField,
  ItemSoldFormLabel,
  ItemWeightFormField,
  ItemWeightFormLabel,
} from '../../item-form.types'

export const convertItemDetailsData = (item: IItem | null): ICardData[] => [
  {
    value: item ? item[ItemDetailsFormField.ItemType] : '',
    label: ItemDetailsFormLabel.ItemType,
  },
  {
    value: item ? item[ItemDetailsFormField.ClassCode] : '',
    label: ItemDetailsFormLabel.ClassCode,
  },
  {
    value: item ? item[ItemDetailsFormField.InventoryUOM] : '',
    label: ItemDetailsFormLabel.InventoryUOM,
  },
  {
    value: item ? item[ItemDetailsFormField.PickList] : '',
    label: ItemDetailsFormLabel.PickList,
  },
  {
    value: item ? item[ItemDetailsFormField.Fractional] : '',
    label: ItemDetailsFormLabel.Fractional,
  },
  {
    value: item ? item[ItemDetailsFormField.Configured] : '',
    label: ItemDetailsFormLabel.Configured,
  },
  {
    value: item ? item[ItemDetailsFormField.MaximumDesiredCost] : '',
    label: ItemDetailsFormLabel.MaximumDesiredCost,
  },
  {
    value: item ? item[ItemDetailsFormField.FreightClass] : '',
    label: ItemDetailsFormLabel.FreightClass,
  },
  {
    value: item ? item[ItemDetailsFormField.BarCode] : '',
    label: ItemDetailsFormLabel.BarCode,
  },
]

export const convertItemSoldData = (item: IItem | null): ICardData[] => [
  {
    value: item ? item[ItemSoldFormField.ItemIsSold] : '',
    label: ItemSoldFormLabel.ItemIsSold,
  },
  {
    value: item ? item[ItemSoldFormField.ProductCategory] : '',
    label: ItemSoldFormLabel.ProductCategory,
  },
  {
    value: item ? item[ItemSoldFormField.ListPrice] : '',
    label: ItemSoldFormLabel.ListPrice,
  },
  {
    value: item ? item[ItemSoldFormField.InventoryUOM][ItemSoldFormField.SellingUOM]?.map((v) => `${v.uom_to}`)[0] : '',
    label: ItemSoldFormLabel.SellingUOM,
  },
  {
    value: item ? item[ItemSoldFormField.Warranty] : '',
    label: ItemSoldFormLabel.Warranty,
  },
  {
    value: item ? item[ItemSoldFormField.WholesalePrice] : '',
    label: ItemSoldFormLabel.WholesalePrice,
  },
  {
    value: item ? item[ItemSoldFormField.Exclusive] : '',
    label: ItemSoldFormLabel.Exclusive,
  },
]

export const convertItemWeightData = (item: IItem | null): ICardData[] => [
  {
    value: item ? item[ItemWeightFormField.Product] : '',
    label: ItemWeightFormLabel.Product,
  },
  {
    value: item ? item[ItemWeightFormField.Packaging] : '',
    label: ItemWeightFormLabel.Packaging,
  },
]
