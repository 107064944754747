import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { cls } from 'common/utils'
import { ErrorHandler } from 'services/ErrorService'
import { XtButton } from 'components/xtButton/XtButton'
import LoadingSpinner from 'components/LoadingSpinner'
import { ToastService } from 'services/ToasterService'
import { useMediaQuery } from '@material-ui/core'
import { xsMq } from 'common/constants'
import { useCharacteristics } from 'common/hooks/characteristics'
import { characteristicsToPayload } from 'characteristics/characteristic.utils'
import { useRemarks } from 'common/hooks/remarks'
import { DocumentType } from 'documents/documents.types'
import { useDocuments } from 'common/hooks/documents'
import { ICustomeDetailsMode, ICustomerDetailsState, ICustomerFormState } from './customer-details.types'
import styles from './customer-details.module.scss'
import { ICustomer } from '../customers.types'
import { convertMode, defaultCustomerState } from './customer-details.utils'
import { CustomersService } from '../customers.service'
import { CustomerDetailsForm } from './customer-details-form/customer-details-form'
import { CustomerDetailsTabs } from './customer-details-tabs/customer-details-tabs'

export const CustomerDetails: FC = () => {
  const isMobile = useMediaQuery(xsMq)
  const { id, mode } = useParams<{ id: string; mode: ICustomeDetailsMode }>()
  const history = useHistory()
  const [isViewMode, isEditMode] = convertMode(mode, !id && !mode)
  const [{ loading, customer, formState }, setState] = useState<ICustomerDetailsState>(defaultCustomerState)

  const remarksState = useRemarks(DocumentType.Customer, customer?.customer_number, customer?.notes)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.Customer, customer?.customer_number)

  const disabled = isViewMode || formState.isSubmitting || isMobile
  const isNewMode = !id && !mode
  const isDirty = formState.isDirty || remarksState.isDirty || characteristicsState.isDirty || documentsState.isDirty

  async function init(): Promise<void> {
    try {
      setState((prev) => ({ ...prev, loading: true }))

      if (isNewMode) {
        if (isMobile) {
          history.push('/crm/customers')
        }
        setState(defaultCustomerState)
      } else {
        const customerData = await CustomersService.get(id)
        setState((prev) => ({
          ...prev,
          loading: false,
          customer: customerData,
          formState: { state: { customer_number: customerData.customer_number, notes: customerData.notes } },
        }))
        characteristicsState.reset(customerData.customer_characteristics ?? [])
      }
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }))
      ErrorHandler.handleError(error)
    }
  }

  useEffect(() => void init(), [])

  const onCancel = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the page? Updates will not be applied.')) {
      return
    }

    history.push('/crm/customers')
  }

  const onSubmit = async (): Promise<void> => {
    setState((prev) => ({ ...prev, formState: { ...prev.formState, isSubmitting: true } }))
    if (!formState.isValid) {
      return
    }
    try {
      if (!customer || !isEditMode) {
        await CustomersService.create(
          {
            ...(formState.state as ICustomer),
            customer_characteristics: characteristicsToPayload(characteristicsState.characteristics),
            notes: remarksState.notes,
          },
          documentsState.getUnsavedDocuments()
        )
        history.push('/crm/customers')
        ToastService.showSuccess(`Customer ${formState.state?.customer_number} has been created.`)
      } else {
        await CustomersService.update({
          ...(formState.state as ICustomer),
          customer_characteristics: characteristicsToPayload(characteristicsState.characteristics),
          notes: remarksState.notes,
        })
        history.push('/crm/customers')
        ToastService.showSuccess(`Customer ${formState.state?.customer_number} has been updated.`)
      }
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const handleMainFormChange = useCallback<(formValues: ICustomerFormState) => void>(({ state, isDirty: isFormDirty, isValid }) => {
    setState((prev) => ({
      ...prev,
      formState: { state: { ...prev.formState.state, ...state }, isDirty: isFormDirty, isValid },
    }))
  }, [])

  const title = !isNewMode ? `Customer #: ${customer?.customer_number}` : 'Customer New'
  return (
    <div>
      {loading && <LoadingSpinner />}
      <div hidden={loading} className="xt-content xt-content-with-remarks">
        <div className={styles.customerDetails}>
          <div className={cls(styles.customerDetailsHeader, 'xt-section-border')}>
            <h3 className="xt-page-title" title={title}>
              {title}
            </h3>
            <div className={styles.customerDetailsHeaderButtons}>
              <XtButton label="Cancel" onClick={onCancel} />
              <XtButton disabled={disabled || !isDirty} loading={formState.isSubmitting} label="Save" onClick={onSubmit} />
            </div>
          </div>
          <div className={styles.customerDetailsContent}>
            <CustomerDetailsForm
              isSubmitting={formState?.isSubmitting ?? false}
              disabled={disabled}
              customer={customer}
              onChange={handleMainFormChange}
            />
            <CustomerDetailsTabs
              remarks={remarksState}
              characteristics={characteristicsState}
              documents={documentsState}
              isMobile={isMobile}
              disabled={disabled}
              customer={customer}
              onChange={handleMainFormChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
