import { SvgIconIds } from '../svgIcon/SvgIcon.types'

export enum XtButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}
export interface IXtButton {
  icon?: SvgIconIds
  iconClass?: string
  label: string
  labelClass?: string
  className?: string
  onClick?: () => void
  disabled?: boolean
  type?: 'submit' | 'button'
  variant?: XtButtonVariant
  hidden?: boolean
  loading?: boolean
}
