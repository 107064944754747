import React, { FC, useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { ToastService } from 'services/ToasterService'

import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { TableActionHandler } from 'common/types/table.types'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtConfirmationDialog } from 'components/xtConfirmationDialog/XtConfirmationDialog'
import { confirmationMessages } from 'common/constants'
import { ErrorHandler } from 'services/ErrorService'
import { ISalesOrderPageParams, SalesOrderMode } from 'sales-orders/sales-orders-new/sales-orders-new.types'
import { SalesOrdersService } from 'sales-orders/sales-orders.service'
import { XtQuickAddButton } from 'components/quickAddButton/QuickAddButton'
import { useFilter } from 'common/hooks/filter'
import { IFilterFormState } from 'components/filter/filter.types'
import { XtFilter } from 'components/filter/filter'
import { XtFilterButton } from 'components/filter/filterButton'
import * as styles from './sales-orders-list.module.scss'
import { defaultOrderDialogState, orderFilters, salesOrderActions, tableColumns } from './sales-orders-list.constants'
import { SalesOrderRow, SalesOrdersListAction } from './sales-orders-list.types'
import { defineDefaultFilterValues, requestSalesOrders } from './sales-orders-list.utils'
import { OrderDetailsDialog } from '../order-details-dialog/order-details-dialog'

export const OrdersList: FC<{
  disabled: boolean
  isMobile: boolean
  customerNumber?: string
}> = ({ disabled, isMobile, customerNumber }) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { itemId, open, openDialog, closeDialog } = useConfirmationDialog<string>()
  const defaultFilterValues = defineDefaultFilterValues(customerNumber)
  const [orderDialog, setOrderDialog] = useState<ISalesOrderPageParams & { open: boolean }>(defaultOrderDialogState)
  const { state, pagination, refresh, filter } = useTable(defaultFilterValues, requestSalesOrders)
  const { openFilters, closeFilters, onFiltersSubmit, filterOpen, filters } = useFilter(async () => orderFilters ?? [], filter)

  const handleClick = useCallback<(item: SalesOrderRow) => void>(
    (item) => {
      setOrderDialog({ orderNumber: item.order_number, mode: SalesOrderMode.Edit, open: true })
    },
    [history, path]
  )
  const copySalesOrder = async (orderNumber: string) => {
    try {
      await SalesOrdersService.copySalesOrder(orderNumber)
      ToastService.showSuccess(`Sales Order ${orderNumber} has been copied.`)
      await refresh()
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const handleAction = useCallback<TableActionHandler<SalesOrderRow, SalesOrdersListAction>>(
    (item, action) => {
      // TODO: update SalesOrderRow
      switch (action) {
        case SalesOrdersListAction.View: {
          setOrderDialog({ orderNumber: item.order_number, mode: SalesOrderMode.View, open: true })
          break
        }
        case SalesOrdersListAction.Edit: {
          setOrderDialog({ orderNumber: item.order_number, mode: SalesOrderMode.Edit, open: true })
          break
        }
        case SalesOrdersListAction.Delete: {
          openDialog(item.order_number)
          break
        }
        case SalesOrdersListAction.Copy: {
          void copySalesOrder(item.order_number)
          break
        }
        case SalesOrdersListAction.Print: {
          console.log('PRINT') // TODO update me
          break
        }
        default: {
          setOrderDialog({ orderNumber: item.order_number, mode: SalesOrderMode.View, open: true })
        }
      }
    },
    [history, path, openDialog]
  )

  const onDeletionConfirm = useCallback<() => Promise<void>>(async () => {
    try {
      if (!itemId) {
        return
      }
      await SalesOrdersService.delete(itemId)
      ToastService.showSuccess(`Sales Order ${itemId} has been deleted.`)
      await refresh()
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      closeDialog()
    }
  }, [itemId, closeDialog])
  const onFilterSubmitWithConversion = useCallback(
    async (filterState: IFilterFormState): Promise<void> => {
      onFiltersSubmit(filterState)
    },
    [onFiltersSubmit]
  )
  const filtersLoaded = Boolean(filters.length)

  const newSalesOrder = () => {
    setOrderDialog({ orderNumber: '', mode: SalesOrderMode.Edit, open: true })
  }

  const closeOrderDialog = useCallback<VoidFunction>(() => {
    setOrderDialog(defaultOrderDialogState)
  }, [])
  const onSaveOrderDetails = useCallback<VoidFunction>(async () => {
    await refresh()
  }, [])

  return (
    <div>
      <XtConfirmationDialog
        open={open}
        message={confirmationMessages.deleted}
        title="Delete Sales Order"
        confirmationButtonLabel="Delete"
        onConfirm={onDeletionConfirm}
        onClose={closeDialog}
      />
      <OrderDetailsDialog
        onSubmit={onSaveOrderDetails}
        onCancel={closeOrderDialog}
        mode={orderDialog.mode}
        orderNumber={orderDialog?.orderNumber}
        open={orderDialog.open}
      />

      {filtersLoaded && (
        <XtFilter
          open={filterOpen}
          label="Filters"
          onClose={closeFilters}
          onSubmit={onFilterSubmitWithConversion}
          filters={filters}
          defaultValues={defaultFilterValues}
        />
      )}

      <div />
      <div className={styles.filters}>
        <XtFilterButton isMobile={isMobile} loading={state.loading || !filtersLoaded} onClick={openFilters} />
      </div>
      <div hidden={isMobile} className={styles.addItemButtonContainer}>
        <XtQuickAddButton disabled={disabled} className={styles.addItemButton} onClick={newSalesOrder} />
      </div>
      <div className={styles.table}>
        <XtList
          isMobile={isMobile}
          onRowClick={handleClick}
          onAction={handleAction}
          columns={tableColumns}
          data={state.data}
          loading={state.loading}
          pagination={pagination}
          actions={!disabled ? salesOrderActions : []}
        />
      </div>
    </div>
  )
}
