import { AxiosInstance } from 'axios'
import api from '../common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../common/common.types'

export interface IStandardOperation {
  active: boolean
  instructions: string
  inventory_production_ratio: number
  number: string
  operation_description1: string
  operation_description2: string
  operation_type: string
  production_uom: string
  qty_per: number
  report_run: boolean
  report_run_as: string
  report_setup: boolean
  report_setup_as: string
  run_time: number
  setup_time: number
  tooling_reference: string
  use_standard_times: boolean
  work_center: string
}

export interface IStandardOperationService {
  getAll: (params: IPaginationParams) => Promise<IPaginationData<IStandardOperation>>
}

class Service implements IStandardOperationService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<IStandardOperation>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IStandardOperation>>('/stdoperation', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(option: string): Promise<IStandardOperation> {
    const response = await this.apiClient.get(`/stdoperation/${option}`)

    return response.data.data
  }
}

export const StandardOperationService = new Service(api)
