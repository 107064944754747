import { IXtTableCellAction } from 'components/xtTableCellActions/XtTableCellActions'
import { SvgIconIds } from 'components/svgIcon/SvgIcon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { FilterType, IFilter } from 'components/filter/filter.types'
import { loadAccountOptions, loadProspectOptions, loadUserOptions } from 'common/utils'
import { ITaskTableItem, TaskListAction } from './task-list.types'
import { dateConverter } from '../../common/utils/date.utils'

export const defaulDeletionState = {
  task: { id: null, name: '' },
  confirmationOpen: false,
}

export const TaskListActionsEditMode: IXtTableCellAction[] = [
  {
    name: TaskListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: TaskListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: TaskListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: TaskListAction.MarkAsCompleted,
    label: 'Check to Mark the Task as Completed',
    icon: SvgIconIds.MARK_AS_COMPLETED,
  },
]

export const TaskListActionsViewMode: IXtTableCellAction[] = [
  {
    name: TaskListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const taskColumns: IXtTableColumn<ITaskTableItem>[] = [
  {
    id: 'type',
    field: 'type',
    headerName: 'Type',
    flex: '1 0 200px',
    width: 200,
  },

  {
    id: 'priority',
    field: 'priority',
    headerName: 'Priority',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'name',
    field: 'name',
    headerName: 'Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'description',
    field: 'description',
    headerName: 'Description',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'assignees',
    field: 'assignees',
    headerName: 'Assigned To',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'status',
    field: 'status',
    headerName: 'Stage',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'actual_start_date',
    field: 'actual_start_date',
    headerName: 'Start Date',
    flex: '1 0 200px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'due_date',
    field: 'due_date',
    headerName: 'Due date',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'account_name',
    field: 'account_name',
    headerName: 'Account Name',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'parent',
    field: 'parent',
    headerName: 'Parent',
    flex: '1 0 200px',
    width: 200,
  },
]

export const taskFilters: IFilter[] = [
  {
    type: FilterType.Autocomplete,
    label: 'User',
    fieldName: 'user',
    autocompleteProps: {
      loadOptions: loadUserOptions,
    },
  },
  {
    type: FilterType.Autocomplete,
    label: 'Owner',
    fieldName: 'owner',
    autocompleteProps: {
      loadOptions: loadUserOptions,
    },
  },
  {
    type: FilterType.Autocomplete,
    label: 'Assigned To',
    fieldName: 'assignedto',
    autocompleteProps: {
      loadOptions: loadUserOptions,
    },
  },
  {
    type: FilterType.Dropdown,
    label: 'Task Status',
    fieldName: 'status',
    options: [
      { label: 'New', value: 'New' },
      { label: 'Pending', value: 'Pending' },
      { label: 'In-Process', value: 'In-Process' },
      { label: 'Deferred', value: 'Deferred' },
      { label: 'Completed', value: 'Completed' },
    ],
  },
  {
    type: FilterType.Autocomplete,
    label: 'Account',
    fieldName: 'account',
    autocompleteProps: {
      loadOptions: loadAccountOptions,
    },
  },
  // TODO: add "Account group" filter once we have info about where to get values
  {
    type: FilterType.Autocomplete,
    label: 'Prospect',
    fieldName: 'prospect',
    autocompleteProps: {
      loadOptions: loadProspectOptions,
    },
  },
  {
    type: FilterType.Checkbox,
    label: 'Milestone tasks only',
    fieldName: 'milestoneonly',
  },
  {
    type: FilterType.Date,
    label: 'Planned Start Date on or Before',
    fieldName: 'plannedstartbefore',
  },
  {
    type: FilterType.Date,
    label: 'Planned Start Date on or After',
    fieldName: 'plannedstartafter',
  },
  {
    type: FilterType.Date,
    label: 'Start Date on or Before',
    fieldName: 'startbefore',
  },
  {
    type: FilterType.Date,
    label: 'Start Date on or After',
    fieldName: 'startafter',
  },
  {
    type: FilterType.Date,
    label: 'Due Date on or Before',
    fieldName: 'duebefore',
  },
  {
    type: FilterType.Date,
    label: 'Due Date on or After',
    fieldName: 'dueafter',
  },
  {
    type: FilterType.Checkbox,
    label: 'Show Completed Only',
    fieldName: 'showcompleted',
  },
]

export const taskFilterDefaultValues = {
  user: '',
  owner: '',
  assignedto: '',
  status: '',
  account: '',
  plannedstartbefore: null,
  plannedstartafter: null,
  startbefore: null,
  startafter: null,
  duebefore: null,
  dueafter: null,
  completedonly: null,
}
