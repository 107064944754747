import { IXtAutocompleteOption } from '../../../../components/xtAutocomplete/XtAutocomplete.types'
import { ISalesOrder, ISalesOrderDetails, ISalesOrderValidation } from '../../../sales-orders.types'
import { ICurrency } from '../../../../services/dictionary.service'
import { IFormStateChanges } from '../../../../common/hooks/form/form.types'

export interface ILineItemsAdditionSectionParams extends ISalesOrderValidation {
  currencyDisabled: boolean
  data: LineItemAdditionalSectionInput | null
  isViewMode: boolean
  onChange(change: IFormStateChanges<LineItemAdditionalSectionData>): void
}

export enum LineItemAdditionalSectionField {
  RequireSufficientInventory = 'required_sufficient_inventory',
  Currency = 'currency',
  MiscChargeDescription = 'misc_charge_description',
  MiscChargeSalesAccount = 'misc_charge_account_number',
  MiscChargeDiscount = 'misc_charge_is_discount',
  FreightWeight = 'freight_weight',
  FreightTaxType = 'freight_tax_type',
  AllocatedCreditMemoOrInvoice = 'allocated_credit_memo_or_invoice',
  OutstandingCredit = 'outstanding_credit',
  AuthorizedCCPayments = 'authorized_cc_payments',
  AtShipping = 'at_shipping',
  Subtotal = 'subtotal',
  MiscCharge = 'misc_charge_amount',
  Freight = 'freight',
  Tax = 'tax_total',
  Total = 'order_total',
  Balance = 'balance',
  Margin = 'ordermargin',
  MarginPercent = 'ordermarginpercent',
  ChargeTaxType = 'misc_charge_tax_type',
}

export type LineItemAdditionalSectionData = Pick<
  ISalesOrder,
  | 'currency_symbol'
  | LineItemAdditionalSectionField.Currency
  | LineItemAdditionalSectionField.Tax
  | LineItemAdditionalSectionField.MiscCharge
  | LineItemAdditionalSectionField.Freight
  | LineItemAdditionalSectionField.MiscChargeDescription
  | LineItemAdditionalSectionField.MiscChargeSalesAccount
  | LineItemAdditionalSectionField.MiscChargeDiscount
  | LineItemAdditionalSectionField.Margin
  | LineItemAdditionalSectionField.MarginPercent
  | LineItemAdditionalSectionField.FreightTaxType
  | LineItemAdditionalSectionField.ChargeTaxType
>

export type LineItemAdditionalSectionInput = LineItemAdditionalSectionData & ISalesOrderDetails

export type CurrencyOption = ICurrency & IXtAutocompleteOption

export interface ILineItemAdditionalSectionForm {
  [LineItemAdditionalSectionField.RequireSufficientInventory]: boolean
  [LineItemAdditionalSectionField.Currency]: CurrencyOption | null
  [LineItemAdditionalSectionField.MiscChargeDescription]: string
  [LineItemAdditionalSectionField.MiscChargeSalesAccount]: IXtAutocompleteOption | null
  [LineItemAdditionalSectionField.ChargeTaxType]: IXtAutocompleteOption | null
  [LineItemAdditionalSectionField.MiscChargeDiscount]: boolean
  [LineItemAdditionalSectionField.FreightWeight]: number
  [LineItemAdditionalSectionField.FreightTaxType]: IXtAutocompleteOption | null
  [LineItemAdditionalSectionField.AllocatedCreditMemoOrInvoice]: number
  [LineItemAdditionalSectionField.OutstandingCredit]: number
  [LineItemAdditionalSectionField.AuthorizedCCPayments]: number
  [LineItemAdditionalSectionField.AtShipping]: number
  [LineItemAdditionalSectionField.Subtotal]: number
  [LineItemAdditionalSectionField.MiscCharge]: number
  [LineItemAdditionalSectionField.Freight]: number
  [LineItemAdditionalSectionField.Tax]: number
  [LineItemAdditionalSectionField.Total]: number
  [LineItemAdditionalSectionField.Balance]: number
  [LineItemAdditionalSectionField.Margin]: number
  [LineItemAdditionalSectionField.MarginPercent]: string
}
