import { IAssignee, ITask } from 'tasks/tasks.types'
import { ITaskTableItem } from './task-list.types'

export const convertAssigneesValue = (items: IAssignee[] | null) => {
  if (!items) {
    return ''
  }
  return items.map(({ username }) => username).join(', ')
}
export const normalizeData = (data: ITask[]): ITaskTableItem[] =>
  data.map((item) => ({ ...item, assignedto: convertAssigneesValue(item.assignees), customer: '' }))
