import { Assign } from 'utility-types'
import { ISalesOrder, ISalesOrderItemsFilters, ISalesOrderValidation, SalesOrderItem } from '../../sales-orders.types'
import { LineItemAdditionalSectionData } from './sales-order-line-items-additional-section/sales-order-line-items-additional-section.types'
import { ITableRow } from '../../../components/table/table.types'
import { IFormStateChanges } from '../../../common/hooks/form/form.types'

export interface ISalesOrderLineItemsParams extends ISalesOrderValidation {
  customerNumber: string | null | undefined
  disabled?: boolean
  viewMode: boolean
  salesOrder: ISalesOrder | null
  isMobile: boolean
  hidden?: boolean
  onChange(change: IFormStateChanges<ISalesOrderLineItemsData>): void
}

export interface ISalesOrderLineItemsData {
  lineItems: SalesOrderItem[]
  additionalSection: LineItemAdditionalSectionData
}

export interface ISalesOrderLineItemDialog<T> {
  open: boolean
  item: T | null
  viewMode: boolean
}

export enum SalesOrderLineItemAction {
  Edit = 'edit',
  View = 'view',
  Cancel = 'cancel',
}

// TODO complete me
export type SalesOrderLineItem = Assign<SalesOrderItem, ITableRow & { available_qoh: number; extended: number }>

export interface ISalesOrderLineItemsFilter extends ISalesOrderItemsFilters {
  orderNumber: string | null
}
