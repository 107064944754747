import { AxiosInstance } from 'axios'
import api from 'common/api'
import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IQuote, IQuoteFilters } from './quotes.types'

export interface IQuotesService {
  getAll: (pagination: IPaginationParams, filters: IQuoteFilters) => Promise<IPaginationData<IQuote>>
  delete(quoteNnumber: number): Promise<void>
  create(data: IQuote): Promise<void>
  update(data: IQuote): Promise<void>
  copyQuote(quoteNumber: string): Promise<void>
  convertQuoteToSalesOrder(quoteNumber: string): Promise<void>
}

class Service implements IQuotesService {
  constructor(private readonly apiClient: AxiosInstance) {}

  public async getAll(pagination: IPaginationParams, filters: IQuoteFilters): Promise<IPaginationData<IQuote>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IQuote>>('/quote', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async delete(quoteNnumber: number): Promise<void> {
    const body = { data: { quote_number: quoteNnumber } }
    await this.apiClient.post('/quote/delete', body)
  }

  public async create(data: IQuote): Promise<void> {
    const body = { data }
    await this.apiClient.post('/quote/create', body)
  }

  public async update(data: IQuote): Promise<void> {
    const body = { data }
    await this.apiClient.post('/quote/update', body)
  }

  public async copyQuote(quoteNumber: string): Promise<void> {
    const data = { quote_number: quoteNumber }
    await this.apiClient.post('/quote/copy', { data })
  }

  public async convertQuoteToSalesOrder(quoteNumber: string): Promise<void> {
    const data = { quote_number: quoteNumber, convert_to: 'order' }
    await this.apiClient.post('/quote/convert', { data })
  }
}

export const QuotesService = new Service(api)
