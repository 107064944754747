import { IContact } from 'contacts/contacts.types'
import { IContactFormChangeValues, IContactFormValues } from '../contact-dialog-form.types'
import { ContactFormValues } from '../../contacts.types'

export const defineContactData = (data: IContact | null): IContactFormChangeValues => ({
  [ContactFormValues.Number]: data?.contact_number ?? '',
  [ContactFormValues.Active]: data?.active ?? false,
  [ContactFormValues.Honorific]: data?.honorific ? { id: data.honorific, label: data.honorific } : null,
  [ContactFormValues.FirstName]: data?.first_name ?? '',
  [ContactFormValues.MidName]: data?.middle_name ?? '',
  [ContactFormValues.LastName]: data?.last_name ?? '',
  [ContactFormValues.Suffix]: data?.suffix ?? '',
  [ContactFormValues.Company]: data?.company ?? '',
  [ContactFormValues.JobTitle]: data?.job_title ?? '',
  [ContactFormValues.Email]: data?.email ?? '',
  [ContactFormValues.Web]: data?.web ?? '',
  [ContactFormValues.EmailOptIn]: data?.email_opt_in ?? false,
  [ContactFormValues.Accounts]: data?.accounts ?? [],
  [ContactFormValues.AllowMarketing]: data?.contact_address?.allow_marketing ?? false,
})
export function convertContactData(data: IContactFormChangeValues): IContactFormValues {
  return { ...data, honorific: data?.honorific?.id ?? '' }
}
