import { Overwrite } from 'utility-types'
import { Observable } from 'rxjs'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from '../characteristics/characteristics.types'
import { ICustomer } from '../customers/customers.types'
import { IContact } from '../contacts/contacts.types'

export enum HoldTypeField {
  None = 'None',
  Credit = 'Credit',
  Shipping = 'Shipping',
  Packing = 'Packing',
  Return = 'Return',
  Tax = 'Tax',
}

export interface ISalesOrder {
  order_status: string
  order_number: string
  external_number: string
  site: string | null
  order_date: string | null
  scheduled_date: string | null
  pack_date: string | null
  sale_type: string
  sales_rep: string
  tax_zone: string
  terms: string
  terms_description: string
  customer_number: string
  customer_name: string
  bill_to: ISalesOrderBill
  ship_to: ISalesOrderShipment
  shipping_zone: string
  customer_po_number: string
  fob: string
  ship_via: string
  hold_type: HoldTypeField
  shipping_form: string
  shipping_charges: string
  ship_complete: boolean
  currency: string
  currency_symbol: string
  order_total: number
  sales_total: number
  tax_total: number
  misc_charge_description: string
  misc_charge_account_number: string | null
  misc_charge_amount: number
  misc_charge_is_discount: boolean
  misc_charge_tax_type: string
  freight: number
  freight_tax_type: string
  order_notes: string
  shipping_notes: string
  order_items: ISalesOrderItem[]
  salesorder_characteristics: IAppliedCharacteristic[] | null
  created: string
  lastupdated: string
  ordermargin: number
  ordermarginpercent: number
}

export interface ISalesOrderExtended extends ISalesOrder {
  customer: ICustomer
}

export interface ISalesOrderAddress {
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  postal_code: string
  country: string
}

export interface ISalesOrderBill {
  billto_name: string
  billto_contact: IContact | null
  billto_address: ISalesOrderAddress
}

export interface ISalesOrderShipment {
  shipto_number: string | null
  shipto_name: string
  shipto_contact: IContact | null
  shipto_address: ISalesOrderAddress
}

export enum SalesOrderLineItemStatus {
  Open = 'O',
  Close = 'C',
}

export interface ISalesOrderItem {
  order_number: string
  line_number: string
  item_number: string
  item_description: string
  customer_pn?: string
  sold_from_site: string
  status?: SalesOrderLineItemStatus
  qty_ordered: number
  qty_at_shipping?: number
  qty_returned?: number
  qty_shipped?: number
  qty_uom?: string
  qty_uom_description?: string
  sub_items: ISalesOrderSubItem[]
  net_unit_price: number
  price_uom: string
  price_uom_description?: string
  scheduled_date: string | null
  promise_date?: string | null
  warranty: boolean
  tax_type?: string
  notes?: string
  substitute_for?: string | null
  discount_from_list?: number
  discount_from_customer?: number
  list_price?: number
  customer_price?: number
  extended_price?: number
}

export interface ISalesOrderSubItem {
  line_number: number
  item_number: string
  item_description: string
  customer_pn: string
  sold_from_site: string
  status: string
  qty_ordered: number
  qty_at_shipping: number
  qty_returned: number
  qty_shipped: number
  qty_uom: string
  qty_uom_description: string
  warranty: string
  tax_type: string
}

export interface ISalesOrderDetails {
  freight_weight: number
  allocated_memo: number
  outstanding_memo: number
  authorized_cc: number
  at_shipping: number
  subtotal: number
  total_cost: number
}

export type SalesOrderItem = Omit<ISalesOrderItem, 'order_number'>

// TODO support currency_symbol
export type SalesOrderCreateInput = Overwrite<
  Omit<
    ISalesOrder,
    | 'order_number'
    | 'misc_charge_tax_type'
    | 'terms_description'
    | 'lastupdated'
    | 'site'
    | 'created'
    | 'scheduled_date'
    | 'currency_symbol'
    | 'external_number'
    | 'order_total'
    | 'sales_total'
  >,
  { order_items: SalesOrderItem[]; order_status?: string; salesorder_characteristics: IAppliedCharacteristicNew[] }
>

export type SalesOrderUpdateInput = Overwrite<ISalesOrder, { salesorder_characteristics: IAppliedCharacteristicNew[] }>

export interface ISalesOrderItemsFilters {
  showCancelledLines: boolean
  showClosedLines?: boolean
}

export interface ISalesOrderLineItemCreatePayload {
  item_number: string
  customer_pn: string | null
  sold_from_site: string
  qty_ordered: number
  scheduled_date: string | null
  promise_date: string | null
  qty_uom: string | null
  est_unit_cost: number
  net_unit_price: number
  price_uom: string
  notes: string
  warranty: boolean
  tax_type: string | null
  create_order: boolean
  substitute_for: string | null
  status?: SalesOrderLineItemStatus
}

export interface ISalesOrderLineItemEditPayload extends ISalesOrderLineItemCreatePayload {
  line_number: string
}

export interface ISalesOrderValidation {
  validationObservable: Observable<void>
}

export interface ISalesOrderCreateResponse {
  order_number: string
}

export enum FilterFieldName {
  CustomerNumber = 'customer_number',
  StartDate = 'startDate',
  EndDate = 'endDate',
  ShowClosed = 'closed',
  Site = 'site',
  CustomerType = 'customer_type',
  CustomerPattern = 'customer_type_pattern',
  PONumber = 'customer_po',
  SalesRep = 'sales_rep',
  SalesType = 'sale_type',
  Project = 'project',
  CreatedBy = 'created_by',
  HoldType = 'hold_type',
}

export interface ISalesOrderFilters {
  [FilterFieldName.StartDate]?: Date
  [FilterFieldName.EndDate]?: Date
  [FilterFieldName.ShowClosed]?: boolean
  [FilterFieldName.Site]?: string
  [FilterFieldName.CustomerNumber]?: string
  [FilterFieldName.CustomerType]?: string
  [FilterFieldName.CustomerPattern]?: string
  [FilterFieldName.PONumber]?: string
  [FilterFieldName.SalesRep]?: string
  [FilterFieldName.SalesType]?: string
  [FilterFieldName.Project]?: string
  [FilterFieldName.CreatedBy]?: string
  [FilterFieldName.HoldType]?: string
}
