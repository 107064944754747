import { IOrderAvailabilityItem } from '../../../../../items/Items.types'
import { IOrderAvailabilityFilters, ISalesOrderLineItemSupplyParams, ISupplyTableValue } from './sales-order-line-item-supply.types'
import { IPaginationData } from '../../../../../common/common.types'
import { ItemsService } from '../../../../../items/items.service'
import { prepareRequestParams } from '../../../../../common/utils/request.utils'

const convertIndexToId = (index: number): string => String(index).padStart(3, '0')

export const convertNumber = (value: unknown): string => Number(value).toFixed(2)

const convertFilters = (qty: number, order_number?: string, line_number?: string): IOrderAvailabilityFilters => {
  if (!line_number || !order_number) {
    return prepareRequestParams(undefined, { qty })
  }
  return prepareRequestParams(undefined, { qty, order_number, line_number })
}

export function convertToTableItems(items: IOrderAvailabilityItem): ISupplyTableValue[] {
  return (items.bom_availability ?? []).map((item, index) => ({
    id: convertIndexToId(index + 1),
    item_number: item.item_number,
    item_description: item.item_description,
    uom: item.uom,
    total_allocations: item.total_allocations,
    on_order: item.on_order,
    available_qoh: item.available_qoh,
    total_availability: item.total_availability,
  }))
}

// TODO research the request as the current implementation returns incorrect data. We may need to apply additional filters or change the current ones
export async function fetchItems({
  site: siteFilter,
  quantity: quantityFilter,
  orderNumber: orderNumberFilter,
  lineNumber: lineNumberFilter,
  itemNumber: itemNumberFilter,
}: ISalesOrderLineItemSupplyParams): Promise<IPaginationData<ISupplyTableValue>> {
  if (!itemNumberFilter || !siteFilter || !quantityFilter) {
    return { data: [], total: 0 }
  }

  const { status, data } = await ItemsService.getOrderAvailability(
    itemNumberFilter,
    siteFilter,
    convertFilters(quantityFilter, orderNumberFilter, lineNumberFilter)
  )
  return {
    data: convertToTableItems(data),
    total: status.totalrowcount,
  }
}
