import * as React from 'react'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { cls } from 'common/utils'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal, Slide } from '@material-ui/core'
import { ErrorHandler } from 'services/ErrorService'
import styles from './BomItem.module.scss'
import { BomItemFormField, BomItemMode, IBomItemForm, IBomItemParams, IBomItemState } from './BomItem.types'
import { BomItemTabs } from './bomItemTabs/BomItemTabs'
import { defaultBomItemState } from './BomItem.constants'
import { BomItemFormSchema } from './BomItem.validation'
import { IItem } from '../../items/Items.types'
import { BomItemCounter } from './bomItemCounter/BomItemCounter'
import { XtButton } from '../../components/xtButton/XtButton'
import { DocumentType } from '../../documents/documents.types'
import { convertToBomItem, convertToItemSearchOption, defineFormState, requestRoutingItems } from './BomItem.utils'
import { BomItemForm } from './bomItemForm/BomItemForm'
import { IBomItem } from '../bom.types'
import { BomItemService } from '../services/BomItemService'
import { globalConstants } from '../../common/constants'
import { XtItemNumber } from '../../components/item-number/item-number'
import { useComments } from '../../common/hooks/comments'

function registerHiddenFields(register: (name: keyof IBomItemForm) => void, fields: (keyof IBomItemForm)[]): void {
  fields.forEach(register)
}

export const BomItem: FC<IBomItemParams> = memo(({ parentItemNumber, open, bomItem, mode, onSubmit, onClose }) => {
  const [state, setState] = useState<IBomItemState>(defaultBomItemState)

  const commentsState = useComments(DocumentType.BomItem, bomItem?.bom_item_number, mode !== BomItemMode.View)

  const formMethods = useForm<IBomItemForm>({
    defaultValues: defineFormState(bomItem),
    resolver: yupResolver(BomItemFormSchema),
    mode: 'onBlur',
  })
  const {
    formState: { errors, isSubmitting, isDirty },
    control,
    handleSubmit,
    reset,
    setValue: setFormValue,
    watch,
    register,
  } = formMethods

  registerHiddenFields(register, [BomItemFormField.Reference, BomItemFormField.Notes, BomItemFormField.Item])

  const [reference, notes, itemDetailsFormValue] = watch([BomItemFormField.Reference, BomItemFormField.Notes, BomItemFormField.Item])
  const itemDetails = useMemo(() => convertToItemSearchOption(itemDetailsFormValue), [itemDetailsFormValue])

  const tabValue = useMemo(() => ({ notes, reference }), [notes, reference])

  const initRoutingItems: (itemNumber: string | null) => Promise<void> = async (itemNumber) => {
    if (!itemNumber) {
      return
    }
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      const routingItems = await requestRoutingItems(itemNumber)
      setState((prevState) => ({ ...prevState, loading: false, routingItems }))
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false }))
      ErrorHandler.handleError(error)
    }
  }

  const initBomItem: (itemNumber: string, bomItemData: IBomItem | null, reset: (values: IBomItemForm) => void) => Promise<void> = async (
    itemNumber,
    bomItemData,
    formReset
  ) => {
    if (!bomItemData) {
      formReset(defineFormState(null))
      return
    }
    try {
      formReset(defineFormState(bomItemData))
      setState((prevState) => ({ ...prevState, loading: true }))
      // TODO use lazy loading to request chunks of data
      const { data: comments } = await BomItemService.getComments(itemNumber, bomItemData.sequence_number, { limit: 100, page: 0 })
      setState((prevState) => ({
        ...prevState,
        comments,
        loading: false,
      }))
      reset(defineFormState(bomItem))
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false }))
      ErrorHandler.handleError(error)
    }
  }

  useEffect(() => void initRoutingItems(parentItemNumber), [parentItemNumber])
  useEffect(() => void initBomItem(parentItemNumber, bomItem, reset), [parentItemNumber, bomItem, reset])

  const resetState: () => void = () => {
    reset(defineFormState(null))
    setState(defaultBomItemState)
  }

  const save: (data: IBomItemForm) => Promise<void> = async (formData) => {
    try {
      // TODO include created comments in New Mode
      const data = convertToBomItem(bomItem, formData)
      await onSubmit(parentItemNumber, bomItem, data, mode)
      resetState()
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const onCancel = () => {
    const dialog = 'Are you sure you want to leave the page? Updates will not be applied.'
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm(dialog)) {
      return
    }
    onClose()
    resetState()
  }

  const filterItems = useCallback<(item: IItem) => boolean>((item) => item?.item_number !== parentItemNumber, [parentItemNumber])

  const onItemChange = useCallback<(item: IItem | null) => Promise<void>>(
    async (item) => {
      setFormValue(BomItemFormField.Item, item)
      setFormValue(BomItemFormField.IssueUom, item?.inventory_uom_name ?? '', { shouldDirty: true, shouldValidate: true })
    },
    [setFormValue]
  )

  const onTabsValueChange = useCallback<(field: keyof IBomItemForm, value: string) => void>(
    (field, value) => {
      setFormValue(field, value, { shouldDirty: true })
    },
    [setFormValue]
  )

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(save)
    void eventHandler(e)
  }

  return (
    <Modal open={open} className={styles.bomItemDialog}>
      <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
        <div>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...formMethods}>
            <form onSubmit={submitForm} className={cls('xt-content', 'muiBomItem', 'xt-dialog-scrollable')}>
              <div className={styles.bomItemContent}>
                <div className={cls(styles.bomItemFormHeader, 'xt-section-border')}>
                  <div className={styles.bomItemHeaderControls}>
                    <Controller
                      control={control}
                      name={BomItemFormField.SequenceNumber}
                      render={({ field: { onChange, value } }) => (
                        <BomItemCounter
                          hidden={mode === BomItemMode.Search || mode === BomItemMode.Replace}
                          value={value}
                          onChange={(sequenceNumber) => onChange(sequenceNumber)}
                          disabled={isSubmitting || mode === BomItemMode.View}
                        />
                      )}
                    />
                    <XtItemNumber
                      itemNumber={itemDetails?.item_number}
                      uom={itemDetails?.inventory_uom_name}
                      description={itemDetails?.description1}
                      isEditMode={mode === BomItemMode.Replace || mode === BomItemMode.Search}
                      editModeConfig={{
                        value: itemDetails,
                        itemsFilter: filterItems,
                        onChange: onItemChange,
                        error: errors[BomItemFormField.Item]?.message,
                        disabled: isSubmitting,
                      }}
                    />
                  </div>
                  <div className={styles.bomItemHeaderButtonsSection}>
                    <XtButton label="Cancel" onClick={onCancel} />
                    <XtButton
                      hidden={mode === BomItemMode.View}
                      loading={isSubmitting}
                      label="Save"
                      type="submit"
                      disabled={isSubmitting || !isDirty}
                    />
                  </div>
                </div>
                <BomItemForm itemDetails={itemDetails} routingItems={state.routingItems} className={styles.bomItemForm} mode={mode} />
                <BomItemTabs
                  commentsState={commentsState}
                  className={styles.bomItemTabs}
                  disabled={isSubmitting || mode === BomItemMode.View || state.loading}
                  value={tabValue}
                  onChange={onTabsValueChange}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </Slide>
    </Modal>
  )
})
