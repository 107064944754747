import * as React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import * as Icon from 'react-feather'
import { FC, memo, useEffect } from 'react'
import { cls } from '../../../../../common/utils'
import { ISalesOrderLineItemSupplyParams, ISupplyTableValue } from './sales-order-line-item-supply.types'
import { XtTable } from '../../../../../components/table/table'
import { useTable } from '../../../../../common/hooks/useTable'
import { fetchItems } from './sales-order-line-item-supply.utils'
import { salesOrderLineItemSupplyColumns } from './sales-order-line-item-supply.constants'
import * as styles from './sales-order-line-item-supply.module.scss'

export const SalesOrderLineItemSupply: FC<ISalesOrderLineItemSupplyParams> = memo(
  ({ site, quantity, orderNumber, lineNumber, itemNumber }) => {
    const { state, filter } = useTable<ISupplyTableValue, ISalesOrderLineItemSupplyParams>(
      { itemNumber, quantity, site, lineNumber, orderNumber },
      fetchItems
    )

    useEffect(() => {
      void filter({ itemNumber, quantity, site, lineNumber, orderNumber })
    }, [itemNumber, quantity, site, lineNumber, orderNumber])

    return (
      <Accordion component="section" className={cls()}>
        <AccordionSummary expandIcon={<Icon.ChevronDown />} aria-controls="panel1a-content">
          <Typography>Supply</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.salesOrderLineItemSupplyTable}>
            <XtTable columns={salesOrderLineItemSupplyColumns} rows={state.data} loading={state.loading} />
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }
)
