import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { IXtAutocompleteOption } from 'components/xtAutocomplete/XtAutocomplete.types'
import { ICRMProspect } from 'crm-prospects/crm-prospects.types'
import { Overwrite } from 'utility-types'

export enum CRMProspectDetailsMode {
  View = 'view',
  Edit = 'edit',
}

export interface ICRMProspectDetailsState {
  loading: boolean
  prospect: ICRMProspect | null
  isAdditionallyDirty: boolean // TODO remove this field later
  characteristics: IAppliedCharacteristic[]
}

export enum CRMProspectFormField {
  Number = 'number',
  Name = 'name',
  Active = 'active',
  SalesRep = 'sales_rep',
  Source = 'source',
  Site = 'site',
  TaxZone = 'tax_zone',
  Currency = 'currency',
  LastTouched = 'last_touched',
  Owner = 'owner',
  AssignedDate = 'assigned_date',
  AssignedUser = 'assigned_user',
  ProspectCharacteristics = 'prospect_characteristics',
  Created = 'created',
  LastUpdated = 'lastupdated',
}

export enum CRMProspectFormLabel {
  Number = 'Prospect #',
  Name = 'Prospect Name',
  Active = 'Active',
  SalesRep = 'Sales Rep.',
  Source = 'Source',
  Site = 'Site',
  TaxZone = 'Tax Zone',
  Currency = 'Currency',
  LastTouched = 'Last Touched',
  Owner = 'Owner',
  AssignedDate = 'Assigned',
  AssignedUser = 'Assigned To',
  ProspectCharacteristics = 'Characteristics',
  Notes = 'Notes',
  Created = 'Created',
  LastUpdated = 'Last Updated',
}

export interface ICRMProspectForm
  extends Overwrite<
    Pick<
      ICRMProspect,
      | CRMProspectFormField.Number
      | CRMProspectFormField.Name
      | CRMProspectFormField.Owner
      | CRMProspectFormField.Active
      | CRMProspectFormField.AssignedDate
      | CRMProspectFormField.AssignedUser
      | CRMProspectFormField.LastTouched
      | CRMProspectFormField.Site
      | CRMProspectFormField.SalesRep
      | CRMProspectFormField.Source
      | CRMProspectFormField.Created
      | CRMProspectFormField.LastUpdated
      | CRMProspectFormField.Currency
      | CRMProspectFormField.TaxZone
    >,
    {
      [CRMProspectFormField.Owner]: IXtAutocompleteOption | null
      [CRMProspectFormField.AssignedUser]: IXtAutocompleteOption | null
      [CRMProspectFormField.LastTouched]: Date | null
      [CRMProspectFormField.AssignedDate]: Date | null
      [CRMProspectFormField.Site]: IXtAutocompleteOption | null
      [CRMProspectFormField.SalesRep]: IXtAutocompleteOption | null
      [CRMProspectFormField.Source]: IXtAutocompleteOption | null
      [CRMProspectFormField.Created]: Date | null
      [CRMProspectFormField.LastUpdated]: Date | null
      [CRMProspectFormField.Currency]: IXtAutocompleteOption | null
      [CRMProspectFormField.TaxZone]: IXtAutocompleteOption | null
    }
  > {}
