import { IAttachedDocument, IAttachedDocumentWithFile, UsedOnValue } from 'documents/documents.types'

// TODO: maybe unify with IXtCommentsPaginationParams? or remove this TODO
export interface IXtAttachedDocumentsPaginationParams {
  canLoadMore(): boolean
  loadMore(): Promise<void>
  total: number
}

export type AttachedDocumentCallback = (attachedDocument: IAttachedDocument) => Promise<void>

export interface IAttachedDocumentsDescription {
  documents: IAttachedDocument[]
  onDocumentCreate: (attachedDocument: IAttachedDocumentWithFile) => Promise<boolean>
  onDocumentDelete: AttachedDocumentCallback
  loadMore(): Promise<void>
  canLoadMore(): boolean

  isViewMode: boolean
  usedOnFilter: UsedOnValue
  isMobile?: boolean
}

export interface IAttachedDocumentsState {
  editedDocument: IAttachedDocument | null
  isDialogOpen: boolean
  isLoading: boolean
  newFile?: File | null
  previewBlobUrl?: string | null
}

export enum DocumentDialogFormField {
  Notes = 'notes',
  Name = 'name',
}
export interface IDocumentDialogFormState {
  [DocumentDialogFormField.Notes]: string
  [DocumentDialogFormField.Name]: string
}
