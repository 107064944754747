import * as Yup from 'yup'
import { SalesOrderShippingAndSalesFormField } from './sales-order-shipping-and-sales.types'
import { minDate, validationMessage } from '../../../../common/validation/validation'

export const salesOrderShippingAndSalesValidationSchema = Yup.object().shape({
  [SalesOrderShippingAndSalesFormField.SaleType]: Yup.object().nullable().required(validationMessage.isRequired),
  [SalesOrderShippingAndSalesFormField.ShippingForm]: Yup.object().nullable().required(validationMessage.isRequired),
  [SalesOrderShippingAndSalesFormField.Terms]: Yup.object().nullable().required(validationMessage.isRequired),
  [SalesOrderShippingAndSalesFormField.ShippingCharges]: Yup.object().nullable().required(validationMessage.isRequired),
  [SalesOrderShippingAndSalesFormField.SalesRep]: Yup.object().nullable().required(validationMessage.isRequired),
  [SalesOrderShippingAndSalesFormField.ShippingZone]: Yup.object().nullable().required(validationMessage.isRequired),
  [SalesOrderShippingAndSalesFormField.TaxZone]: Yup.object().nullable().required(validationMessage.isRequired),
  [SalesOrderShippingAndSalesFormField.PackDate]: Yup.date()
    .notRequired()
    .nullable()
    .typeError(validationMessage.invalidDate)
    .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
})
