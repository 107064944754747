import React, { FC, useState } from 'react'
import { Paper, Tab, Tabs, TextareaAutosize } from '@material-ui/core'
import { IBomItemTabs } from './BomItemTabs.types'
import { cls } from '../../../common/utils'
import styles from './BomItemTabs.module.scss'

import './BomItemTabs.scss'
import { BomItemFormField, FieldName } from '../BomItem.types'
import { XtComments } from '../../../comments/comments/comments'

const appBarTabs = [FieldName.Notes, FieldName.Reference, FieldName.Comments]

// TODO use XtTabs ?
export const BomItemTabs: FC<IBomItemTabs> = React.memo(({ disabled, value, onChange, className, commentsState }) => {
  const [valueTab, setValueTab] = useState(FieldName.Notes)
  const handleChange: (e: React.ChangeEvent<{}>, value: number) => void = (_, index) => {
    setValueTab(appBarTabs[index])
  }

  return (
    <div className={cls('muiTabsContent', styles.bomItemTabs, className)}>
      <Paper className="muiTabsPaper" square>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          value={appBarTabs.findIndex((tab) => tab === valueTab)}
          onChange={handleChange}
        >
          {appBarTabs.map((label) => (
            <Tab fullWidth key={label} label={label} />
          ))}
        </Tabs>
      </Paper>
      <div className={styles.tabsContent}>
        <TextareaAutosize
          hidden={valueTab !== FieldName.Notes}
          defaultValue={value[BomItemFormField.Notes]}
          className={styles.textAreaTabs}
          placeholder="Notes"
          onBlur={({ target }) => onChange(BomItemFormField.Notes, target.value)}
          disabled={disabled}
        />
        <TextareaAutosize
          hidden={valueTab !== FieldName.Reference}
          defaultValue={value[BomItemFormField.Reference]}
          className={styles.textAreaTabs}
          placeholder="Reference"
          onBlur={({ target }) => onChange(BomItemFormField.Reference, target.value)}
          disabled={disabled}
        />
        <XtComments
          creationEnabled={commentsState.creationEnabled}
          username={commentsState.username}
          canLoadMore={commentsState.canLoadMore}
          loadMore={commentsState.loadMore}
          total={commentsState.total}
          onAdd={commentsState.saveComment}
          onUpdate={commentsState.updateComment}
          comments={commentsState.comments}
          disabled={disabled}
          hidden={valueTab !== FieldName.Comments}
        />
      </div>
    </div>
  )
})
