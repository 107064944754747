import { IObjectWithId, Nullable } from 'common/common.types'
import { Overwrite } from 'utility-types'
import { IRoutingItem, IRouting, RoutingItemMode } from '../Routing.types'

export interface IRoutingDetailsState {
  routing: Nullable<IRouting>
  routingItems: IRoutingItem[]
  routingLoading: boolean
}
export interface IRoutingState {
  routing: Nullable<IRouting>
  initializing: boolean
}
export interface IRoutingItemDialogState {
  open: boolean
  routingItem: IRoutingItem | null
  mode: RoutingItemMode
}

export interface IItemDeletionState {
  itemId: number | null
  confirmationOpen: boolean
}
export type RoutingDetailsFormData = Overwrite<IRouting, { revision_date: string | Date }>

export enum RoutingDetailsAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  Expire = 'Expire',
}

export interface IRoutingDetailsTableItem extends IObjectWithId, IRoutingItem {
  description: string
  id: number
}
