import React, { FC, useState } from 'react'

import { Paper, Tab, Tabs } from '@material-ui/core'
import { cls } from 'common/utils'
import { IXtTabs, IXtTab } from './xt-tabs.types'
import styles from './xt-tabs.module.scss'

const renderTabsPanel = ({ name, markAsInvalid = false }: IXtTab, mapKey: number) => (
  <Tab
    classes={{ root: markAsInvalid ? styles.MuiTabRootInvalid : '', wrapper: styles.muiTabWrapper }}
    fullWidth
    key={`${mapKey}${name}`}
    label={name}
  />
)

export const XtTabs: FC<IXtTabs> = ({ tabs, className }) => {
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleChange = (_: React.ChangeEvent<{}>, value: number) => setTabIndex(value)

  return (
    <div className={cls('muiTabsContent', styles.xTTabs)}>
      <Paper className="muiTabsPaper" classes={{ root: styles.muiPaperRoot }} square>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{ root: styles.muiTabsRoot }}
          value={tabIndex}
          onChange={handleChange}
          className={className}
        >
          {tabs.map(renderTabsPanel)}
        </Tabs>
      </Paper>
      <div className={styles.xTTabsContent}>
        {tabs.map(({ template }, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <div key={`XtTab-${index}`} hidden={tabIndex !== index}>
            {template}
          </div>
        ))}
      </div>
    </div>
  )
}
