import * as Yup from 'yup'
import { maxNumber, validationMessage } from '../../../../common/validation/validation'
import { LineItemFormField } from './sales-order-line-item-details.types'

export const validationSchema = Yup.object().shape({
  [LineItemFormField.Item]: Yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [LineItemFormField.SubstituteFor]: Yup.boolean(),
  [LineItemFormField.Substitute]: Yup.object().when(LineItemFormField.SubstituteFor, {
    is: true,
    then: Yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
    otherwise: Yup.object().nullable(),
  }),
  [LineItemFormField.SellingUom]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [LineItemFormField.QtyOrdered]: Yup.number()
    .typeError(validationMessage.invalidNumber)
    .integer()
    .required(validationMessage.isRequired)
    .moreThan(0, validationMessage.min(0))
    .lessThan(maxNumber, validationMessage.max(maxNumber)),
  [LineItemFormField.NetUnitPrice]: Yup.number()
    .typeError(validationMessage.invalidNumber)
    .required(validationMessage.isRequired)
    .min(0, validationMessage.min(0))
    .lessThan(maxNumber, validationMessage.max(maxNumber)),
  [LineItemFormField.CustomerPriceDiscount]: Yup.number()
    .typeError(validationMessage.invalidNumber)
    .required(validationMessage.isRequired)
    .max(100, validationMessage.max(100)),
  [LineItemFormField.Site]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [LineItemFormField.ScheduledDate]: Yup.date().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [LineItemFormField.PromiseDate]: Yup.date().typeError(validationMessage.invalidDate).nullable(),
})
