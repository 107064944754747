import { ISupplyTableValue } from './sales-order-line-item-supply.types'
import { IXtTableColumn } from '../../../../../components/table/table-head/table-head.types'
import { convertNumber } from './sales-order-line-item-supply.utils'

export const salesOrderLineItemSupplyColumns: IXtTableColumn<ISupplyTableValue>[] = [
  {
    id: 'id',
    field: 'id', // TODO use the correct one
    headerName: '#',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'total_allocations',
    field: 'total_allocations',
    headerName: 'Total Allocated',
    flex: '1 0 160px',
    width: 160,
    converter: convertNumber,
  },
  {
    id: 'on_order',
    field: 'on_order',
    headerName: 'On Order',
    flex: '1 0 160px',
    width: 160,
    converter: convertNumber,
  },
  {
    id: 'available_qoh',
    field: 'available_qoh',
    headerName: 'Available QOH',
    flex: '1 0 160px',
    width: 160,
    converter: convertNumber,
  },
  {
    id: 'total_availability',
    field: 'total_availability',
    headerName: 'Availability',
    flex: '1 0 160px',
    width: 160,
    converter: convertNumber,
  },
]
