import * as React from 'react'
import { MenuItem } from '@material-ui/core'
import { IShipment } from 'shipments/shipments.types'
import { dateToServerFormat } from 'common/utils/date.utils'
import { FilterFieldName } from 'customers/customers.types'
import {
  CustomerData,
  CustomerOption,
  ISalesOrderMainForm,
  SalesOrderMainField,
  SalesOrderMainInput,
  ShipmentOption,
} from './sales-order-main.types'
import { IXtAutocompleteOption } from '../../../components/xtAutocomplete/XtAutocomplete.types'
import * as styles from './sales-order-main.module.scss'
import { IPaginationData } from '../../../common/common.types'
import { CustomersService } from '../../../customers/customers.service'
import { ShipmentsService } from '../../../shipments/shipments.service'
import { HoldTypeField } from '../../sales-orders.types'

export function convertToCustomerOption({ customer_name, customer_number, ...other }: CustomerData): CustomerOption {
  return {
    id: customer_number,
    label: customer_number,
    customer_number,
    customer_name,
    ...other,
  }
}

export function convertMainInputToFormData(data: SalesOrderMainInput | null): ISalesOrderMainForm {
  return {
    [SalesOrderMainField.OrderNumber]: data?.order_number ?? '',
    [SalesOrderMainField.OrderDate]: data?.order_date ? new Date(data.order_date) : new Date(),
    [SalesOrderMainField.HoldType]: data?.hold_type ?? HoldTypeField.None,
    [SalesOrderMainField.CustomerPONumber]: data?.customer_po_number ?? '',
    [SalesOrderMainField.CustomerName]: data?.customer_name ?? '',
    [SalesOrderMainField.ShipToName]: data?.ship_to_name ?? '',
    [SalesOrderMainField.Customer]: data?.customer ? convertToCustomerOption(data.customer) : null,
    [SalesOrderMainField.ShipTo]: data?.ship_to_option ?? null,
    [SalesOrderMainField.ShipVia]: data?.ship_via ? { id: data.ship_via, label: data.ship_via } : null,
    [SalesOrderMainField.Site]: data?.site ? { id: data.site, label: data.site } : null,
  }
}

export function convertFormDataToMainInput(data: ISalesOrderMainForm): SalesOrderMainInput {
  return {
    order_number: data.order_number ?? '',
    order_date: dateToServerFormat(data.order_date),
    hold_type: data.hold_type,
    customer_name: data.customer_name ?? '',
    customer: data.customer ?? null,
    customer_po_number: data.customer_po_number ?? '',
    ship_via: data.ship_via?.id ?? '',
    ship_to_name: data.ship_to_name ?? '',
    site: data.site?.id ?? null,
    ship_to_option: data.ship_to_option,
  }
}

export function convertShipmentToOption(shipment: IShipment): ShipmentOption {
  const { address1, address2, address3, city, state, postalcode } = shipment.shipto_address ?? {}

  return {
    ...shipment,
    id: shipment.shipto_number,
    label: shipment.name,
    addressLine1: [address1, address2, address3].filter(Boolean).join(', '),
    addressLine2: [city, state, postalcode].filter(Boolean).join(', '),
    name: shipment.name,
    shipto_number: shipment.shipto_number,
  }
}

export function renderOption(option: IXtAutocompleteOption): React.ReactNode {
  return (
    <div className={styles.autocompleteOptionContainer}>
      <div className={styles.autocompleteLabel} title={option?.id?.toString()}>
        {option?.id}
      </div>
      <div className={styles.autocompleteLabel} title={option?.label}>
        {option?.label}
      </div>
    </div>
  )
}

export function renderCustomerOption(option: CustomerOption): React.ReactNode {
  return (
    <div className={styles.autocompleteOptionContainer}>
      <div className={styles.autocompleteLabel} title={option?.customer_number?.toString()}>
        {option?.customer_number}
      </div>
      <div className={styles.autocompleteLabel} title={option?.customer_name}>
        {option?.customer_name}
      </div>
    </div>
  )
}

export function renderShipmentOption(option: ShipmentOption | null): JSX.Element {
  return (
    <div className={styles.autocompleteOptionContainer}>
      <div className={styles.autocompleteLabel} title={option?.shipto_number}>
        {option?.shipto_number}
      </div>
      <div className={styles.autocompleteLabel} title={option?.name}>
        {option?.name}
      </div>
      <div className={styles.autocompleteLabel} title={option?.addressLine1}>
        {option?.addressLine1}
      </div>
      <div className={styles.autocompleteLabel} title={option?.addressLine2}>
        {option?.addressLine2}
      </div>
    </div>
  )
}

export async function loadCustomers(page: number, limit: number): Promise<IPaginationData<CustomerOption>> {
  const { data, total } = await CustomersService.getAll({ page, limit }, { [FilterFieldName.ShowDetail]: true })
  return {
    data: data.map(convertToCustomerOption),
    total,
  }
}

export const holdTypeOptions = Object.values(HoldTypeField).map((value: string) => (
  <MenuItem key={value} className={styles.autocompleteLabel} value={value}>
    {value}
  </MenuItem>
))

export async function loadShipments(customerId: string): Promise<ShipmentOption[]> {
  const shipmentsData = await ShipmentsService.get(customerId)
  return shipmentsData.map(convertShipmentToOption)
}
