import { IXtTableColumn } from '../../../components/table/table-head/table-head.types'
import { AddressTableItem } from './address-dialog.types'

export const addressTableColumns: IXtTableColumn<AddressTableItem>[] = [
  {
    id: 'account_name',
    field: 'account_name',
    headerName: 'Account',
    width: 160,
    flex: '2 0 160px',
  },
  {
    id: 'address1',
    field: 'address1',
    headerName: 'Line 1',
    width: 160,
    flex: '2 0 160px',
  },
  {
    id: 'address2',
    field: 'address2',
    headerName: 'Line 2',
    width: 160,
    flex: '2 0 160px',
  },
  {
    id: 'address3',
    field: 'address3',
    headerName: 'Line 3',
    width: 160,
    flex: '2 0 160px',
  },
  {
    id: 'city',
    field: 'city',
    headerName: 'City',
    width: 160,
    flex: '1 0 160px',
  },
  {
    id: 'state',
    field: 'state',
    headerName: 'State',
    width: 100,
    flex: '1 0 100px',
  },
  {
    id: 'country',
    field: 'country',
    headerName: 'Country',
    width: 160,
    flex: '1 0 160px',
  },
  {
    id: 'postalcode',
    field: 'postalcode',
    headerName: 'Postal',
    width: 100,
    flex: '1 0 100px',
  },
]
