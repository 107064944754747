import { ContactFormValues } from '../../../contacts.types'
import { IAddressAndPhoneData, IAddressAndPhoneValues, IAddressAndPhoneChangeValues } from './address-and-phone.types'
import { convertStateForRequest, CountryStates } from '../../../../common/utils/country-state.utils'

export function defineAddressData(data: IAddressAndPhoneData | null, countryStates?: CountryStates): IAddressAndPhoneValues {
  return {
    [ContactFormValues.Phones]: data?.phones ?? [],
    [ContactFormValues.Address1]: data?.address?.address1 ?? '',
    [ContactFormValues.Address2]: data?.address?.address2 ?? '',
    [ContactFormValues.Address3]: data?.address?.address3 ?? '',
    [ContactFormValues.City]: data?.address?.city ?? '',
    [ContactFormValues.Country]: countryStates?.initialCountry ?? null,
    [ContactFormValues.State]: countryStates?.initialState ?? '',
    [ContactFormValues.Postalcode]: data?.address?.postalcode ?? '',
    [ContactFormValues.Latitude]: data?.address?.latitude ?? 0,
    [ContactFormValues.Longitude]: data?.address?.longitude ?? 0,
    [ContactFormValues.Accuracy]: data?.address?.accuracy ?? 0,
  }
}
export function convertAddressAndPhonesData(data: IAddressAndPhoneValues): IAddressAndPhoneChangeValues {
  return { ...data, country: data?.country?.id ?? '', state: convertStateForRequest(data?.state) ?? '' }
}
