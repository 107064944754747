import { IContact, PhoneRole } from './contacts.types'

export function buildFullContactName(contact: IContact | null): string {
  return contact ? [contact.honorific, contact.first_name, contact.middle_name, contact.last_name].filter(Boolean).join(' ') || 'N/A' : ''
}

export function retrievePhone(contact: IContact | undefined | null, phoneRole: PhoneRole): string {
  if (!contact || !Array.isArray(contact?.phones)) {
    return ''
  }
  const phone = contact.phones.find(({ role }) => role === phoneRole)
  return phone?.number ?? ''
}
