import { IObjectWithId } from '../../common/common.types'
import { ICustomer } from '../customers.types'

export interface IDeletionState {
  customersId: string
  confirmationOpen: boolean
}

export enum CustomersAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'view',
}

export interface ICustomersTableItem
  extends IObjectWithId,
    Pick<ICustomer, 'customer_number' | 'customer_name' | 'customer_type' | 'credit_status'> {
  correspond_contact_first: string
  correspond_contact_last: string
  correspond_contact_job_title: string
  correspond_contact_voice: string
  correspond_contact_email: string
  correspond_contact_address1: string
  correspond_contact_city: string
  correspond_contact_state: string
  correspond_contact_country: string
  correspond_contact_postalcode: string
  active: string
}
