export const validationMessage = {
  isRequired: 'Required.',
  invalidNumber: 'Invalid number.',
  invalidDate: 'Invalid date format.',
  invalidEmail: ' Please enter a valid email address',
  minimumDate: '01/01/1900',
  min: (length: number, scale: number = 0) => `Should be more than ${length.toFixed(scale)}.`,
  max: (length: number, scale: number = 0) => `Should be less than ${length.toFixed(scale)}.`,
  minDate: (min: string) => `Should be later than ${min}.`,
  maxDate: (max: string) => `Should be earlier than ${max}.`,
}

export const maxNumber = 10 ** 12
export const minDate = '1900-01-01'
